<template>
  <f7-popup
    tablet-fullscreen
    class="bg-color-lightgray"
    v-model:opened="isOpened"
    @popup:closed="onHide"
  >
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="position-absolute"
      key="1"
    ></f7-progressbar>
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          <i class="icon-back size-20"></i>
        </f7-link>
      </f7-nav-left>
 
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link popup-close href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link popup-close icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link popup-close href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link popup-close href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
        >{{
          userData.FirstName + " " + userData.SubName
        }}</f7-actions-label
      >
    </f7-actions-group>

    <f7-actions-group class="row customer-menu padding-top no-gap">
      <f7-actions-button class="col-100" @click="setUserState">
        <div
          :class="`actions-icon  bg-color-${
            userData.State === 0 ? 'green' : 'red'
          }`"
        >
          <i
            :class="`icon-${
              userData.State === 0 ? 'activation' : 'deactivation'
            } size-20 text-color-white display-flex`"
          ></i>
        </div>
        <span>{{ userData.State === 0 ? "Enable" : "Disable" }}</span>
      </f7-actions-button>

      <f7-actions-button
        class="col-100 padding-top-half"
        @click="resetPassword"
      >
        <div class="actions-icon bg-color-blue">
          <i class="icon-refresh size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("ResetPassword") }}</span>
      </f7-actions-button>

      <f7-actions-button
        class="col-100 padding-top-half"
        @click="openSummaryUserInfo"
      >
        <div class="actions-icon bg-color-green">
          <i class="icon-reports size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("Summary User Info") }}</span>
      </f7-actions-button>
    </f7-actions-group>
  </f7-popup>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { QTAPI } from "../../../services/qt-api";

export default {
  name: "user-menu",

  props: {
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isOpened: { type: Boolean, default: false },
  },

  emits: ["update:isOpened"],

  data() {
    return {
      isLoading: false,
      CurrentUserState: "",
    };
  },

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
  },

  watch: {
    userData(newVal) {
      this.CurrentUserState = newVal.State === 0 ? "Enable" : "Disable";
    },
  },

  methods: {
    async resetPassword() {
      

      let data = {
        Code: this.userData.Code,
      };
      this.isLoading = true;
      let response = await QTAPI.UserResetPassword(data);
      
      this.isLoading = false;

      if (!response) return;
      f7.popup.close();
      f7.views.main.router.refreshPage();
       
      f7.dialog.alert(
          'Password reset. New password is 123456',
          "M2M Data Controller"
        );
    },
    async setUserState() {
      let data = {
        Codes: this.userData.Code,
        State: this.userData.State === 1 ? "Disable" : "Enable",
      };
      this.isLoading = true;
      let response = await QTAPI.setUserState(data);
      if (response?.isCanceled) return;
      this.isLoading = false;

      if (!response) return;


      f7.popup.close();
      f7.views.main.router.refreshPage();
       
      f7.notification
        .create({
          subtitle: this.$t("Success"),
          text: "State changed successfully",
        })
        .open();
    },
    openSummaryUserInfo() {
      this.onHide();
       console.log('this.userData', this.userData)
      f7.views.main.router.navigate({
        name: "summary",
        query: { 
          orgCode: this.userData.OrganizeCode,
          token: this.userData.Token,
         },
        // props: { orgCode: this.customerData.OrganizeCode },
      });
    },
    onHide() {
      this.$emit("update:isOpened", false);
    },
  },

  mounted() {},

  beforeDestroy() {},
};
</script>