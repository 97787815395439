<template>
  <f7-page name="credits-transfer" ref="page">
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back">
      </f7-nav-left>
      <f7-nav-title sliding>{{ $t('TransferCustomer') }}</f7-nav-title>
      <f7-nav-right>
        <label :for="`submit-${submitId}`" class="link icon-only">
          <i class="icon f7-icons">checkmark_alt</i>
        </label>
      </f7-nav-right>
    </f7-navbar>
    <f7-progressbar v-if="isLoading" infinite color="green" class="progressbar-top"></f7-progressbar>

    <f7-block-title>{{ $t('TransferCustomer') }}</f7-block-title>
    <f7-block>
    </f7-block>

    <form action="" @submit.prevent="onSubmit">
      <input type="submit" :id="`submit-${submitId}`" hidden >

      <f7-list class="no-margin-top no-hairline-bottom">
        <li class="item-input custom-smart-select-wrapper">
          <a ref="AutocompleteTransferTo" class="item-link item-content" href="#" id="autocomplete-standalone-ajax">
            <input type="hidden" :value="SelectedToCustomerCode"/>
            <span class="item-media">
              <f7-icon icon="f7-icons icon-customer-details text-color-lightgray"></f7-icon>
            </span>
            <div class="item-inner">
              <div class="item-title">{{ $t('TransferTo') }}</div>
              <div class="item-after">{{ SelectedToCustomerName }}</div>
            </div>
          </a>
        </li>
        
      </f7-list>
    </form>
  </f7-page>
</template>

<script>
import {mapGetters} from "vuex";
import {f7} from "framework7-vue";
import {QTAPI} from "../../services/qt-api";

export default {
  name: "credits-transfer",

  props: {
    f7router: Object,
    f7route: Object,
  },

  data() {
    return {
      submitId: f7.utils.id(),
      isLoading: false,

      MinSearchLength: 2,
      SelectedFromCustomerCode: this.f7route.query?.customerCode || null,
      SelectedToCustomerCode: null, //this.f7route.query?.customerCode || null,
      SelectedToCustomerName: null, // this.f7route.query?.customerName ? `${ this.f7route.query.customerName } ` : null,
      autocompleteTransferTo: null,
    }
  },

  computed: {
    ...mapGetters(['UserInfo', 'MajorToken', 'MinorToken']),
  },

  mounted() {
    let self = this

    this.autocompleteTransferTo = f7.autocomplete.create({
      openIn: 'page', //open in page
      openerEl: self.$refs.AutocompleteTransferTo, //link that opens autocomplete
      valueProperty: 'Code', //object's "value" property name
      textProperty: 'customDisplayName', //object's "text" property name
      closeOnSelect: true,
      autoFocus: true,
      limit: 50,
      preloader: true, //enable preloader
      searchbarPlaceholder: self.$t('EnterMinCharacters', {q: self.MinSearchLength}),
      source: async function (query, render) {
        let autocomplete = this;

        if (query.length < self.MinSearchLength) {
          return;
        }
        // Show Preloader
        autocomplete.preloaderShow();

        let data = {
          MajorToken: self.MajorToken,
          MinorToken: self.MinorToken,
          page: 1,
          rows: 20,
          sort: 'Name',
          order: 'asc',
          q: query,
        }
        let response = await QTAPI.GetCustomers(data)
        autocomplete.preloaderHide();
        if(!response || !response.rows){
          render([]);
          return
        }

        for (var i = 0; i < response.rows.length; i++) {
          response.rows[i].customDisplayName = `${ response.rows[i].Name } (${ response.rows[i].Email })`
        }

        render(response.rows);
      },
      on: {
        change: function (value) {
          var itemText = [],
              inputValue = [];
          for (var i = 0; i < value.length; i++) {
            itemText.push(value[i].customDisplayName);
            inputValue.push(value[i].Code);
          }
          self.SelectedToCustomerCode = inputValue.join(', ')
          self.SelectedToCustomerName = itemText.join(', ')
        },
      },
    });

  },
  beforeUnmount() {
    const self = this;
    // Destroy popup when page removed
    if (self.autocompleteTransferTo) self.autocompleteTransferTo.destroy();
  },

  methods: {
    async onSubmit(){
      if(!this.SelectedToCustomerCode){
        f7.dialog.alert( this.$t('SelectCustomerAccount'), this.$t('Error') )
        return
      }
      let data = {
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,
        CustomerCode: this.SelectedFromCustomerCode,
        AgentCode: this.SelectedToCustomerCode,
      }

      this.isLoading = true;
      let response = await QTAPI.CustomerTransfer(data)
      this.isLoading = false;
      if (!response){
        return
      }
      //this.$store.dispatch('GetCreditBalance')
      f7.dialog.alert(this.$t('CustomerTransferred', { name: this.SelectedToCustomerName }), this.$t('Success'))
      this.f7router.back()
    }
  }
}
</script>

<style scoped>

</style>