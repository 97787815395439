<template>
  <!--<f7-login-screen id="my-login-screen">
  <f7-view>-->
  <f7-page login-screen class="theme-light">
    <f7-login-screen-title class="logo padding-horizontal">
      <img :src="logo" alt />
    </f7-login-screen-title>
    <form @submit.prevent="signIn">
      <f7-list>
        <f7-list-input
          class="login-list-input"
          outline
          type="text"
          required
          validate
          name="username"
          :placeholder="$t('LoginNameEmail')"
          v-model:value="username"
        ></f7-list-input>
        <f7-list-input
          class="login-list-input"
          outline
          required
          validate
          :type="passwordType"
          name="password"
          :placeholder="$t('Password')"
          v-model:value="password"
        >
          <template #content-end>
            <div
              @click="switchVisability('passwordType')"
              class="view-pass link"
            >
              <i class="f7-icons size-22 icon-other-view-password"></i>
            </div>
          </template>
        </f7-list-input>
      </f7-list>
      <f7-list>
        <f7-list-item class="login-list-input">
          <f7-button
            type="submit"
            fill
            large
            preloader
            class="color-custom"
            :loading="isLoading"
            :class="{ disabled: isLoading }"
            >{{ $t("SignIn") }}</f7-button
          >
        </f7-list-item>
      </f7-list>
    </form>
  </f7-page>
  <!--  </f7-view>
  </f7-login-screen>-->
</template>


<script>
//import getDevicePushInfo from "../js/helpers/get-device-push-info";
import { f7, f7ready } from "framework7-vue";
import { HideKeyboard, CustomerTypes, getDevicePushInfo } from "../js/helpers";
import { QTAPI } from "../services/qt-api";
import { mapGetters } from "vuex";

export default {
  name: "login-screen",

  data() {
    return {
      isLoading: false,
      passwordType: "password",
      logo: "static/images/logo.png",
      username: null,
      password: null,
      isPermissionsLoading: false,
    };
  },
  mounted() {
    //getDevicePushInfo();
    f7ready(() => {
      //
      //  var vConsole = new VConsole();
      if (localStorage.ACCOUNT && localStorage.PASSWORD) {
        this.username = localStorage.ACCOUNT;
        this.password = localStorage.PASSWORD;
        this.signIn();
      }

   
    });
  },
  computed: {
    ...mapGetters(["RootToken", "UserInfo", "ERROR"]),
  },
  methods: {
    
    switchVisability(name) {
      this[name] = this[name] === "password" ? "text" : "password";
    },
    async signIn() {
      getDevicePushInfo();
      const formData = {
        account: this.username,
        password: this.password,

        AppKey: !localStorage.PUSH_APP_KEY ? "123" : localStorage.PUSH_APP_KEY,
        MobileToken: !localStorage.PUSH_MOBILE_TOKEN
          ? "123"
          : localStorage.PUSH_MOBILE_TOKEN,
        DeviceToken: !localStorage.PUSH_DEVICE_TOKEN
          ? "123"
          : localStorage.PUSH_DEVICE_TOKEN,
        DeviceType: !localStorage.DEVICE_TYPE
          ? "webapp"
          : localStorage.DEVICE_TYPE,
      };

      HideKeyboard();
      this.isLoading = true;
      f7.progressbar.show("green");
      const result = await QTAPI.Login(formData);
      f7.progressbar.hide();
      this.isLoading = false;
      if (!result) {
        return;
      }

 

      // console.log({
      //   result
      // });
      // if (
      //   result.UserInfo.CustomerType !== CustomerTypes.Admin &&
      //   result.UserInfo.CustomerType !== CustomerTypes.Agent
      // ) {
      //   f7.dialog.alert(this.$t("AccountTypeError"), this.$t("Error"));
      //   return;
      // }

      localStorage.ACCOUNT = formData.account;
      localStorage.PASSWORD = formData.password;

      let UserInfo = {
        FirstName: result.FirstName,
        SurName: result.SubName,
        Email: result.Email,
        CustomerName: result.Account,
        Mobile: result.Mobile,
        CountryCode: result.CountryCode,
        RoleCode: result.RoleCode,
        OrganizeCode: result.OrganizeCode,
        Token: result.Token,
        UserCode: result.Code,
      };



      try {
        await Promise.all([
         
          this.$store.commit("SET_USER_INFO", UserInfo),
          this.$store.commit("SET_MAJOR_TOKEN", result.Token),
          this.$store.commit("SET_MINOR_TOKEN", result.MinorToken),
          this.$store.commit(
            "SET_IS_ROOT",
            result.Token === "00000000-0000-0000-0000-000000000000"
          ),
          this.$store.commit("SET_AUTH_STATE", true),
          this.$store.commit("SET_ROOT_TOKEN", result.Token),
        ]);
        await this.$store.dispatch("GetSimCommands");
      } catch (error) {
        console.error("Store init error:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style >
.login-screen-content .list input {
  background-color: #fff;
}

.view-pass {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-40%);
}

.item-input-with-error-message .view-pass {
  transform: translateY(-85%);
}
</style>