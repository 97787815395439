<template>
    <div class="map-wrapper">
      <l-map
          ref="map"
          :zoom="zoom"
          :center="center"
          style="height: 100%"
          :options="{zoomControl: false, tap: false, preferCanvas: true}"
      >
        <l-control-layers position="topright"  ></l-control-layers>
        <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :subdomains="tileProvider.subdomains"
            layer-type="base"/>

        <l-marker
            v-if="markerLatLng.length"
            :lat-lng="markerLatLng"
            :icon="markerIcon"
        >
          <!--<l-icon :icon-url="iconUrl" :icon-size="[40,40]" :icon-anchor="[20, 39]" />-->

          <l-popup>
            <table cellpadding="0" cellspacing="0" border="0" class="marker-data-table">
              <tbody>
              <tr>
                <td class="marker-data-caption">{{ $t('Name') }}</td>
                <td class="marker-data-value">{{ notificationData.AssetName }}</td>
              </tr>
              <tr v-if="notificationData.alarm">
                <td class="marker-data-caption">{{ $t('AlarmName') }}</td>
                <td class="marker-data-value">{{ notificationData.alarm }}</td>
              </tr>
              <tr>
                <td class="marker-data-caption">{{ $t('IMEI') }}</td>
                <td class="marker-data-value">{{ notificationData.Imei }}</td>
              </tr>
              <tr>
                <td class="marker-data-caption">{{ $t('PositionTime') }}</td>
                <td class="marker-data-value">{{ notificationData.PositionTimeLocal ? notificationData.PositionTimeLocal : (notificationData.PositionTime) ? notificationData.PositionTime : (notificationData.CreateDateTime) ? notificationData.CreateDateTime : notificationData.time }}</td>
              </tr>
              <!--<tr>
                <td class="marker-data-caption">{{ $t('Mileage') }}</td>
                <td class="marker-data-value">{{ mapPopupData.mileage }} {{ mileageUnit }}</td>
              </tr>-->
              <tr>
                <td class="marker-data-caption">{{ $t('Speed') }}</td>
                <td class="marker-data-value">{{ notificationData.Speed }} {{ speedUnit }}</td>
              </tr>
              <tr v-if="notificationData.Direction">
                <td class="marker-data-caption">{{ $t('Direction') }}</td>
                <td class="marker-data-value">{{ directionFormat(notificationData.Direction) }}</td>
              </tr>
              <tr>
                <td class="marker-data-caption">{{ $t('GPS') }}</td>
                <td class="marker-data-value marker-address asset-address">{{ parseFloat(notificationData.Lat).toFixed(4) }}, {{ parseFloat(notificationData.Lng).toFixed(4) }}</td>
              </tr>
              <tr>
                <td class="marker-data-caption">{{ $t('Address') }}</td>
                <td class="marker-data-value marker-address asset-address">{{ customAddress ? customAddress : $t('Loading') }}</td>
              </tr>
              </tbody>
            </table>
          </l-popup>
        </l-marker>

      </l-map>
    </div>


</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LControlLayers, LPopup } from "@vue-leaflet/vue-leaflet";
import {mapGetters} from "vuex";
import { f7 } from 'framework7-vue';
import { dateFormat, directionText } from "../../../js/helpers";
import { QTAPI } from "../../../services/qt-api"

export default {
  name: "notification-asset-position",
  components: {
    LMap, LTileLayer, LMarker, LControlLayers, LPopup
  },

  props: {
    notificationData: { type: Object, default: () => { return {} } },
  },

  computed: {
    ...mapGetters(['MinorToken'])
  },

  data: function(){
    let mapCenter = L.latLng(47.41322, -1.219482)
    let markerLatLng = []
    if(this.notificationData.Lat && this.notificationData.Lng){
      mapCenter = L.latLng(this.notificationData.Lat, this.notificationData.Lng)
      markerLatLng = [this.notificationData.Lat, this.notificationData.Lng]
    }
    return {
      pageTitle: this.notificationData.CustomAlarmName,
      isLoading: false,
      isIncorrectData: false,

      zoom: 9,
      center: mapCenter,
      map: '',

      tileProviders: [
        {
          name: 'Map',
          visible: true,
          url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          //subdomains: ['mt0','mt1','mt2','mt3']
          subdomains: ['mt0','mt1','mt2','mt3']
        },
        {
          name: 'OpenStreetMap',
          visible: false,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Satelitte',
          visible: false,
          url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          subdomains: ['mt0','mt1','mt2','mt3']
        },
      ],

      markerIcon: L.icon({
        iconUrl: './static/images/map-marker.svg',
        iconSize:     [40, 40], // size of the icon
        iconAnchor:   [20, 39], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
      }),
      markerLatLng: markerLatLng,
      //mapPopupData: this.f7route.notificationData,
      mileageUnit: 'km',
      speedUnit: 'km/h',
      //assetName: f7.views.main.router.currentRoute.query.name,
      customAddress: ''
    }
  },

  async mounted() {
    if (this.notificationData.Lat && this.notificationData.Lng){
      this.getAddress({Lat: this.notificationData.Lat, Lng: this.notificationData.Lng})
    }
  },

  methods: {
    directionFormat(direction){
      return directionText(direction) + `(${direction}°)`
    },
    dateFormat(...options){
      return dateFormat(...options)
    },

    async getAddress(coords){
      const response = await QTAPI.GetAddressByCoords({
        latitude: coords.Lat,
        longitude: coords.Lng
      })
      if (!response) {
        return
      }
      this.customAddress = response.display_name
    },
  }
}
</script>

<style scoped lang="scss">

</style>