<template>
  <f7-page
 
    class="bg-color-lightgray"
 
 
  >
    <f7-navbar>
      <f7-nav-left back-link="Back"> </f7-nav-left>

    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'" href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'" href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'" href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'" href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
      >
        Watchdog Alerts
      </f7-actions-label>
    </f7-actions-group>

    <f7-list
      media-list
      no-hairlines
      no-chevron
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      form
      @submit.prevent="onSubmitCommand"
    >
      <f7-list-item class="toggle-list-item" required validate validate-on-blur>
        <span>Suspended If</span>
        <template #after>
          <f7-toggle v-model:value="isPublic" checked color="blue"></f7-toggle>
        </template>
      </f7-list-item>

      <f7-list-input
        type="text"
        class="command-menu-list"
        v-model:value="watchdogData.YYMB"
        label="SIM exceeds YY MB of data per month"
        placeholder=""
      >
        <template #media>
          <i class="icon-data size-18 text-color- text-color-green display-flex"></i>
        </template>
      </f7-list-input>

      <f7-list-input
        class="command-menu-list"
        v-model:value="watchdogData.YYSMS"
        label="SIM exceeds YY SMS per month"
        placeholder=""
      >
        <template #media>
          <i class="icon-mail size-18 text-color- text-color-orange display-flex"></i>
        </template>
      </f7-list-input>
    </f7-list>
    <div style="position: absolute; bottom: 60px; width: 100%">
      <f7-block>
        <f7-button @click="editWatchdog()" color="blue" fill class="col"
          >SAVE CHANGES</f7-button
        >
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
  import { QTAPI } from "../services/qt-api";

export default {
  name: "command-menu",

 

  data() {
    return {
      watchdogData: {},
    };
  },
 
  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
  },
  mounted() {
    this.getWatchdog();
  },
  methods: {
    async editWatchdog() {
      let response = await QTAPI.editWatchdog({
        MajorToken: this.MajorToken,
        XXMB: 0,
        YYMB: this.watchdogData.YYMB,
        XXSMS: 0,
        YYSMS: this.watchdogData.YYSMS,
        ISXX: false,
        ISYY: true,
      });

      if(response) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
         })
          .open(); 
      }
      
    },

    async getWatchdog() {
      let response = await QTAPI.getWatchdog({
        MajorToken: this.MajorToken
      });

      this.watchdogData = response 
    },
  },

  beforeDestroy() {},
};
</script>

<style>
.toggle-list-item .item-after {
  position: absolute;
  right: 0;
  top: 50%;
}

.command-menu-list .item-inner {
  margin: 0 !important;
}
</style>