<template>
  <f7-page name="sim-status" class="bg-color-lightgray">
    <!-- Top Navbar -->
    <f7-progressbar
      v-if="isLoading || isLoadingCSP || isLoadingCustomer"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-link back>
        <i class="icon-back size-20"></i>
      </f7-link>
    </f7-navbar>

    <f7-toolbar position="top" class>
      <f7-nav-title sliding>{{ $t("SimStatus") }}</f7-nav-title>
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link  v-tooltip="'SIM List'" href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link  v-tooltip="'Commands'" href="/commands/"  icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'" href="/users/"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link   v-tooltip="'Summary'" href="/summary/"  icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-card v-if="simInfo && !isNoData" class="simstatus-card">
      <f7-card-content>
        <f7-list>
          <f7-list-item title="IMSI:">
            <span>{{ simInfo.IMSI }}</span>
          </f7-list-item>
          <f7-list-item class="text-color-lightgray" title="ICCID:">
            <span>{{ simInfo.ICCID }}</span>
          </f7-list-item>
          <f7-list-item class="text-color-lightgray" title="MSISDN:">
            <span>{{ simInfo.SimMSISDN }}</span>
          </f7-list-item>
          <f7-list-item class="text-color-lightgray" title="State:">
            <span>{{ simInfo.State ? simInfo.State : $t("na") }}</span>
          </f7-list-item>
          <f7-list-item class="text-color-lightgray" title="State:">
            <span
              class="status"
              :class="getAssetStatusClass(simInfo.State)"
            ></span>
          </f7-list-item>
        </f7-list>
      </f7-card-content>
    </f7-card>
    <f7-block class="no-margin-top margin-bottom-half">
      <div
        v-if="simInfo && !isNoData"
        class="row list grid-match no-margin-top margin-bottom-half"
        style="align-items: stretch"
      >
        <f7-card class="no-margin col-33">
          <f7-card-content>
            <div
              class="display-flex flex-direction-column justify-content-center align-items-center"
            >
              <div class="actions-icon bg-color-green">
                <i class="icon-data size-20 text-color-white display-flex"></i>
              </div>
              <span class="text-align-center size-14 margin-top-half"
                >{{ TotalData }} KB</span
              >
            </div>
          </f7-card-content>
        </f7-card>

        <f7-card class="no-margin col-33">
          <f7-card-content>
            <div
              class="display-flex flex-direction-column justify-content-center align-items-center"
            >
              <div class="actions-icon bg-color-lilac">
                <i class="icon-time size-20 text-color-white display-flex"></i>
              </div>
             
              <span
                class="text-align-center size-14 line-height-1-2 margin-top-half"
                >{{
                  simInfo.DataUpdateTime && simInfo.DataUpdateTime !== "0001-01-01T00:00:00"
                    ? dateFormat(simInfo.DataUpdateTime + "+0000", "datetime")
                    : $t("na")
                }}</span
              >
         
            </div>
          </f7-card-content>
        </f7-card>

        <f7-card class="no-margin col-33">
          <f7-card-content>
            <div
              class="display-flex flex-direction-column justify-content-center align-items-center"
            >
              <div class="actions-icon bg-color-orange">
                <i class="icon-mail size-20 text-color-white display-flex"></i>
              </div>
              <span class="text-align-center size-14 margin-top-half">{{
                 TotalSMS
              }}</span>
            </div>
          </f7-card-content>
        </f7-card>
      </div>
    </f7-block>

    <f7-list
      class="margin-top-half media-center "
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      v-if="simInfo && !isNoData"
    >
      <f7-list-item link="#" @click="openSelectCustomer()" header="Customer" :title="simCustomer.Name" >
        <template #media>
          <i class="icon-customer size-20 text-color-yellow display-flex"></i>
        </template>
      </f7-list-item>
      <f7-list-item link="#" @click="openSelectState()" header="State" :title="simInfo.State"  >
        <template #media>
          <i class="icon-status size-20 text-color-red display-flex"></i>
        </template>
      </f7-list-item>
      <f7-list-item link="#" @click="openSelectCSP()"  header="CSP" :title="simCSP ?  simCSP.Name : 'Select CSP'">
        <template #media>
          <i class="icon-profile size-20 text-color-customgray display-flex"></i>
        </template>
      </f7-list-item>
    </f7-list>
  <f7-block v-if="simInfo && !isNoData">
    <f7-button @click="openLocation()" color="black" fill class="col">SHOW LOCATION</f7-button>
  </f7-block>
  <f7-block v-if="simInfo && !isNoData">
    <f7-button  @click="forceReconnect" color="blue" fill class="col">FORCE RECONNECT</f7-button>
  </f7-block>
     

    <f7-block v-if="!simInfo && isNoData">
      <p>{{ $t("NoDataFound") }}</p>
    </f7-block>

    <f7-list v-else-if="!simInfo" no-hairlines>
      <f7-list-item
        header="header"
        title="simInfo.IMSI"
        class="skeleton-text skeleton-effect-blink"
        v-for="n in 4"
        :key="n"
      >
        <template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 100%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>
 

    <select-customer
      v-model:isOpened="isSelectCustomerOpened"
      :simData="simInfo"
      :customerList="CustomerList"
    />  
    <select-state
      v-model:isOpened="isSelectStateOpened"
      :simData="simInfo"
     />  
    <select-csp
      v-model:isOpened="isSelectCSPOpened"
      :simData="simInfo"
      :CSPList="CSPList"
    />  
  </f7-page>
</template>

<script>
import { QTAPI } from "../../services/qt-api";
import { f7 } from "framework7-vue";
import { dateFormat } from "../../js/helpers";
import { mapGetters } from "vuex";
import SelectState from "./components/select-state"
import SelectCustomer from "./components/select-customer"
import SelectCsp from "./components/select-csp"


export default {
  name: "sim-status",

  props: {
    f7router: Object,
    f7route: Object,
  },
  components: {
    SelectState,
    SelectCustomer,
    SelectCsp
  },
  data() {
    return {
      simCSP: {},
      simCustomer: {},
      inputSubmitId: f7.utils.id(),
      isLoading: false,
      isNoData: false,
      isLoadingCSP: false,
      isLoadingCustomer: false,
      simInfo: null,
      keyCSPList: 1,
      keyCustomerList: 1,
      CSPCode: null,
      CustomerCode: null,
      CSPList: [],
      CustomerList: [],
      isSelectCustomerOpened: false,
      isSelectStateOpened: false,
      isSelectCSPOpened: false,
      TotalData: 0,
      TotalSMS: 0
    };
  },

  computed: {
    ...mapGetters(["IS_ROOT", "MajorToken", "MinorToken"]),
    simIconColorClass() {
      let ret = "text-color-lightgray";
      switch (this.simInfo.State) {
        case "Productive":
        case "TestProductive":
          ret = "text-color-green";
          break;
        case "Suspended":
          ret = "text-color-orange";
          break;
        case "Deleted":
          ret = "text-color-red";
          break;
      }
      return ret;
    },
  },

  async mounted() {
    await this.getSimStatus();
    await this.getCSP();
    await this.getCustomer();


    

   },

  methods: {
    async forceReconnect() {
      this.isLoading = true;

      let result = await QTAPI.ForceReconnect({
        IMSIs: [this.simInfo.IMSI],
      });

      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: "Session refreshed",
          })
          .open();  
      }

    },
    openSelectCSP() {
      
       this.isSelectCSPOpened = true;
    },
    openSelectState() {
       this.isSelectStateOpened = true;
    },
    openSelectCustomer() {
      this.isSelectCustomerOpened = true;
    },
    async onSubmit() {
      this.isLoadingSave = true;

      if (this.CustomerCode) {
        let data = {
          IMSIs: [this.simInfo.IMSI],
          toorganizecode: this.CustomerCode,
          Number: "",
          Remark: "",
        };
        let response = await QTAPI.SetCustomer(data);
        if (!response) {
          f7.dialog.alert(this.$t("CustomerChangingError"), this.t("Error"));
        }
      }
      if (this.CSPCode) {
        let data = {
          IMSIs: [this.simInfo.IMSI],
          ServiceProfileCode: this.CSPCode,
        };
        let response = await QTAPI.SetCSP(data);
        if (!response) {
          f7.dialog.alert(this.$t("CSPChangingError"), this.t("Error"));
        }
      }

      this.isLoadingSave = false;
      this.$store.commit("SIM_UPDATED");
      f7.notification
        .create({
          subtitle: this.$t("Success"),
          text: this.$t("DataUpdated"),
        })
        .open();
      this.f7router.back();
    },
    dateFormat(...options) {
      return dateFormat(...options);
    },
    getAssetStatusClass(rag) {
      let ragColors = {
        Deleted: "status-red",
        Deleting: "status-red",
        OnStock: "status-blue",
        EuiccControl: "status-gray",
        Suspended: "status-orange",
        Productive: "status-green",
        TestProductive: "status-aqua",
        Ordered: "status-aqua",
      };
      return ragColors[rag] ? ragColors[rag] : "status-gray";
    },
    isChangeStateOptionDisabled(action) {
      let ret = false;
      if (this.simInfo.State === "Suspended") {
        if (action === "suspend") ret = true;
      } else if (
        this.simInfo.State === "Productive" ||
        this.simInfo.State === "TestProductive" ||
        this.simInfo.State === "Test" ||
        this.simInfo.State === "Deleted"
      ) {
        if (action === "resume") ret = true;
      } else if (this.simInfo.State === "OnStock") {
        if (action === "resume") ret = true;
        if (action === "suspend") ret = true;
        if (action === "testProductive") ret = true;
        if (action === "terminate") ret = true;
      }
      return ret;
    },
    async getCustomer() {
      this.isLoadingCustomer = true;
      let list = [];
      let page = 1;
      let isLast = false;
      let requestLimit = 100;
      let pageSize = 100;

      while (!isLast) {
        let result = await QTAPI.GetSimOwners({
          Page: page,
          Rows: pageSize,
          IncludeSelf: true,
          Order: "ASC",
          Sort: "Name",
        });
        let owners = result.rows;
        if (!owners || !Array.isArray(owners)) {
          isLast = true;
          break;
        }
        page++;
        if (owners.length) {
          list = [...list, ...owners];
        }
        if (
          !owners.length ||
          owners.length < pageSize ||
          page === requestLimit
        ) {
          isLast = true;
        }
      }

      this.isLoadingCustomer = false;
      this.isNoData = !list.length;

      this.CustomerList = list;


      this.simCustomer  = this.CustomerList.find(item => item.Code === this.simInfo.OrgCode)

      
      this.keyCustomerList++;
    },
    async getCSP() {
      this.isLoadingCSP = true;
      let csp = await QTAPI.GetCSP({});
      this.isLoadingCSP = false;
      if (!csp) {
        return;
      }
      this.CSPList = csp.Data;


      console.log('this.CSPList', this.CSPList)
      console.log('this.CSPCode', this.CSPCode)
      this.simCSP = this.CSPList.find(item => item.Code === this.simInfo?.ServiceProfileCode)

      
      this.keyCSPList++;
    },
    async getSimStatus() {
      if (!this.f7route.query.imsi) {
        this.isNoData = true;
        f7.dialog.alert(this.$t("MissingIMSI", this.$t("Error")));
        return;
      }

      this.isLoading = true;
      let data = {
        IMSIs: [this.f7route.query.imsi],
      };
      let response = await QTAPI.GetSimStatus(data);
      this.isLoading = false;
      
      if (!response || !response.length) {
        this.isNoData = true;
        f7.dialog.alert(
          this.$t("IMSINotFound", { imsi: data.IMSIs }),
          this.$t("Error")
        );
        return;
      }
     
      this.simInfo = response[0];

      this.TotalData =  this.simInfo.DataDay ? (this.simInfo.DataDay/1024).toFixed(3) : 0
      this.TotalSMS = this.simInfo.SMSMODay + this.simInfo?.SMSMTDay
      this.CSPCode = this.simInfo.ServiceProfileCode;
      this.CustomerCode = this.simInfo.OrgCode;
      // console.log('this.CSPCode', this.CSPCode)
      // console.log('this.simInfo', this.simInfo)
    },
    openLocation() {
       f7.views.main.router.navigate({
        name: "sim-position",
        query: { 
          imsi: this.simInfo.IMSI
        },
      });
    },
    async changeSimState(action) {
      if (!action) {
        return;
      }

      let data = {
        IMSIs: this.f7route.query.imsi,
      };

      let response = null;
      this.isLoading = true;
      if (action === "resume") {
        response = await QTAPI.SimSetResume(data);
      }
      if (action === "suspend") {
        response = await QTAPI.SimSetSuspend(data);
      }
      if (action === "terminate") {
        response = await QTAPI.SimSetTerminate(data);
      }
      this.isLoading = false;
      if (!response) {
        return;
      }
      f7.dialog.alert(this.$t("SimStatusChanged"), this.$t("Success"));
      //setTimeout(() => {
        this.getSimStatus();
     // }, 2000);
    },
  },
};
</script>

<style>
.simple-list li:after,
.links-list a:after,
.list .item-inner:after {
  display: none;
}
</style>