<template>
  <f7-page
    ref="page"
    name="dashboard"
    class="bg-color-lightgray sim-list"

  >
    <!--     infinite
    :infinite-distance="100"
    :infinite-preloader="isLoading"
    @infinite="onLoadMore"<f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="position-absolute"
      key="1"
    ></f7-progressbar> -->
    <f7-navbar :sliding="false">
  
      <f7-nav-left>
        <f7-link href="/panel-left/" icon-only>
          <f7-icon icon="f7-icons icon-menu"></f7-icon>
        </f7-link>
      </f7-nav-left>

   
    </f7-navbar>
   
    <f7-toolbar position="top" class>
       <f7-nav-title sliding
      >{{ $t("Dashboard") }}
      </f7-nav-title
    > 
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link tab-link-active icon-only tab-link="#tab-1">
        <i class="icon-reports size-20"></i>
      </f7-link>
      <f7-link  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-20"></i>
      </f7-link>
      <f7-link  href="/commands/"  icon-only tab-link="#tab-3">
        <i class="icon-commands size-20"></i>
      </f7-link>
      <f7-link   href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-20"></i>
      </f7-link>
      <f7-link href="/summary/"   icon-only tab-link="#tab-5">
        <i class="icon-data size-20"></i>
      </f7-link>
    
    </f7-toolbar>
       
  
      <f7-block>
        <p>This section is under development</p>
      </f7-block>

    

 
  </f7-page>
</template>

<script>
 
import { mapGetters } from "vuex";
 

export default {
  name: "reports",

  props: {
    f7router: Object,
    f7route: Object,
  },

  components: {
 
  },
  data() {
    return {
 
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
      "UserInfo",
      "AssetUpdateKey",

      "NewNotificationsCount",
    ]),
  },

  watch: {
    
  },

  mounted() {
  
  },

  methods: {
   
  },

  beforeDestroy() {},
};
</script>

 
 