import store from '../store'
import axios from "axios";
import { isObjEmpty, SortArrayByObjProps } from "../js/helpers"
const qs = require('qs');

String.prototype.format = function (e) { var t = this; if (arguments.length > 0) if (arguments.length == 1 && typeof e == "object") { for (var n in e) if (e[n] != undefined) { var r = new RegExp("({" + n + "})", "g"); t = t.replace(r, e[n]) } } else for (var i = 0; i < arguments.length; i++) if (arguments[i] != undefined) { var r = new RegExp("({)" + i + "(})", "g"); t = t.replace(r, arguments[i]) } return t };
String.prototype.subStrEx = function (e) { return this.length + 3 > e ? this.substr(0, e) + "..." : this };

//const DOMAIN1 = "https://api.m2mglobaltech.com/";
const DOMAIN0 = "https://testapi.quiktrak.co/";
const DOMAIN1 = "https://newapi.quiktrak.co/";
const DOMAIN3 = "https://test4.m2mdata.co/";
const DOMAIN4 = "https://nominatim.sinopacific.com.ua/";
const DOMAIN5 = "https://upload.quiktrak.co/"
const DOMAIN6 = "https://activation.gps-secure.co/"
const DOMAIN7 = "https://app.quikprotect.co/"
const DOMAIN8 = "https://test4.m2mdata.co/"


 
 

 
 
 

export const ApiMethods = {
	DOMAIN1,



	LOGIN: DOMAIN3 + 'service/User/Auth',
	//LOGIN: DOMAIN1 + 'Quikloc8/V1/user/Auth3',
	//AUTH2: DOMAIN1 + 'Quikloc8/V1/user/Auth2',
	VIEW_POSITION: DOMAIN1 + 'QuikTrak/V1/Device/GetPosInfosDB',
	//AUTH1: DOMAIN1 + 'Quikloc8/v1/user/GetLocateDetails',

	DASHBOARD_M2M: DOMAIN3 + 'JT/Report/Bashboard',
	GET_TOTAL_SMS: DOMAIN3 + 'JT/SMS/GetLast30DaysSMS',
	GET_SIM_INFO_TABLE: DOMAIN3 + 'JT/Sim/Query',

	DAILY_USAGE: DOMAIN3 + 'JT/Report/DailyUsage',

	GET_WATCHDOG: DOMAIN1 + 'QuikData/V1/Watchdog/GetWatchdog',
	EDIT_WATCHDOG: DOMAIN1 + 'QuikData/V1/Watchdog/Edit',

	GET_SMS_TEMPLATE: DOMAIN3 + 'Service/SMSTemplate/Query',
	EDIT_SMS_TEMPLATE: DOMAIN3 + 'Service/SMSTemplate/Edit',
	ADD_SMS_TEMPLATE: DOMAIN3 + 'Service/SMSTemplate/Add',
	REMOVE_SMS_TEMPLATE: DOMAIN3 + 'Service/SMSTemplate/Remove',


	GET_SIM_LIST: DOMAIN8 + 'JT/Sim/GetSimInfo?Token={0}',//?Token=00000000-0000-0000-0000-000000000000', //JT/SIM/GetSimInfo



	LOGOUT: DOMAIN1 + 'QuikProtect/V1/Client/Logoff',
	REFRESH_PUSH_TOKEN: DOMAIN1 + "QuikTrak/V1/User/RefreshToken",//RefreshToken", RefereshAdminControllerToken
	GET_NEW_NOTIFICATIONS: DOMAIN1 + "Quikloc8/V1/asset/Alarms",
	SET_NOTIFICATION_STATUS: DOMAIN1 + "Installer/V1/Client/Notification",

	RESET_MILEAGE: DOMAIN1 + 'Quiktrak/V2/Asset/ResetMileage',
	RESET_HISTORY: DOMAIN1 + 'Quiktrak/V2/Asset/ClearHistory',
	RESET_HOURS: DOMAIN1 + 'Quiktrak/V2/Asset/ResetLaunchHours',

	GET_REPORT: DOMAIN1 + 'Quiktrak/v1/Report/GetGpsSecureActivations2',//?MajorToken={0}&MinorToken=00000000-0000-0000-0000-000000000000&IsSub=1&Type=Activation&BeginDate={1}&EndDate={2}',//=2022-10-30

	FAKE_RENEWAL: DOMAIN1 + 'Common/V1/Activation/FakeRenewal',
	GET_WEBSITES: DOMAIN1 + 'QuikTrak/V1/Device/GetWebsitesOfAgent',
 	ACCOUNT_EDIT: DOMAIN3 + 'service/User/Edit',
	GET_CREDIT_BALANCE: DOMAIN1 + 'QuikProtect/V1/Client/Balance',
	 
	//GET_ASSET_LIST: DOMAIN1 + 'Installer/V1/Client/GetAssetList',
	GET_ASSET_LIST: DOMAIN1 + 'QuikTrak/V2/Asset/GetList',
	ASSET_EDIT: DOMAIN1 + 'QuikTrak/V2/Asset/Edit',
	ASSET_TRANSFER: DOMAIN1 + 'QuikTrak/V2/Asset/Transport2Customer',
	GET_ASSET_LOCATION: DOMAIN1 + 'Installer/V1/Client/LivePostion',
	GET_ASSET_LAST_ALARMS: DOMAIN1 + 'Installer/V1/Client/GetTop5Alarms',
	GET_ASSET_LAST10_ALARMS: DOMAIN1 + 'Installer/V1/Client/GetTop10Alarms',
	GET_ASSET_ALARM_HISTORY: DOMAIN1 + 'Installer/V1/Client/GetAlarms',
	ASSET_DEACTIVATE: DOMAIN1 + 'Common/V1/Activation/DeActivate',
	GET_SSP: DOMAIN1 + 'Common/v1/Activation/SSP',
	GET_ASSET_INFO: DOMAIN1 + 'Common/v1/Activation/GetAssetsInfo',
	SET_INPUT_CONFIG: DOMAIN1 + 'Installer/V1/Client/GPIO',
	SET_POWER_LEVELS: DOMAIN1 + 'Installer/V1/Client/PowerLevels',
	CALIBRATE_VOLTAGE: DOMAIN1 + 'Installer/V1/Client/CalibrateVoltage?MajorToken={0}&MinorToken={1}&IMEI={2}&Vol={3}',




	SEND_COMMAND_POSITION: DOMAIN1 + 'Installer/V1/Client/ProtectPostion2',
	SEND_COMMAND_STATUS: DOMAIN1 + 'Installer/V1/Client/Status2',
	SEND_COMMAND_IMMOBILISE: DOMAIN1 + 'Installer/V1/Client/Immobilise2',
	SEND_COMMAND_UNIMMOBILISE: DOMAIN1 + 'Installer/V1/Client/Unimobilise2',
	SEND_COMMAND_ACCVOLTAGE_ON: DOMAIN1 + 'Installer/V1/Client/AccVoltageOn',
	SEND_COMMAND_ACCVOLTAGE_OFF: DOMAIN1 + 'Installer/V1/Client/AccVoltageOff',
	SEND_COMMAND_CONFIG: DOMAIN1 + 'Installer/V1/Client/Config',

	CHANGE_NOTIFICATION_STATUS: DOMAIN1 + 'Installer/V1/Client/Notification',

	GET_DASHBOARD: DOMAIN1 + 'Quiktrak/v1/Report/Dashboard',
	GET_USAGE_OVERVIEW: DOMAIN1 + 'Quiktrak/v1/Report/UsageOverview',
	GET_DASHBOARD2: DOMAIN1 + 'Quikloc8/V1/User/Dashboard',
	GET_ACTIVATED_DEVICES: DOMAIN1 + 'QuikTrak/V1/Report/GetDevicesSold2',

	GET_CUSTOMERS: DOMAIN1 + 'Quiktrak/V2/Customer/GetList',
	GET_PRE_EDIT_CUSTOMER_INFO: DOMAIN0 + 'Quiktrak/V2/Customer/PreEdit',
	USER_RESET_PASSWORD: DOMAIN3 + 'Service/User/ResetPassword',
	CUSTOMER_DELETE: DOMAIN1 + 'Quiktrak/V2/Customer/Remove',
	CUSTOMER_DISABLE: DOMAIN1 + 'Quiktrak/V2/Customer/Disable',
	CUSTOMER_ENABLE: DOMAIN1 + 'Quiktrak/V2/Customer/Enable',
	CUSTOMER_EDIT: DOMAIN1 + 'Quiktrak/V2/Customer/Edit',
	SEND_CUSTOMER_REG_LETTER: DOMAIN1 + 'Common/V1/Activation/DealerRegistion',
	SEND_ASSET_ACTIVATION_LETTER: DOMAIN1 + 'Common/V1/Activation/ReSendActivation',
	GET_ACTIVATION_HISTORY: DOMAIN1 + 'Common/V1/Activation/QueryHistory',
	GET_ACTIVATION_HISTORY2: DOMAIN1 + 'Quiktrak/v1/Report/GetGpsSecureActivations2?MajorToken={0}&MinorToken={1}&IsSub=1&Type={2}&BeginDate={3}&EndDate={4}',
	GET_ACTIVATION_HISTORY2_TOTAL: DOMAIN1 + 'Quiktrak/v1/Report/GetGpsSecureActivationsCounts2?MajorToken={0}&MinorToken={1}&IsSub=1&Type={2}&BeginDate={3}&EndDate={4}',

	GET_SIM_STATUS: DOMAIN3 + 'JT/Sim/GetSimStatus', //JT/Sim/GetSimStatus
	GET_SIM_STATUS_TOTAL: DOMAIN3 + 'JT/Sim/QueryTotal',
	GET_SMS_HISTORY: DOMAIN3 + 'JT/SMS/History',
	GET_SMS_HISTORY_TCP: DOMAIN1 + 'Quikdata/V1/SMS/GetHistory',//?from={0}&to={1}&numbers={2}
	SIM_SET_SUSPEND: DOMAIN3 + 'JT/Sim/Suspend',
	SIM_SET_RESUME: DOMAIN3 + 'JT/Sim/Resume',
	SIM_SET_TERMINATE: DOMAIN3 + 'JT/Sim/Terminate',
	SIM_SET_TEST: DOMAIN3 + 'JT/Sim/SetTestMode',
	SIM_SET_ACTIVATE: DOMAIN3 + 'JT/Sim/Activate',
	GET_HLR_INFO: DOMAIN3 + 'JT/Sim/GetHlrInfo',
	GET_SIM_CURRENT_SESSION: DOMAIN3 + 'JT/Sim/GetActiveSession',
	GET_SIM_SESSIONS: DOMAIN3 + 'JT/Sim/GetSessions',
	SIM_REPLACE: DOMAIN1 + 'Common/V1/Activation/ReplaceSim',
	SIM_SEND_COMMAND: DOMAIN1 + 'QuikData/V1/Command/SendByType',
	SIM_SEND_COMMAND_TCP: DOMAIN1 + 'QuikData/V1/Command/SendByTypeViaTcp',
	SIM_GET_COMMANDS: DOMAIN1 + 'QuikData/V1/Command/GetCommandTypeList4Clients',
	GET_CSP: DOMAIN3 + 'JT/ServiceProfile/Query',
	GET_SIM_OWNERS: DOMAIN3 + 'Service/Organize/Query',
	EDIT_SIM_OWNERS: DOMAIN3 + 'Service/Organize/Edit', 
	SIM_SET_SERVICE_PROFILE: DOMAIN3 + 'JT/Sim/SetSimServiceProfile',
	SIM_MOVE: DOMAIN3 + 'JT/Sim/Transfer',
	FORCE_RECONNECT: DOMAIN3 + 'JT/Sim/Reboot',
	GET_SIM_LOCATION: DOMAIN3 + 'JT/Sim/QueryLBSInfo',
	UNSUBSCRIBE_STRIPE: DOMAIN1 + '/Common/V1/Activation/Unsubscribe',
	GET_SIM_USERS: DOMAIN3 + 'service/User/Query?Token={0}',
  SET_USER_STATE: DOMAIN3 + 'service/User/SetState',

	

	RESET_ASSET_DATA: 'https://testapi.quiktrak.co/Common/v1/Activation/PresetAssetData',


	GET_ADDRESS_BY_COORDS: DOMAIN4 + 'reverse.php',

	ASSET_IMAGE_URL: DOMAIN5 + 'Attachment/images/',
	GO_FOR_SUPPORT: 'https://support.agent.gps-secure.co/',
	GO_FOR_ACTIVATION: DOMAIN6,
	GO_FOR_INSTALL_NOTICE: DOMAIN7,

	GET_CARTRACK_CUSTOMER: DOMAIN1 + 'quiktrak/V2/Customer/GetCartrackCustomer',

	JT_LAST_UPDATE: DOMAIN8 + 'JT/Report/JtLastUpdate1',
	CUSTOMER_SUMMARY: DOMAIN0 + 'QuikTrak/V2/Report/CustomerSummary'

	//https://testapi.quiktrak.co/?CustomerCode=2087d174-71cf-43a5-922f-5f6e8fdaa8be


}
//https://api.m2mglobaltech.com/Quikloc8/V1/
// asset/Alarms
//https://api.m2mglobaltech.com/QuikProtect/V1/Client/
// Logoff
//https://api.m2mglobaltech.com/QuikProtect/V1/Client/
//API_URL.GET_BALANCE = API_DOMIAN3 + "Balance";
//API_URL.URL_GET_PROTECT_POSITION = API_DOMIAN1 + "Client/ProtectPostion2";
//API_URL.URL_SET_POWER_LEVELS_CONFIG = API_DOMIAN1 + "CLIENT/PowerLevels";
//API_URL.URL_SET_INPUT_CONFIG = API_DOMIAN1 + "Client/GPIO";
//https://api.m2mglobaltech.com/Quiktrak/V2/Customer/PreEdit
//https://api.m2mglobaltech.com/Quiktrak/V1/User/QueryAcccount
//
// Quikloc8/V1/SubDealer/ResetPassword

function getAjaxRequestOptions({ url, method, data }) {
	const ajaxRequestOptions = {
		"url": url,
		"method": method,
		"timeout": 0,
		"headers": {
			"token": '00000000-0000-0000-0000-000000000000', //store.getters.MajorToken, //
			"Content-Type": "application/x-www-form-urlencoded"
		},
		"data": data
	}

	return ajaxRequestOptions
}

export const QTAPI = {
	async Login(query) {
		try {
			const response = await axios.get(ApiMethods.LOGIN, { params: query });
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'LOGIN';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'LOGIN';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async AUTH2(query) {
		try {
			const response = await axios.get(ApiMethods.AUTH2, { params: query });
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'AUTH2';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'AUTH2';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async getWatchdog(query) {
		try {
			const response = await axios.get(ApiMethods.GET_WATCHDOG, { params: query });
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_WATCHDOG';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_WATCHDOG';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async editWatchdog(query) {
		try {
			const {
				MajorToken,
				XXMB,
				YYMB,
				XXSMS,
				YYSMS,
				ISXX,
				ISYY
			} = query

			let url = ApiMethods.EDIT_WATCHDOG.format(
				encodeURIComponent(MajorToken),
				encodeURIComponent(XXMB),
				encodeURIComponent(YYMB),
				encodeURIComponent(XXSMS),
				encodeURIComponent(YYSMS),
				encodeURIComponent(ISXX),
				encodeURIComponent(ISYY)
			)

			let data = qs.stringify(query)
			const response = await axios.post(url, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'EDIT_WATCHDOG';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'EDIT_WATCHDOG';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async VIEW_POSITION(query) {
		try {
			const response = await axios.get(ApiMethods.VIEW_POSITION, { params: query });
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'VIEW_POSITION';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'VIEW_POSITION';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async Logout(query) {
		try {
			const response = await axios.get(ApiMethods.LOGOUT, { params: query });
			return response.data
			/* if (response.data.MajorCode === '000') {
				 return response.data.Data
			 }else{
				 response.data.method = 'LOGOUT';
				 store.commit('SET_ERROR', response.data)
				 return false
			 }*/
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'LOGOUT';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async removeSMSTemplate(query) {
		try {

			let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.REMOVE_SMS_TEMPLATE, data, config);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'REMOVE_SMS_TEMPLATE';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'REMOVE_SMS_TEMPLATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async addSMSTemplate(query) {
		try {

			let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.ADD_SMS_TEMPLATE, data, config);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'ADD_SMS_TEMPLATE';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'ADD_SMS_TEMPLATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async editSMSTemplate(query) {
		try {

			let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.EDIT_SMS_TEMPLATE, data, config);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'EDIT_SMS_TEMPLATE';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'EDIT_SMS_TEMPLATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async getSMSTemplate(query) {
		try {

			//	let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.GET_SMS_TEMPLATE, null, config);
			//console.log(response)
			if (response.data.length) {
				return response.data
			} else {
				// response.data.method = 'GET_SMS_TEMPLATE';
				// store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SMS_TEMPLATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async RefreshPushToken(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.REFRESH_PUSH_TOKEN, data);
			console.log(response)
			/*if (response.data.MajorCode === '000') {
				return response.data.Data
			}else{
				response.data.method = 'LOGIN';
				store.commit('SET_ERROR', response.data)
				return false
			}*/
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'REFRESH_PUSH_TOKEN';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetNewNotifications(query) {
		try {
			const response = await axios.get(ApiMethods.GET_NEW_NOTIFICATIONS, { params: query });
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_NEW_NOTIFICATIONS';
				store.commit('SET_ERROR', response.data)
				return []
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_NEW_NOTIFICATIONS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SetNotificationStatus(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SET_NOTIFICATION_STATUS, data);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SET_NOTIFICATION_STATUS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SET_NOTIFICATION_STATUS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async AUTH1(query) {
		try {
			const response = await axios.get(ApiMethods.AUTH1, { params: query });
			if (response?.data) {
				return response.data
			} else {
				response.data.method = 'AUTH1';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'AUTH1';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async GetWebsiteList(query) {
		try {
			const response = await axios.get(ApiMethods.GET_WEBSITES, { params: query });
			if (response) {
				return response
			} else {
				response.data.method = 'GET_WEBSITES';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_WEBSITES';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	// async GetAssetList(query) {
	// 	try {
	// 		const response = await axios.get(ApiMethods.GET_ASSET_LIST, { params: query });
	// 		if (response.data && response.data.rows) {
	// 			return response.data
	// 		} else {
	// 			response.data.method = 'GET_ASSET_LIST';
	// 			store.commit('SET_ERROR', response.data)
	// 			return false
	// 		}

	// 	} catch (e) {
	// 		if (e && e.response && e.response.data) e.response.data.method = 'GET_ASSET_LIST';
	// 		store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
	// 		throw e
	// 	}
	// },

	async GetSimList(query) {
		try {
			let data = qs.stringify(query)
			const config = {
				headers: {
					'Token': store.getters.MajorToken,
				}
			};

			let url = ApiMethods.GET_SIM_LIST.format(
				encodeURIComponent(store.getters.MajorToken),
			)
			const response = await axios.post(url, data, config);

			if (response.data && response.data.Data) {
				return response.data.Data
			} else {
				response.data.method = 'GET_SIM_LIST';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_LIST';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async AssetEdit(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.ASSET_EDIT, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'ASSET_EDIT';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'ASSET_EDIT';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetCreditBalance(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_CREDIT_BALANCE, data);
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_CREDIT_BALANCE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_CREDIT_BALANCE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async CustomerTransfer(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CUSTOMER_TRANSFER, data);
			if (response.data.MajorCode === '000') {
				return response
			} else {
				response.data.method = 'CUSTOMER_TRANSFER';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CUSTOMER_TRANSFER';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetSimCommandList(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.SIM_GET_COMMANDS, {
				params: query,
			});
			//console.log(response)
			if (response.data && Array.isArray(response.data)) {
				return response.data
			} else {
				response.data.method = 'SIM_GET_COMMANDS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_GET_COMMANDS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async AssetTransfer(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.ASSET_TRANSFER, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'ASSET_TRANSFER';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'ASSET_TRANSFER';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SetInputConfig(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SET_INPUT_CONFIG, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SET_INPUT_CONFIG';
				//store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SET_INPUT_CONFIG';
			//store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async CalibrateVoltage(query) {
		try {
			const {
				MajorToken,
				MinorToken,
				IMEI,
				Vol
			} = query

			let url = ApiMethods.CALIBRATE_VOLTAGE.format(
				encodeURIComponent(MajorToken),
				encodeURIComponent(MinorToken),
				encodeURIComponent(IMEI),
				encodeURIComponent(Vol)
			)

			let data = qs.stringify(query)
			const response = await axios.post(url, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'CALIBRATE_VOLTAGE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CALIBRATE_VOLTAGE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SetPowerLevels(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SET_POWER_LEVELS, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SET_POWER_LEVELS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SET_POWER_LEVELS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandPosition(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_POSITION, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_POSITION';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_POSITION';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async ChangeNotificationStatus(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CHANGE_NOTIFICATION_STATUS, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'CHANGE_NOTIFICATION_STATUS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CHANGE_NOTIFICATION_STATUS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandStatus(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_STATUS, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_STATUS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_STATUS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandImmobilise(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_IMMOBILISE, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_IMMOBILISE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_IMMOBILISE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandUnImmobilise(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_UNIMMOBILISE, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_UNIMMOBILISE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_UNIMMOBILISE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandACCVoltageOn(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_ACCVOLTAGE_ON, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_ACCVOLTAGE_ON';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_ACCVOLTAGE_ON';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandACCVoltageOff(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_ACCVOLTAGE_OFF, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_ACCVOLTAGE_OFF';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_ACCVOLTAGE_OFF';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendCommandConfig(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_COMMAND_CONFIG, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_COMMAND_CONFIG';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_COMMAND_CONFIG';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetCustomers(query) {
		try {
			let cancelToken = axios.CancelToken.source()
			store.commit("SET_SEARCH_CANCEL_TOKEN", cancelToken)
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_CUSTOMERS, {
				params: query,
				cancelToken: cancelToken.token
			});
			if (response.data && response.data.rows) {
				store.commit("SET_SEARCH_CANCEL_TOKEN", null)
				return response.data
			} else {
				response.data.method = 'GET_CUSTOMERS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (axios.isCancel(e)) return { isCanceled: true }
			if (e && e.response && e.response.data) e.response.data.method = 'GET_CUSTOMERS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	// async GetPreEditCustomerInfo(query) {
	// 	try {
	// 		let data = qs.stringify(query)
	// 		const response = await axios.post(ApiMethods.GET_PRE_EDIT_CUSTOMER_INFO, data);
	// 		if (!response.data.MajorCode) {
	// 			return response.data
	// 		} else {
	// 			response.data.method = 'GET_PRE_EDIT_CUSTOMER_INFO';
	// 			store.commit('SET_ERROR', response.data)
	// 			return false
	// 		}

	// 	} catch (e) {
	// 		if (e && e.response && e.response.data) e.response.data.method = 'GET_PRE_EDIT_CUSTOMER_INFO';
	// 		store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
	// 		throw e
	// 	}
	// },
	async CustomerDelete(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CUSTOMER_DELETE, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'CUSTOMER_DELETE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CUSTOMER_DELETE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async CustomerSuspend(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CUSTOMER_DISABLE, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'CUSTOMER_DISABLE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CUSTOMER_DISABLE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async CustomerUnSuspend(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CUSTOMER_ENABLE, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'CUSTOMER_ENABLE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CUSTOMER_ENABLE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async UserResetPassword(query) {
		try {
			let data = qs.stringify(query)
			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.USER_RESET_PASSWORD, data, config);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'USER_RESET_PASSWORD';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'USER_RESET_PASSWORD';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async CustomerEditInfo(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CUSTOMER_EDIT, data);
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'CUSTOMER_EDIT';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CUSTOMER_EDIT';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SendRegLetter(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_CUSTOMER_REG_LETTER, data);
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SEND_CUSTOMER_REG_LETTER';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_CUSTOMER_REG_LETTER';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetActivationHistory(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_ACTIVATION_HISTORY, data);
			if (response.data?.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_ACTIVATION_HISTORY';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ACTIVATION_HISTORY';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async GetActivationHistory2(query) {
		try {

			const {
				MajorToken,
				MinorToken,
				ReportType,
				From,
				To
			} = query

			let url = ApiMethods.GET_ACTIVATION_HISTORY2.format(
				encodeURIComponent(MajorToken),
				encodeURIComponent(MinorToken),
				encodeURIComponent(ReportType),
				encodeURIComponent(From),
				encodeURIComponent(To),
			)
			/*let url = ApiMethods.GET_ACTIVATION_HISTORY2.format(
				encodeURIComponent('00000000-0000-0000-0000-000000000000'),
				encodeURIComponent('00000000-0000-0000-0000-000000000000'),
				encodeURIComponent('2023-06-13'),
				encodeURIComponent('2023-06-14'),
			)*/

			let response = await fetch(url, {
				method: "POST"
			});

			response = await response.json();
			if (response) {
				return response
			} else {
				response.method = 'GET_ACTIVATION_HISTORY2';
				//commit('SET_ERROR', response)

				console.log('SET_ERROR', response)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ACTIVATION_HISTORY2';
			//commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async SendAssetActivationLetter(query) {
		try {
			/*let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SEND_ASSET_ACTIVATION_LETTER, data );*/

			const response = await axios.get(ApiMethods.SEND_ASSET_ACTIVATION_LETTER, { params: query });

			if (response.data.MajorCode === '000' && response.data.Data !== 'Client type incorrect') {
				return response.data
			} else {
				response.data.method = 'SEND_ASSET_ACTIVATION_LETTER';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SEND_ASSET_ACTIVATION_LETTER';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},


	async GetAssetLocation(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_ASSET_LOCATION, data);
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_ASSET_LOCATION';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ASSET_LOCATION';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	/*async GetSimLocation(query){
		try {
		const options = {
			"url": ApiMethods.GET_SIM_LOCATION,
			"method": "POST",
			"data": query
		}
		return new Promise((resolve, reject) => {
			$.ajax(getAjaxRequestOptions(options)).done(function (response) {
			if(response.Data && response.Data.LbsLat){
				resolve(response.Data)
			}else{
				response.method = 'GET_SIM_LOCATION'
				reject()
			}
			}).fail(function (e){
			if(e && e.response && e.response.data) e.response.data.method = 'GET_SIM_LOCATION'
			 store.commit('SET_ERROR',  !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
			})
		})
  
		} catch (e) {
		if(e && e.response && e.response.data) e.response.data.method = 'GET_SIM_LOCATION';
		store.commit('SET_ERROR',  !e.response ? e : (!e.response.data) ? e.response : e.response.data)
		throw e
		}
	},*/

	async GetSimLocation(query) {
		try {
			let data = qs.stringify(query, { arrayFormat: 'brackets' })
			return new Promise(async (resolve, reject) => {
				const response = await axios.post(ApiMethods.GET_SIM_LOCATION, data, {
					headers: {
						'token': store.getters.RootToken
					}
				});

				if (response.data.Data && response.data.Data.LbsLat) {
					resolve(response.data.Data)
				} else {
					response.data.method = 'GET_SIM_LOCATION'
					//store.commit('SET_ERROR', response.data)
					reject()
				}
			})

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_LOCATION';
			//store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async UnsubscribeStripe(query) {
		try {

			let response = await fetch(ApiMethods.UNSUBSCRIBE_STRIPE + '?MajorToken=' + query.MajorToken + '&MinorToken=' + query.MinorToken + '&imei=' + query.IMEI, {
				method: "POST"
			});

			response = await response.json();
			if (response) {
				return true
			} else {
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'UNSUBSCRIBE_STRIPE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async ResetAssetData(query) {
		try {

			let response = await fetch(ApiMethods.RESET_ASSET_DATA + '?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&imei=' + query.imei, {
				method: "POST"
			});

			response = await response.json();
			if (response) {
				return true
			} else {
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'RESET_ASSET_DATA';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async ForceReconnect(query) {
		try {
			const options = {
				"url": ApiMethods.FORCE_RECONNECT,
				"method": "POST",
				"data": query
			}
			return new Promise((resolve, reject) => {
				$.ajax(getAjaxRequestOptions(options)).done(function (response) {
					if (response.MajorCode == '000') {
						resolve(true)
					} else {
						response.method = 'FORCE_RECONNECT'
						reject()
					}
				}).fail(function (e) {
					if (e && e.response && e.response.data) e.response.data.method = 'FORCE_RECONNECT'
					store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
					throw e
				})
			})

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'FORCE_RECONNECT';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},


	async resetInitialValue(query, type) {
		let url = '';
		switch (type) {
			case 'mileage':
				url = ApiMethods.RESET_MILEAGE
				break;
			case 'hours':
				url = ApiMethods.RESET_HOURS
				break;
			case 'history':
				url = ApiMethods.RESET_HISTORY
				break;
			default:
				url = '';
		}
		try {
			let data = qs.stringify(query)
			const response = await axios.post(url, data);
			if (response.data.MajorCode === '000') {
				return response
			} else {
				response.data.method = 'RESET_VALUE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'RESET_VALUE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async AssetDeactivation(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.ASSET_DEACTIVATE, data);
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'ASSET_DEACTIVATE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'ASSET_DEACTIVATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetAssetLastAlarms(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_ASSET_LAST_ALARMS, {
				params: query,
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_ASSET_LAST_ALARMS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ASSET_LAST_ALARMS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetAssetLast10Alarms(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_ASSET_LAST10_ALARMS, {
				params: query,
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_ASSET_LAST10_ALARMS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ASSET_LAST10_ALARMS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetAssetAlarmHistory(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_ASSET_ALARM_HISTORY, {
				params: query,
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_ASSET_ALARM_HISTORY';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ASSET_ALARM_HISTORY';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetSimStatus(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_SIM_STATUS, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});

			if (!response.data.Data.MajorCode) {
				return response.data.Data
			} else {
				response.data.method = 'GET_SIM_STATUS';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_STATUS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetSimStatusTotal(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_SIM_STATUS_TOTAL, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});

			//console.log('responsem2m', response.data)
			if (response.data.MajorCode == '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_SIM_STATUS_TOTAL';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_STATUS_TOTAL';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async GetSmsHistoryTCP(query) {
		try {
			//let cancelToken = axios.CancelToken.source()
			//store.commit("SET_SEARCH_CANCEL_TOKEN", cancelToken)
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_SMS_HISTORY_TCP, {
				params: query,
				//cancelToken: cancelToken.token
			});
			if (response.data) {
				//store.commit("SET_SEARCH_CANCEL_TOKEN", null)
				return response.data
			} else {
				response.data.method = 'GET_SMS_HISTORY_TCP';
				store.commit('SET_ERROR', response)
				return false
			}

		} catch (e) {
			if (axios.isCancel(e)) return { isCanceled: true }
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SMS_HISTORY_TCP';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async GetSmsHistory(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_SMS_HISTORY, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});

			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_SMS_HISTORY';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SMS_HISTORY';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SimSetSuspend(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SET_SUSPEND, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SET_SUSPEND';
				//store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_SUSPEND';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SimSetResume(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SET_RESUME, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SET_RESUME';
				//store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_RESUME';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SimSetTerminate(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SET_TERMINATE, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SET_TERMINATE';
				//store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_TERMINATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SimSetTest(query) {
		
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SET_TEST, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SET_TEST';
				//store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_TEST';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async SimSetActivate(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SET_ACTIVATE, data, {
				headers: {
					'token': store.getters.RootToken
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SET_ACTIVATE';
				//store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_ACTIVATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetHLRInfo(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_HLR_INFO, {
				params: query,
				headers: {
					'token': store.getters.RootToken
				}
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_HLR_INFO';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_HLR_INFO';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetSimCurrentSession(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_SIM_CURRENT_SESSION, {
				params: query,
				headers: {
					'token': store.getters.RootToken
				}
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_SIM_CURRENT_SESSION';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_CURRENT_SESSION';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetSimSessions(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_SIM_SESSIONS, {
				params: query,
				headers: {
					'token': store.getters.RootToken
				}
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_SIM_SESSIONS';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_SESSIONS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	// async GetSimCommandList(query) {
	// 	try {
	// 		//let data = qs.stringify(query)
	// 		const response = await axios.get(ApiMethods.SIM_GET_COMMANDS, {
	// 			params: query,
	// 		});
	// 		//console.log(response)
	// 		if (response.data && Array.isArray(response.data)) {
	// 			return response.data
	// 		} else {
	// 			response.data.method = 'SIM_GET_COMMANDS';
	// 			store.commit('SET_ERROR', response.data)
	// 			return false
	// 		}

	// 	} catch (e) {
	// 		if (e && e.response && e.response.data) e.response.data.method = 'SIM_GET_COMMANDS';
	// 		store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
	// 		throw e
	// 	}
	// },
	async SimSendCommand(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SEND_COMMAND, data, {
				headers: {
					/*'token': store.getters.RootToken*/
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SEND_COMMAND';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SEND_COMMAND';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},


	async FakeRenewal(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.FAKE_RENEWAL, data, {
				headers: {
					//'token': store.getters.RootToken,
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});
			if (response) {
				return response
			} else {
				response.data.method = 'FAKE_RENEWAL';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'FAKE_RENEWAL';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async SimSendCommandTCP(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.SIM_SEND_COMMAND_TCP, data, {
				headers: {
					/*'token': store.getters.RootToken,
					'Connection': 'keep-alive',
					'Host': 'newapi.quiktrak.co',
					'Origin': 'https://cartrack.co',
					'Referer': 'https://cartrack.co/',
					'Sec-Fetch-Dest': 'empty',
					'Sec-Fetch-Mode': 'cors',
					'Sec-Fetch-Site': 'cross-site',*/
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'SIM_SEND_COMMAND_TCP';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SEND_COMMAND_TCP';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
 
	async AccountEdit(query) {
		try {
		   let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.ACCOUNT_EDIT, data , config);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'ACCOUNT_EDIT';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'ACCOUNT_EDIT';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async GetAddressByCoords(params) {
		let data = {
			format: 'json',
			addressdetails: '1',
			lat: params.latitude,
			lon: params.longitude,
		}
		try {
			const response = await axios.get(ApiMethods.GET_ADDRESS_BY_COORDS, {
				params: data,
			});
			return response.data
		} catch (e) {
			/*commit('SET_ERROR', e)
			throw e*/
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ADDRESS_BY_COORDS';
			//store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async SetCustomer(query) {
		try {
			const options = {
				"url": ApiMethods.SIM_MOVE,
				"method": "POST",
				"data": query
			}
			return new Promise((resolve, reject) => {
				$.ajax(getAjaxRequestOptions(options)).done(function (response) {
					if (response.MajorCode == '000') {
						resolve(true)
					} else {
						response.method = 'SIM_MOVE'
						reject()
					}
				}).fail(function (e) {
					if (e && e.response && e.response.data) e.response.data.method = 'SIM_MOVE'
					store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
					throw e
				})
			})

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_SERVICE_PROFILE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async SetCSP(query) {
		try {
			const options = {
				"url": ApiMethods.SIM_SET_SERVICE_PROFILE,
				"method": "POST",
				"data": query
			}
			return new Promise((resolve, reject) => {
				$.ajax(getAjaxRequestOptions(options)).done(function (response) {
					if (response.MajorCode == '000') {
						resolve(true)
					} else {
						response.method = 'SIM_SET_SERVICE_PROFILE'
						reject()
					}
				}).fail(function (e) {
					if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_SERVICE_PROFILE'
					store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
					throw e
				})
			})

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SIM_SET_SERVICE_PROFILE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},


	async GetSimUsers(query) {
		try {

			let url = ApiMethods.GET_SIM_USERS.format(
				encodeURIComponent(store.getters.MajorToken),
			)
			const options = {
				"url": url,
				"method": "POST",
				"data": query,
				"timeout": 0,
				"headers": {
					"token": store.getters.MajorToken,  
					"Content-Type": "application/x-www-form-urlencoded"
				},
 
			}
			
			return new Promise((resolve, reject) => {
				$.ajax( options).done(function (response) {
					if (response) {
						resolve(response)
					} else {
						response.method = 'GET_SIM_USERS'
						reject()
					}
				}).fail(function (e) {
					if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_USERS'
					store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
					throw e
				})
			})

		} catch (e) {

			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_USERS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async setUserState(query) {
		try {

			let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.SET_USER_STATE, data, config);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'SET_USER_STATE';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'SET_USER_STATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async EditSimOwners(query) {
		try {
			const options = {
				"url": ApiMethods.EDIT_SIM_OWNERS,
				"method": "POST",
				"data": query
			}
			return new Promise((resolve, reject) => {
				$.ajax(getAjaxRequestOptions(options, )).done(function (response) {
					if (response) {
						resolve(response)
					} else {
						response.method = 'EDIT_SIM_OWNERS'
						reject()
					}
				}).fail(function (e) {
					if (e && e.response && e.response.data) e.response.data.method = 'EDIT_SIM_OWNERS'
					store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
					throw e
				})
			})

		} catch (e) {

			if (e && e.response && e.response.data) e.response.data.method = 'EDIT_SIM_OWNERS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetSimOwners(query) {
		try {
			const options = {
				"url": ApiMethods.GET_SIM_OWNERS,
				"method": "POST",
				"data": query
			}
			return new Promise((resolve, reject) => {
				$.ajax(getAjaxRequestOptions(options, )).done(function (response) {
					if (response) {
						resolve(response)
					} else {
						response.method = 'GET_SIM_OWNERS'
						reject()
					}
				}).fail(function (e) {
					if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_OWNERS'
					store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
					throw e
				})
			})

		} catch (e) {

			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_OWNERS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	/*async GetCSP(query){
		try {
		const options = {
			"url": ApiMethods.GET_CSP,
			"method": "POST",
			"data": query
		}
		return new Promise((resolve, reject) => {
			$.ajax(getAjaxRequestOptions(options)).done(function (response) {
			if(response.MajorCode == '000'){
				resolve(response)
			}else{
				response.method = 'GET_CSP'
				reject()
			}
			}).fail(function (e){
			if(e && e.response && e.response.data) e.response.data.method = 'GET_CSP'
			 store.commit('SET_ERROR',  !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
			})
		})
  
		} catch (e) {
		if(e && e.response && e.response.data) e.response.data.method = 'GET_CSP';
		store.commit('SET_ERROR',  !e.response ? e : (!e.response.data) ? e.response : e.response.data)
		throw e
		}
	},*/

	async GetCSP(query) {
		try {
			let data = qs.stringify(query)
			return new Promise(async (resolve, reject) => {
				const response = await axios.post(ApiMethods.GET_CSP, data, {
					headers: {
						'token': store.getters.RootToken
					}
				});
				if (response.data.MajorCode === '000') {
					resolve(response.data)
				} else {
					response.data.method = 'GET_SIM_LOCATION'
					store.commit('SET_ERROR', response.data)
					reject()
				}
			})

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_CSP';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async GetSSP(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_SSP, data);
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'GET_SSP';
				store.commit('SET_ERROR', response)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SSP';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetAssetInfo(query) {
		try {
			//let data = qs.stringify(query)
			const response = await axios.get(ApiMethods.GET_ASSET_INFO, {
				params: query,
			});
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data.Data && response.data.Data.Assets && response.data.Data.Assets[0] ? response.data.Data.Assets[0] : {}
			} else {
				response.data.method = 'GET_ASSET_INFO';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ASSET_INFO';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async GetDashboardInfo(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_DASHBOARD, data);
			//console.log(response)
			if (response.data.MajorCode === '000' && !isObjEmpty(response.data.Data)) {

				store.commit('SET_ALL_SOLUTION_STATS', response.data.Data.Total.length ? response.data.Data.Total : [])
				store.commit('SET_ACTIVATED_IN_PAST_PERIOD', response.data.Data.Past14days.length ? response.data.Data.Past14days : [])
				store.commit('SET_ACTIVATED_PER_DAY', response.data.Data.Weekly.length ? response.data.Data.Weekly : [])
				store.commit('SET_ALL_SIMS_STATES', response.data.Data.Sims.length ? SortArrayByObjProps(response.data.Data.Sims, [{ prop: 'SimStatus', direction: 1 }]) : [])

				return response.data.Data
			} else {
				response.data.method = 'GET_DASHBOARD';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_DASHBOARD';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	/*async GetDashboardInfo2(query){
		try {
		let data = qs.stringify(query)
		const response = await axios.post(ApiMethods.GET_DASHBOARD2, data);
		//console.log(response)
		if (response.data.MajorCode === '000' && !isObjEmpty(response.data.Data)) {
  
			store.commit('SET_ACTIVATED_DEVICES', response.data.Data.DevicesSold ? response.data.Data.DevicesSold : {})
  
			return response.data.Data
		}else{
			response.data.method = 'GET_DASHBOARD2';
			store.commit('SET_ERROR', response.data)
			return false
		}
  
		} catch (e) {
		if(e && e.response && e.response.data) e.response.data.method = 'GET_DASHBOARD2';
		store.commit('SET_ERROR',  !e.response ? e : (!e.response.data) ? e.response : e.response.data)
		throw e
		}
	},*/
	async GetUsageOverview(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_USAGE_OVERVIEW, data);

			if (response.data.MajorCode === '000' && !isObjEmpty(response.data.Data)) {
				store.commit('SET_USAGE_OVERVIEW', response.data.Data ? response.data.Data : {})

				return response.data.Data
			} else {
				response.data.method = 'GET_USAGE_OVERVIEW';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_USAGE_OVERVIEW';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	/*async GetActivatedDevices(query){
		try {
		let data = qs.stringify(query)
		const response = await axios.post(ApiMethods.GET_ACTIVATED_DEVICES, data);
  
		if (response.data.MajorCode === '000') {
  
			return response.data.Data
		}else{
			response.data.method = 'GET_ACTIVATED_DEVICES';
			store.commit('SET_ERROR', response.data)
			return false
		}
  
		} catch (e) {
		if(e && e.response && e.response.data) e.response.data.method = 'GET_ACTIVATED_DEVICES';
		store.commit('SET_ERROR',  !e.response ? e : (!e.response.data) ? e.response : e.response.data)
		throw e
		}
	},*/

	async getActivationStatistic(query) {
		try {
			let cancelToken = axios.CancelToken.source()
			store.commit("SET_SEARCH_CANCEL_TOKEN", cancelToken)
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.GET_ACTIVATION_HISTORY, data, { cancelToken: cancelToken.token });
			if (response.data?.MajorCode === '000') {
				store.commit("SET_SEARCH_CANCEL_TOKEN", null)
				return response.data.Data
			} else {
				response.data.method = 'GET_ACTIVATION_HISTORY';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (axios.isCancel(e)) return { isCanceled: true }
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ACTIVATION_HISTORY';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},

	async getActivationStatistic2(query) {
		try {

			const {
				MajorToken,
				MinorToken,
				ReportType,
				From,
				To
			} = query

			let url = ApiMethods.GET_ACTIVATION_HISTORY2.format(
				encodeURIComponent(MajorToken),
				encodeURIComponent(MinorToken),
				encodeURIComponent(ReportType),
				encodeURIComponent(From),
				encodeURIComponent(To),
			)

			let response = await fetch(url, {
				method: "POST"
			});

			response = await response.json();
			if (response) {
				return response
			} else {
				console.log('SET_ERROR', response)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ACTIVATION_HISTORY2';
			throw e
		}
	},

	async getActivationStatistic2Total(query) {
		try {

			const {
				MajorToken,
				MinorToken,
				ReportType,
				From,
				To
			} = query

			let url = ApiMethods.GET_ACTIVATION_HISTORY2_TOTAL.format(
				encodeURIComponent(MajorToken),
				encodeURIComponent(MinorToken),
				encodeURIComponent(ReportType),
				encodeURIComponent(From),
				encodeURIComponent(To),
			)

			let response = await fetch(url, {
				method: "GET"
			});

			response = await response.json();
			if (response) {
				return response
			} else {
				console.log('SET_ERROR', response)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_ACTIVATION_HISTORY2_TOTAL';
			throw e
		}
	},
	async GetCartrackCustomer(query) {
		try {
			const response = await axios.get(ApiMethods.GET_CARTRACK_CUSTOMER, { params: query });
			if (response.data.MajorCode === '000') {
				return response.data
			} else if (response.data.MajorCode === '200') {
				return response.data
			}  else {
				response.data.method = 'GET_CARTRACK_CUSTOMER';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_CARTRACK_CUSTOMER';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async getLatUpdate() {
		try {
			const response = await axios.get(ApiMethods.JT_LAST_UPDATE, { params: { token: '00000000-0000-0000-0000-000000000000' } });
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'JT_LAST_UPDATE';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'JT_LAST_UPDATE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async CustomerSummary(query) {
		try {
			let data = qs.stringify(query)
			const response = await axios.post(ApiMethods.CUSTOMER_SUMMARY, data);
			console.log(response)
			/*if (response.data.MajorCode === '000') {
				return response.data.Data
			}else{
				response.data.method = 'LOGIN';
				store.commit('SET_ERROR', response.data)
				return false
			}*/
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'CUSTOMER_SUMMARY';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async getTotalSMS(query) {

		try {
			// const config = {
			// 	headers: {
			// 		'token': query?.Token ? query?.Token : store.getters.RootToken,
			// 	}
			// };
			const response = await axios.get(ApiMethods.GET_TOTAL_SMS, {
				params: {
					UserCode: query.UserCode
				},
				headers: {
					'token':  query?.Token ? query?.Token : store.getters.RootToken,
				}
			} );
			if (response.data.MajorCode === '000') {
				return response.data
			} else if (response.data.MajorCode === '200') {
				return response.data
			}  else {
				response.data.method = 'GET_TOTAL_SMS';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_TOTAL_SMS';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	 
	},
	async getM2MDashboard(query) {
		try {

			//console.log('query', query)
			let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': query?.Token ? query?.Token : store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.DASHBOARD_M2M, data, config);
			//console.log(response)
			if (response.data.MajorCode === '000') {
				return response.data
			} else {
				response.data.method = 'DASHBOARD_M2M';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'DASHBOARD_M2M';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async getSIMInfoTable(query) {
		try {

			let data = qs.stringify(query)

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			const response = await axios.post(ApiMethods.GET_SIM_INFO_TABLE, data, config);
			//console.log(response)
			if (response.data) {
				return response.data
			} else {
				response.data.method = 'GET_SIM_INFO_TABLE';
				store.commit('SET_ERROR', response.data)
				return false
			}
		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'GET_SIM_INFO_TABLE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
	async getDataUsage(query, params) {
		try {

			const config = {
				headers: {
					'Token': store.getters.RootToken,
				}
			};
			let url = ApiMethods.DAILY_USAGE + '?IMSI=' + query

			let data = qs.stringify(params)
			const response = await axios.post(url, data, config);
			if (response.data.MajorCode === '000') {
				return response.data.Data
			} else {
				response.data.method = 'DAILY_USAGE';
				store.commit('SET_ERROR', response.data)
				return false
			}

		} catch (e) {
			if (e && e.response && e.response.data) e.response.data.method = 'DAILY_USAGE';
			store.commit('SET_ERROR', !e.response ? e : (!e.response.data) ? e.response : e.response.data)
			throw e
		}
	},
}

