<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import resize from "./mixins/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    page: {
      type: String,
      default: "dashboard",
    },
    width: {
      type: String,
      default: " 100%  ",
    },
    height: {
      type: String,
      default: "350px",
    },
    bar: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    bar: function (val) {
      const arr = [];
      val.forEach(({ value, name }) => {
        arr.push({
          value,
          name,
        });
      });
      console.log('val', val)
      this.initChart(arr);
    },
  },
  mounted() {
    console.log('bar', this.bar)
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart(data) {
      let self = this;
      console.log('data', data)
      const colors = [
        "#2ec7c9",
        "#efe26e",
        "#00a0d3",
        "#b5a3dd",
        "#d47980",
        "#faa671",
        "#38b491",
        "#8d98b3",
      ];

      const seriesData = data.map((item, index) => ({
        name: item.name,
        type: "bar",
        stack: "stack",
        barWidth: 20,
        itemStyle: {
          barBorderRadius: self.page == "usage-data" ? 0 : 5,
          color:
          self.page == "usage-data"
              ? "#2ec7c9"
              : colors[index % colors.length],
        },
        data: Array(data.length)
          .fill(null)
          .map((e, i) => (i === index ? item.value : "")),
      }));

      console.log('seriesData', seriesData)
      const legendData = data.map((item) => item.name);

      this.chart = echarts.init(this.$el, "macarons", { renderer: "svg" });
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value !== null) {
              const total = data.reduce(
                (sum, item) => sum + (item.value || 0),
                0
              );
              const percent = ((params.value / total) * 100).toFixed(2);
              return  self.page == "usage-data" ?  `${params.seriesName} <br/>${params.name} : ${params.value}` : `${params.seriesName} <br/>${params.name} : ${params.value} (${percent}%)`;
            } else {
              return `${params.seriesName} <br/>${params.name} : No data`;
            }
          },
        },
        grid: {
          top: 10,
          left: 40,
          right: 10,
        },
        legend: {
          data: legendData,
          bottom: -30,
          left: "center",
          show: self.page == "usage-data" ? false : true,
          textStyle: {
            fontSize: "10px",
          },
        },

        xAxis: {
          type: "category",
          data: data.map((item) => ""),
        },
        yAxis: {
          axisLabel: {
            fontSize: 10,
          },
        },

        series: seriesData, // [
        //   {
        //     name: "SIM Inventory",
        //     type: "bar",
        //     data: data.map((item, index) => {
        //       return {
        //         value: item.value,
        //         name: item.name,
        //         stack: 'stack',
        //         itemStyle: {
        //           color: colors[index % colors.length],
        //         },
        //       };
        //     }),
        //     animationEasing: "cubicInOut",
        //     animationDuration: 2600,
        //   },
        // ],
      });
    },
  },
};
</script>
<style>
</style>