<template>
  <f7-page ref="page" name="data-usage" class="summary bg-color-lightgray">
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="position-absolute"
      key="1"
    ></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link href="/panel-left/" icon-only>
          <f7-icon icon="f7-icons icon-menu"></f7-icon>
        </f7-link>
      </f7-nav-left>
    </f7-navbar>

    <f7-toolbar position="top" class>
      <f7-nav-title sliding>Data Usage</f7-nav-title>
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link  v-tooltip="'Dashboard'" href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link  v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link  v-tooltip="'Commands'" href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'"  href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Summary'" href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-block-title>Total SIMs</f7-block-title>

    <f7-actions-group
      v-if="isTotalDataStatsLoaded"
      class="row total-data no-gap"
    >
      <f7-actions-button class="col-33 margin-bottom">
        <div class="actions-icon bg-color-blue margin-bottom">
          <i class="icon-data size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">Total Data</span>
        <span>{{ totalData }}</span>
      </f7-actions-button>
      <f7-actions-button class="col-33 margin-bottom">
        <div class="actions-icon bg-color-orange margin-bottom">
          <i class="icon-mail size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">Total SMS</span>
        <span>{{ totalSMS }}</span>
      </f7-actions-button>
      <f7-actions-button class="col-33 margin-bottom">
        <div class="actions-icon bg-color-green margin-bottom">
          <i class="icon-data size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">Avg Data</span>
        <span>{{ averageData }}</span>
      </f7-actions-button>
    </f7-actions-group>
    <f7-list v-else-if="!isTotalDataStatsLoaded" no-hairlines>
      <f7-list-item
        header="header"
        title="simInfo.IMSI"
        class="skeleton-text skeleton-effect-blink"
        v-for="n in 4"
        :key="n"
      >
        <template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 100%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>

    <f7-card header-divider footer-divider>
      <f7-list>
        <f7-list-input
          :label="`Since ${selectedDate}`"
          type="datepicker"
          :value="inputBeginTime"
          @calendar:change="(value) => (inputBeginTime = value)"
          class="datepicker"
     

          readonly
        />
      </f7-list>

      <f7-card outline header-divider footer-divider >
        <f7-card-content>
          <bar-chart :bar="usageData" page="usage-data"/>
        </f7-card-content>
      </f7-card>

      <div v-if="!isLoading" class="data-table">
        <table>
          <thead>
            <tr>
         
              <th class="numeric-cell">Date</th>
              <th class="numeric-cell">Date(MB)</th>
              <th class="numeric-cell">SMS</th>
              <th class="numeric-cell">Session</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataTable" :key="index">
              <td class="numeric-cell">{{ item.date }}</td>
              <td class="numeric-cell">{{ item.dateMB }}</td>
              <td class="numeric-cell">{{ item.sms }}</td>
              <td class="numeric-cell">{{ item.session }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <f7-progressbar
        v-else
        color="green"
        infinite
        class="position-absolute"
        key="1"
      ></f7-progressbar>
    </f7-card>
  </f7-page>
</template>

<script>
import BarChart from "./dashboard/components/bar-chart";
import { QTAPI } from "../services/qt-api";
import moment from "moment";

export default {
  name: "data-usage",

  props: {
    f7router: Object,
    f7route: Object,
    orgCode: {
      type: String,
      default: () => {
        return "";
      },
    },
  },

  components: {
    BarChart
  },
  data() {
    return {
      isLoading: false,
      inputBeginTime: "",
      dataStats: [],
      usageData: [],
      dataTable: [],
      isTotalDataStatsLoaded: false,
      totalData: "0",
      averageData: "0",
      totalSMS: "0",
      selectedDate: "",
      
    };
  },

  computed: {},

  watch: {
    inputBeginTime(newVal) {
      var formattedDate = moment(new Date(newVal)).format("YYYY-MM-DD");
      this.getAllStats(formattedDate);
    },
  },

  mounted() {
    this.getAllStats();
  },

  methods: {
    async getAllStats(date) {
      let self = this;
      this.isLoading = true;

      var now = moment();
      var oneWeekAgo = now.subtract(7, "days");
      var formattedDate = oneWeekAgo.format("YYYY-MM-DD");

      if (date) {
        self.selectedDate = date;
      } else {
        self.selectedDate = formattedDate;
      }

      let data = {
        From: date ? date : formattedDate,
        page: "1",
        rows: "100",
      };

      let query = {
        IMSI: this.f7route.query.imsi,
      };

      let result = await QTAPI.getDataUsage(query.IMSI, data);
      this.isLoading = false;

   

     // console.log(result);
      if (result.length) {
        let arr = [];
        let chartArr = [];
        let totalData = 0;
        let totalSMS = 0;
        let averageData = 0;
        let objTemp = {};

        $.each(result, function (index, value1) {
          let totalField = value1.Usage / 1048576;
          let smsField = value1.SMSMOUsage + value1.SMSMTUsage;

          objTemp = {
            date: value1.Date.slice(0, 10),
            dateMB: totalField.toFixed(3),
            sms: smsField,
            session: value1.Session,
         
          };
          arr.push(objTemp);
         
          chartArr.push({
            name: value1.Date.slice(0, 10),
            value: totalField.toFixed(3),
          });
      
          totalData += +totalField;
          totalSMS += smsField;
         
        });
        this.dataTable = arr
        this.usageData = chartArr;
 

        this.totalData = totalData.toFixed(3);
        this.totalSMS = totalSMS;
        this.averageData = (totalData / result.length).toFixed(3);
        this.isTotalDataStatsLoaded = true;
      } else {
        this.totalData = "n/a";
        this.totalSMS = "n/a";
        this.averageData = "n/a";
        this.isTotalDataStatsLoaded = true;
      }

   
    },
  },

  beforeDestroy() {},
};
</script>

 <style lang="scss">
 .datepicker {
  border-bottom: 1px solid #f0f2f5;
  border-radius: 0;
  .item-label {
    position: absolute;
    padding-top: 5px;
    font-size: 14px;
    color: #000;
   }
   .item-input-wrap  input{
    color: transparent;
  }
 }


</style>
 