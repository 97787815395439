// Import Vue
import { createApp } from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'

import '@/js/jquery-global.js';

import store from '../store'
import i18n from './i18n'
import VueNumerals from 'vue-numerals';
import VTooltipPlugin from 'v-tooltip'

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import "leaflet/dist/leaflet.css";
import '../css/icons.css';
import '../css/app.scss';
import '../fonts/fontastic/styles.css'
import 'v-tooltip/dist/v-tooltip.css'
// import VConsole from 'vconsole';
// const vConsole = new VConsole();


//import '../js/vconsole.min.js'
// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);
app.config.devtools = true;


// Register Framework7 Vue components
registerComponents(app);


app.use(store)
// with options
app.use(VueNumerals, {
	locale: 'en'
});
app.use(i18n)
app.use(VueAxios, axios)
app.use(VTooltipPlugin, {
	defaultTrigger: 'hover focus click'
})



// Mount the app
app.mount('#app');

