<template>
  <f7-page ref="page" name="summary" class="summary bg-color-lightgray">
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="position-absolute"
      key="1"
    ></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link href="/panel-left/" icon-only>
          <f7-icon icon="f7-icons icon-menu"></f7-icon>
        </f7-link>
      </f7-nav-left>
    </f7-navbar>

    <f7-toolbar position="top" class>
      <f7-nav-title sliding>{{ PageTitle }} </f7-nav-title>
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link
        v-tooltip="'Dashboard'"
        href="/dashboard/tab-dashboard/"
        icon-only
        tab-link="#tab-1"
      >
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link
        v-tooltip="'SIM List'"
        href="/sim-list/"
        icon-only
        tab-link="#tab-2"
      >
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Commands'"
        href="/commands/"
        icon-only
        tab-link="#tab-3"
      >
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Users'"
        href="/users/"
        icon-only
        tab-link="#tab-4"
      >
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
        <span>Summary</span>
      </f7-link>
    </f7-toolbar>

    <!-- <f7-block-title v-if="!isNoData">Total SIMs</f7-block-title>

    <f7-actions-group
      v-if="isSolutionStatsLoaded && solutionStats.length && !isNoData"
      class="row total-summary no-gap"
    >
      <f7-actions-button
        v-for="item in solutionStats"
        class="col-50 margin-bottom"
        :key="`card-devices-solution-stat-${item.name}`"
      >
        <div :class="`actions-icon bg-color-${item.bgColor} margin-bottom`">
          <i class="icon-sim size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">{{ item.name }}</span>
        <span>{{ item.value }}</span>
      </f7-actions-button>
    </f7-actions-group>
    <f7-list v-else-if="!isSolutionStatsLoaded && !isNoData" no-hairlines>
      <f7-list-item
        header="header"
        title="simInfo.IMSI"
        class="skeleton-text skeleton-effect-blink"
        v-for="n in 4"
        :key="n"
      >
        <template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 100%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list> -->

    <f7-block-title v-if="!isNoData">Total SIMs State</f7-block-title>

    <f7-actions-group
      v-if="!isLoading && simStats.length && !isNoData"
      class="row total-summary no-gap"
    >
      <f7-actions-button
        v-for="item in simStats"
        class="col-50 margin-bottom"
        :key="`card-devices-solution-stat-${item.name}`"
      >
        <div :class="`actions-icon bg-color-${item.bgColor} margin-bottom`">
          <i class="icon-sim size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">{{ item.name }}</span>
        <span>{{ item.value }}</span>
      </f7-actions-button>
    </f7-actions-group>
    <f7-list v-else-if="isLoading && !isNoData" no-hairlines>
      <f7-list-item
        header="header"
        title="simInfo.IMSI"
        class="skeleton-text skeleton-effect-blink"
        v-for="n in 4"
        :key="n"
      >
        <template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 100%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>

    <f7-block-title v-if="!isNoData">
      SMS Usage (in the last 30 days)</f7-block-title
    >

    <f7-actions-group v-if="!isNoData" class="row total-summary no-gap">
      <f7-actions-button class="col-50 margin-bottom" @click="openSimStatus">
        <div class="actions-icon bg-color-orange margin-bottom">
          <i class="icon-mail size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">Received</span>
        <span>{{ receivedSMS }}</span>
      </f7-actions-button>

      <f7-actions-button
        class="col-50 margin-bottom"
        @click="openCustomerDetails"
      >
        <div class="actions-icon bg-color-blue margin-bottom">
          <i class="icon-mail size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">Submitted</span>
        <span>{{ submittedSMS }}</span>
      </f7-actions-button>
    </f7-actions-group>

    <f7-block-title v-if="!isNoData">Other Info</f7-block-title>

    <f7-actions-group class="row total-summary no-gap">
      <f7-actions-button
        class="col-50 margin-bottom"
        v-for="item in otherStats"
        :key="`card-devices-solution-stat-${item.name}`"
      >
        <div :class="`actions-icon bg-color-${item.bgColor} margin-bottom`">
          <i class="icon-status size-20 text-color-white display-flex"></i>
        </div>
        <span class="actions-title">{{ item.name }}</span>
        <span>{{ item.value }}</span>
      </f7-actions-button>
    </f7-actions-group>

    <f7-block v-if="!isLoading && isNoData">
      <p>{{ $t("No Data") }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { QTAPI } from "../services/qt-api";

export default {
  name: "reports",

  props: {
    f7router: Object,
    f7route: Object,
    orgCode: {
      type: String,
      default: () => {
        return "";
      },
    },
  },

  components: {},
  data() {
    return {
      PageTitle: this.f7route.query.orgCode
        ? "Summary User Info"
        : "Total Summary Users Info",
      ownerCode: this.f7route.query.orgCode,

      isLoading: true,
      submittedSMS: 0,
      receivedSMS: 0,
      solutionStats: [],
      simStats: [],
      otherStats: [],
      isSolutionStatsLoaded: false,
      isSimStatsLoaded: false,
      CustomerCode: "00000000-0000-0000-0000-000000000000",
      isNoData: false,
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
      "UserInfo",
      "AssetUpdateKey",
      "IS_ROOT",
      "NewNotificationsCount",
    ]),
  },

  watch: {},

  mounted() {
    // this.getCustomerInfo()
    //   .then((res) => {
    //this.isLoading = false;
    // if(res) {

    // }

    this.getAllStats();
    this.getTotalSMS();
    this.updateOtherStats();
    // })
    // .catch((error) => {
    //   console.error(error);
    //  });
  },

  methods: {
    async getCustomerInfo() {
      //let result = false
      //this.isLoading = true;

      // console.log('this.UserInfo.UserCode, ', this.UserInfo.UserCode)

      let orgCode = this.f7route.query.orgCode || this.UserInfo.UserCode;

      if (orgCode === "00000000-0000-0000-0000-000000000000") {
        orgCode = "";
      }

      let [res1, res2] = await Promise.all([
        //res1,
        QTAPI.GetCartrackCustomer({ OrgCode: orgCode }),
        QTAPI.getLatUpdate(),
      ]);

      if (res1.MajorCode === "200") {
        this.isNoData = true;
        return;
      }

      this.CustomerCode = res1.Data ? res1.Data : this.MajorToken; //
      let data = {
        MajorToken: this.CustomerCode,
        MinorToken: this.f7route.query.orgCode
          ? this.f7route.query.orgCode
          : "00000000-0000-0000-0000-000000000000",
        LastUpdate: res2.Data.JTITFILE_CREATE_TIME,
      };
      // if (this.CustomerCode !== "00000000-0000-0000-0000-000000000000") {
      let result = await QTAPI.GetUsageOverview(data);
      //}
      if (!result) {
        this.isNoData = true;
      }

     // console.log("result", result);
      //let result2 = await QTAPI.CustomerSummary({ CustomerCode: res1.Data });
      return result;
    },

    async getAllStats() {
      let self = this;
      self.isLoading = true;

      const statuses = [
        { status: "Suspended", bgColor: "blue" },
        { status: "OnStock", bgColor: "orange" },
        { status: "Productive", bgColor: "green" },
        { status: "TestProductive", bgColor: "lilac" },
      ];

      try {
        const statsPromises = statuses.map(async (status) => {
          const response = await QTAPI.GetSimStatusTotal({
            "Status[]": status.status,
            OrganizeCode: self.ownerCode,
          });

          const value = response.Total ? response.Total + " " : "0 ";

          self.simStats.push({
            name: status.status,
            value: value,
            bgColor: status.bgColor,
          });
        });

        await Promise.all(statsPromises);
      } catch (error) {
        console.error("Error fetching status totals:", error);
      } finally {
        self.isLoading = false;
      }
    },

    async getTotalSMS() {
      let self = this;
      self.isLoading = true;
      try {

        console.log('this.f7route.query.orgCode || this.UserInfo.UserCode', this.f7route.query.orgCode , this.UserInfo.UserCode)
        let result = await QTAPI.getTotalSMS({
          Token: "00000000-0000-0000-0000-000000000000",
          UserCode:  this.f7route.query.token
            ? this.f7route.query.token
            : self.MajorToken

        });

        self.receivedSMS = result.Data.SmsMtLast30; 
        self.submittedSMS = result.Data.SmsM0Last30;
      } catch (error) {
        console.error("Error fetching sms totals:", error);
      } finally {
        self.isLoading = false;
      }
    },
    async updateOtherStats() {
      let self = this;
      self.isLoading = true;

      const ragStatuses = [
        { color: "GREEN", variable: "totalGreen", name: 'Online' },
        { color: "YELLOW", variable: "totalYellow", name: 'Reporting' },
        { color: "RED", variable: "totalRed", name: 'Offline' },
      ];

      self.otherStats = [];

      try {
        for (const status of ragStatuses) {
          let data = {
            "RAGs[]": status.color,
            OrganizeCode: self.ownerCode ? self.ownerCode : self.UserInfo.OrganizeCode,
          };

          let response = await QTAPI.getSIMInfoTable(data);

         // console.log('response', response)

          let total = response.total ? response.total + " " : "0 ";


          

          self.otherStats.push({
            name: status.name,
            value: total,
            bgColor: status.color.toLowerCase(),
          });

         //ole.log('self.otherStats', self.otherStats)
        }
      } catch (error) {
        console.error("Error updating other stats:", error);
      } finally {
        self.isLoading = false;
      }
    },
  },

  beforeDestroy() {},
};
</script>

 
 