<template>
  <f7-block-title>{{ title }}</f7-block-title>
  <f7-list class="no-margin-top no-hairline-bottom">
    <f7-list-input
        type="email"
        :error-message="emailErrorMsg"
        :error-message-force="isEmailInvalid"
        name="email"
        :label="$t('AddEmail')"
        :placeholder="$t('AddEmail')"
        v-model:value.trim="Email"
    >
      <template #media>
        <f7-icon icon="f7-icons icon-email text-color-lightgray"></f7-icon>
      </template>
      <template #content-end>
        <f7-button @click="addNewEmail" fill color="green" class="margin-right min-width-36"><f7-icon icon="icon f7-icons icon-header-added" class="size-15"></f7-icon></f7-button>
      </template>
    </f7-list-input>

    <f7-list-input
        v-for="(emailItem, index) of newEmailList" :key="index"
        type="email"

        name="email"
        :label="$t('Email')"
        :placeholder="$t('Email')"
        :value.trim="emailItem"
        readonly
    >
      <template #media>
        <f7-icon icon="f7-icons text-color-lightgray"></f7-icon>
      </template>
      <template #content-end>
        <f7-button @click="removeEmail" fill color="red" class="margin-right min-width-36"><f7-icon icon="icon f7-icons icon-close" class="size-15"></f7-icon></f7-button>
      </template>
    </f7-list-input>
  </f7-list>
</template>

<script>
import {isEmailValid} from '../js/helpers'
export default {
  name: "email-list",

  props: {
    title: {
      type: String,
      default: '',
    },
    emails: {
      type: [Array, String],
      /*default: function (){
        return []
      }*/
    }
  },

  emits: ['update:emails'],

  data(){
    let newEmailList = []
    if(this.emails && this.emails.length){
      if(Array.isArray(this.emails)){
        newEmailList = this.emails
      }else if(this.emails.indexOf('\r\n') > -1){
        newEmailList = this.emails.split('\r\n')
        newEmailList.forEach(itm => itm.trim())
      }else{
        newEmailList = this.emails.split(',')
        newEmailList.forEach(itm => itm.trim())
      }
    }
    return {
      isEmailInvalid: false,
      Email: null,
      newEmailList,
      emailErrorMsg: this.$t('InvalidEmail')
    }
  },

  mounted() {

  },

  methods: {
    addNewEmail(){
      if(!this.Email){
        return
      }

      if(!isEmailValid(this.Email)){
        this.emailErrorMsg = this.$t('InvalidEmail')
        this.isEmailInvalid = true
        return
      }

      if(!this.isUnique(this.Email)){
        this.emailErrorMsg = this.$t('EmailAlreadyAdded')
        this.isEmailInvalid = true
        return
      }

      this.newEmailList.push(this.Email)
      this.Email = ''
      this.isEmailInvalid = false
      this.$emit('update:emails', this.newEmailList)
    },
    removeEmail(index){
      this.newEmailList.splice(index, 1)
      this.$emit('update:emails', this.newEmailList)
    },
    isUnique(value){
      let index = this.newEmailList.findIndex( itm => itm === value)
      return index === -1
    }
  }
}
</script>

<style scoped>

</style>