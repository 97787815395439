<template>
  <f7-page name="sim-connectivity" class="sim-connectivity bg-color-lightgray" ref="page" :page-content="false">
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back">
      </f7-nav-left>
      <f7-nav-right>

      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar top class="toolbar-font-size-12">
      <f7-nav-title sliding>{{ $t('Connectivity') }}</f7-nav-title>
      <div
      class="display-flex"
      style="justify-content: space-between; width: 100%"
    >
      <f7-link v-if="isShowHLR" href="/sim/connectivity/hlr-info/" route-tab-id="tab-hlr-info" tab-link>{{ $t('HLRInfo') }}</f7-link>
      <f7-link v-if="isShowSession" href="/sim/connectivity/current-session/" route-tab-id="tab-current-session" tab-link>{{ $t('CurrentSession') }}</f7-link>
      <f7-link  v-if="isShowSession" href="/sim/connectivity/sessions-data/" route-tab-id="tab-sessions-data" tab-link>{{ $t('SessionsData') }}</f7-link>
      </div>
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link  v-tooltip="'Dashboard'" href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link  v-tooltip="'SIM List'" href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link  v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'" href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Summary'" href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>
  

    <f7-tabs :routable="true">
    
      <f7-tab id="tab-hlr-info" class="page-content"></f7-tab>
      <f7-tab id="tab-current-session" class="page-content"></f7-tab>
      <f7-tab id="tab-sessions-data" class="page-content"></f7-tab>
 
    </f7-tabs>

 

  </f7-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "sim-connectivity",
  computed: {
    ...mapGetters([,
      "Permissions",
    ]),
  },
  data(){
    return {
      isShowHLR: true,
      isShowSession: true,
    }
  },

  mounted() {
  //  console.log('this.Permissions', this.Permissions)
    this.isShowSession = this.Permissions.SIM_SHOW_SESSIONS>0
    this.isShowHLR =		this.Permissions.SIM_SHOW_HLR>0 
  }
}
</script>

<style scoped>

</style>