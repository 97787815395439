<template>
  <f7-page name="sim-position">
    <!-- Top Navbar -->
    <f7-progressbar v-if="isLoading" infinite color="green" class="progressbar-top"></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back">
      </f7-nav-left>
       <f7-nav-right>
        
        <f7-link @click="getSimLocation" href="#">
          <i class="icon-refresh size-20"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'" href="/sim-list/"  icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link  v-tooltip="'Commands'" href="/commands/"  icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'" href="/users/"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Summary'"  href="/summary/"  icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    
    </f7-toolbar>
    <f7-toolbar position="top" class>
      <f7-nav-title sliding
     >Location</f7-nav-title
   > 
   </f7-toolbar>
 

    <div class="map-wrapper">
      <l-map
          ref="map"
          :zoom="zoom"
          :center="center"
          style="height: 100%"
          :options="{zoomControl: false, tap: false, preferCanvas: true}"
      >
        <l-control-layers position="topright"  ></l-control-layers>
        <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :subdomains="tileProvider.subdomains"
            layer-type="base"/>

        <l-marker
            v-if="markerLatLng.length"
            :lat-lng="markerLatLng"
            :icon="markerIcon"
        >
          <!--<l-icon :icon-url="iconUrl" :icon-size="[40,40]" :icon-anchor="[20, 39]" />-->

          <l-popup>
            <table cellpadding="0" cellspacing="0" border="0" class="marker-data-table">
              <tbody>
              <!-- <tr>
                <td class="marker-data-caption">{{ $t('Name') }}</td>
                <td class="marker-data-value">{{ f7route.query.name }}</td>
              </tr> -->
              <tr>
                <td class="marker-data-caption">Update Time

                <i class="f7-icons text-color-gray icon-hint margin-left-4 size-14 popover-open" style="cursor:pointer" data-popover=".popover-about"></i>
              
                  <div class="popover popover-about">
                    <div class="popover-inner">
                    <div class="block">
                      <p>Position Type: {{posType}}</p>
                      <p>Update Type: {{updType}}</p>
                    </div>
                    </div>
                  </div>

                </td>
                <td class="marker-data-value">{{ mapPopupData.hasOwnProperty('DataUpdateTime') ? dateFormat(mapPopupData?.DataUpdateTime+'+0000', 'datetime') : dateFormat(mapPopupData.StateUpdateTime+'+0000', 'datetime')}}</td>
              </tr>
              <!-- <tr>
                <td class="marker-data-caption">{{ $t('PositionTime') }}</td>
                <td class="marker-data-value">{{ dateFormat(mapPopupData.DateTime+'+0000', 'datetime') }}</td>
              </tr> -->
              
              <tr v-if="productFeatures & 4096">
                <td class="marker-data-caption">{{ $t('Temperature') }}</td>
                <td class="marker-data-value">{{featureTemperature}}</td>
              </tr>
              <tr v-if="productFeatures & 16">
                <td class="marker-data-caption">{{ $t('Mileage') }}</td>
                <td class="marker-data-value">{{featureMileage}}</td>
              </tr>
              <tr v-if="productFeatures & 8" >
                <td class="marker-data-caption">{{ $t('Speed') }}</td>
                <td class="marker-data-value">{{ mapPopupData.Speed }} {{ speedUnit }}</td>
              </tr>
              <tr v-if="productFeatures & 128">
                <td class="marker-data-caption">{{ $t('Ignition') }}</td>
                <td class="marker-data-value">{{featureACC}}</td>
              </tr>
              <tr v-if="productFeatures & 512">
                <td class="marker-data-caption">{{ $t('Voltage') }}</td>
                <td class="marker-data-value">{{featureVoltage}}</td>
              </tr>
              <tr v-if="productFeatures & 1024">
                <td class="marker-data-caption">{{ $t('Battery') }}</td>
                <td class="marker-data-value">{{featureBattery}}</td>
              </tr>
              <tr v-if="productFeatures & 2">
                <td class="marker-data-caption">{{ $t('Alt') }}</td>
                <td class="marker-data-value">{{featureAlt}}</td>
              </tr>
              <tr v-if="productFeatures & 2048">
                <td class="marker-data-caption">{{ $t('Fuel') }}</td>
                <td class="marker-data-value">{{featureFuel}}</td>
              </tr>
              <tr v-if="productFeatures & 16384">
                <td class="marker-data-caption">{{ $t('RFID/IButton') }}</td>
                <td class="marker-data-value">{{featureRFid}}</td>
              </tr>
              <tr v-if="productFeatures & 65536">
                <td class="marker-data-caption">{{ $t('DrivingTime') }}</td>
                <td class="marker-data-value">{{featureDrivingTime}}</td>
              </tr>
              <tr v-if="productFeatures & 262144">
                <td class="marker-data-caption">{{ $t('Heartrate') }}</td>
                <td class="marker-data-value">{{featureHeartrate}}</td>
              </tr>
              <!--<tr>
                <td class="marker-data-caption">{{ $t('Mileage') }}</td>
                <td class="marker-data-value">{{ mapPopupData.mileage }} {{ mileageUnit }}</td>
              </tr>-->
              <tr v-if="productFeatures & 4">
                <td class="marker-data-caption">{{ $t('Direction') }}</td>
                <td class="marker-data-value">{{ directionFormat(mapPopupData.Direct) }}</td>
              </tr>
              <tr v-if="productFeatures & 1">
                <td class="marker-data-caption">{{ $t('GPS') }}</td>
                <td class="marker-data-value marker-address asset-address">{{ parseFloat(mapPopupData.Lat).toFixed(4) }}, {{ parseFloat(mapPopupData.Lng).toFixed(4) }}</td>
              </tr>
              <tr v-if="productFeatures & 1">
                <td class="marker-data-caption">{{ $t('Address') }}</td>
                <td class="marker-data-value marker-address asset-address">{{ customAddress ? customAddress : $t('Loading') }}</td>
              </tr>
              </tbody>
            </table>
          </l-popup>
        </l-marker>

      </l-map>
    </div>
  </f7-page>

</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LControlLayers, LPopup } from "@vue-leaflet/vue-leaflet";
import {mapGetters} from "vuex";
import { f7 } from 'framework7-vue';
import { dateFormat, directionText, getBatteryValue, getBatteryUnit, getFeatureValueByClass } from "../../js/helpers";
import { QTAPI } from "../../services/qt-api"

export default {
  name: "sim-position",
  components: {
    LMap, LTileLayer, LMarker, LControlLayers, LPopup
  },

  props: {
    f7router: Object,
    f7route: Object,
  },

  computed: {
    ...mapGetters(['MinorToken'])
  },

  data: function(){

    return {
      pageTitle: this.$t('LastUpdate'),
      isLoading: false,
      isIncorrectData: false,

      zoom: 9,
      center: L.latLng(47.41322, -1.219482),
      map: '',
      posType: '',
      updType: '',
      
      productFeatures: 0,
      featureStatus: '',
      featureInput1: 'Off',
      featureInput2: 'Off',
      featureInput3: 'Off',
      featureOutput1: 'Off',
      featureOutput2: 'Off',
      featureVoltage: '',
      featureBattery: '',
      currentAlramSetting: '',
      featureTemperature: '-',
      featureMileage: '-',
      featureFuel: '-',
      featureRfid: '-',
      featureDrivingTime: '-',
      featureAlt: '-',
      featureHeartrate: '-',
      featureACC: '-',


      tileProviders: [
        {
          name: 'Map',
          visible: true,
          url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          //subdomains: ['mt0','mt1','mt2','mt3']
          subdomains: ['mt0','mt1','mt2','mt3']
        },
        {
          name: 'OpenStreetMap',
          visible: false,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Satelitte',
          visible: false,
          url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          subdomains: ['mt0','mt1','mt2','mt3']
        },
      ],

      markerIcon: L.icon({
        iconUrl: './static/images/map-marker.svg',
        iconSize:     [40, 40], // size of the icon
        iconAnchor:   [20, 39], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
      }),
      markerLatLng: [],
      mapPopupData: {},
      mileageUnit: 'km',
      speedUnit: 'km/h',
      //assetName: f7.views.main.router.currentRoute.query.name,
      customAddress: ''
    }
  },

  async mounted() {
    //console.log('imsi', this.f7route.query.imsi)
    if(this.f7route.query.imsi){ 
      this.getSimLocation()
    //  this.getPosData(this.f7route.query.imei)
    }
  },

  methods: {
    directionFormat(direction){
      return directionText(direction) + `(${direction}°)`
    },
    getBatteryValue(...options){
      return getBatteryValue(...options)
    },
    getBatteryUnit(...options){
      return getBatteryUnit(...options)
    },
    dateFormat(...options){
      return dateFormat(...options)
    },
     
    async getPosData(imei){
      let AssetCode = ''
      /*let response = await this.$store.dispatch("AUTH2", {
          account: this.selectedAsset.CustomerCode,//MajorToken.value,
          password: this.selectedAsset.UserCode,//MinorToken.value,
        });*/
    
        const response = await QTAPI.AUTH1({
          /*account: this.f7route.query.customer,//MajorToken.value,
          password: this.f7route.query.user,//MinorToken.value,
          appKey: 'e821ed8e-5fab-6e86-a51d-c171efc89ed0',
          mobileToken: 'fe738c48-f043-33e4-6da4-916627e884de',
          deviceToken: 'fe738c48-f043-33e4-6da4-916627e884de',
          deviceType:'browser'*/
          imei,
        });

        if (!response) {
          return;
        }
        let currentAsset = response?.Data //AssetArray?.filter(el => el.includes(imei))
        if(currentAsset){ //?.length
          
          this.productFeatures = currentAsset[12]
          AssetCode = currentAsset[0]
          this.isLBS = currentAsset[44]
        }
      
      var data = {
        MajorToken: this.f7route.query.customer,//MajorToken.value,
        MinorToken: this.f7route.query.user,//MinorToken.value,
        codes: AssetCode
      };
      
      //let result = await this.$store.dispatch("VIEW_POSITION", data);
      const result = await QTAPI.VIEW_POSITION(data);

        console.log('status',result.Data[0][20])
      if (result.Data[0][20] & 512) { 
        this.featureInput1 = 'On' 
      }
      if (result.Data[0][20] & 128) { 
        this.featureInput2 = 'On' 
      }
      if (result.Data[0][20] & 1024) { 
        this.featureInput3 = 'On' 
      }
      if (result.Data[0][20] & 4096) { 
        this.featureOutput1 = 'On' 
      }
      if (result.Data[0][20] & 8192) { 
        this.featureOutput2 = 'On' 
      }

      /*
      if (typeof result.Data[0][14] == "undefined") {
        this.featureTemperature = '-';
      } else {
        this.featureTemperature = result.Data[0][14].toFixed(2) + '&nbsp;°C';
      }*/
      
      //this.featureMileage = (this.getMileageValue('KPH', result.Data[0][17]) + parseInt(currentAsset[0][6]) + parseInt(currentAsset[0][21])) + ' km';
    
      
      this.featureHeartrate = getFeatureValueByClass(result.Data[0][2], result.Data[0], 262144)
      this.featureAlt = getFeatureValueByClass(result.Data[0][2], result.Data[0], 2)
      this.featureFuel = getFeatureValueByClass(result.Data[0][2], result.Data[0], 2048, currentAsset[19],currentAsset[20])
      this.featureRfid = getFeatureValueByClass(result.Data[0][2], result.Data[0], 16384)
      this.featureDrivingTime = getFeatureValueByClass(result.Data[0][2], result.Data[0], 65536)
      this.featureTemperature = getFeatureValueByClass(result.Data[0][2], result.Data[0], 4096)
      this.featureMileage = (this.getMileageValue('KPH', result.Data[0][17]) + parseInt(currentAsset[6]) + parseInt(currentAsset[21])) + ' km';
      this.featureVoltage = getFeatureValueByClass(result.Data[0][2], result.Data[0], 512)
      this.featureBattery = getFeatureValueByClass(result.Data[0][2], result.Data[0], 1024)
      
      if (result.Data[0][20] & 1) {
        this.featureACC = 'ON';
      } else {
        this.featureACC = 'OFF';
      }
      

      /*this.GSMnetwork = + result.Data[0][11] > 0 ? result.Data[0][11] : 'N/A'
      this.posType = result.Data[0][3]
      this.GPS = result.Data[0][10]
      console.log('pos',result.Data)
      let positionHours = 0
      if(result.Data[0][5] !== null) {
          this.positionTime = result.Data[0][5];
          positionHours = moment(result.Data[0][5]?.split('.')[0]).add(CurrentTimeZone, 'hours');
      }
      let CurrentTimeZone = moment().utcOffset() / 60;
      let dateTimeSecond = 24* 600 * 60 * 1000;
      if(result.Data[0][11] !== null) {
          try{
              dateTimeSecond = Math.abs(moment(moment(positionHours.toDate()).add('2', 'hours').toDate()).diff(moment(moment(moment().toDate()).add((moment().utcOffset()/60),'hours').toDate()), 'milliseconds'));
          }catch(error){
              console.log(error);
          }
      }
      this.GPSstate = 'color-softgrey';
      this.LBSstate = 'color-softgrey';
      if(result.Data[0][12]===0||result.Data[0][13]===0||dateTimeSecond > 40 * 60 * 1000){
          this.GPSstate = 'color-softgrey';
      }
      console.log('dateTimeSecond',dateTimeSecond, 40 * 60 * 1000 )
      if(dateTimeSecond > 5 * 60 * 60 * 1000){
        this.LBSstate = 'color-softgrey';
      }
      if(parseInt(result.Data[0][16]) > 0){
        this.LBSstate = 'color-blue';
      }
      if(dateTimeSecond > 72 * 60 * 60 * 1000){
        this.LBSstate = 'color-red';
      }
      else if(dateTimeSecond > 24 * 60 * 60 * 1000){
        this.LBSstate = 'color-orange';
      }
      else if(dateTimeSecond > 12 * 60 * 60 * 1000){
        this.LBSstate = 'color-softgrey';
      }else{
        this.LBSstate = 'color-blue';
      }

      if(dateTimeSecond > 48 * 60 * 60 * 1000){
        this.GPSstate = 'color-softgrey';
      }
      else {
        this.GPSstate = 'color-softgreen';
      }*/

      this.isLoading = false

      if(!result){
        return
      }
      

    },
    capitalize: function (s) {
			if (typeof s !== 'string') return ''
			return s.charAt(0).toUpperCase() + s.slice(1)
		},
    getMileageValue: function (speedUnit, mileage) {
			var ret = 0;
			switch (speedUnit) {
				case "KT":
					ret = parseInt(mileage * 0.53995680345572);
					break;
				case "KPH":
					ret = parseInt(mileage);
					break;
				case "MPS":
					ret = parseInt(mileage * 1000);
					break;
				case "MPH":
					ret = parseInt(mileage * 0.62137119223733);
					break;
				default:
					break;
			}
			return ret;
		},
    async ForceReconnect() {
      this.isLoading = true
      const response = await QTAPI.ForceReconnect({
        IMSIs: this.f7route.query.imsi
      })
      this.isLoading = false
      if (!response) {
        f7.dialog.alert(this.$t('NoLocationData'), this.$t('Error'))
        return
      }
      f7.dialog.alert(this.$t('SimConnectionRefreshed'), this.$t('Success'))
    },
    async getSimLocation() {
      this.isLoading = true
      const response = await QTAPI.GetSimLocation({
        imsi: [this.f7route.query.imsi]
      })
      this.isLoading = false
   
      if (!response || !response.LbsLat || !response.LbsLng) {
        f7.dialog.alert(this.$t('NoLocationData'), this.$t('Error'))
        this.isIncorrectData = true
        return
      }
      this.isIncorrectData = false

      let prevCoords = {
        Lat: this.mapPopupData.LbsLat,
        Lng: this.mapPopupData.LbsLng,
      }
      this.mapPopupData = response
      console.log('response', response)
      if (prevCoords.LbsLat !== response.LbsLat || prevCoords.LbsLng !== response.LbsLng){
        this.getAddress({Lat: response.LbsLat, Lng: response.LbsLng})
      }
      this.center = [response.LbsLat, response.LbsLng]
      this.markerLatLng = [response.LbsLat, response.LbsLng]

      console.log('this.mapPopupData', this.mapPopupData)
    },
    async getAddress(coords){
      const response = await QTAPI.GetAddressByCoords({
        latitude: coords.Lat,
        longitude: coords.Lng
      })
      if (!response) {
        return
      }
      this.customAddress = response.display_name
    },
  }
}
</script>

<style scoped lang="scss">

</style>