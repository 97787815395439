<template>
  <f7-page
    ref="page"
    name="sims"
    class="bg-color-lightgray sim-list"
    infinite
    :infinite-distance="100"
    :infinite-preloader="isLoading"
    @infinite="onLoadMore"
    @page:beforein="handlePageBeforeIn"
    @page:beforeout="handlePageBeforeOut"
  >
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      key="1"
    ></f7-progressbar>
    <f7-navbar :sliding="false" :back-link="false">
      <f7-nav-left>
        <f7-link href="/panel-left/" icon-only>
          <f7-icon icon="f7-icons icon-menu"></f7-icon>
        </f7-link>
      </f7-nav-left>

      <f7-nav-right>
        <f7-link icon-only @click="scanBarCode">
          <f7-icon icon="f7-icons icon-barcode"></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar position="top" class>
      <f7-nav-title sliding
        >{{ $t("SimList") }}
        {{ !customerName ? "" : "- " + customerName }}</f7-nav-title
      >

      <form class="searchbar custom-searchbar" @submit.prevent="onSearchSubmit">
        <div class="searchbar-inner">
          <div class="searchbar-input-wrap">
            <input
              id="searchInput"
              type="search"
              v-model.trim="search"
              :placeholder="$t('SearchbyIMSIICCIDorMSISDN')"
            />
            <span class="input-clear-button"></span>
            <button type="submit" class="submit-button ripple">
              <i class="icon icon-other-search text-color-black"></i>
            </button>
          </div>
        </div>
      </form>
    </f7-toolbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link
        v-tooltip="'Dashboard'"
        icon-only
        href="/dashboard/tab-dashboard/"
        tab-link="#tab-1"
      >
        <i class="icon-reports text-color-green size-18"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
        <span>SIMs</span>
      </f7-link>
      <f7-link
        v-tooltip="'Commands'"
        href="/commands/"
        icon-only
        tab-link="#tab-3"
      >
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'" href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Summary'"
        href="/summary/"
        icon-only
        tab-link="#tab-5"
      >
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-block v-if="!isFirstSearchDone">
      <p v-html="$t('SearchDescription')"></p>
    </f7-block>
    <f7-block v-else-if="!isLoading && isNoData && queryPage === 1">
      <p>{{ $t("NoSimFound") }}</p>
    </f7-block>
    <!-- <input
      id="numberTxt"
      placeholder="Enter mobile number"
      v-model="phoneNumber"
      type="tel"
    />
    <textarea
      id="messageTxt"
      v-model="message"
      placeholder="Enter message"
    ></textarea>
    <input type="button" @click="sendSMS" value="Send SMS" /> -->

    <f7-list
      ref="vl"
      class="vl vl-assets no-margin-top media-center bigger-item-text"
      virtual-list
      media-list
      no-hairlines
      no-chevron
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      :virtual-list-params="{ items, renderExternal, height: heightCalc }"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          link="#"
          :key="index"
          :style="`top: ${vlData.topPosition}px`"
          @click="openItemMenu(item, index)"
        >
          <template #media>
            <!-- <img
              v-if="item.Photo && /^IMEI_/i.test(item.Photo)"
              slot="media"
              :src="assetImageURL + item.Photo"
              alt
            /> -->
            <div class="bg-color-blue icon-sim_container">
              <f7-icon class="icon-sim text-color-white"></f7-icon>
              <span
                class="status"
                :class="getAssetStatusClass(item.State)"
              ></span>
            </div>
          </template>
          <template #text>
            <div>
              {{ $t("IMSI") }}:
              <b>{{ item.IMSI ? item.IMSI : $t("na") }}</b>
            </div>
            <div>
              {{ $t("ICCID") }}:
              <b>{{ item.ICCID ? item.ICCID : $t("na") }}</b>
            </div>
            <div>
              {{ $t("MSISDN") }}:
              <b>{{ item.SimMSISDN ? item.SimMSISDN : $t("na") }}</b>
            </div>
            <div>
              {{ $t("State") }}:
              <b>{{ item.State ? item.State : $t("na") }}</b>
            </div>
            <div>
              {{ $t("Owner") }}:
              <b>{{ item.OrgName ? item.OrgName : $t("na") }}</b>
            </div>
          </template>
        </f7-list-item>
      </ul>
    </f7-list>

    <sim-menu 
      v-model:isOpened="isSimMenuOpened" 
      :simData="selectedSimData" 
      v-if="isPermissionsLoaded" 
      @menu-opened:isOpened="initializePermissions"
    />
  </f7-page>
</template>

<script>
//import VConsole from 'vconsole';
import { HideKeyboard, getAssetTypeIcon } from "../../js/helpers";
import { f7, theme } from "framework7-vue";
import { mapGetters } from "vuex";
import { ApiMethods, QTAPI } from "../../services/qt-api";
import $$ from "dom7";
import VConsole from "vconsole";

import SimMenu from "./components/sim-menu";

export default {
  name: "sims",

  props: {
    f7router: Object,
    f7route: Object,
  },

  components: {
    SimMenu,
  },
  data() {
    return {
      phoneNumber: "",
      message: "",
      isLoading: false,
      isFirstSearchDone: false,
      isNoData: false,
      isInfiniteAllowed: true,
      isSimMenuOpened: false,
      assetImageURL: ApiMethods.ASSET_IMAGE_URL,

      items: [],
      vlData: {
        items: [],
      },
      vl: null,
      queryPage: 1,
      search: "",
      selectedSimData: {},

      customerName: this.f7route.query.customerName,
      customerCode: this.f7route.query.customerCode,
      newNotification: false,
     // isPermissionsLoaded: false,
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
      "UserInfo",
      "AssetUpdateKey",
      "isPermissionsLoaded",
      "NewNotificationsCount",
    ]),
  },

  watch: {
    AssetUpdateKey() {
      this.onSearchSubmit();
    },

    NewNotificationsCount() {
      this.newNotification = true;
    },
    // isPermissionsLoaded(newVal) {
    //    this.isPermissionsLoaded = newVal;
    // }
  },

  async mounted() {
    //const vConsole = new VConsole();

    //const vConsole = new VConsole();
    //console.log('Current NODE_ENV:', process.env.NODE_ENV);
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
    //console.log(this.$refs.vl.$el)
    //if(this.customerCode){
    this.onSearchSubmit();
    //}

    this.checkUpdate();
    console.log('isPermissionsLoaded', this.isPermissionsLoaded)

  },

  methods: {
    initializePermissions() {
      console.log('df')
      console.log('isShowSimStatus', this.isShowSimStatus)

      this.isShowCommands =
          newPermissions.SMS > 0 &&
          (newPermissions.SMS_SEND_CUSTOM > 0 || newPermissions.SMS_SEND_SYSTEM > 0);

        this.isShowCommandsHistory = newPermissions.SIM_SHOW_SMS_HISTORY > 0;
        this.isShowSimStatus = newPermissions.SIM_PROFILE > 0;
        this.isShowReports = newPermissions.REPORTS > 0;

        this.isShowConnectivity =
          newPermissions.SIM_SHOW_SESSIONS > 0 && newPermissions.SIM_SHOW_HLR > 0;
    },
    checkUpdate() {
      document.addEventListener(
        "deviceready",

        function () {
          console.log("deviceready");
          var onSuccessCheck = function (strSuccess) {
            console.log("Update availability: " + strSuccess);
            if (strSuccess === "UPDATE_AVAILABLE") {
              updateImmediate();
            } else {
              console.log(
                "No updates available or other status: " + strSuccess
              );
            }
          };

          var onFailureCheck = function (strError) {
            console.warn("Error checking for update: " + strError);
          };

          cordova.plugins.InAppUpdate.getUpdateAvailability(
            onSuccessCheck,
            onFailureCheck
          );

          function updateImmediate() {
            var onSuccessImmediate = function (strSuccess) {
              console.log("Immediate update status: " + strSuccess);
            };

            var onFailureImmediate = function (strError) {
              console.warn("Error during immediate update: " + strError);
            };

            cordova.plugins.InAppUpdate.updateImmediate(
              onSuccessImmediate,
              onFailureImmediate
            );
          }
        },
        false
      );
    },  
    sendSMS() {
      this.checkSMSPermission();
    },
    checkSMSPermission() {
      var success = (hasPermission) => {
        if (hasPermission) {
          this.sendSMSMessage();
        } else {
          this.requestSMSPermission();
        }
      };
      var error = (e) => alert("Something went wrong:" + e);
      window.sms.hasPermission(success, error);
    },
    requestSMSPermission() {
      var success = (hasPermission) => {
        if (!hasPermission) {
          window.sms.requestPermission(
            () => {
              console.log("[OK] Permission accepted");
              this.sendSMSMessage();
            },
            (error) => {
              console.info("[WARN] Permission not accepted");
              console.log("window.sms", window.sms);
              console.log("cordova", cordova);
              console.log("sms", sms);
              // Обработка случая, когда разрешение не получено
            }
          );
        }
      };
      var error = (e) => alert("Something went wrong:" + e);
      window.sms.hasPermission(success, error);
    },
    sendSMSMessage() {
      window.sms.send(
        this.phoneNumber,
        this.message,
        { replaceLineBreaks: true, android: { intent: "" } },
        () => {
          alert("SMS успешно отправлено!");
        },
        (error) => {
          alert("Произошла ошибка при отправке SMS: " + error);
        }
      );
    },

    openAssetEdit() {
      f7.views.main.router.navigate(
        {
          name: "asset-edit",
          //query: { imei: item.IMEI },
        },
        {
          // props: { simData: this.simData }
        }
      );
    },
    getAssetTypeIcon(assetType) {
      return !assetType ? "assets" : getAssetTypeIcon(assetType);
    },
    getAssetStatusClass(rag) {
      let ragColors = {
        Deleted: "status-red",
        Deleting: "status-red",
        OnStock: "status-blue",
        EuiccControl: "status-gray",
        Suspended: "status-orange",
        Productive: "status-green",
        TestProductive: "status-aqua",
        Ordered: "status-aqua",
      };
      return ragColors[rag] ? ragColors[rag] : "status-gray";
    },
    onSearchSubmit() {
      //hide keyboard at phones
      HideKeyboard();
      //fix to not loadMore when user has scrolled down list
      $$(this.$refs.page.$el)
        .find(".page-content.infinite-scroll-content")
        .scrollTo(0, 0);

      this.queryPage = 1;
      this.isInfiniteAllowed = true;
      this.getData();
    },
    onLoadMore() {
      if (!this.isInfiniteAllowed) return;
      this.isInfiniteAllowed = false;
      ++this.queryPage;
      this.getData();
    },
    async getData() {
      var data = {
        // MajorToken: this.MajorToken,
        // MinorToken: this.MinorToken,

        // page: this.queryPage,
        // rows: 20,
        // sort: "IMEI",
        // order: "asc",

        q: this.search,

        Page: this.queryPage,
        Rows: 10,
        Order: "ASC",
        Sort: "IMSI",
      };
      if (this.customerCode) {
        data.CsCode = this.customerCode;
      }
      this.isLoading = true;

      // f7.dialog.preloader();
      let result = await QTAPI.GetSimList(data);

      this.isLoading = false;

      this.isFirstSearchDone = true;
      if (!result) {
        return;
      }
      // console.log("result", result);
      this.isNoData = !result.length;
      if (this.queryPage === 1) {
        if (this.isNoData) {
          this.vl.deleteAllItems();
          return;
        }
        this.vl.replaceAllItems(result);
      } else {
        if (this.isNoData) {
          return;
        }
        this.vl.appendItems(result);
        this.isInfiniteAllowed = true;
      }
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc(item) {
      if (!item.Name) {
        return theme.ios ? 118 : 122;
      }
      return theme.ios ? 141 : 146;
    },

    openItemMenu(item) {
      console.log("item", item);
      this.selectedSimData = item;
      this.isSimMenuOpened = true;
    },
    scanBarCode() {
      let self = this;
      //let input = $$(this).siblings('input');
      let input = $$(this.$refs.page.$el).find("#searchInput");

      let permissions = cordova.plugins.permissions;
      if (!permissions) {
        console.log("bar-code-scanner plugin not supported");
        //App.alert('plugin not supported')
      } else {
        permissions.checkPermission(permissions.CAMERA, function (status) {
          if (status.checkPermission) {
            self.openBarCodeReader(input);
          } else {
            permissions.requestPermission(
              permissions.CAMERA,
              function (status1) {
                self.openBarCodeReader(input);
                if (!status1.checkPermission)
                  console.log("requestPermissionCameraError");
                //error();
              },
              //requestPermissionCameraError
              console.log("requestPermissionCameraError")
            );
          }
        });
      }
    },
    openBarCodeReader(input) {
      //console.log(input);
      if (window.device && cordova.plugins && cordova.plugins.barcodeScanner) {
        cordova.plugins.barcodeScanner.scan(
          function (result) {
            /*alert("We got a barcode\n" +
                "Result: " + result.text + "\n" +
                "Format: " + result.format + "\n" +
                "Cancelled: " + result.cancelled);*/
            if (result && result.text) {
              input.val(result.text);
              input.change(); // fix to trigger onchange / oninput event listener
            }
          },
          function (error) {
            alert("Scanning failed: " + error);
          },
          {
            //preferFrontCamera : true, // iOS and Android
            showFlipCameraButton: true, // iOS and Android
            showTorchButton: true, // iOS and Android
            torchOn: true, // Android, launch with the torch switched on (if available)
            //saveHistory: true, // Android, save scan history (default false)
            prompt: "Place a barcode inside the scan area", // Android
            resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            //formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
            //orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
            //disableAnimations : true, // iOS
            //disableSuccessBeep: false // iOS and Android
          }
        );
      } else {
        App.alert("Your device does not support this function");
      }
    },
    handlePageBeforeIn() {
      this.showBackButton = false;
    },
    handlePageBeforeOut() {
      this.showBackButton = true;
    },
  },

  beforeDestroy() {},
};
</script>

<style lang="scss">
.icon-sim_container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  .icon-sim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}
.sim-list .media-list li.media-item:nth-last-child(1) {
  margin-bottom: calc(
    var(--f7-toolbar-height) + var(--f7-safe-area-bottom) + 20px
  );
}

.navbar-previous {
  display: none;
}
</style>