<template>
  <f7-page
    ref="page"
    name="commands"
     
    class="user-list bg-color-lightgray"
  >
    <!-- <f7-progressbar
      v-if="isLoading"
      color="green"
       
      class="position-absolute"
      key="1"
    ></f7-progressbar> -->
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-link back>
        <i class="icon-back size-20"></i>
      </f7-link>
      <f7-nav-right >
        <f7-link @click="openCommandMenu()">
          <i class="f7-icons">plus</i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar position="top">
      <f7-nav-title sliding>{{ $t("Commands") }}</f7-nav-title>
      <form class="searchbar custom-searchbar" @submit.prevent="onSearchSubmit">
        <div class="searchbar-inner">
          <div class="searchbar-input-wrap">
            <input
              type="search"
              v-model.trim="search"
              :placeholder="$t('Search')"
            />
            <span class="input-clear-button"></span>
            <button type="submit" class="submit-button ripple">
              <i class="icon icon-other-search text-color-black"></i>
            </button>
          </div>
        </div>
      </form>
    </f7-toolbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link> 
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
        <span>Commands</span>
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-block v-if="!isFirstSearchDone">
      <!-- <p v-html="$t('SearchCustomersDescription')"></p> -->
    </f7-block>
    <f7-block v-else-if="!isLoading && isNoData && queryPage === 1">
      <!-- <p>{{ $t("NoCustomersFound") }}</p> -->
    </f7-block>

    <f7-list
      ref="vl"
      class="vl no-margin-top bigger-item-text template-list"
      virtual-list
 
      no-hairlines
 
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      :virtual-list-params="{ items, renderExternal}"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          link="#"
          :key="item.Code"
          :title="item.Name"
          @click="openCommandMenu(item, index)"
        >
          <template #media>
            <i class="icon-commands size-20 display-flex text-color-blue"></i>
          </template>
        </f7-list-item>
      </ul>
    </f7-list>

    <command-menu
      v-model:isOpened="isCommandMenuOpened"
      :commandData="selectedMenuData"
    />  
  </f7-page>
</template>

<script>
//import {ApiMethods} from "../services/qt-api";
import { f7, theme } from "framework7-vue";
import { QTAPI } from "../../services/qt-api";
import { mapGetters } from "vuex";
import { HideKeyboard } from "../../js/helpers";
import $$ from "dom7";

 import CommandMenu from "./components/command-menu";

export default {
  name: "commands",

  props: {
    f7router: Object,
    f7route: Object,
  },

  components: {
    CommandMenu,
  },

  data() {
    return {
      isLoading: false,
      isFirstSearchDone: false,
      isInfiniteAllowed: true,
      isNoData: false,
      isCommandMenuOpened: false,
       
      items: [],
      vlData: {
        items: [],
      },
      vl: null,
      queryPage: 1,
      search: "",
      selectedMenuData: {},
      mainCustomerName: this.f7route.query.mainCustomerName,
      mainCustomerCode: this.f7route.query.mainCustomerCode,
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
      "CustomerUpdateKey",
      "SearchCancelToken",
    ]),
  },

  watch: {
    CustomerUpdateKey() {
      this.onSearchSubmit();
    },
  },

  mounted() {
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
    this.onSearchSubmit();
  },

  methods: {
    onSearchSubmit() {
      //hide keyboard at phones
      HideKeyboard();
      //fix to not loadMore when user has scrolled down list
      $$(this.$refs.page.$el)
        .find(".page-content.infinite-scroll-content")
        .scrollTo(0, 0);
      this.queryPage = 1;
      this.isInfiniteAllowed = true;
      this.getData();
    },
 
    async getData() {
 
      this.isLoading = true;
      let response = await QTAPI.getSMSTemplate();

     // console.log('response', response)

      this.isLoading = false;

      if (!response) return;

      this.isFirstSearchDone = true;
      this.isNoData = !response.length;
      if (this.queryPage === 1) {
        if (this.isNoData) {
          this.vl.deleteAllItems();
          return;
        }
        this.vl.replaceAllItems(response);
      } else {
        if (this.isNoData) {
          return;
        }
        this.vl.appendItems(response);
        this.isInfiniteAllowed = true;
      }
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc() {
      return theme.ios ? 162 : 166;
    },
   
    getCustomerStatusClass(state) {
      let states = {
        //'9_NO_DATA': 'status-gray',
        Suspended: "status-red",
        //'Suspended': 'status-orange',
        Active: "status-green",
      };
      return states[state] ? states[state] : "status-gray";
    },
    getCustomerIconLetters(name) {
      name = name.trim();
      let ret = name[0];
      let splitted = name.split(" ");
      if (splitted.length > 1) {
        ret += " " + splitted[1][0];
      } else if (name[1]) {
        ret += name[1];
      }
      return ret.toUpperCase();
    },
    openCommandMenu(item) {
       console.log('item', item)
      this.selectedMenuData = item;
      this.isCommandMenuOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .template-list {
    .item-inner {
      margin: 0 !important;
    }
  }

 .list ul li:nth-last-child(1) {
    padding-bottom: calc(
      var(--f7-toolbar-height) + var(--f7-safe-area-bottom) + 10px
    );
  }


</style>