import store from '../store'
import ReportsPage from '../pages/reports/reports';
import SummaryPage from '../pages/total-summary';

import DataUsagePage from '../pages/data-usage';
import TransferSimPage from '../pages/sim/transfer-sim';
import AlertsPage from '../pages/watchdog-alerts';
import CommandsPage from '../pages/commands/commands';
import RenewalPage from '../pages/renewal/renewal';
import RenewalSimsPage from '../pages/renewal/sims';
import RenewalSPPage from '../pages/renewal/service-plan'
import BilingPage from '../pages/biling/biling';
import ServiceProfilePage from '../pages/sim/service-profile';
import Dashboard from '../pages/dashboard/dashboard';
import SimListPage from '../pages/sim/sim-list';
import SimPosition from '../pages/sim/sim-position'

import SimStatus from '../pages/sim/sim-status'
 
 
import UserList from '../pages/users/user-list';

import CustomerList from '../pages/customers/customer-list';
import ResellerEdit from '../pages/customers/reseller-edit';



import CustomerTransfer from '../pages/customers/transfer'
import CustomerEdit from '../pages/customers/customer-edit';
import CustomerSummary from '../pages/customers/customer-summary';
import CustomerResetPassword from '../pages/customers/customer-reset-password';
import LoginPage from '../pages/login-screen'
import PanelLeft from '../pages/panel-left'

import Notifications from '../pages/notifications/notifications'
import Notification from '../pages/notifications/notification'


import CommandHistory from '../pages/sim-manage/command-history/command-history'
 

import SimSendCommand from '../pages/sim/send-command'
import SimConnectivity from '../pages/sim-manage/sim-connectivity/sim-connectivity'
import SimHLRInfo from '../pages/sim-manage/sim-connectivity/tabs/sim-hlr-info'
import SimCurrentSession from '../pages/sim-manage/sim-connectivity/tabs/sim-current-session'
import SimSessionsData from '../pages/sim-manage/sim-connectivity/tabs/sim-sessions-data'

import DashboardTab from '../pages/dashboard/tabs/dashboard'
import TopSIMTab from '../pages/dashboard/tabs/top-sim'

import UserSettings from '../pages/user-settings'



import NotFoundPage from '../pages/404.vue';



var routes = [
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    name: 'panel-left',
    path: '/panel-left/',
    panel: {
      component: PanelLeft,
    },
  },
  {
    name: 'watchdog-alerts',
    path: '/watchdog-alerts/',
    component: AlertsPage,
    beforeEnter: checkAuth,
  },
  {
    name: 'user-settings',
    path: '/user-settings/',
    component: UserSettings,
    beforeEnter: checkAuth,
  },
  {
    name: 'dashboard',
    path: '/dashboard/',
    component: Dashboard,
    beforeEnter: checkAuth,

    tabs: [
      {
        path: 'tab-dashboard/',
        id: 'tab-dashboard',
        component: DashboardTab,
      },
      {
        path: 'top-sim/',
        id: 'tab-top-sim',
        component: TopSIMTab,
      },

    ]
  },


  {
    name: 'summary',
    path: '/summary/',
    component: SummaryPage,
    beforeEnter: checkAuth,
  },
  {
    name: 'reports',
    path: '/reports/',
    component: ReportsPage,
    beforeEnter: checkAuth,
  },
  {
    name: 'biling',
    path: '/biling/',
    component: BilingPage,
    beforeEnter: checkAuth,

  },
  {
    name: 'renewal',
    path: '/renewal/',
    component: RenewalPage,
    beforeEnter: checkAuth,
    routes: [

      {
        name: 'renewal-sims',
        path: 'renewal-sims/',
        component: RenewalSimsPage,
        beforeEnter: checkAuth,
      },
      {
        name: 'service-plan',
        path: 'service-plan/',
        component: RenewalSPPage,
        beforeEnter: checkAuth,
      },
 
    ]
  },
  {
    name: 'commands',
    path: '/commands/',
    component: CommandsPage,
    beforeEnter: checkAuth,
  },
  {
    name: 'sim-list',
    path: '/sim-list/',
    component: SimListPage,
    beforeEnter: checkAuth,
    routes: [

      {
        name: 'service-profile',
        path: 'service-profile/',
        component: ServiceProfilePage,
        beforeEnter: checkAuth,
      },
      {
        name: 'sim-position',
        path: 'position/',
        component: SimPosition,
        beforeEnter: checkAuth,
      },


      {
        name: 'transfer-sim',
        path: 'transfer-sim/',
        component: TransferSimPage,
        beforeEnter: checkAuth,
      },
      {
        name: 'sim-status',
        path: 'status/',
        component: SimStatus,
        beforeEnter: checkAuth,
      },
     
      
      {
        name: 'data-usage',
        path: 'data-usage/',
        component: DataUsagePage,
        //beforeEnter: checkAuth,
      },
       
    ]
  },
  {
    name: 'sim-manage',
    path: '/sim/',
    //component: AssetsPage,
    //beforeEnter: checkAuth,
    routes: [

      {
        name: 'command-history',
        path: 'command-history/',
        component: CommandHistory,
        beforeEnter: checkAuth,
 
      },

      {
        name: 'sim-send-command',
        path: 'send-command/',
        component: SimSendCommand,
        beforeEnter: checkAuth,
      },

     
      {
        name: 'sim-connectivity',
        path: 'connectivity/',
        component: SimConnectivity,
        beforeEnter: checkAuth,
        tabs: [
          {
            path: 'hlr-info/',
            id: 'tab-hlr-info',
            component: SimHLRInfo,
          },
          {
            path: 'current-session/',
            id: 'tab-current-session',
            component: SimCurrentSession,
          },
          {
            path: 'sessions-data/',
            id: 'tab-sessions-data',
            component: SimSessionsData,
          },
        ]
      },
    ]
  },
  {
    name: 'users',
    path: '/users/',
    component: UserList,
    beforeEnter: checkAuth,
    routes: [
      // {
      //   name: 'customer-reset-password',
      //   path: 'reset-password/',
      //   component: CustomerResetPassword,
      //   beforeEnter: checkAuth,
      // },
      {
        name: 'customer-edit',
        path: 'edit/',
        component: CustomerEdit,
        beforeEnter: checkAuth,
      },
      // {
      //   name: 'customer-transfer',
      //   path: 'transfer/',
      //   component: CustomerTransfer,
      //   beforeEnter: checkAuth,
      // },
      // {
      //   name: 'customer-summary',
      //   path: 'summary/',
      //   component: CustomerSummary,
      //   beforeEnter: checkAuth,
      // },
    
    ]
  },
  {
    name: 'customer-list',
    path: '/customer-list/',
    component: CustomerList,  
    beforeEnter: checkAuth,
    routes: [
      // {
      //   name: 'customer-reset-password',
      //   path: 'reset-password/',
      //   component: CustomerResetPassword,
      //   beforeEnter: checkAuth,
      // },
      {
        name: 'reseller-edit',
        path: 'edit/',
        component: ResellerEdit,
        beforeEnter: checkAuth,
      },
    ]
  },
  {
    name: 'notifications',
    path: '/notifications/',
    component: Notifications,
    //beforeEnter: checkAuth,
    routes: [
      {
        name: 'notification',
        path: 'notification/',
        component: Notification,
        beforeEnter: checkAuth,
      },
    ]
  },



  {
    path: '(.*)',
    component: NotFoundPage,
  },
];


function checkAuth({ to, from, resolve, reject, router }) {
  //console.log(store.getters.IS_LOGGED_IN)

  if (store.getters.IS_LOGGED_IN) {
    resolve();
  } else {
    reject();
    router.navigate('/login/');
  }
}

export default routes;
