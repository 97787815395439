<template>
  <f7-page
      ref="page"
      name="notifications"
      ptr
      @ptr:refresh="getNewNotifications"
  >

    <f7-progressbar v-if="isLoading" color="green" infinite class="position-absolute" key="1"></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back">
      </f7-nav-left>
      <f7-nav-title sliding>{{ $t('Notifications') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon="f7-icons icon-delete" href="#" :class="{ disabled: !items.length }" @click="deleteNotifications"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block v-if="!items.length">
      <p>{{$t('NoNotifications')}}</p>
    </f7-block>

    <f7-list
        ref="vl"
        class="vl vl-notifications no-margin-top"
        virtual-list
        media-list
        no-hairlines
        :virtual-list-params="{ items, renderExternal, height: heightCalc }"
    >
      <ul>
        <f7-list-item
            v-for="(item, index) in vlData.items"
            link="#"
            :key="item.UniqueId"
            :title="item.alarm"
            :after="item.CreateDateTime ? item.CreateDateTime : item.time"
            :text="item.AssetName ? item.AssetName : item.name"
            :style="`top: ${vlData.topPosition}px`"
            swipeout
            @click="openItem(item)"
        >
        <f7-swipeout-actions right>
        <f7-swipeout-button delete>Delete</f7-swipeout-button>
      </f7-swipeout-actions>
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>

<script>
import {QTAPI} from "../../services/qt-api";
import {f7, theme} from "framework7-vue";

export default {
  name: "notifications",

  data(){
    return {
      isLoading: false,
      items: [],
      vlData: {
        items: [],
      },
      vl: null,
     
    }
  },

  mounted() {
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
    this.setNotification()
    this.getNewNotifications()

  },

  methods: {
    async setNotification(){
      let notificationList = await this.$store.dispatch('getFromStorage', "notifications")
      if (notificationList && notificationList[localStorage.ACCOUNT] && notificationList[localStorage.ACCOUNT].length){
        this.items = notificationList[localStorage.ACCOUNT]
        this.vl.replaceAllItems(notificationList[localStorage.ACCOUNT]);
      }
    },
    async getNewNotifications(ptrDone){
      this.isLoading = true;
      let newNotifications = await this.$store.dispatch('getNewNotifications')
      this.newNotificationsCount = newNotifications.length
      this.isLoading = false;
      if(typeof ptrDone === 'function'){
        ptrDone()
      }

      if(!newNotifications.length){
        return
      }
      let notificationList = await this.$store.dispatch('getFromStorage', "notifications")

      if(notificationList && notificationList[localStorage.ACCOUNT] && notificationList[localStorage.ACCOUNT].length > this.vlData.items.length){
        this.items = notificationList[localStorage.ACCOUNT]
        this.vl.replaceAllItems(notificationList[localStorage.ACCOUNT]);
        this.$store.commit('SET_NEW_NOTIFICATIONS_COUNT', 0)
      }
       
    },
    deleteNotifications(){
      f7.dialog.confirm(this.$t('DeleteAllNotifications'),
          async () => {
            let notificationList = await this.$store.dispatch('getFromStorage', "notifications")
            if(notificationList && notificationList[localStorage.ACCOUNT] && notificationList[localStorage.ACCOUNT].length){
              notificationList[localStorage.ACCOUNT] = []
              this.$store.dispatch('setInStorage', {name: 'notifications', data: notificationList})
              this.vl.deleteAllItems(notificationList[localStorage.ACCOUNT]);
              this.items = notificationList[localStorage.ACCOUNT]
            }
          }
      );
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc(){
      return theme.ios ? 64 : 72;
    },
    openItem(item){
      f7.views.main.router.navigate({
        name: 'notification',
      }, {
        props: {notificationData: item}
      })
    }
  }
}
</script>

<style scoped>

</style>