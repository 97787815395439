export default {
  state: () => ({
    AllSolutionStats: [],
    ActivatedInPastPeriod: [],
    ActivatedPerDay: [],
    UsageOverview: {},
    AllSimsStates: [],
    //ActivatedDevices: {},
  }),
  mutations: {
    SET_ALL_SOLUTION_STATS(state, data) {
      state.AllSolutionStats = data
    },
    SET_ACTIVATED_IN_PAST_PERIOD(state, data) {
      state.ActivatedInPastPeriod = data
    },
    SET_ACTIVATED_PER_DAY(state, data) {
      state.ActivatedPerDay = data
    },
    SET_USAGE_OVERVIEW(state, data) {
      state.UsageOverview = data
    },
    SET_ALL_SIMS_STATES(state, data) {
      state.AllSimsStates = data
    },
    /*SET_ACTIVATED_DEVICES(state, data) {
      state.ActivatedDevices = data
    },*/
  },
  getters: {
    AllSolutionStats: s => s.AllSolutionStats,
    ActivatedInPastPeriod: s => s.ActivatedInPastPeriod,
    ActivatedPerDay: s => s.ActivatedPerDay,
    UsageOverview: s => s.UsageOverview,
    AllSimsStates: s => s.AllSimsStates,
    //ActivatedDevices: s => s.ActivatedDevices,

  },
  actions:{

  }
}