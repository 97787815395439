<template>
  <div>
    <f7-progressbar v-if="isLoading" infinite color="green" class="progressbar-top"></f7-progressbar>
    <f7-list  media-list
    no-hairlines
    no-chevron
    no-hairlines-between
    dividers-ios
    strong
    outline
    inset
    form v-if="isLoading" class="skeleton-text skeleton-effect-wave" key="simCurrentSessionLoadSkeleton">
      <f7-list-item
          v-for="n in 4"
          :key="n"
          header="Header"
          title="Title"
      >
      </f7-list-item>
    </f7-list>

    <f7-block v-else-if="!isLoading && isNoData">
      <p>{{ $t('NoDataFound') }}</p>
    </f7-block>

    <f7-list v-else  media-list
   
    no-hairlines
    no-chevron
    no-hairlines-between
    dividers-ios
    strong
    outline
    inset
    form class="no-margin-top connectivity-list">
      <f7-list-item v-for="(item, index) in sessionList"
                    :key="`key-session-${index}`"
                    :title="item.value"
                    :header="item.name">
                    <template #media>
                      <i :class="`icon-${item.icon} size-20 text-color-${item.color} display-flex`"></i>
                    </template>
      </f7-list-item>
    </f7-list>
    <f7-block :style=" !sessionList.length ? 'position: absolute; bottom: 60px; width: 100%' : ''">
      <f7-button  @click="forceReconnect" color="blue" fill class="col">REFRESH SIM</f7-button>
    </f7-block>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {QTAPI} from "../../../../services/qt-api";
import { dateFormat } from "../../../../js/helpers";
import { f7 } from "framework7-vue";
export default {
  name: "sim-current-session",

  data(){
    return {
      isLoading: false,
      isNoData: false,
      sessionList: [],
    }
  },

  computed: {
    ...mapGetters(['TargetAsset']),
  },

  mounted() {
    if(this.TargetAsset.IMSI?.toString().startsWith('234')){
      this.getCurrentSession()
    }else{
      this.getCurrentSession2()
    }
  },

  methods: {
    async forceReconnect() {
      this.isLoading = true;

      let result = await QTAPI.ForceReconnect({
        IMSIs: [this.TargetAsset.IMSI],
      });

      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: "Session refreshed",
          })
          .open();  
      }

    },
    async getCurrentSession2(){
      this.isLoading = true;
      let result = await QTAPI.GetSimSessions2({ imsi: this.TargetAsset.IMSI })
      this.isLoading = false;
      if(!result){
        this.isNoData = true;
        return
      }
      //console.log(result)

      result = result?.sessions?.reverse()
      this.sessionList = [
        {
          name: this.$t('IMSI'),
          value: this.TargetAsset.IMSI,
          icon: 'imsi',
          color: 'blue'
        },
        {
          name: this.$t('Duration (s)'),
          value: result[0].duration_in_seconds,
          icon: 'date',
          color: 'lilac'
        },
        {
          name: this.$t('StartDate'),
          value: !result[0].start_time ? this.$t('na') : dateFormat(result[0].start_time, 'datetime'),
          icon: 'date',
          color: 'green'
        },
        {
          name: this.$t('EndDate'),
          value: !result[0].end_time ? this.$t('na') : dateFormat(result[0].end_time, 'datetime'),
          icon: 'date',
          color: 'red'
        },
        {
          name: `${ this.$t('TotalData') } (${ this.$t('In') } / ${ this.$t('Out') })`,
          value: `${ this.numeralFormat(result[0].data_size, '0.0 b') } (${ this.numeralFormat(result[0].data_size, '0.0 b') } / ${ this.numeralFormat(result[0].data_size, '0.0 b') })`,
          icon: 'data',
          color: 'aqua'
        },
        {
          name: this.$t('Operator'),
          value: result[0].operator,
          icon: 'customer',
          color: 'customgray'
        },
        /*{
          name: this.$t('CellInfo'),
          value: '',
        },
        {
          name: this.$t('CloseStatus'),
          value: '',
        },*/
      ]
    
  },
    async getCurrentSession(){
      this.isLoading = true;
      let result = await QTAPI.GetSimCurrentSession({ imsi: this.TargetAsset.IMSI })
      this.isLoading = false;
      if(!result){
        this.isNoData = true;
        return
      }
      console.log(result)

      this.sessionList = [
        {
          name: this.$t('IMSI'),
          value: result.imsi,
          icon: 'imsi',
          color: 'blue'
        },
        {
          name: this.$t('IMEI'),
          value: result.imei,
          icon: 'imsi',
          color: 'lilac'
        },
        {
          name: this.$t('StartDate'),
          value: !result.startDateSpecified || !result.startDate ? this.$t('na') : dateFormat(result.startDate, 'datetime'),
          icon: 'date',
          color: 'green'
        },
        {
          name: this.$t('UpdateDate'),
          value: !result.lastInterimDateSpecified || !result.lastInterimDate ? this.$t('na') : dateFormat(result.lastInterimDate, 'datetime'),
          icon: 'date',
          color: 'orange'
        },
        {
          name: this.$t('EndDate'),
          value: !result.endDateSpecified || !result.endDate ? this.$t('na') : dateFormat(result.endDate, 'datetime'),
          icon: 'date',
          color: 'red'
        },
        {
          name: `${ this.$t('TotalData') } (${ this.$t('In') } / ${ this.$t('Out') })`,
          value: `${ this.numeralFormat(result.totalBytes, '0.0 b') } (${ this.numeralFormat(result.bytesIn, '0.0 b') } / ${ this.numeralFormat(result.bytesOut, '0.0 b') })`,
          icon: 'data',
          color: 'aqua'
        },
        {
          name: this.$t('Operator'),
          value: result.networkCode,
          icon: 'customer',
          color: 'customgray'
        },
        /*{
          name: this.$t('CellInfo'),
          value: '',
        },
        {
          name: this.$t('CloseStatus'),
          value: '',
        },*/
      ]
    }
  }
}
</script>

<style scoped>

</style>