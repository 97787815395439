<template>
  <f7-page
    name="transfer-sim"
    class="bg-color-lightgray"
    @page:beforeremove="onPageBeforeRemove"
  >
    <!-- Top Navbar -->
    <f7-progressbar
      v-if="isLoading"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back"> </f7-nav-left>
    </f7-navbar>

    <f7-toolbar position="top" class>
      <f7-nav-title sliding>{{ $t("Transfer SIM") }}</f7-nav-title>
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'" href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'" href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Summary'" href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-list
      class="margin-top-half media-center"
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
    >
      <!-- v-if="simInfo && !isNoData" -->
      <!-- <f7-list-input
        link="#"
        header="Organize Name"
        title="M2M Data"
        id="organize-select-dropdown"
        @autocomplete="onAutocomplete"
        :data-autocomplete="autocompleteData"
      >
        <template #media>
          <i class="icon-customer size-20 text-color-blue display-flex"></i>
        </template>
      </f7-list-input> -->

      <!-- <f7-list-input
        id="organize-select-dropdown"
        type="text"
        header="Organize Name"
        title="Organize Name"
        label="Organize Name"
      >
 

        <template #media>
          <i class="icon-profile size-20 text-color-blue display-flex"></i>
        </template>
      </f7-list-input> -->

      <f7-list-input
        label="Organize Name"
        type="text"
        placeholder="Type something"
        ref="autocompleteInput"
      >
        <template #media>
          <i class="icon-profile size-20 text-color-blue display-flex"></i>
        </template>
      </f7-list-input>
      <f7-list-item
        label="Send To"
        class="item-input custom-smart-select-wrapper"
        :title="$t('Send To')"
        smart-select
        :smart-select-params="{
          openIn: 'popover',
          closeOnSelect: true,
          formColorTheme: 'green',
        }"
      >
        <template #media>
          <i class="icon-sim size-20 text-color-orange display-flex"></i>
        </template>
        <select
          name="timeperiod"
          v-model="selectedPeriod"
          @change="changePeriod"
        >
          <option v-for="item in sendWayOptions" :key="item.Code" :value="item.Code">{{item.Name}}</option>       
        </select>
      </f7-list-item>
    
      <f7-list-input
        type="text"
        placeholder="Number"
        v-model:value.trim="NumberCode"
      >
        <template #media>
          <i class="icon-phone size-20 text-color-green display-flex"></i>
        </template>
      </f7-list-input>

      <f7-list-input
        
        type="text"
        placeholder="Remark"
        v-model:value.trim="Remark"
      >
        <template #media>
          <i class="icon-remark size-20 text-color-lilac display-flex"></i>
        </template>
      </f7-list-input>
    </f7-list>

   
   

    <div style="position: absolute; bottom: 60px; width: 100%">
      <f7-block>
        <f7-button @click="transferSIM()" color="blue" fill class="col"
          >TRANSFER</f7-button
        >
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { QTAPI } from "../../services/qt-api";
import { f7, f7ready } from "framework7-vue";
import { mapGetters } from "vuex";

export default {
  name: "transfer-sim",

  props: {
    f7router: Object,
    f7route: Object,
  },
  components: {},
  data() {
    return {
      selectedCustomer: null,
      Remark: null,
      NumberCode: null,
      organizeArr: [],
      customerVal: "",
      sendWayOptions: [ { Code: "0", Name: "Selected SIMs" }, { Code: "1",
        Name: "IMSI range" }, { Code: "2", Name: "ICCID range" }, { Code: "3",
        Name: "IMSI list" }, { Code: "4", Name: "ICCID list" } ],
    };
  },

  computed: {
    ...mapGetters(["IS_ROOT", "MajorToken", "MinorToken"]),
  },

  watch: {},

  async mounted() {
    let self = this;

    self.getCustomers();
  },

  methods: {
    async getCustomers() {
      let self = this;
      let page = 1;
      let pageSize = 100;

      let result = await QTAPI.GetSimOwners({
        Page: page,
        Rows: pageSize,
        IncludeSelf: true,
        Order: "ASC",
        Sort: "Name",
      });
      this.organizeArr = result.rows;

      if (result) {
        self.initAutocomplete();
      }
    },

    initAutocomplete() {
      let self = this;
      f7ready((f7) => {
        f7.autocomplete.create({
          inputEl: this.$refs.autocompleteInput.$el.querySelector("input"),
          openIn: "dropdown",
          source: function (query, render) {
            var results = [];
            if (query.length === 0) {
              render(results);
              return;
            }
            results = self.organizeArr
              .filter((item) =>
                item.Account.toLowerCase().includes(query.toLowerCase())
              )
              .map((item) => item.Account)

            
            render(results);
          },
          on: {
            change: function (value) {
              //console.log("Autocomplete value selected: ", value);
               self.selectedCustomer = self.organizeArr.find(
                (item) => item.Account === value[0]
              );
              //console.log("Selected object:", selectedObject);
            },
          },
        });
      });
    },
    async transferSIM() {
      //console.log(this.selectedCustomer)
      let data = {
          IMSIs: this.f7route.query.imsi,
          toorganizecode: this.selectedCustomer.Code,
          Number: this.NumberCode,
          Remark: this.Remark,
      };
      let response = await QTAPI.SetCustomer(data);
      if (!response) {
        f7.dialog.alert(this.$t("CustomerChangingError"), this.t("Error"));
      } else {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
        this.f7router.back();
      }
    }
  },
};
</script>

 
 