<template>
  <f7-page
    ref="page"
    name="renewal"
    class=" bg-color-lightgray"
    infinite
    :infinite-distance="100"
    :infinite-preloader="isLoading"
    @infinite="onLoadMore"
  >
 
    <f7-navbar :sliding="false">
      <f7-link back>
        <i class="icon-back size-20"></i>
      </f7-link>
    </f7-navbar>
    
    <f7-toolbar position="top">
      <f7-nav-title sliding>{{ $t("SIMs") }}</f7-nav-title>
    </f7-toolbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link> 
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'" href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

 

    <f7-list
      ref="vl"
      class="vl no-margin-top bigger-item-text template-list"
      virtual-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      :virtual-list-params="{ items, renderExternal}"
    >
      <ul>
        <f7-list-item
        checkbox
        name="sim-checkbox" 
        v-for="(item, index) in vlData.items"
        :key="index"
        :style="`top: ${vlData.topPosition}px`"
         :title="item.IMSI" 
      >

      </f7-list-item>
      </ul>
    </f7-list>

    
  </f7-page>
</template>

<script>
//import {ApiMethods} from "../services/qt-api";
import { f7, theme } from "framework7-vue";
import { QTAPI } from "../../services/qt-api";
import { mapGetters } from "vuex";
import { HideKeyboard } from "../../js/helpers";
import $$ from "dom7";

 
export default {
  name: "commands",

  props: {
    f7router: Object,
    f7route: Object,
  },

  data() {
    return {
      isLoading: false,
      isFirstSearchDone: false,
      isInfiniteAllowed: true,
      isNoData: false,
      items: [],
      vlData: {
        items: [],
      },
      vl: null,
      queryPage: 1,
      search: "",
  
   
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
 
      "SearchCancelToken",
    ]),
  },
 

  mounted() {
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
    this.onSearchSubmit();
  },

  methods: {
    onSearchSubmit() {
      //hide keyboard at phones
      HideKeyboard();
      //fix to not loadMore when user has scrolled down list
      $$(this.$refs.page.$el)
        .find(".page-content.infinite-scroll-content")
        .scrollTo(0, 0);
      this.queryPage = 1;
      this.isInfiniteAllowed = true;
      this.getData();
    },
 
    onLoadMore() {
      if (!this.isInfiniteAllowed) return;
      this.isInfiniteAllowed = false;
      ++this.queryPage;
      this.getData();
    },
    async getData() {
      var data = {
        q: this.search,
        Page: 1,
        Rows: 10,
        Order: "ASC",
        Sort: "IMSI",
      };
      if (this.customerCode) {
        data.CsCode = this.customerCode;
      }
       this.isLoading = true;

     // f7.dialog.preloader();
      let result = await QTAPI.GetSimList(data);

      this.isLoading = false;

      this.isFirstSearchDone = true;
      if (!result) {
        return;
      }
      // console.log("result", result);
      this.isNoData = !result.length;
      if (this.queryPage === 1) {
        if (this.isNoData) {
          this.vl.deleteAllItems();
          return;
        }
        this.vl.replaceAllItems(result);
      } else {
        if (this.isNoData) {
          return;
        }
        this.vl.appendItems(result);
        this.isInfiniteAllowed = true;
      }
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc() {
      return theme.ios ? 162 : 166;
    },
  },
};
</script>

<style lang="scss">
  .template-list {
    .item-inner {
      margin: 0 !important;
    }
  }
</style>