<template>
  <f7-popup
    tablet-fullscreen
    class="bg-color-lightgray"
    v-model:opened="isOpened"
    @popup:closed="onHide"
  >
    <f7-progressbar
      v-if="isLoading"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          <i class="icon-back size-20"></i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-right>
        <f7-link @click="setCustomer">
          <i class="icon-header-apply size-20"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link
        v-tooltip="'Dashboard'" 
        href="/dashboard/tab-dashboard/"
        popup-close
        icon-only
        tab-link="#tab-1"
      >
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" popup-close icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/" popup-close icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'"  href="/summary/" popup-close icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
        >Customer</f7-actions-label
      >
    </f7-actions-group>

    <f7-list
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      class="page-content"
      style="padding: 0; margin-top: 0; padding-bottom: 150px"
    >
      <f7-list-item
        v-for="item in customerList"
        :key="item.Code"
        radio
        radio-icon="start"
        :title="item.Name"
        :value="item"
        :checked="item.Code === selectedCustomer.Code"
        name="customer-radio-start"
        @change="onCustomerChange(item)"
      />
    </f7-list>
  </f7-popup>
  <!-- :checked="selectedCustomer === item" -->
</template>

<script>
import { f7, f7router, f7route } from "framework7-vue";
import { mapGetters } from "vuex";
import { ApiMethods, QTAPI } from "../../../services/qt-api";
// import moment from "moment-mini";

export default {
  name: "select-customer",

  props: {
    f7router: Object,
    f7route: Object,
    simData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isOpened: { type: Boolean, default: false },
  },

  emits: ["update:isOpened"],

  data() {
    return {
      isLoading: false,
      selectedCustomer: null,
    };
  },

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
  },

  watch: {
    customerList() {
      this.selectedCustomer = this.customerList.find(item => item.Code === this.simData.OrgCode)
    }
  },

  methods: {
    onHide() {
      this.$emit("update:isOpened", false);
    },
    onCustomerChange(item) {
      this.selectedCustomer = item;
    },
    async setCustomer() {
      let self = this;

      this.isLoading = true;
      let data = {
        IMSIs: [this.simData.IMSI],
        toorganizecode: this.selectedCustomer.Code,
        Number: "",
        Remark: "",
      };
      let response = await QTAPI.SetCustomer(data);

      this.isLoading = false;
      if (response) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
        
          f7.views.main.router.refreshPage();
        f7.popup.close();
      }
    },
  },

  mounted() {

  
  },

  beforeDestroy() {},
};
</script>