<template>
  <f7-page name="dashboard" class="dashboard bg-color-lightgray" ref="page">
    <f7-navbar :sliding="false">
      <f7-navbar :sliding="false">
        <f7-nav-left>
          <f7-link href="/panel-left/" icon-only>
            <f7-icon icon="f7-icons icon-menu"></f7-icon>
          </f7-link>
        </f7-nav-left>
      </f7-navbar>
    </f7-navbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard//" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
        <span>Dashboard</span>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-toolbar tabbar top class="toolbar-font-size-12">
      <f7-nav-title sliding>{{ $t("Dashboard") }}</f7-nav-title>
      <div
        class="display-flex"
        style="justify-content: space-between; width: 100%"
      >
    
        <f7-link
          href="/dashboard/tab-dashboard/"
          route-tab-id="tab-dashboard"
          tab-link
          >{{ $t("Dashboard") }}</f7-link
        >
        <f7-link
          href="/dashboard/top-sim/"
          route-tab-id="tab-top-sim"
          tab-link
          >{{ $t("Top SIM") }}</f7-link
        > 
      </div>
    </f7-toolbar>

    <f7-tabs :routable="true">
       
      <f7-tab id="tab-dashboard" class=""></f7-tab>
      <f7-tab id="tab-top-sim" class=""></f7-tab>
    </f7-tabs>

    
  </f7-page>
</template>


<script>
 
 
 

export default {
  
};
</script>

<style scoped>
</style>