import dashboard from './dashboard'
/*import credits from './credits'
import simManage from './sim-manage'*/
import { createStore } from "vuex"
import {QTAPI} from "../services/qt-api";
import {SortArrayByObjProps, isObjEmpty, formatNewNotification} from "../js/helpers";
import { f7 } from 'framework7-vue';
/*import {getFormDataFromObject, isObjEmpty, sortArrayByObjProps} from "../js/helper";
import APIMETHODS from "../js/api-methods";*/
//import axios from "axios";

//let qs = require('qs');

const store = createStore({
  state: {
    IS_LOGGED_IN: false,
    IS_ROOT: false,
    ERROR: null,
    //API_VALIDATION_ERROR: null,
    UserInfo: {},
    UserInfoPreEdit: {},
    MajorToken: '',
    MinorToken: '',
    AppVersion: '',
    RootToken: '00000000-0000-0000-0000-000000000000',
    SimUpdateKey: 1,
    AssetUpdateKey: 1,
    CustomerUpdateKey: 1,
    SimCommands: [],
    TargetAsset: {},
    NewNotificationsCount: 0,
    SearchCancelToken: null,
    Permission: {}
    //Solutions: [],
    //Products: [],

  },
  mutations: {
    SET_ERROR(state, error) {
      state.ERROR = error
    },
    CLEAR_ERROR(state) {
      state.ERROR = null
    },


    SET_USER_INFO(state, info){
      state.UserInfo = info;
      //localStorage.USERINFO = JSON.stringify(info);
    },
    SET_USER_INFO_PRE_EDIT(state, info){
      state.UserInfoPreEdit = info;
      //localStorage.USERINFO = JSON.stringify(info);
    },
    CLEAR_USER_INFO(state){
      state.UserInfo = {};
      localStorage.clear();
    },

    SET_AUTH_STATE(state, auth){
      state.IS_LOGGED_IN = auth
    },
    SET_ROOT_TOKEN(state, token){
      state.RootToken = token
    },
    SET_IS_ROOT(state, isRoot){
      state.IS_ROOT = isRoot
    },
    SET_MAJOR_TOKEN(state, token){
      state.MajorToken = token
    },
    SET_MINOR_TOKEN(state, token){
      state.MinorToken = token
    },
    SET_APP_VERSION(state, version){
      state.AppVersion = version
    },
    SET_SIM_COMMANDS(state, commands){
      state.SimCommands = [{Name: 'Custom command', Code: 'custom'}].concat(commands)
    },
    SET_TARGET_ASSET(state, TargetAsset){
      state.TargetAsset = {...TargetAsset}
    },
    SET_PERMISSIONS(state, permissions){
      state.Permissions =  {...permissions}
      state.isPermissionsLoaded = true;
    },
    /*SET_SOLUTIONS(state, solutions){
      state.Solutions = solutions
    },
    SET_PRODUCTS(state, products){
      state.Products = products
    },*/
    SIM_UPDATED(state){
      state.SimUpdateKey++
    },
    ASSET_UPDATED(state){
      state.AssetUpdateKey++
    },
    CUSTOMER_UPDATED(state){
      state.CustomerUpdateKey++
    },
    SET_NEW_NOTIFICATIONS_COUNT(state, count){
      state.NewNotificationsCount = count
    },
    SET_SEARCH_CANCEL_TOKEN(state, searchCancelToken){
      state.SearchCancelToken = searchCancelToken
    }


  },
  getters: {
    ERROR: s => s.ERROR,
    //API_VALIDATION_ERROR: s => s.API_VALIDATION_ERROR,
    IS_LOGGED_IN: s => s.IS_LOGGED_IN,
    IS_ROOT: s => s.IS_ROOT,

    UserInfo: s => s.UserInfo,
    UserInfoPreEdit: s => s.UserInfoPreEdit,

    MajorToken: s => s.MajorToken,
    MinorToken: s => s.MinorToken,
    AppVersion: s => s.AppVersion,
    RootToken: s => s.RootToken,
    SimUpdateKey: s => s.SimUpdateKey,
    AssetUpdateKey: s => s.AssetUpdateKey,
    CustomerUpdateKey: s => s.CustomerUpdateKey,
    SimCommands: s => s.SimCommands,
    TargetAsset: s => s.TargetAsset,
    NewNotificationsCount: s => s.NewNotificationsCount,
    SearchCancelToken: s => s.SearchCancelToken,
    
    Permissions: s => s.Permissions,
    isPermissionsLoaded: (state) => state.isPermissionsLoaded,


    /*Solutions: s => s.Solutions,
    Products: s => s.Products,*/

  },
  actions: {
    async logout({commit, dispatch, rootState}, query){
      let mobileToken = !localStorage.PUSH_MOBILE_TOKEN? '' : localStorage.PUSH_MOBILE_TOKEN;
      let deviceToken = !localStorage.PUSH_DEVICE_TOKEN? '' : localStorage.PUSH_DEVICE_TOKEN;
      let additionalFlags = await dispatch('getFromStorage', 'additionalFlags')
      let notifications = await dispatch('getFromStorage', 'notifications')
      localStorage.clear()

      if (additionalFlags) dispatch('setInStorage', { name: 'additionalFlags', data: additionalFlags });
      if (notifications)  dispatch('setInStorage', { name: 'notifications', data: notifications });
      if (deviceToken) localStorage.PUSH_DEVICE_TOKEN = deviceToken;
      if (mobileToken) localStorage.PUSH_MOBILE_TOKEN = mobileToken;
      commit('SET_AUTH_STATE', false)
      QTAPI.Logout({
        MinorToken: rootState.MinorToken,
        deviceToken: deviceToken,
        mobileToken: mobileToken,
      })
    },
    async GetSimCommands({commit, rootState}){
      let response = await QTAPI.GetSimCommandList(
        { MajorToken: '00000000-0000-0000-0000-000000000000', 
         //rootState.MajorToken,
           MinorToken: rootState.MinorToken })
      response = SortArrayByObjProps(response, [{prop: 'Name', direction: 1}])
      commit('SET_SIM_COMMANDS', response)
    },
    async GetCreditBalance({commit, rootState}){
      let response = await QTAPI.GetCreditBalance({ MajorToken: rootState.MajorToken, MinorToken: rootState.MinorToken })
      //console.log({ ...rootState.UserInfo, SMSTimes: response.SMSTimes })
      commit('SET_USER_INFO', { ...rootState.UserInfo, SMSTimes: response.SMSTimes })
      return response.SMSTimes
    },
    // async GetUserInfoPreEdit({commit, rootState}){
    //   let response = await QTAPI.GetPreEditCustomerInfo({ MajorToken: rootState.MajorToken, MinorToken: rootState.MinorToken, Code: rootState.MajorToken})
    //   if(!response) return
    //   commit('SET_USER_INFO_PRE_EDIT', response)
    // },
    async getNewNotifications({commit, rootState, dispatch}){
      let newNotifications = await QTAPI.GetNewNotifications({
        MinorToken: rootState.MinorToken,
        deviceToken: !localStorage.PUSH_DEVICE_TOKEN ? '' : localStorage.PUSH_DEVICE_TOKEN,
      })
      //newNotifications = ['{"title":"test","type":32,"imei":"0000001683122697","name":"0000001683122697","lat":50.249984,"lng":32.282368,"speed":130,"direct":0,"time":"2018-08-23 16:56:37"}'];
      console.log(newNotifications)
      //newNotifications = ['{"Imei":"0354188046337940","AssetName": "Test","Acc":"OFF","Relay":"OFF","Battery":"4477(mV)","Charger":"0(mV)","Power":"1","GPS":"V,0","GSM":"2,-107(dB)","GPRS":"Offline","alarm":"status","Imsi":"43688875220070"}'];
      if(newNotifications.length){
        await dispatch('setNotificationList', newNotifications)
        commit('SET_NEW_NOTIFICATIONS_COUNT', rootState.NewNotificationsCount+newNotifications.length)
      }
      return newNotifications
    },
    async setNotificationList({commit, rootState, dispatch}, newNotifications){
      let notifications = await dispatch('getFromStorage', 'notifications')
      let user = localStorage.ACCOUNT;

      if(!notifications || isObjEmpty(notifications)){
        notifications = {};
      }
      if(!notifications[user]){
        notifications[user] = [];
      }

      if (Array.isArray(newNotifications)) {
        newNotifications = formatNewNotification(newNotifications)
        for (let i = 0; i < newNotifications.length; i++) {
          newNotifications[i].UniqueId = f7.utils.id();
          notifications[user].unshift(newNotifications[i]);
        }
      }
      dispatch('setInStorage', {name: 'notifications', data: notifications})
    },

    async getFromStorage({commit}, name){
      var ret = [];
      var str = '';
      if (!name) {
        console.log('getFromStorage: Wrong query parameters!')
        return
      }
      switch (name){
        case 'additionalFlags':
          str = localStorage.getItem("COM.QUIKTRAK.AGENT.ADDIITIONALFLAGS");
          if(str) {
            ret = JSON.parse(str);
          }else{
            ret = {};
          }
          break;

        case 'notifications':
          str = localStorage.getItem("COM.QUIKTRAK.AGENT.NOTIFICATIONS");
          if(str) {
            ret = JSON.parse(str);
          }else{
            ret = {};
          }
          break;

        default:
          //this.dialog.alert('There is no item saved with such name - '+name);
          console.log('There is no item saved with such name - '+name)
      }

      return ret;
    },
    async setInStorage({commit, dispatch}, params){
      if (typeof(params) !== 'object' || !params.name || !params.data) {
        console.log('setInStorage: Wrong query parameters!')
        return
      }
      switch (params.name){
        case 'additionalFlags':
          let flags = await dispatch('getFromStorage', params.name);
          const keys = Object.keys(params.data);
          for (const key of keys) {
            flags[key] = params.data[key];
          }
          localStorage.setItem("COM.QUIKTRAK.AGENT.ADDIITIONALFLAGS", JSON.stringify(flags));
          break;

        case 'notifications':
          localStorage.setItem("COM.QUIKTRAK.AGENT.NOTIFICATIONS", JSON.stringify(params.data));
          break;

        default:
          console.log('There is no function associated with this name - '+params.name);
      }

    },


  },
  modules: {
    //user,

    dashboard,
    //credits,
    //simManage
  }
})

export default store