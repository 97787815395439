<template>
  <f7-page name="customer-reset-password">
    <f7-progressbar v-if="isLoading" color="green" infinite class="progressbar-top" key="1"></f7-progressbar>
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back">
      </f7-nav-left>
      <f7-nav-title sliding>{{ $t('ResetPassword') }}</f7-nav-title>
      <f7-nav-right>
        <label for="submit-asset-transfer" class="link icon-only">
          <i class="icon f7-icons">checkmark_alt</i>
        </label>
      </f7-nav-right>
    </f7-navbar>

    <form action="" @submit.prevent="onSubmit">
      <input type="submit" id="submit-asset-transfer" hidden >

      <!-- <f7-block-title>{{ $t('ServiceDetails') }}</f7-block-title>-->
      <f7-list class="no-margin-top no-hairline-bottom">
        <f7-list-input
            type="text"
            name="CustomerName"
            :label="$t('Customer')"
            :placeholder="$t('Customer')"
            class="disabled"
            v-model:value="CustomerName"
        >
          <template #media>
            <f7-icon icon="f7-icons icon-customer-details text-color-lightgray"></f7-icon>
          </template>
        </f7-list-input>
        <f7-list-input
            type="text"
            name="LoginName"
            required
            validate
            validate-on-blur
            :label="$t('LoginName')"
            :placeholder="$t('LoginName')"
            class="disabled"
            v-model:value="LoginName"

        >
          <template #media>
            <f7-icon icon="f7-icons icon-customer-details text-color-lightgray"></f7-icon>
          </template>
        </f7-list-input>
        <f7-list-input
            type="text"
            name="Email"
            :label="$t('Email')"
            :placeholder="$t('Email')"
            v-model:value="Email"
            required
            validate
            class="disabled"
        >
          <template #media>
            <f7-icon icon="f7-icons icon-email text-color-lightgray"></f7-icon>
          </template>
        </f7-list-input>
        <f7-list-input
            type="text"
            name="Name"
            :label="$t('ContactName')"
            :placeholder="$t('ContactName')"
            v-model:value="UserName"
            required
            validate
            class="disabled"
        >
          <template #media>
            <f7-icon icon="f7-icons icon-customer-details text-color-lightgray"></f7-icon>
          </template>
        </f7-list-input>
        <f7-list-input
            floating-label
            :type="passwordTypeNew"
            name="newPassword"
            :label="$t('NewPassword')"
            :placeholder="$t('NewPassword')"
            v-model:value.trim="NewPassword"
            required
            validate

            validate-on-blur
            :minlength="6"
        >
          <template #media>
            <f7-icon icon="f7-icons icon-password text-color-lightgray"></f7-icon>
          </template>
          <template #content-end>
            <div @click="switchVisability('passwordTypeNew')" class="margin-right link">
              <i class="f7-icons icon-view"
                 :class="{'text-color-gray' : passwordTypeNew === 'password', 'text-color-black' : passwordTypeNew === 'text'}"></i>
            </div>
          </template>
        </f7-list-input>

      </f7-list>
    </form>
  </f7-page>
</template>

<script>
import {mapGetters} from "vuex";
import {QTAPI} from "../../services/qt-api";
import { f7 } from "framework7-vue";

export default {
  name: "customer-reset-password",

  props: {
    f7router: Object,
    f7route: Object,

    userData: { type: Object, default: ()=> { return {} } },
  },

  data() {
    return {
      isLoading: false,

      CustomerName: this.userData.Name,
      UserCode: this.userData.UserCode,
      LoginName: this.userData.LoginName,
      Email: this.userData.Email,
      UserName: this.userData.Contact,

      NewPassword: null,
      passwordTypeNew: 'password',
    }
  },

  computed: {
    ...mapGetters(['MajorToken', 'MinorToken'])
  },

  methods: {
    switchVisability(name) {
      this[name] = this[name] === 'password' ? 'text' : 'password';
    },
     onSubmit(){
      f7.dialog.confirm(this.$t('ConfirmPasswordReset',{name: this.CustomerName}), async () => {
        var data = {
          MajorToken: this.MajorToken,
          Code: this.UserCode,
          Password: this.NewPassword,
        };

        this.isLoading = true;
        let response = await QTAPI.CustomerResetPassword(data)
        this.isLoading = false;
        if (!response){
          return
        }

        f7.notification.create({
          subtitle: this.$t('Success'),
          text: this.$t('PasswordResetComplete', {name: this.CustomerName}),
        }).open()
        this.f7router.back()
      })
    }
  }
}
</script>

<style scoped>

</style>