<template>
  <div>
    <f7-progressbar v-if="isLoading" infinite color="green" class="progressbar-top"></f7-progressbar>
    <f7-list  media-list
    no-hairlines
    no-chevron
    no-hairlines-between
    dividers-ios
    strong
    outline
    inset
    form v-if="isLoading" class="skeleton-text skeleton-effect-wave" key="simSessionsLoadSkeleton">
      <f7-list-item
          v-for="n in 4"
          :key="n"
          header="Header"
          title="Title"
      >
      </f7-list-item>
    </f7-list>

    <f7-block v-else-if="!isLoading && isNoData">
      <p>{{ $t('NoDataFound') }}</p>
    </f7-block>

    <f7-list
        ref="vl"
        class="vl vl-sim-sessions no-margin-top"
        virtual-list
        media-list
          no-hairlines
          no-chevron
          no-hairlines-between
          dividers-ios
          strong
          outline
          inset
          form
        :virtual-list-params="{ items, renderExternal, height: heightCalc }"
    >
      <ul>
        <f7-list-item
            v-for="(item, index) in vlData.items"
            :key="`key-sim-sessions-${index}`"
            :title="item.operator"
            :header="item.start.slice(0,20) + ' - ' + item.end.slice(0,20)"
            :style="`top: ${vlData.topPosition}px`"
            :after="numeralFormat(item.total, '0.0 b')"
        ><!--:header="dateFormat(item.start+'+0000', 'datetime') + ' - ' + dateFormat(item.end+'z', 'datetime')"-->
        <template #media>
          <i :class="`icon-${item.icon} size-20 text-color-${item.color} display-flex`"></i>
        </template>
        </f7-list-item>
      </ul>
    </f7-list>
   
    <f7-block :style=" !vlData.items.length ? 'position: absolute; bottom: 60px; width: 100%' : ''">
      <f7-button  @click="forceReconnect" color="blue" fill class="col">REFRESH SIM</f7-button>
    </f7-block>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {QTAPI} from "../../../../services/qt-api";
import moment from "moment-mini";
import {f7, theme} from "framework7-vue";
import {dateFormat} from "../../../../js/helpers";

export default {
  name: "sim-sessions-data",

  data(){
    return {
      isLoading: false,
      isNoData: false,

      items: [],
      vlData: {
        items: [],
      },
      vl: null,
    }
  },

  computed: {
    ...mapGetters(['TargetAsset']),
  },

  mounted() {
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
    if(this.TargetAsset.IMSI?.toString().startsWith('234')){
      this.getSimSessions()
    }else{
      this.getSimSessions2()
    }
  },

  methods: {
    async forceReconnect() {
      this.isLoading = true;

      let result = await QTAPI.ForceReconnect({
        IMSIs: [this.TargetAsset.IMSI],
      });

      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: "Session refreshed",
          })
          .open();  
      }

    },
    dateFormat(...options){
      return dateFormat(...options)
    },
    async getSimSessions(){
      this.isLoading = true;
      let result = await QTAPI.GetSimSessions({ imsi: this.TargetAsset.IMSI })
      this.isLoading = false;
      if(!result){
        this.isNoData = true;
        return
      }
      //console.log(result)
      let resList = []
      result = result.split('\r\n')
      result.pop() // remove las item without timestamps
      for (const element of result) {
        let dataJson = element.split(',')
        resList.push({
          start: dataJson[4],
          end: dataJson[5],
          total: dataJson[3],
          operator: dataJson[1]+dataJson[2],
          icon: 'data',
          color: 'blue'
        })
      }
      if(resList.length){
        resList.sort((a,b) => moment(b.start).valueOf() - moment(a.start).valueOf())
        this.vl.replaceAllItems(resList);
      }
      //this.sessionsList = resList

      //console.log(resList)
    },
    async getSimSessions2(){
      this.isLoading = true;
      let result = await QTAPI.GetSimSessions2({ imsi: this.TargetAsset.IMSI })
      this.isLoading = false;
      if(!result){
        this.isNoData = true;
        return
      }
      //console.log(result) 312300004982152
      
      let resList = []
      result = result?.sessions?.reverse()
      for (const element of result) {
       //console.log(dateFormat(element.start_time, 'datetime'), dateFormat(element.end_time))
        resList.push({
          start: dateFormat(element.start_time, 'datetime'),
          end:dateFormat(element.end_time, 'datetime'),
          total: element.data_size,
          operator: element.operator,
          icon: 'data',
          color: 'blue'
        })
      }
      if(resList.length){
        resList.sort((a,b) => moment(b.start).valueOf() - moment(a.start).valueOf())
        this.vl.replaceAllItems(resList);
      }
      //this.sessionsList = resList

      //console.log(resList)
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc(){
        return theme.ios ? 86 : 86.27;
    },
  }
}
</script>

<style scoped>

</style>