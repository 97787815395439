<template>
  <f7-page
    ref="page"
    name="users"
    infinite
    class="user-list bg-color-lightgray"
    :infinite-distance="100"
    :infinite-preloader="isLoading"
    @infinite="onLoadMore"
  >
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="position-absolute"
      key="1"
    ></f7-progressbar>
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back"> </f7-nav-left>
    </f7-navbar>
    <f7-toolbar position="top">
      <f7-nav-title sliding>{{ $t("Users") }}</f7-nav-title>
      <form class="searchbar custom-searchbar" @submit.prevent="onSearchSubmit">
        <div class="searchbar-inner">
          <div class="searchbar-input-wrap">
            <input
              type="search"
              v-model.trim="search"
              :placeholder="$t('Search')"
            />
            <span class="input-clear-button"></span>
            <button type="submit" class="submit-button ripple">
              <i class="icon icon-other-search text-color-black"></i>
            </button>
          </div>
        </div>
      </form>
    </f7-toolbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
        <span>Users</span>
      </f7-link>
      <f7-link v-tooltip="'Summary'"   href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-block v-if="!isFirstSearchDone">
      <p v-html="$t('SearchCustomersDescription')"></p>
    </f7-block>
    <f7-block v-else-if="!isLoading && isNoData && queryPage === 1">
      <p>{{ $t("NoCustomersFound") }}</p>
    </f7-block>

    <f7-list
      ref="vl"
      class="vl vl-users no-margin-top bigger-item-text"
      virtual-list
      media-list
      no-hairlines
      no-chevron
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      :virtual-list-params="{ items, renderExternal, height: heightCalc }"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          link="#"
          :key="item.Code"
          :title="item.Name"
          :header="item.Agent"
          :style="`top: ${vlData.topPosition}px`"
          @click="openItemMenu(item, index)"
        >
        <!--  -->
          <template #media>
            <!-- {{ getCustomerIconLetters(item.Name) }} -->

            <div class="bg-color-blue icon-sim_container">
              <f7-icon class="icon-customer text-color-white"></f7-icon>
              <span
                class="status"
                :class="getUserStatusClass(item.State)"
              ></span>
            </div>
          </template>
          <template #text>
           
            <div>
              {{ $t("Name") }}:
              <b>{{ item.FirstName + " " + item.SubName }}</b>
            </div>
            <div>
              {{ $t("Account") }}:
              <b>{{ item.Account ? item.Account : $t("na") }}</b>
            </div>
            <div>
              {{ $t("Org") }}:
              <b>{{ item.OrganizeName ? item.OrganizeName : $t("na") }}</b>
            </div>
            <div>
              {{ $t("Role") }}:
              <b>{{ item.RoleName ? item.RoleName : $t("na") }}</b>
            </div>
            <div>
              {{ $t("Mobile") }}:
              <b>{{ item.Mobile ? item.Mobile : $t("na") }}</b>
            </div>
            <div>
              {{ $t("Email") }}:
              <b>{{ item.Email ? item.Email : $t("na") }}</b>
            </div>
           
          </template>
        
        </f7-list-item>
      </ul>
    </f7-list>

    <user-menu
      v-model:isOpened="isUserMenuOpened"
      :userData="selectedUserData"
    />
  </f7-page>
</template>

<script>
//import {ApiMethods} from "../services/qt-api";
import { f7, theme } from "framework7-vue";
import { QTAPI } from "../../services/qt-api";
import { mapGetters } from "vuex";
import { HideKeyboard, getCustomerTypesFull } from "../../js/helpers";
import $$ from "dom7";

import UserMenu from "./components/user-menu";

export default {
  name: "users",

  props: {
    f7router: Object,
    f7route: Object,
  },

  components: {
    UserMenu,
  },

  data() {
    return {
      isLoading: false,
      isFirstSearchDone: false,
      isInfiniteAllowed: true,
      isNoData: false,
      isUserMenuOpened: false,
      UserTypeList: getCustomerTypesFull(),
      items: [],
      vlData: {
        items: [],
      },
      vl: null,
      queryPage: 1,
      search: "",
      selectedUserData: {},
      mainUserName: this.f7route.query.mainUserName,
      mainUserCode: this.f7route.query.mainUserCode,
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
      "CustomerUpdateKey",
      "SearchCancelToken",
    ]),
  },

  watch: {
    UserUpdateKey() {
      this.onSearchSubmit();
    },
  },

  mounted() {
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
    this.onSearchSubmit();
  },

  methods: {
    
    onSearchSubmit() {
      //hide keyboard at phones
      HideKeyboard();
      //fix to not loadMore when user has scrolled down list
      $$(this.$refs.page.$el)
        .find(".page-content.infinite-scroll-content")
        .scrollTo(0, 0);
      this.queryPage = 1;
      this.isInfiniteAllowed = true;
      this.getData();
    },
    onLoadMore() {
      if (!this.isInfiniteAllowed) return;
      this.isInfiniteAllowed = false;
      ++this.queryPage;
      this.getData();
    },
    async getData() {
      let data = {
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,
        Page:   this.queryPage,
        rows: '20',
        Order: "ASC",
        q: this.search,
        Sort: 'Account',
        IncludeSelf: true,
        Email: '',
        FirstName: '',
        SubName: '',
        Mobile: '',
        Account: '',
      };
 
      if (this.mainUserCode) {
        data.PCode = this.mainUserCode;
      }
      if (this.SearchCancelToken) {
        this.SearchCancelToken.cancel("Operation canceled due to new request.");
      }
      this.isLoading = true;
      let response = await QTAPI.GetSimUsers(data);
      if (response?.isCanceled) return;
      this.isLoading = false;

      if (!response) return;

      this.isFirstSearchDone = true;
      this.isNoData = !response.rows.length;
      if (this.queryPage === 1) {
        if (this.isNoData) {
          this.vl.deleteAllItems();
          return;
        }
        this.vl.replaceAllItems(response.rows);
      } else {
        if (this.isNoData) {
          return;
        }
        this.vl.appendItems(response.rows);
        this.isInfiniteAllowed = true;
      }
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc() {
      return theme.ios ? 162 : 166;
    },
    getUserType(type) {
      let typeObg = this.UserTypeList.find(
        (itm) => itm.code === parseInt(type)
      );
      return !typeObg ? type : typeObg.name;
    },
    getUserStatusClass(state) {
      let states = {
        //'9_NO_DATA': 'status-gray',
        Suspended: "status-red",
        //'Suspended': 'status-orange',
        Active: "status-green",
      };
      return states[state] ? states[state] : "status-gray";
    },
    getUserIconLetters(name) {
      name = name.trim();
      let ret = name[0];
      let splitted = name.split(" ");
      if (splitted.length > 1) {
        ret += " " + splitted[1][0];
      } else if (name[1]) {
        ret += name[1];
      }
      return ret.toUpperCase();
    },
    openItemMenu(item) {
      console.log('item', item);
      this.selectedUserData = item;
      this.isUserMenuOpened = true;
    },
  },
};
</script>

<style lang="scss">
.icon-sim_container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  .icon-customer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}
</style>