<template>
  <f7-popup
    ref="popupSelectCSP"
    tablet-fullscreen
    class="bg-color-lightgray popup-select-csp"
    v-model:opened="isOpened"
    @popup:closed="onHide"
  >
    <f7-progressbar
      v-if="isLoading"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          <i class="icon-back size-20"></i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-right>
        <f7-link @click="setCSP">
          <i class="icon-header-apply size-20"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
        >CSP</f7-actions-label
      >
    </f7-actions-group>

    <f7-list
      class="page-content"
      style="padding: 0; margin-top: 0; padding-bottom: 150px"
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
    >
      <f7-list-item
        v-for="item in CSPList"
        :key="item.Code"
        radio
        radio-icon="start"
        :title="item.Name"
        name="csp-radio-start"
        :value="item"
        :checked="item.Code === selectedCSP?.Code"
        @change="onCSPChange(item)"
      />
    </f7-list>
  </f7-popup>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { ApiMethods, QTAPI } from "../../../services/qt-api";
// import moment from "moment-mini";

export default {
  name: "select-csp",

  props: {
    simData: {
      type: Object,
      default: () => {
        return {};
      },
    },

    CSPList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isOpened: { type: Boolean, default: false },
  },

  emits: ["update:isOpened"],

  data() {
    return {
      isLoading: false,
      selectedCSP: null,
    };
  },
  watch: {
    CSPList() {
      this.selectedCSP = this.CSPList.find(
        (item) => item.Code === this.simData?.ServiceProfileCode
      );
    },
  },

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
  },

  methods: {
    onHide() {
      this.$emit("update:isOpened", false);
    },
    onCSPChange(item) {
      this.selectedCSP = item;
    },
    async setCSP() {
      let self = this;

      this.isLoading = true;
      let data = {
        IMSIs: [this.simData.IMSI],
        ServiceProfileCode: this.selectedCSP.Code,
      };
      let response = await QTAPI.SetCSP(data);

      this.isLoading = false;
      if (response) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
    
        f7.views.main.router.refreshPage();
        f7.popup.close();
      }
    },
  },

  mounted() {},

  beforeDestroy() {},
};
</script>