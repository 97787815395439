<template>
  <f7-page
    name="user-settings"
    class="user-settings bg-color-lightgray"
    :page-content="false"
  >
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="progressbar-top"
      key="1"
    ></f7-progressbar>
    <f7-navbar>
      <f7-nav-left back-link="Back"> </f7-nav-left>
      <!-- <f7-nav-right>
        <f7-link
          v-if="currentTab === 'tab-user'"
          @click="onSubmitProfile"
          href="#"
          icon-f7="checkmark_alt"
        ></f7-link>
        <f7-link
          v-else-if="currentTab === 'tab-password'"
          @click="onSubmitPassword"
          href="#"
          icon-f7="checkmark_alt"
        ></f7-link>
      </f7-nav-right> -->
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link
        v-tooltip="'Dashboard'"
        href="/dashboard/tab-dashboard/"
        icon-only
        tab-link="#tab-1"
      >
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link
        v-tooltip="'SIM List'"
        href="/sim-list/"
        icon-only
        tab-link="#tab-2"
      >
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Commands'"
        href="/commands/"
        icon-only
        tab-link="#tab-3"
      >
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'" href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Summary'"
        href="/summary/"
        icon-only
        tab-link="#tab-5"
      >
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-toolbar position="top" tabbar>
      <f7-nav-title sliding>{{ $t("UserSettings") }}</f7-nav-title>
      <div
        class="display-flex"
        style="justify-content: space-between; width: 100%"
      >
        <f7-link tab-link="#tab-user" tab-link-active>{{
          $t("UserProfile")
        }}</f7-link>
        <f7-link tab-link="#tab-password">{{ $t("Password") }}</f7-link>
      </div>
    </f7-toolbar>

    <f7-tabs animated>
      <f7-tab
        id="tab-user"
        class="page-content"
        tab-active
        @tab:show="currentTab = 'tab-user'"
      >
        <f7-list
          media-list
          no-hairlines
          no-chevron
          no-hairlines-between
          dividers-ios
          strong
          outline
          inset
          form
          @submit.prevent="onSubmitProfile"
        >
          <input type="submit" hidden />

          <f7-list-input
            v-if="isShown"
            floating-label
            type="text"
            name="customerName"
            :label="$t('CustomerName')"
            :placeholder="$t('CustomerName')"
            v-model:value="customerName"
          >
            <template #media>
              <i class="icon-customer size-20 text-color-blue display-flex"></i>
            </template>
          </f7-list-input>
          <f7-list-input
            v-else
            floating-label
            type="text"
            name="customerName"
            :label="$t('CustomerName')"
            :placeholder="$t('CustomerName')"
            v-model:value="customerName"
            disabled
          >
            <template #media>
              <i class="icon-customer size-20 text-color-blue display-flex"></i>
            </template>
          </f7-list-input>
          <f7-list-input
            v-if="isShown"
            floating-label
            type="text"
            required
            validate
            name="firstName"
            :label="$t('FirstName')"
            :placeholder="$t('FirstName')"
            v-model:value="firstName"
          >
            <template #media>
              <i
                class="icon-customer size-20 text-color-green display-flex"
              ></i>
            </template>
          </f7-list-input>

          <f7-list-input
            v-else
            floating-label
            type="text"
            required
            validate
            name="firstName"
            :label="$t('FirstName')"
            :placeholder="$t('FirstName')"
            v-model:value="firstName"
            disabled
          >
            <template #media>
              <i
                class="icon-customer size-20 text-color-green display-flex"
              ></i>
            </template>
          </f7-list-input>
          <f7-list-input
            floating-label
            type="text"
            required
            validate
            name="subName"
            :label="$t('LastName')"
            :placeholder="$t('LastName')"
            v-model:value="lastName"
            v-if="isShown"
          >
            <template #media>
              <i
                class="icon-customer size-20 text-color-lilac display-flex"
              ></i>
            </template>
          </f7-list-input>
          <f7-list-input
            v-else
            floating-label
            type="text"
            required
            validate
            name="subName"
            :label="$t('LastName')"
            :placeholder="$t('LastName')"
            v-model:value="lastName"
            disabled
          >
            <template #media>
              <i
                class="icon-customer size-20 text-color-lilac display-flex"
              ></i>
            </template>
          </f7-list-input>
          <f7-list-input
            v-if="isShown"
            floating-label
            type="email"
            required
            validate
            name="email"
            :label="$t('Email')"
            :placeholder="$t('Email')"
            v-model:value.trim="email"
          >
            <template #media>
              <i class="icon-mail size-20 text-color-orange display-flex"></i>
            </template>
          </f7-list-input>
          <f7-list-input
            v-else
            floating-label
            type="email"
            required
            validate
            name="email"
            :label="$t('Email')"
            :placeholder="$t('Email')"
            v-model:value.trim="email"
            disabled
          >
            <template #media>
              <i class="icon-mail size-20 text-color-orange display-flex"></i>
            </template>
          </f7-list-input>
          <f7-list-input
            v-if="isShown"
            floating-label
            type="tel"
            required
            validate
            name="phone"
            :label="$t('Phone')"
            :placeholder="$t('Phone')"
            v-model:value.trim="phone"
            :error-message="$t('OnlyNumbers')"
            pattern="[0-9]*"
          >
            <template #media>
              <i class="icon-phone size-20 text-color-red display-flex"></i>
            </template>
          </f7-list-input>

          <f7-list-input
            v-else
            floating-label
            type="tel"
            required
            validate
            name="phone"
            :label="$t('Phone')"
            :placeholder="$t('Phone')"
            v-model:value.trim="phone"
            :error-message="$t('OnlyNumbers')"
            pattern="[0-9]*"
            disabled
          >
            <template #media>
              <i class="icon-phone size-20 text-color-red display-flex"></i>
            </template>
          </f7-list-input>
        </f7-list>
        <f7-block>
          <f7-button
            @click="onSubmitProfile"
            class="submit-btn col"
            color="blue"
            fill
            >SAVE CHANGES</f7-button
          >
        </f7-block>
      </f7-tab>

      <f7-tab
        form
        id="tab-password"
        class="page-content"
        @tab:show="currentTab = 'tab-password'"
      >
        <f7-list
          media-list
          no-hairlines
          no-chevron
          no-hairlines-between
          dividers-ios
          strong
          outline
          inset
          form
          @submit.prevent="onSubmitPassword"
        >
          <input type="submit" hidden />
          <f7-list-input
          
            :type="passwordTypeCurrent"
            name="currentPassword"
            :label="$t('CurrentPassword')"
            :placeholder="$t('CurrentPassword')"
            v-model:value="currentPassword"
            required
            validate
            validate-on-blur
          >
            <template #media>
              <i
                class="icon-other-password size-20 text-color-green display-flex"
              ></i>
            </template>
            <template #content-end>
              <div
                @click="switchVisability('passwordTypeCurrent')"
                class="margin-right link"
              >
                <i
                  class="f7-icons icon-view"
                  :class="{
                    'text-color-gray': passwordTypeCurrent === 'password',
                    'text-color-black': passwordTypeCurrent === 'text',
                  }"
                ></i>
              </div>
            </template>
          </f7-list-input>
          <f7-list-input
             
            :type="passwordTypeNew"
            name="newPassword"
            :label="$t('NewPassword')"
            :placeholder="$t('NewPassword')"
            v-model:value.trim="newPassword"
            required
            validate
            validate-on-blur
            :minlength="6"
             :error-message="$t('PassLengthErrorMessage')"
          >
            <template #media>
              <i
                class="icon-other-password size-20 text-color-lilac display-flex"
              ></i>
            </template>
            <template #content-end>
              <div
                @click="switchVisability('passwordTypeNew')"
                class="margin-right link"
              >
                <i
                  class="f7-icons icon-view"
                  :class="{
                    'text-color-gray': passwordTypeNew === 'password',
                    'text-color-black': passwordTypeNew === 'text',
                  }"
                ></i>
              </div>
            </template>
          </f7-list-input>
          <f7-list-input
            
            :type="passwordTypeConfirmNew"
            name="confirmNewPassword"
            :label="$t('ConfirmNewPassword')"
            :placeholder="$t('ConfirmNewPassword')"
            v-model:value.trim="confirmNewPassword"
            required
            validate
            validate-on-blur
            :minlength="6"
            :error-message="$t('PassLengthErrorMessage')"

          >
            <template #media>
              <i
                class="icon-other-password size-20 text-color-orange display-flex"
              ></i>
            </template>
            <template #content-end>
              <div
                @click="switchVisability('passwordTypeConfirmNew')"
                class="margin-right link"
              >
                <i
                  class="f7-icons icon-view"
                  :class="{
                    'text-color-gray': passwordTypeConfirmNew === 'password',
                    'text-color-black': passwordTypeConfirmNew === 'text',
                  }"
                ></i>
              </div>
            </template>
          </f7-list-input>
        </f7-list>
        <f7-block>
          <f7-button
            @click="onSubmitPassword"
            class="submit-btn col"
            color="blue"
            fill
            >SAVE CHANGES</f7-button
          >
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { getCountries, getTimeZones } from "../js/helpers";
import { QTAPI } from "../services/qt-api";

export default {
  name: "user-settings",

  props: {
    f7router: Object,
    f7route: Object,
  },

  data() {
    return {
      isLoading: false,
      currentTab: "tab-user",
      customerName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "",
      timeZone: "",
      address: "",
      address1: "",
      city: "",
      state: "",
      zipcode: "",

      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      passwordTypeCurrent: "password",
      passwordTypeNew: "password",
      passwordTypeConfirmNew: "password",

      countryList: getCountries(),
      keyCountryList: 1,
      timeZonesList: getTimeZones(),
      keyTimeZonesList: 1,
      isShown: true,
    };
  },

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "Permissions"]),
  },

  mounted() {
    if (this.Permissions.PROFILE > 1) {
      this.isShown = true;
    } else {
      this.isShown = false;
    }
    this.customerName = this.UserInfo.CustomerName;
    this.firstName = this.UserInfo.FirstName;
    this.lastName = this.UserInfo.SurName;
    this.email = this.UserInfo.Email;
    this.phone = this.UserInfo.Mobile;
    this.countryCode = this.UserInfo.CountryCode;
    this.keyCountryList++;
    this.timeZone = this.getSelectedTimeZone(this.UserInfo.TimeZoneID);
    this.keyTimeZonesList++;
    this.address = this.UserInfo.Address0;
    this.address1 = this.UserInfo.Address1;
    this.city = this.UserInfo.Address2;
    this.state = this.UserInfo.Address3;
    this.zipcode = this.UserInfo.Address4;
  },

  methods: {
    async onSubmitProfile() {
      let timezoneArr = this.timeZone.split("_");
      let data = {
        Code: this.UserInfo.OrganizeCode,
        RoleCode: this.UserInfo.RoleCode,
        Account: this.customerName,
        FirstName: this.firstName,
        SubName: this.lastName,
        Mobile: this.phone,
        Email: this.email,
      };
      this.isLoading = true;
      let response = await QTAPI.AccountEdit(data);
      this.isLoading = false;
      if (!response) {
        return;
      }
      response.TimeZoneID = timezoneArr[0];

      if (this.isShown) {
        this.$store.commit("SET_USER_INFO", {
          ...this.UserInfo,
          FirstName: response.FirstName,
          SurName: response.SubName,
          Email: response.Email,
          Mobile: response.Mobile,
        });
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
        this.f7router.back();
      } else {
        f7.notification
          .create({
            //subtitle: this.$t("Success"),
            text: this.$t("The root account can't be editable"),
          })
          .open();
      }
    },
    async onSubmitPassword() {
      if (this.newPassword !== confirmNewPassword) {
        f7.dialog.alert(this.$t("NewPasswordNotMatch"), this.$t("Error"));
        return;
      }
      let data = {
        MinorToken: this.MinorToken,
        oldpwd: this.currentPassword,
        newpwd: this.newPassword,
      };
      this.isLoading = true;

      let response = await QTAPI.ResetPassword(data);
      this.isLoading = false;
      if (!response) {
        return;
      }
      f7.dialog.alert(this.$t("PasswordChanged"), this.$t("Success"), () => {
        this.f7router.back();
        this.$store.dispatch("logout");
      });
    },
    getSelectedTimeZone(selected) {
      let ret = this.timeZonesList[0]?.code;
      if (selected) {
        let index = this.timeZonesList.findIndex(
          (el) => el.code.indexOf(selected) > -1
        );
        if (index !== -1) {
          ret = this.timeZonesList[index].code;
        }
      }
      return ret;
    },
    switchVisability(name) {
      this[name] = this[name] === "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.tabs-animated-wrap > .tabs > .tab {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}

.submit-btn {
  position: sticky;
  z-index: 10;
}

.list .item-inner {
  --f7-list-item-padding-vertical: 0;
  padding-top: 0;
}
</style>