<template>
  <f7-popup
    tablet-fullscreen
    class="bg-color-lightgray"
    v-model:opened="isOpened"
    @popup:closed="onHide"
    ref="popup"
  >
    <f7-progressbar
      v-if="isLoading"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          <i class="icon-back size-20"></i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-right>
        <f7-link @click="setState">
          <i class="icon-header-apply size-20"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
        >State</f7-actions-label
      >
    </f7-actions-group>

    <f7-list
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
    >
      <f7-list-item
        radio
        radio-icon="start"
        :class="!onlyActivate ? 'disabled' : ''"
        title="Activate"
        name="state-radio-start"
        @change="selectedState = 'Activate'"
      />
      <f7-list-item
        radio
        radio-icon="start"
        title="Suspend"
        name="state-radio-start"
        :class="!onlySuspend ? 'disabled' : ''"
        @change="selectedState = 'Suspend'"
      />
      <f7-list-item
        radio
        radio-icon="start"
        title="Resume"
        name="state-radio-start"
        :class="!onlyResume ? 'disabled' : ''"
        @change="selectedState = 'Resume'"
      />
      <f7-list-item
        radio
        radio-icon="start"
        title="Terminate"
        name="state-radio-start"
        :class="!onlyTerminate ? 'disabled' : ''"
        @change="selectedState = 'Terminate'"
      />
    </f7-list>
  </f7-popup>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { ApiMethods, QTAPI } from "../../../services/qt-api";
// import moment from "moment-mini";

export default {
  name: "select-state",

  props: {
    simData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isOpened: { type: Boolean, default: false },
  },

  emits: ["update:isOpened"],

  data() {
    return {
      isLoading: false,
      selectedState: null,
    };
  },
  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
  },

  watch: {
    simData() {
      this.updateStates(this.simData.State);
    },
  },
  methods: {
    onHide() {
      this.$emit("update:isOpened", false);
    },

    updateStates(state) {
      //let self = this;

      switch (state) {
        case "Productive":
          this.onlySuspend = true;
          this.onlyResume = false;
          this.onlyTerminate = true;
          this.onlyActivate = false;

          break;
        case "TestProductive":
          this.onlySuspend = true;
          this.onlyResume = false;
          this.onlyTerminate = false;
          this.onlyActivate = true;

          break;
        case "Suspended":
          this.onlySuspend = false;
          this.onlyResume = true;
          this.onlyTerminate = true;
          this.onlyActivate = false;

          break;
        case "OnStock":
          this.onlySuspend = false;
          this.onlyResume = false;
          this.onlyTerminate = false;
          this.onlyActivate = true;

          break;
        case "TestEnd":
          this.onlySuspend = false;
          this.onlyResume = false;
          this.onlyTerminate = false;
          this.onlyActivate = true;

          break;
        default:
          this.onlySuspend = false;
          this.onlyResume = false;
          this.onlyTerminate = false;
          this.onlyActivat = false;
      }
    },
    async setState() {
      let self = this;
      let result = null;
      this.isLoading = true;
      let data = {
        IMSIs: [this.simData.IMSI],
      };
      if (this.selectedState == "Suspend") {
        if(this.simData.IMSI?.toString()?.startsWith('234')){
          result = await QTAPI.SimSetSuspend(data);
        }else{
          result = await QTAPI.SimSetSuspend2(data);
        }
      } else if (this.selectedState == "Resume") {
        if(this.simData.IMSI?.toString()?.startsWith('234')){
          result = await QTAPI.SimSetResume(data);
        }else{
          result = await QTAPI.SimSetResume2(data);
        }
      } else if (this.selectedState == "Terminate") {
        if(this.simData.IMSI?.toString()?.startsWith('234')){
          result = await QTAPI.SimSetTerminate(data);
        }else{
          result = await QTAPI.SimSetTerminate2(data);
        }
      } else if (this.selectedState == "Activate") {
        f7.dialog
          .create({
            cssClass: "csp-dialog-select",
            title: "CSP",
            text: "Please select CSP to activate",
            buttons: [
              {
                text: "M2M Data",
                onClick: function () {
                  if(self.simData.IMSI?.toString()?.startsWith('234')){
                    self.activateSim("M2M Data");
                  }else{
                    self.activateSim2("M2M Data");
                  }
                },
              },
              {
                text: "M2M Data Normal CSP Z1 &2",
                onClick: function () {
                  if(self.simData.IMSI?.toString()?.startsWith('234')){
                    self.activateSim("M2M Data Normal CSP Z1 &2");
                  }else{
                    self.activateSim2("M2M Data Normal CSP Z1 &2");
                  }
                },
              },
              {
                text: "M2M Data High CSP Z1-3",
                onClick: function () {
                  if(self.simData.IMSI?.toString()?.startsWith('234')){
                    self.activateSim("M2M Data High CSP Z1-3");
                  }else{
                    self.activateSim2("M2M Data High CSP Z1-3");
                  }
                },
              },
              {
                text: "M2M Data Emergency CSP Z1- 9",
                onClick: function () {
                  if(self.simData.IMSI?.toString()?.startsWith('234')){
                    self.activateSim("M2M Data Emergency CSP Z1- 9");
                  }else{
                    self.activateSim2("M2M Data Emergency CSP Z1- 9");
                  }
                },
              },
              {
                text: "M2M Data Custom Profile Tier 1",
                onClick: function () {
                  if(self.simData.IMSI?.toString()?.startsWith('234')){
                    self.activateSim("M2M Data Custom Profile Tier 1");
                  }else{
                    self.activateSim2("M2M Data Custom Profile Tier 1");
                  }
                },
              },
              {
                text: "M2M Data Telstra Only",
                onClick: function () {
                  if(self.simData.IMSI?.toString()?.startsWith('234')){
                    self.activateSim("M2M Data Telstra Only");
                  }else{
                    self.activateSim2("M2M Data Telstra Only");
                  }
                },
              },
              {
                text: "Cancel Activation",
                onClick: function () {
                  f7.dialog.close();
                },
              },
            ],
            verticalButtons: true,
          })
          .open();
      }

      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
        
          f7.views.main.router.refreshPage();
        f7.popup.close();
      }
    },
    async activateSim(spc) {
      this.isLoading = true;
      let result = await QTAPI.SimSetActivate({
        IMSIs: [this.simData.IMSI],
        ServiceProfileCode: spc,
      });
      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
        //  
        f7.views.main.router.refreshPage();
        f7.popup.close();
      }
    },
  },

  mounted() {},

  beforeDestroy() {},
};
</script>