<template>
  <f7-page name="customer-edit" ref="page">
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back">
      </f7-nav-left>
      <f7-nav-title sliding>Summary</f7-nav-title>
      <f7-nav-right>
       
      </f7-nav-right>
    </f7-navbar>
    <f7-progressbar v-if="isLoading || isLoadingGetData" infinite color="green" class="progressbar-top"></f7-progressbar>

    <f7-list v-if="isLoadingGetData" media-list class="skeleton-text skeleton-effect-wave" key="assetEditSkeleton">
      <f7-list-item
          v-for="n in 4"
          :key="n"
          title="Title"
          subtitle="Subtitle"
      >
        <template #media>
          <f7-skeleton-block
              style="width: 40px; height: 40px; border-radius: 50%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>

    <form v-else>
      
      <f7-block-title>Total Assets</f7-block-title>
      
      <f7-block v-if="isSolutionStatsLoaded && solutionStats.length" strong>
        <f7-row>
          <f7-col v-for="item in solutionStats"
                  width="50"
                  small="33"
                  large="auto"
                  :key="`card-devices-solution-stat-${ item.name }`">
            <div class="card margin-bottom dashboard-card">
              <div class="card-header">
                {{ item.name }}
                <span v-if="item.hint" v-tooltip="item.hint"><i class="f7-icons icon-hint text-color-lightblue size-20" ></i></span></div>
              <div class="card-content card-content-padding text-color-white" :class="item.bgColor">
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    {{ numeralFormat(item.value) }}
                  </div>
                  <!--<div v-if="item.valueSecond">
                    {{ numeralFormat(item.valueSecond, '+0,0') }}
                  </div>-->
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block v-else-if="isSolutionStatsLoaded && !solutionStats.length" strong>
        <p>{{ $t('NoAssetsFound') }}</p>
      </f7-block>
      <f7-block v-else strong>
        <f7-row>
          <f7-col v-for="item in 6" width="50" small="33" large="auto" :key="`skeleton-all-devices-${item}`">
            <div class="card margin-bottom skeleton-text skeleton-effect-wave">
              <div class="card-header">header</div>
              <div class="card-content card-content-padding text-color-white " >
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    value
                  </div>
                  <div class="">
                    value
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      <!---->
      
      <f7-block-title>Total SIMs State</f7-block-title>
      <f7-block v-if="isSimStatsLoaded && simStats.length" strong>
        <f7-row>
          <f7-col v-for="item in simStats"
                  width="50"
                  small="33"
                  large="auto"
                  :key="`card-devices-solution-stat-${ item.name }`">
            <div class="card margin-bottom dashboard-card">
              <div class="card-header">
                {{ item.name }}
                <span v-if="item.hint" v-tooltip="item.hint"><i class="f7-icons icon-hint text-color-lightblue size-20" ></i></span></div>
              <div class="card-content card-content-padding text-color-white" :class="item.bgColor">
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    {{ numeralFormat(item.value) }}
                  </div>
                  <div v-if="item.valueSecond">
                    {{ numeralFormat(item.valueSecond, '+0,0') }}
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block v-else-if="isSimStatsLoaded && !simStats.length" strong>
        <p>{{ $t('NoAssetsFound') }}</p>
      </f7-block>
      <f7-block v-else strong>
        <f7-row>
          <f7-col v-for="item in 6" width="50" small="33" large="auto" :key="`skeleton-all-devices-${item}`">
            <div class="card margin-bottom skeleton-text skeleton-effect-wave">
              <div class="card-header">header</div>
              <div class="card-content card-content-padding text-color-white " >
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    value
                  </div>
                  <div class="">
                    value
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      <!---->

      <f7-block-title>Activated Past Month</f7-block-title>
      <f7-block v-if="isActivatedPastMonthStatsLoaded && activatedPastMonthStats.length" strong>
        <f7-row>
          <f7-col v-for="item in activatedPastMonthStats"
                  width="50"
                  small="33"
                  large="auto"
                  :key="`card-devices-solution-stat-${ item.name }`">
            <div class="card margin-bottom dashboard-card">
              <div class="card-header">
                {{ item.name }}
                <span v-if="item.hint" v-tooltip="item.hint"><i class="f7-icons icon-hint text-color-lightblue size-20" ></i></span></div>
              <div class="card-content card-content-padding text-color-white" :class="item.bgColor">
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    {{ numeralFormat(item.value) }}
                  </div>
                  <div v-if="item.valueSecond">
                    {{ numeralFormat(item.valueSecond, '+0,0') }}
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block v-else-if="isActivatedPastMonthStatsLoaded && !activatedPastMonthStats.length" strong>
        <p>{{ $t('NoAssetsFound') }}</p>
      </f7-block>
      <f7-block v-else strong>
        <f7-row>
          <f7-col v-for="item in 6" width="50" small="33" large="auto" :key="`skeleton-all-devices-${item}`">
            <div class="card margin-bottom skeleton-text skeleton-effect-wave">
              <div class="card-header">header</div>
              <div class="card-content card-content-padding text-color-white " >
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    value
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      
      <!---->
    

      <f7-block-title>Dectivated Past Month</f7-block-title>
      <f7-block v-if="isDeactivatedPastMonthStatsLoaded && deactivatedPastMonthStats.length" strong>
        <f7-row>
          <f7-col v-for="item in deactivatedPastMonthStats"
                  width="50"
                  small="33"
                  large="auto"
                  :key="`card-devices-solution-stat-${ item.name }`">
            <div class="card margin-bottom dashboard-card">
              <div class="card-header">
                {{ item.name }}
                <span v-if="item.hint" v-tooltip="item.hint"><i class="f7-icons icon-hint text-color-lightblue size-20" ></i></span></div>
              <div class="card-content card-content-padding text-color-white" :class="item.bgColor">
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    {{ numeralFormat(item.value) }}
                  </div>
                  <div v-if="item.valueSecond">
                    {{ numeralFormat(item.valueSecond, '+0,0') }}
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block v-else-if="isDeactivatedPastMonthStatsLoaded && !deactivatedPastMonthStats.length" strong>
        <p>{{ $t('NoAssetsFound') }}</p>
      </f7-block>
      <f7-block v-else strong>
        <f7-row>
          <f7-col v-for="item in 6" width="50" small="33" large="auto" :key="`skeleton-all-devices-${item}`">
            <div class="card margin-bottom skeleton-text skeleton-effect-wave">
              <div class="card-header">header</div>
              <div class="card-content card-content-padding text-color-white " >
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    value
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      <!---->

      <f7-block-title>Renewals</f7-block-title>
      <f7-block v-if="isRenewalStatsLoaded && renewalStats.length" strong>
        <f7-row>
          <f7-col v-for="item in renewalStats"
                  width="50"
                  small="33"
                  large="auto"
                  :key="`card-devices-solution-stat-${ item.name }`">
            <div class="card margin-bottom dashboard-card">
              <div class="card-header">
                {{ item.name }}
                <span v-if="item.hint" v-tooltip="item.hint"><i class="f7-icons icon-hint text-color-lightblue size-20" ></i></span></div>
              <div class="card-content card-content-padding text-color-white" :class="item.bgColor">
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    {{ item.value.split('&')[0] }}
                  </div>
                  <div v-if="item.value.includes(item.value, '&')">
                    {{ item.value.split('&')[1]  }}
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block v-else-if="isRenewalStatsLoaded && !renewalStats.length" strong>
        <p>{{ $t('NoAssetsFound') }}</p>
      </f7-block>
      <f7-block v-else strong>
        <f7-row>
          <f7-col v-for="item in 6" width="50" small="33" large="auto" :key="`skeleton-all-devices-${item}`">
            <div class="card margin-bottom skeleton-text skeleton-effect-wave">
              <div class="card-header">header</div>
              <div class="card-content card-content-padding text-color-white " >
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    value
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>

      <!---->

      <f7-block-title>Other Info</f7-block-title>
      <f7-block v-if="isOtherStatsLoaded && otherStats.length" strong>
        <f7-row>
          <f7-col v-for="item in otherStats"
                  width="50"
                  small="33"
                  large="auto"
                  :key="`card-devices-solution-stat-${ item.name }`">
            <div class="card margin-bottom dashboard-card">
              <div class="card-header">
                {{ item.name }}
                <span v-if="item.hint" v-tooltip="item.hint"><i class="f7-icons icon-hint text-color-lightblue size-20" ></i></span></div>
              <div class="card-content card-content-padding text-color-white" :class="item.bgColor">
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    {{ numeralFormat(item.value) }}
                  </div>
                  <div v-if="item.valueSecond">
                    {{ numeralFormat(item.valueSecond, '+0,0') }}
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block v-else-if="isOtherStatsLoaded && !otherStats.length" strong>
        <p>{{ $t('NoAssetsFound') }}</p>
      </f7-block>
      <f7-block v-else strong>
        <f7-row>
          <f7-col v-for="item in 6" width="50" small="33" large="auto" :key="`skeleton-all-devices-${item}`">
            <div class="card margin-bottom skeleton-text skeleton-effect-wave">
              <div class="card-header">header</div>
              <div class="card-content card-content-padding text-color-white " >
                <div class="display-flex justify-content-space-between">
                  <div class="">
                    value
                  </div>
                  <div class="">
                    value
                  </div>
                </div>
              </div>
            </div>
          </f7-col>
        </f7-row>
      </f7-block>



    </form>



  </f7-page>
</template>

<script>
import {f7} from "framework7-vue";
import {
  CustomerTypes,
  getCountries,
  getTimeZones,
  getTimeFormats,
  getLanguages,
  getSpeedUnitList,
  getCustomerTypes,
  getUserPermissions,
  getAdminPermissions,
  getAlarmMonitorList,
  getInstallerPermissionList,
  InstallerPermissionEnum,
  SortArrayByObjProps,
  AdminPermissionsEnum,
  Permissions2Enum,
} from "../../js/helpers";
import {QTAPI} from "../../services/qt-api";
import {mapGetters} from "vuex";
import EmailList from "../../components/email-list"
//import ActivationService from "../../services/activations-service";


export default {
  name: "customer-edit",

  props: {
    f7router: Object,
    f7route: Object,
  },

  components: {
    EmailList
  },

  data(){
    let UserInfoPreEdit = this.$store.getters.UserInfoPreEdit

    return {
      inputSubmitId: f7.utils.id(),
      isLoading: false,
      isLoadingGetData: false,
      isLoadingSSP: false,



      isSolutionStatsLoaded: false,
      solutionStats: [],
      isSimStatsLoaded: false,
      simStats: [],
      isOtherStatsLoaded: false,
      otherStats: [],
      LoginName: '',

      isActivatedPastMonthStatsLoaded: false,
      activatedPastMonthStats: [],
      isDeactivatedPastMonthStatsLoaded: false,
      deactivatedPastMonthStats: [],
      isRenewalStatsLoaded: false,
      renewalStats: [],


      keyCustomerTypeList: 1,
      keyLanguageList: 1,
      keyCountryList: 1,
      keyTimeFormatList: 1,
      keyTimeZoneList: 1,
      keyThemeList: 1,
      keyWebsiteList: 1,
      keyGroupList: 1,
      keySpeedUnitList: 1,


      CustomerTypesEnum: CustomerTypes,
      CustomerTypeList: getCustomerTypes(),
      LanguageList: getLanguages(),
      CountryList: getCountries(),
      TimeFormatList: getTimeFormats(),
      TimeZoneList: getTimeZones(),
      ThemeList: [{Code: '0', Name: this.$t('Default')}],
      WebsiteList: [],
      GroupList: [],
      SpeedUnitList: getSpeedUnitList(),
      SolutionTypeList: [],
      UserPermissionList: SortArrayByObjProps(getUserPermissions(), [{prop: 'name', direction: 1}]),
      AdminPermissionList: [],
      InstallerPermissions: SortArrayByObjProps(getInstallerPermissionList(), [{prop: 'name', direction: 1}]),
      AlarmMonitorList: SortArrayByObjProps(getAlarmMonitorList(), [{prop: 'name', direction: 1}]),
      Permissions: {},
      AlarmMonitorIds: {},
      UserPermissions: {},
      InstallerPermissionIds: {},

      AccountName: null,
      CustomerTypeCode: 5,
      LanguageCode: UserInfoPreEdit?.ContactInfo?.Language || "en",
      CountryCode: UserInfoPreEdit?.ContactInfo?.CountryCode || "AUS",
      TimeFormatCode: UserInfoPreEdit?.ContactInfo?.TimeFormat || "dd/MM/yyyy HH:mm:ss",
      TimeZoneCode: "W. Australia Standard Time_8",
      ThemeCode: UserInfoPreEdit?.CustomerInfo?.ExtDoaminCode || "0",
      WebsiteCode: UserInfoPreEdit?.CustomerInfo?.WebSiteCode || "00000000-0000-0000-0000-000000000000",
      FirstName: null,
      LastName: null,
      LoginName: null,
      Email: null,
      Mobile: null,
      Phone: null,
      GroupCode: null,
      SpeedUnitCode: UserInfoPreEdit?.CustomerInfo?.SpeedUnit || 'KPH',
      Address: null,
      Address1: null,
      City: null,
      State: null,
      Zipcode: null,
      AlarmEmails: null,
      //ActivationEmails: null,
      Credits: null,
      PaymentType: 'NONE',
      Number: null,
      UserCode: null,
      SecurityCode: null,
      CustomerCode: this.f7route.query.customerCode,
      isSolutionSyncSubAgent: false,
      isSendRegLetter: false,
      isSendMonthlyAccountReport: true,
    }
  },

  computed: {
    ...mapGetters(['IS_ROOT', 'MajorToken', 'MinorToken', 'UserInfoPreEdit']),
    phoneCode(){
      let country = this.CountryList.length ? this.CountryList.find((itm)=> itm.CountryCode === this.CountryCode) : ''
      return country ? country.CountryPhoneCode : '';
    },
    isAgentOrDealer(){
      return +this.CustomerTypeCode === +this.CustomerTypesEnum?.Agent || +this.CustomerTypeCode === +this.CustomerTypesEnum?.Dealer
    }
  },

  mounted() {
    this.AdminPermissionList = SortArrayByObjProps(getAdminPermissions(this.IS_ROOT), [{prop: 'name', direction: 1}])

    this.getPreEditCustomerInfo();
    //this.AllSolutionStats()
  },

  methods: {
    async AllSolutionStats() {
       const subs = {
        name: 'Sub Customers',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-8'
      }

      
      
      const renewed = {
        name: 'Total Number Agent/Stripe',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-8'
      }
      
      const renewed2 = {
       name: 'Total Value ($) Agent/Stripe',
       value: 0,
       valueSecond: 0,
       bgColor: 'bg-custom-color-7'
     }
     const renewed3 = {
       name: 'This and past month Agent/Stripe',
       value: 0,
       valueSecond: 0,
       bgColor: 'bg-custom-color-18'
     }
     const renewed4 = {
       name: 'This and past month ($) Agent/Stripe',
       value: 0,
       valueSecond: 0,
       bgColor: 'bg-custom-color-3'
     }


      const onstock = {
        name: 'OnStock',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-18',
      }
      
      const productive = {
        name: 'Productive',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-3'
      }

      
      const otherA = {
        name: 'Other',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-8'
      }
      
      const other = {
        name: 'Other',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-8'
      }

      const testproductive = {
        name: 'TestProductive',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-8'
      }

      const suspended = {
        name: 'Suspended',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }

      
      
      const green = {
        name: 'Online',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }
      
      const yellow = {
        name: 'Reporting',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }


      const red = {
        name: 'Offline',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-5'
      }

      
      const NonA = {
        name: 'NonActivated (transmitting)',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-18'
      }

      const protect = {
        name: 'Protect',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }
      const protectA = {
        name: 'Protect',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }
      const al3rtprotect = {
        name: 'Al3rtProtect',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }
      const witiqprotect = {
        name: 'WiTiQProtect',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-2'
      }
      
      const qProtect = {
        name: 'QProtect',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-3'
      }
      const qProtectA = {
        name: 'QProtect',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-3'
      }

      
      const loc8 = {
        name: 'Loc8',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-4'
      }
      const loc8A = {
        name: 'Loc8',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-4'
      }


      const witiloc8 = {
        name: 'WitiLoc8',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-4'
      }
      
      const al3rtloc8 = {
        name: 'Al3rtLoc8',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-4'
      }
      const ll = {
        name: 'LLP',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-7'
      }
      const llA = {
        name: 'LLP',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-7'
      }
      
      const deactivated = {
        name: 'Deactivate',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-18'
      }
      const deactivatedA = {
        name: 'Deactivate',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-18'
      }
      
      
      const watch = {
        name: 'Watch',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-6'
      }
      const watchA = {
        name: 'Watch',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-6'
      }

      
      const track = {
        name: 'Track',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-5'
      }
      const trackA = {
        name: 'Track',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-5'
      }
      const activatedA = {
        name: 'Activated',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-5'
      }

      
      const wititrack = {
        name: 'WiTiTrack',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-5'
      }
      const al3rttrack = {
        name: 'Al3rtTrack',
        value: 0,
        valueSecond: 0,
        bgColor: 'bg-custom-color-5'
      }
      //protect
      let all = 0
      let type = 0
      let account =  this.CustomerCode
      let ownercode = ''

      const resp = await fetch('https://newapi.quiktrak.co/QuikTrak/V1/Device/GetWebsitesbyLoginname?loginname='+this.LoginName)
      let resp1 = await resp.json()
      //console.log('resp1',resp1)
      let agent = resp1.Data.WebList[0]?.AgentCode;

      if(agent == '2087d174-71cf-43a5-922f-5f6e8fdaa8be' || agent == '994a7b7e-00a0-4d19-a7fb-1ff5fdf23422') type = 1
      if(agent == 'aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || agent == '7aa602f4-3685-4a11-a55f-dccd06194548' ) type = 2

      switch (agent){
					case '938c3600-be37-4915-b612-861c7717e83d'://m2m ais
						ownercode = '891b91a2-e68b-495b-b0aa-a6671f13a9ca'//cartrack
            
						break
					case '2087d174-71cf-43a5-922f-5f6e8fdaa8be'://al3rt
						ownercode = '9b6bfbcc-0f73-4e50-aed6-a878723daa23';
						/*type = 1
						self.$setState({
							isAl3rt: true
						})*/
						break
					case '892c9488-2bb8-42ee-bc2e-113e49de8a0b'://Automotive Mobile Installations
						ownercode = 'e7bb3a69-898e-4db6-a392-cbc397655508'
						break
					case 'aabc9ee0-b36d-4806-8e4d-b65899f7da7c'://ATGA Admin
						ownercode = '0943e1b7-396c-41ed-9b31-04fe6ee19de0'
						
						/*type = 2
						self.$setState({
							isWiTi: true
						})*/
						break
					case '885812a9-3967-47df-95f6-af3b01088369'://Autofidelity
						ownercode = 'dcc15199-2ea0-487e-befa-c9624023e1eb'
						break
					case '98d31f20-db6a-466b-868d-a48480a2c318'://AutoProtect
						ownercode = '7da9b69d-87a3-4a88-9c28-bacd8559a661'
						break
					case 'f956033f-709e-425b-b898-c72c8b14a81d'://Borderless Asset Tracking
						ownercode = '0392d2fe-9b86-4931-9663-c56bace4b33b'
						break
					case '926be43e-8f61-43b9-8f15-3a43c6ae6b50'://Boatfix
						ownercode = 'd2e62eb5-2bb1-46cf-a6b0-198e8f8dccf2'
						break
					case '95b6e884-2943-4027-9d92-512c8a626f0e'://Charged Install
						ownercode = '149b3e04-70d1-4b70-a5e5-2dcf0abb29ec'
						break
					/*case '5e5ff904-a683-46ef-853a-d5cd4bfdaf87'://Cindy - China
						account = 'DataWeek'
						break*/
					case 'f0842588-e648-4516-b1da-47c2f1d4d97e'://Cloudnet Pty Ltd
						ownercode = '6b5fc71f-3975-4618-ad5c-6e85e9089078'
						break
					case '94aa0c45-b6b7-40a5-bc51-5f25f4c92caf'://Cube Voice & Data
						ownercode = '070839df-844c-4b4d-bb1b-ac88a4b62f7a'
						break
					case 'ee40c364-8afe-44ad-a0cc-c72b8c380dbd'://Dealers Own
						ownercode = '78196b7c-9e2a-48a5-be33-794ad3728542'
						break
					/*case '6c69cb85-ffd9-4b4e-9fda-23ee14fb8fbc'://DOBUYDIRECT
						account = 'DataWeek'
						break*/
					case '0068bb44-b3bf-4531-bbbf-abba1a701385'://Ufind Gps Tracking Pty Ltd
						ownercode = 'fc12866f-6208-4a13-aad5-d34b08397cbe'
						break
					/*case '68beee45-b9cc-43ec-8a74-c77585c460e1'://EELink - China
						account = 'DataWeek'
						break
					case '86a6c93d-949a-4ef3-ac3a-1059c981ec71'://Geelinx GPS
						account = 'DataWeek'
						break*/
					case '936a0e17-dce1-4333-af80-8b9faf40a7d5'://GPS Tracking WA
						ownercode = '14c3a933-4779-474c-bc27-5ba17c251aed'
						break
					/*case '99e0849b-b652-43aa-ae61-b887875e2d91'://Iridium - China
						account = 'DataWeek'
						break*/
					case 'f4a2312b-8ce5-4e65-8b77-0b03e13e24ff'://ISPY GPS Tracking
						ownercode = 'eafad048-e288-4ea3-8a36-0094c861e33c'
						break
					case '4f3eb7d5-6ddb-4bdf-bdea-8d16a4078e98'://Ignition Security Systems
						ownercode = 'fd2c48b7-7ae4-4938-b88b-816dbc1411a1'
						break
					case '936e1b8d-c90d-4d03-b231-ef35cfb00cb2'://luisfz
						ownercode = '12c4b86e-fe9f-4330-8eae-beddcabafca8'
						break
					case 'e9a34092-79ee-410d-8886-a818bca93b2d'://Mobitrak Admin
						ownercode = '71e556f8-849a-4b59-8216-1b6192c8bd89'
						break
					case 'f89b5d96-4526-4df1-912c-72285d69bbc2'://MarineMax
						ownercode = 'a352cc2b-7ad2-43ea-8139-84ba28a2e3fc'
						break
					case '7a8fd515-99bf-48d7-8e54-a5a197f7ad6c'://MEP Films
						ownercode = '29b20d2d-003e-474b-a326-de46dff58442'
						break
					case 'faf97ca0-bcef-48d5-8679-7c71243fa5bb'://NuTech
						ownercode = 'edbf42cb-3d02-43f4-91df-b0397a6d6bb0'
						break
					case '35488ae8-e164-4668-b7ff-eef185adc9a8'://Pillalo GPS
						ownercode = '0b57f8b9-7e0b-4239-9676-0ce1d88ebbdf'
						break
					/*case 'f7ddeb97-a9ee-4239-a855-dc756e76db69'://Pranafreeconnex Pty Ltd
						account = 'DataWeek'
						break*/
					case '96afa0c3-95f6-4a61-a31f-569c70db68f8'://Plant and Vehicle Services Limited
						ownercode = 'dc24f0e8-79cf-445c-a2e0-a1605db03389'
						break
					case '21b1fc96-8907-4203-91de-19eb73affa0e'://Quiktrak Global Canada
						ownercode = '3fd507a8-08f3-4cb0-a317-06938033060f'
						break
					case 'b4de2d16-b855-4ed3-a3e7-66410f81a2fb'://QUIKTRAKGLOBAL USA
						ownercode = 'cbf3f3de-e84e-4e63-af30-abc16ffafe5e'
						break
					case 'b4de2d16-b855-4ed3-a3e7-66410f81a2fb'://Quiktrak USA
						ownercode = '359429d7-bbdf-4f0b-8382-5b328de92b93'
						break
					case '7184ffc4-f317-4b90-9583-8ad6c38edb11'://Quiktrak
						ownercode = '028435cf-344a-4f43-b5ea-15fd2ed6c46a'
						break
					case '55e877dc-8e28-42b9-947f-564c8c7dcdea'://Quiktrak Ukraine Limited
						ownercode = '77b2b16f-1bb9-4c68-87c2-9870dce4586d'
						break
					case 'c6406310-3acb-4944-b8ec-02bba22c1dc7'://ReconoGPS
						ownercode = '31a49b01-1164-47db-ab8c-3e73ac5fc98a'
						break
					case 'c89160b8-65ea-4a38-bc22-efb508bd1c35'://Selftrack Chile
						ownercode = '0f006f7c-cc80-49d8-9499-1faab182bb38'
						break
					/*case '3ec0394d-69e0-4e0c-a9f7-76f357033679'://Shane - China
						account = 'DataWeek'
						break*/
					case '6a234166-69bd-43c3-9f07-4d744dd6e22d'://Sinopacific Africa
						ownercode = '642793f9-3383-45dc-a9ec-4e6ef7bf3a49'
						break
					case '34e62d97-714a-4650-9ff0-672523442f50'://Sino Ukraine
						ownercode = '645e86dc-d3a9-4088-af37-dd983eb42117'
						break
					case '645e3c7b-af57-4874-a387-fb79866b6f74'://Smartostrich
						ownercode = '675afe69-1249-47d3-93e8-717d9f5b35d8'
						break
				  }

          
        var cur = new Date()
        var curyear = cur.getFullYear()
        var prevmonth = cur.getMonth()
        var prevyear = prevmonth == 0 ? curyear - 1 : curyear

        var curmonth = prevmonth == 0 ? '01' : (prevmonth + 1).toString().padStart(2, "0")
        var prevmonth = prevmonth == 0 ? '12' : prevmonth.toString().padStart(2, "0")

        /*var cur = new Date()
        var prevyear = cur.getFullYear()
        var prevmonth = cur.getMonth()
        var curyear = prevmonth == 0 ? prevyear - 1 : prevyear
        var curmonth = prevmonth == 0 ? prevmonth : prevmonth + 1*/


        let responseA1 = await QTAPI.getActivationStatistic2Total({
          MajorToken: account,
          MinorToken: this.MinorToken,
          ReportType: 'Activation',
          From: prevyear + '-' + prevmonth + '-01',
          To: curyear + '-' + curmonth + '-01',//setHours(23,59,59,999))
        })
        let activatedA1= responseA1.Data?.Activations
        let responseA2 = await QTAPI.getActivationStatistic2Total({
          MajorToken: account,
          MinorToken: this.MinorToken,
          ReportType: 'Upgrade',
          From: prevyear + '-' + prevmonth + '-01',
          To: curyear + '-' + curmonth + '-01',//setHours(23,59,59,999))
        })
        let activatedA2 = responseA2.Data?.Activations
        let responseA3 = await QTAPI.getActivationStatistic2Total({
          MajorToken: account,
          MinorToken: this.MinorToken,
          ReportType: 'Replace',
          From: prevyear + '-' + prevmonth + '-01',
          To: curyear + '-' + curmonth + '-01',//setHours(23,59,59,999))
        })
        let activatedA3 = responseA3.Data?.Activations
        activatedA.value = activatedA1 + activatedA2 + activatedA3
        let responseA4 = await QTAPI.getActivationStatistic2Total({
          MajorToken: account,
          MinorToken: this.MinorToken,
          ReportType: 'DeActivate',
          From: prevyear + '-' + prevmonth + '-01',
          To: curyear + '-' + curmonth + '-01',//setHours(23,59,59,999))
        })
        deactivatedA.value = responseA4.Data?.Activations

        this.activatedPastMonthStats = [activatedA].filter(itm => itm.value > 0)
        this.isActivatedPastMonthStatsLoaded = true
        //protectA,qProtectA,loc8A,llA,watchA,trackA
        this.deactivatedPastMonthStats = [deactivatedA].filter(itm => itm.value > 0)
        this.isDeactivatedPastMonthStatsLoaded = true

          //this.activatedPastMonthStats = [protectA,qProtectA,loc8A,llA,watchA,trackA].filter(itm => itm.value > 0)
        //this.isActivatedPastMonthStatsLoaded = true

      const responseAll = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
			let resAll = await responseAll.json()

      all = resAll.total ? resAll.total : 0




			const response1 = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Protect&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
			let res1 = await response1.json()

      protect.value = res1.total ? res1.total + ' ' : '0 '

				
				if(type == 1){
					///al3rtqprotect
					const response2 = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Al3rtProtect&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res2 = await response2.json()
          al3rtprotect.value = res2.total ? res2.total + ' ' : '0 '
				}
				if(type == 2){
					///witiqprotect
					const response2_ = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=WiTiQProtect&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res2_ = await response2_.json()
          witiqprotect.value = res2_.total ? res2_.total + ' ' : '0 '
				}

        

          ///qprotect
        const response2__ = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=QProtect&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
        .catch(e => {
        })
        let res2__ = await response2__.json()
        qProtect.value = res2__.total ? res2__.total + ' ' : '0 '
        
        
          ///qprotect
          const responseNonA__ = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=NonActivated&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
        .catch(e => {
        })
        let resNonA__ = await responseNonA__.json()
        NonA.valueSecond = resNonA__.total ? resNonA__.total + ' ' : '0 '

        const responseNonA__1 = await fetch(`https://newapi.quiktrak.co/Quiktrak/V2/Asset/GetNonActivatedAssetList?MajorToken=`+account+`&MinorToken=00000000-0000-0000-0000-000000000000`)
        .catch(e => {
        })
        let resNonA__1 = await responseNonA__1.json()
        NonA.value = resNonA__1?.Data[0]?.TOTAL_ASSETS ? resNonA__1?.Data[0]?.TOTAL_ASSETS + ' ' : '0 '

        if(type == 1){
					///al3rtloc8
					const response3 = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Al3rtLoc8&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res3 = await response3.json()
          al3rtloc8.value = res3.total ? res3.total + ' ' : '0 '
				}
					///loc8
					const response3_ = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Loc8&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res3_ = await response3_.json()
          loc8.value = res3_.total ? res3_.total + ' ' : '0 '
				
        if(type == 2){
					///WiTiTrack
					const responseL = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=WitiLoc8&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let resL = await responseL.json()
          
          witiloc8.value = resL.total ? resL.total + ' ' : '0 '
				
				}
        
				///ll
				const response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=LongLifePortable&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
				})
				let res = await response.json()
          ll.value = res.total ? res.total + ' ' : '0 '

        ///watch
				const response4 = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Watch&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
				})
				let res4 = await response4.json()
          watch.value = res4.total ? res4.total + ' ' : '0 '

        if(type == 1){
					///Al3rtTrack
					const response5 = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Al3rtTrack&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res5 = await response5.json()
          
          al3rttrack.value = res5.total ? res5.total + ' ' : '0 '
				}
				if(type == 2){
					///WiTiTrack
					const response5_ = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=WiTiTrack&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res5_ = await response5_.json()
          
          wititrack.value = res5_.total ? res5_.total + ' ' : '0 '
				
				}
					///Track
					const response5__ = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Track&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let res5__ = await response5__.json()
          track.value = res5__.total ? res5__.total + ' ' : '0 '


          ///Deactivated
					const responseD = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=Deactivate&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
					.catch(e => {
					})
					let resD = await responseD.json()
          deactivated.value = resD.total ? resD.total + ' ' : '0 '


//renewal
          const response7t = await fetch(`https://newapi.quiktrak.co/Quiktrak/v1/Report/GetGpsSecureActivations2?MajorToken=`+account+`&MinorToken=00000000-0000-0000-0000-000000000000&IsSub=1&Type=Renewal&BeginDate=2021-01-01&EndDate=2040-01-01`,{
            method: "POST"
          })
					.catch(e => {
					})
					let res7t = await response7t.json()
          
          var totalmonth1 = res7t.Data?.Activations ? res7t.Data?.Activations?.filter(el => el.IsFake == true)?.length + '' : '0'
          var totalmonth2 = res7t.Data?.Activations ? res7t.Data?.Activations?.filter(el => el.IsFake == false)?.length + '' : '0'
          
//this m          
          var today = new Date()
          var todaysyear = today.getFullYear()
          var todaysmonth = today.getMonth() + 1
					const response7 = await fetch(`https://newapi.quiktrak.co/Quiktrak/v1/Report/GetGpsSecureActivations2?MajorToken=`+account+`&MinorToken=00000000-0000-0000-0000-000000000000&IsSub=1&Type=Renewal&BeginDate=`+ todaysyear.toString().padStart(4, "0") +`-`+todaysmonth.toString().padStart(2, "0")+`-01&EndDate=2040-01-01`,{
            method: "POST"
          })
					.catch(e => {
					})
					let res7 = await response7.json()
          
          var thismonth1 = res7.Data?.Activations ? res7.Data?.Activations?.filter(el => el.IsFake == true)?.length + '' : '0'
          var thismonth2 = res7.Data?.Activations ? res7.Data?.Activations?.filter(el => el.IsFake == false)?.length + '' : '0'
          console.log('thismonth1:',thismonth1,'thismonth2:',thismonth2 )
//past m
          const response7p = await fetch(`https://newapi.quiktrak.co/Quiktrak/v1/Report/GetGpsSecureActivations2?MajorToken=`+account+`&MinorToken=00000000-0000-0000-0000-000000000000&IsSub=1&Type=Renewal&BeginDate=`+ prevyear + '-' + prevmonth + '-01&EndDate='+curyear + '-' + curmonth + '-01',{
            method: "POST"
          })
					.catch(e => {
					})
					let res7p = await response7p.json()
          
          var pastmonth1 =  res7p.Data?.Activations ? res7p.Data?.Activations?.filter(el => el.IsFake == true)?.length + '' : '0'
          var pastmonth2 =  res7p.Data?.Activations ? res7p.Data?.Activations?.filter(el => el.IsFake == false)?.length + '' : '0'
          

          
          var total1 = 0;
					var total2 = 0;
          var total1t = 0;
					var total2t = 0;
          var total1p = 0;
					var total2p = 0;

//total
          res7t.Data?.Activations.forEach(val => {		
						if(val?.Imei && val?.IsFake == true){
              
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                if(account=='aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || account=='10fc38b4-2093-49e9-a256-f0330579691b' || account=='7aa602f4-3685-4a11-a55f-dccd06194548' || account=='d86b26da-3307-4c6c-a518-410000838cb8'){sp = '17.00';}else{ sp = '8.00';}
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }
              total1 += +sp
            }else if(val?.Imei && val?.IsFake == false){
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                if(account=='aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || account=='10fc38b4-2093-49e9-a256-f0330579691b' || account=='7aa602f4-3685-4a11-a55f-dccd06194548' || account=='d86b26da-3307-4c6c-a518-410000838cb8'){sp = '17.00';}else{ sp = '8.00';}
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }

              total2 += +sp
            }
          });

          //total this
          res7.Data?.Activations.forEach(val => {		
						if(val?.Imei && val?.IsFake == true){
              
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                if(account=='aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || account=='10fc38b4-2093-49e9-a256-f0330579691b' || account=='7aa602f4-3685-4a11-a55f-dccd06194548' || account=='d86b26da-3307-4c6c-a518-410000838cb8'){sp = '17.00';}else{ sp = '8.00';}
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }
              total1t += +sp
            }else if(val?.Imei && val?.IsFake == false){
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                if(account=='aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || account=='10fc38b4-2093-49e9-a256-f0330579691b' || account=='7aa602f4-3685-4a11-a55f-dccd06194548' || account=='d86b26da-3307-4c6c-a518-410000838cb8'){sp = '17.00';}else{ sp = '8.00';}
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }

              total2t += +sp
            }
          });

          //total past
          res7p.Data?.Activations.forEach(val => {		
						if(val?.Imei && val?.IsFake == true){
              
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                if(account=='aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || account=='10fc38b4-2093-49e9-a256-f0330579691b' || account=='7aa602f4-3685-4a11-a55f-dccd06194548' || account=='d86b26da-3307-4c6c-a518-410000838cb8'){sp = '17.00';}else{ sp = '8.00';}
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }
              total1p += +sp
            }else if(val?.Imei && val?.IsFake == false){
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                if(account=='aabc9ee0-b36d-4806-8e4d-b65899f7da7c' || account=='10fc38b4-2093-49e9-a256-f0330579691b' || account=='7aa602f4-3685-4a11-a55f-dccd06194548' || account=='d86b26da-3307-4c6c-a518-410000838cb8'){sp = '17.00';}else{ sp = '8.00';}
              }else if((val?.SolutionType.toLowerCase().indexOf('life') != -1 || val?.SolutionType.toLowerCase().indexOf('track') != -1) && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }

              total2p += +sp
            }
          });
					/*var total1 = 0;
					var total2 = 0;

          res7.Data?.Activations.forEach(val => {		
						if(val?.Imei && val?.IsFake == true){
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('track') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '8.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('track') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }

              total1 += +sp
            }else if(val?.Imei && val?.IsFake == false){
							let sp = '0';
              if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '2.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('protect') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '20.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '4.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('loc8') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '40.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('track') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '8.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('track') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '80.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('month') != -1){
                  sp = '10.00'; 
              }else if(val?.SolutionType.toLowerCase().indexOf('watch') != -1 && val?.ServicePlan.toLowerCase().indexOf('annual') != -1){
                  sp = '110.00'; 
              }

              total2 += +sp
            }
          });*/
          
          console.log('thismonth1:',thismonth1,'thismonth2:',thismonth2 )
          
          /*renewed.value = totalmonth1 + ' ('+thismonth1 +')'
          renewed2.value = totalmonth2 + ' ('+thismonth2 +')'*/
          renewed.value = totalmonth1 + ' / ' + totalmonth2 +' '
          renewed2.value = total1 + ' / ' + total2 +' '
          renewed3.value = thismonth1 + ' / ' + thismonth2 +'&'+pastmonth1 + ' / ' + pastmonth2
          renewed4.value = total1t + ' / ' + total2t +'&'+total1p + ' / ' + total2p

            
          this.renewalStats = [renewed,renewed2,renewed3,renewed4];//.filter(itm => itm.value > 0)
        

          let otherAssets = all - +witiloc8.value - +protect.value - +deactivated.value - +al3rtprotect.value - +witiqprotect.value - +qProtect.value - +al3rtloc8.value - +loc8.value - +ll.value - +NonA.valueSecond -+watch.value - +track.value - +al3rttrack.value - +wititrack.value
          otherA.value = otherAssets > 0 ? otherAssets + ' ' : '0 '

        this.solutionStats = [protect,al3rtprotect,witiqprotect,qProtect,al3rtloc8,loc8,witiloc8,ll,NonA,deactivated,watch,track,al3rttrack,wititrack,otherA].filter(itm => itm.value > 0)
        this.isSolutionStatsLoaded = true

        //~this.renewalStats = [renewed,renewed2];//.filter(itm => itm.value > 0)
        this.isRenewalStatsLoaded = true
        /******/
            
        


        if(ownercode){

          let response = await QTAPI.GetSimStatusTotal({
            "Status[]":  "Suspended",
            "OrganizeCode":  ownercode,
          })
          suspended.value = response.Total ? response.Total + ' ' : '0 '

          
           response = await QTAPI.GetSimStatusTotal({
            "Status[]":  "OnStock",
            "OrganizeCode":  ownercode,
          })
          onstock.value = response.Total ? response.Total + ' ' : '0 '

          
           response = await QTAPI.GetSimStatusTotal({
            "Status[]":  "Productive",
            "OrganizeCode":  ownercode,
          })
          productive.value = response.Total ? response.Total + ' ' : '0 '

           response = await QTAPI.GetSimStatusTotal({
            "Status[]":  "TestProductive",
            "OrganizeCode":  ownercode,
          })
          testproductive.value = response.Total ? response.Total + ' ' : '0 '

          
           response = await QTAPI.GetSimStatusTotal({
            "RAGs[]":  "GREEN",
            "OrganizeCode":  ownercode,
          })
          green.value = response.Total ? response.Total + ' ' : '0 '
          
           response = await QTAPI.GetSimStatusTotal({
            "RAGs[]":  "YELLOW",
            "OrganizeCode":  ownercode,
          })
          yellow.value = response.Total ? response.Total + ' ' : '0 '
          
           response = await QTAPI.GetSimStatusTotal({
            "RAGs[]":  "RED",
            "OrganizeCode":  ownercode,
          })
          red.value = response.Total ? response.Total + ' ' : '0 '
        }else{
          
          let response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=Suspended&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
          let resalt = await response.json()
          suspended.value = resalt.total ? resalt.total + ' ' : '0 '

          
           response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=Productive&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
           resalt = await response.json()
          productive.value = resalt.total ? resalt.total + ' ' : '0 '


           response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=TestProductive&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
           resalt = await response.json()
          testproductive.value = resalt.total ? resalt.total + ' ' : '0 '


           response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=OnStock&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
           resalt = await response.json()
          onstock.value = resalt.total ? resalt.total + ' ' : '0 '




           response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=3_OFFLINE&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
           resalt = await response.json()
          red.value = resalt.total ? resalt.total + ' ' : '0 '

          
           response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=2_OFFLINE&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
           resalt = await response.json()
          yellow.value = resalt.total ? resalt.total + ' ' : '0 '



           response = await fetch(`https://newapi.quiktrak.co/QUIKTRAK/V2/ASSET/GETLIST?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&CsCode=`+account+`&page=1&rows=1&sort=IMEI&order=desc&q=&SIMS=&AssetStatus=1_ONLINE&AssetType=&payPlanCode=&Solution=&productCode=&SimStatus=&IMEIS=&IsNewConfig=&IsMonitor=&IsCartrack=`)
				.catch(e => {
			})
           resalt = await response.json()
          green.value = resalt.total ? resalt.total + ' ' : '0 '


        }
        
        let otherTotal = all - +suspended.value - +productive.value - +testproductive.value - +onstock.value
        other.value = otherTotal > 0 ? otherTotal + ' ' : '0 '

        this.simStats = [suspended, productive,testproductive,onstock,other].filter(itm => itm.value > 0)
        this.isSimStatsLoaded = true

        
				const response6 = await fetch(`https://newapi.quiktrak.co/Quiktrak/V2/Customer/GetList?MajorToken=00000000-0000-0000-0000-000000000000&MinorToken=00000000-0000-0000-0000-000000000000&rows=1&page=1&sort=Name&order=asc&PCode=`+ account).catch(e => {
				
				})
				let res6 = await response6.json()
				
        subs.value = res6.total ? res6.total + ' ' : '0 '

        this.otherStats = [subs,green,yellow,red].filter(itm => itm.value > 0)
        this.isOtherStatsLoaded = true


    },


    async getPreEditCustomerInfo(){
      let data = {
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,
      }
      if(this.CustomerCode){
        data.Code = this.CustomerCode
      }
      this.isLoadingGetData = true;
      let result = await QTAPI.GetPreEditCustomerInfo(data)
      this.isLoadingGetData = false;
      if(!result){
        return
      }
      this.LoginName = result.ContactInfo.LoginName
      //console.log(result)
      if(result.ExternalDomains && result.ExternalDomains.length){
        this.ThemeList = [...this.ThemeList, ...result.ExternalDomains]
      }
      if(result.WebSites && result.WebSites.length){
        this.WebsiteList = result.WebSites
      }
      if(result.Solutions && result.Solutions.length){
        this.SolutionTypeList = result.Solutions
      }
      if(result.AlarmEmails){
        this.AlarmEmails = result.AlarmEmails
      }
      if(result.MonitorAlarmIDs){
        this.setAlarmMonitor(result.MonitorAlarmIDs)
      }
      if(this.CustomerCode && result.Permissions){
        this.setSolutionAndAdminPermissions(result.Permissions)
      }

      if(this.CustomerCode && result.CustomerInfo){
        this.setCustomerInfo(result.CustomerInfo)
      }
      if(this.CustomerCode && result.ContactInfo){
        this.setContactInfo(result.ContactInfo)
      }
      if(this.CustomerCode && result.CustomerInfo?.TimeZoneID || this.CustomerCode && result.ContactInfo?.TimeZone){
        this.TimeZoneCode = result?.CustomerInfo?.TimeZone ? this.TimeZoneList.find(itm => parseFloat(itm.offset) === parseFloat(result.ContactInfo?.TimeZone))?.code : this.TimeZoneList.find(itm => itm.id === result.CustomerInfo?.TimeZoneID)?.code
 
      }
      if(this.IS_ROOT && this.CustomerCode && result.ContactInfo && result.ContactInfo.Permissions){
        this.setInstallerPermissions(result.ContactInfo.Permissions)
      }

      this.UserPermissions.Controllable = !!result.Controllable
      this.UserPermissions.AllowCommand = !!result.AllowCommand

      if(result?.BillInfo?.PaymentType){
        this.PaymentType = result.BillInfo.PaymentType
      }

      this.isSendMonthlyAccountReport = (result?.Permissions2 & Permissions2Enum.MonthlyAccountReport) === 0

      this.AllSolutionStats()

    },
    setContactInfo(contactInfo){
      this.UserCode = contactInfo.Code
      this.LanguageCode = contactInfo.Language
      this.CountryCode =  contactInfo.CountryCode
      this.TimeFormatCode = contactInfo.TimeFormat
      //this.TimeZoneCode = this.TimeZoneList.find(itm => parseFloat(itm.code) === parseFloat(contactInfo.TimeZone)).code
      this.FirstName = contactInfo.FirstName
      this.LastName = contactInfo.SurName
      this.LoginName = contactInfo.LoginName
      this.Email = contactInfo.Mail
      this.Mobile = contactInfo.MobilePhone
      this.Phone = contactInfo.WorkPhone
      this.GroupCode = contactInfo.CustomerContactGroupCode
      this.Credits = contactInfo._FIELD_INT1


      //console.log(this.InstallerPermissionIds)

      this.keyTimeZoneList++
      this.keyCountryList++
      this.keyLanguageList++
      this.keyTimeFormatList++
      this.keyGroupList++
    },
    setCustomerInfo(customerInfo){

      //console.log(customerInfo)
      this.AccountName = customerInfo.Name
      this.Number = customerInfo.Number
      this.CustomerTypeCode = customerInfo.Type
      this.ThemeCode = !customerInfo.ExtDoaminCode ? "0" : customerInfo.ExtDoaminCode
      this.WebsiteCode = customerInfo.WebSiteCode
      this.SpeedUnitCode = !customerInfo.SpeedUnit ? 'KPH' : customerInfo.SpeedUnit
      this.SecurityCode = customerInfo.SecurityCode

      let addressArr = !customerInfo.Address ? [] : customerInfo.Address.split('\n')
      if(addressArr.length){
        this.Address = addressArr[1]
        this.Address1 = addressArr[2]
        this.City = addressArr[3]
        this.State = addressArr[4]
        this.Zipcode = addressArr[5]
      }

      this.keyCustomerTypeList++
      this.keyWebsiteList++
      this.keySpeedUnitList++

    },
    setSolutionAndAdminPermissions(permissionsStr){
      if(!permissionsStr.trim()){
        return
      }
      this.SolutionTypeList.map((itm) => {
        if(permissionsStr.indexOf(itm.Value+',') > -1){
          this.Permissions[itm.Value] = true
        }
      })
      this.AdminPermissionList.map((itm) => {
        if(permissionsStr.indexOf(itm.code+',') > -1){
          this.Permissions[itm.code] = true
        }
      })
    },
    setAlarmMonitor(alarmsIdsStr){
      if(!alarmsIdsStr.trim()){
        return
      }
      let alarmIds = alarmsIdsStr.split(',')
      alarmIds.forEach((itm) => {
        if(!itm){ return }
        this.AlarmMonitorIds[itm] = true
      })
    },
    setInstallerPermissions(permissions){
      permissions = +permissions
      if(!permissions) return

      Object.values(InstallerPermissionEnum).forEach(itm => {
        this.InstallerPermissionIds[itm] = (permissions & itm) > 0
      })
    },

    async onSubmit(){
      let uniqNum = f7.utils.id('xxxxxx')
      let data = {
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,

        Name: this.AccountName,
        Type: this.CustomerTypeCode,
        SecurityCode: this.SecurityCode || uniqNum,
        Number: this.Number || uniqNum,
        CountryCode: this.CountryCode,
        TimeFormat: this.TimeFormatCode,
        TimeZone: this.TimeZoneCode,
        Language: this.LanguageCode,
        ExtDoaminCode: this.ThemeCode !== "0" ? this.ThemeCode : '',
        WebSiteCode: this.WebsiteCode,
        SpeedUnit: this.SpeedUnitCode,

        FirstName: this.FirstName,
        SurName: this.LastName,
        LoginName: this.LoginName,
        Mail: this.Email,
        MobilePhone: this.Mobile,
        WorkPhone: this.Phone,

        AddressInfo1: this.Address,
        AddressInfo2: this.Address1,
        AddressInfo3: this.City,
        AddressInfo4: this.State,
        AddressInfo5: this.Zipcode,

        PayType: this.PaymentType,

        Controllable: !!this.UserPermissions.Controllable ? 1 : 0,
        AllowCommand: !!this.UserPermissions.AllowCommand ? 1 : 0,
      }

      if(this.CustomerCode){
        data.Code = this.CustomerCode
      }

      if(!this.isAgentOrDealer){
        data.autoMonthlyReport = !this.isSendMonthlyAccountReport ? Permissions2Enum.MonthlyAccountReport : 0
      }

      if(this.isAgentOrDealer){
        data.ActivationDevices = []
        Object.entries(this.Permissions).forEach(([key, val]) => { if(val) data.ActivationDevices.push(key) })
        data.ActivationDevices = data.ActivationDevices.toString()

        data.Alerts = []
        Object.entries(this.AlarmMonitorIds).forEach(([key, val]) => { if(val) data.Alerts.push(key) })
        data.Alerts = data.Alerts.toString()

        data.AEmails = this.AlarmEmails?.length ? this.AlarmEmails.toString() : ''

        if(this.isSolutionSyncSubAgent){
          data.ActivationDevices_SynchronizsubAgent = 'SynchronizsubAgent'
        }
      }
      if(this.IS_ROOT){
        data.Credits = this.Credits
        if(this.isAgentOrDealer){
          data.Permission = []
          Object.entries(this.InstallerPermissionIds).forEach(([key, val]) => { if(val) data.Permission.push(key) })
          data.Permission = data.Permission.toString()

          Object.entries(AdminPermissionsEnum).forEach(([key, val]) => {
            if(this.Permissions[val]){
              data[key] = val
            }
          })
        }
      }

      this.isLoading = true;
      let result = await QTAPI.CustomerEditInfo(data)
      this.isLoading = false;
      if(!result) return

      if(this.isSendRegLetter){
        this.sendRegLetter(this.CustomerCode || result )
      }

      this.$store.commit('CUSTOMER_UPDATED')
      f7.notification.create({
        subtitle: this.$t('Success'),
        text: !this.CustomerCode ? this.$t('CustomerCreated', { name: data.Name }) : this.$t('CustomerUpdated', { name: data.Name }),
      }).open()
      this.f7router.back()

    },
    async sendRegLetter(code){
      let result = await QTAPI.SendRegLetter({
        EMails: [this.Email],
        WebsiteCode: this.WebsiteCode,
        LoginName: this.LoginName,
        CustomerCode: code,
      })
      if(!result) return

      f7.notification.create({
        subtitle: this.$t('Success'),
        text: this.$t('LetterSent'),
      }).open()
    }
  }
}
</script>

<style scoped>

</style>