<template>
  <f7-panel left cover>
    <f7-view>
      <f7-page name="panel-left" class="bg-color-lightgray">
        <f7-toolbar bottom >
          <div class="row width-100">
              <f7-link href="/credits/recharge/" view=".view-main" class="col panel-close">{{ $t('SMS Credits: 1000') }}</f7-link>
          </div>

          <!-- {r: UserInfo.SMSTimes} -->

        </f7-toolbar>  

        <f7-block class="bg-header no-margin padding-vertical">
          <template v-if="IS_LOGGED_IN && UserInfo">
            <div
              class="user-img bg-color-custom color-custom display-flex justify-content-center align-items-center"
            >
              <span class="size-14 font-weight-bold text-color-white">{{
                Initials
              }}</span>
            </div>
            <p
              class="text-color-dark no-margin-bottom margin-top font-weight-bold"
            >
              {{ UserInfo.FirstName }} {{ UserInfo.SurName }}
            </p>
            <p class="text-color-dark no-margin-top">{{ UserInfo.Email }}</p>
          </template>
          <span class="app-version text-color-gray">v{{ AppVersion }}</span>
        </f7-block>
        <f7-list
          no-chevron
          no-hairlines
          no-hairlines-between
          dividers-ios
          strong
          outline
          inset
          class="panel-left-list"
        >
          <!-- <f7-list-item link="/dashboard/" view=".view-main" :title="$t('Dashboard')" back force panel-close>
            <template #media>
              <f7-icon icon="f7-icons icon-dashboard text-color-lightgray"></f7-icon>
            </template>
          </f7-list-item> -->
          <f7-list-item
            link="/dashboard/tab-dashboard/"
            view=".view-main"
            :title="$t('Dashboard')"
            back
            force
            panel-close
          >
            <template #media>
              <i class="icon-reports text-color-green size-22"></i>
            </template>
          </f7-list-item>
          <f7-list-item
            link="/user-settings/"
            view=".view-main"
            :title="$t('UserSettings')"
            panel-close
          >
            <template #media>
              <i class="icon-settings size-22 text-color-lilac"></i>
            </template>
          </f7-list-item>
          <f7-list-item
            :link="manualUrl"
            external
            target="_blank"
            view=".view-main"
            :title="$t('UserManual')"
            panel-close
          >
          <template #media>
            <i class="icon-manual size-22 text-color-blue"></i>
          </template>
          </f7-list-item>
          <f7-list-item
            v-if="isShowWatchAlerts"
            link="/watchdog-alerts/"
            view=".view-main"
            :title="$t('WatchdogAlerts')"
            panel-close
          >
            <template #media>
              <i class="icon-alerts size-22 text-color-orange"></i>
            </template>
          </f7-list-item>
          <f7-list-item
            v-if="isShowWatchAlerts"
            link="/customer-list/"
            view=".view-main"
            :title="$t('CustomerList')"
            panel-close
          >
            <template #media>
              <i class="icon-users size-22 text-color-blue"></i>
            </template>
          </f7-list-item>

     <f7-list-item
            link="/renewal/"
            view=".view-main"
            :title="$t('Renewal')"
            panel-close
          >
            <template #media>
              <i class="icon-menu-credits size-22 text-color-pelorous"></i>
            </template>
          </f7-list-item>  
       <f7-list-item
            link="/biling/"
            view=".view-main"
            :title="$t('Billing')"
            panel-close
          >
            <template #media>
              <i class="icon-other-email size-22 text-color-orange"></i>
            </template>
          </f7-list-item>  
          <!-- <f7-list-item link="/sim-list/" view=".view-main" :title="$t('Assets')" panel-close>
            <template #media>
              <f7-icon icon="f7-icons icon-assets text-color-lightgray"></f7-icon>
            </template>
          </f7-list-item>
          <f7-list-item link="/customers/" view=".view-main" :title="$t('Customers')" panel-close>
            <template #media>
              <f7-icon icon="f7-icons icon-customers text-color-lightgray"></f7-icon>
            </template>
          </f7-list-item>
     
          <f7-list-item :link="supportUrl" :title="$t('Support')" panel-close external target="_blank">
            <template #media>
              <f7-icon icon="f7-icons icon-details text-color-lightgray"></f7-icon>
            </template>
          </f7-list-item> -->

          <f7-list-item
            link="#"
            :title="$t('Logout')"
            @click="logout"
            panel-close
            class=""
          >
            <template #media>
              <i class="icon-signout text-color-red size-22"></i>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>


<script>
import { f7, theme } from "framework7-vue";
import { mapGetters } from "vuex";
import { ApiMethods } from "../services/qt-api";

export default {
  name: "panel-left",

  data() {
    return {
      isShowWatchAlerts: true,
      logo: "static/images/logo.svg",
      isCreditsDisplayed: false,
      manualUrl:
        "https://m2mdata.co/home/download/M2M%20Data%20Mobile%20App%20User%20Guide.pdf",
    };
  },

  computed: {
    ...mapGetters([
      "IS_LOGGED_IN",
      "UserInfo",
      "AppVersion",
      "MajorToken",
      "MinorToken",
      "Permissions"
    ]),
    supportUrl() {
      //console.log(ApiMethods.GO_FOR_SUPPORT)
      return (
        ApiMethods.GO_FOR_SUPPORT +
        "?" +
        f7.utils.serializeObject({
          MajorToken: this.MajorToken,
          MinorToken: this.MinorToken,
        })
      );
    },
    Initials() {
      if (this.UserInfo && this.UserInfo.FirstName && this.UserInfo.SurName) {
        return `${this.UserInfo.FirstName[0]}${this.UserInfo.SurName[0]}`;
      }
      return "";
    },
  },

  async mounted() {
    let additionalFlags = await this.$store.dispatch(
      "getFromStorage",
      "additionalFlags"
    );
    //console.log(additionalFlags)
    this.isCreditsDisplayed = !theme.ios ? true : !!additionalFlags?.elemRc;

    this.isShowWatchAlerts = this.Permissions.WATCHDOG > 0
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
</style>