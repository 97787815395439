//import { useI18n } from 'vue-i18n'
//import { useStore } from 'vuex'
import moment from "moment-mini";
import i18n from './i18n'
import store from "../store";
import cordovaApp from "../js/cordova-app.js";



/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */


export function getFormDataFromObject(query){
  let data = new FormData();
  if (!isObjEmpty(query)){
    const arr = Object.keys(query)
    arr.forEach(element => {
      if(Array.isArray(query[element])){
        for (let i = 0; i < query[element].length; i++) {
          if (query[element][i]) data.append(element+'[]', query[element][i])
        }
      }else{
        if (query[element] || query[element] === 0 || query[element] === false) data.append(element, query[element])
      }
    })
  }
  return data
}


export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function HideKeyboard() {
  document.activeElement.blur();
}




export function openExternalUrl(href){
  
  window.open(href, '_blank');
  //if (device.cordova) {
    //cordovaApp.InAppBrowser.open(href, '_system', 'hidden=yes,location=no')
  //}
    /*if (this.device.ios) {
    window.open(href, '_blank', 'location=yes');
  } else {
    if (typeof navigator !== "undefined" && navigator.app) {
      navigator.app.loadUrl(href, { openExternal: true });
    } else {
      window.open(href, '_blank');
    }
  }*/
}

export function isEmailValid(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const CustomerTypes = {
  Admin: 1,
  Agent: 2,
  Enterprise: 4,
  Personal: 5,
  Dealer: 6,
  Master: 7,
  ProtectEnterprise: 8,
}

export const FitmentOptionsEnum = {
  RemoteImmobilise: 1,
  AlarmIntegration: 2,
  SOSButton: 4,
  NotConnectedToIgnition: 8,
  FittedAfterIsolator: 16,
  EngineTheft: 32,
  Siren: 64,
  RemoteDoor: 512,
  DriverIDs: 2048,
}

export const ProductFeaturesEnum = {
  Input1: 16777216,
  Input2: 131072,

  Input3: 33554432,
  Input4: 67108864,
  Output1: 134217728,
  Output2: 268435456,

  Power: 128,
}

export const SimSMSDirectionEnum = {
  Outbound: 1,
  Inbound: 2
}

export const SimSMSStatusEnum = {
  Error: 0,
  Sent: 1,
  Submitted: 2,
  Delivered: 3,
  Received: 4,
}

export const Permissions2Enum = {
  MonthlyAccountReport: 64,
}


export function getPositionAlertsTranslations() {
  const { t } = i18n.global
  return {
    0: t('None'),
    1: t('Custom'),
    2: t('SOSDuress'),
    4: t('PowerDisconnection'),
    8: t('EnterGeofence'),
    16: t('LeaveGeofence'),
    32: t('HighSpeed'),
    64: t('LowSpeed'),
    128: t('Theft'),
    256: t('TiltShock'),
    512: t('LowBattery'),
    1024: t('Moving'),
    2048: t('Fire'),
    4096: t('MedicalHelp'),
    8192: t('Defence'),
    16384: t('Destroy'),
    32768: t('IgnitionOn'),
    65536: t('IgnitionOff'),
    131072: t('INPUT1'),
    262144: t('INPUT2'),
    524288: t('INPUT1LOW'),
    1048576: t('INPUT2LOW'),
    2097152: t('HarshBraking'),
    4194304: t('TemperatureAlarmLow'),
    8388608: t('TemperatureAlarmHigh'),
    16777216: t('FatigueDriving'),
    33554432: t('Accelerate'),
    67108864: t('Offline'),
    134217728: t('INPUT3'),
    268435456: t('INPUT3_OFF'),
    536870912: t('INPUT4'),
    1073741824: t('INPUT4_OFF'),

  }
}

export function SortArrayByObjProps(list, sortBy = []){
  //sortBy[i].prop == name     sort by property name
  //sortBy[i].direction == 1   means asc
  //sortByp[i].direction == -1 means desc
  //example assetTypes = SortArrayByObjProps(assetTypes, [{prop: 'name', direction: 1}])
  if(list && list.length){
    list.sort(function(a,b){
      let i = 0, result = 0;
      while(i < sortBy.length && result === 0) {
        result = sortBy[i].direction*(a[ sortBy[i].prop ].toString().toUpperCase() < b[ sortBy[i].prop ].toString().toUpperCase() ? -1 : (a[ sortBy[i].prop ].toString().toUpperCase() > b[ sortBy[i].prop ].toString().toUpperCase() ? 1 : 0));
        i++;
      }
      return result;
    })
  }
  return list;
}
export function getDaysInMonth(y,m){
  return  new Date(y, m +1, 0).getDate();
}

export function isObjEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0)    return false;
  if (obj.length === 0)  return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function dateFormat(dateStr, outFormat = ''){
  //const store = useStore()

  const options={};
  if(outFormat.includes('date')){
    options.day = '2-digit'
    options.month = '2-digit'
    options.year = 'numeric'
  }
  if(outFormat.includes('time')){
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
    options.hour12 = false
  }

  const locale = store.getters.UserInfo.Locale || 'en-US'
  return !outFormat ? new Date(dateStr) : new Intl.DateTimeFormat(locale, options).format(new Date(dateStr))
}

export function fixDateFormat(dateStr, outFormat, inFormat){
  return moment(dateStr, inFormat).format(outFormat)
}

export function directionText(direction){
  const { t } = i18n.global
  direction = parseFloat(direction);
  let ret = t('Unknown');
  switch (true){
    case (direction >= 338 || direction <= 22 ):
      ret = t('North');
      break;
    case (direction >= 23 && direction <= 75 ):
      ret = t('Northeast');
      break;
    case (direction >= 76 && direction <= 112 ):
      ret = t('East');
      break;
    case (direction >= 113 && direction <= 157 ):
      ret = t('Southeast');
      break;
    case (direction >= 158 && direction <= 202 ):
      ret = t('South');
      break;
    case (direction >= 203 && direction <= 247 ):
      ret = t('Southwest');
      break;
    case (direction >= 248 && direction <= 292 ):
      ret = t('West');
      break;
    case (direction >= 293 && direction <= 337 ):
      ret = t('Northwest');
      break;
  }
  return ret
}


export function getFeatureValueByClass(className, posArr, featureCode, FIELD_FLOAT1 = 0, FIELD_FLOAT2 = 0){
  let ret = 'No data';
  let featureVoltage = ret;
  let featureBattery = ret;
  let featureBatteryExtended = '';
  let featureTemperature = '0°C';
  let featureFuel = ret;
  let featureDrivingTime = ret;
  let featureRfid = ret;
  let featureAlt = ret;
  let featureHeartrate = ret;

  switch (className){
    case ('JT812_KMXXX'):
      featureVoltage = posArr[14] + 'V'
      featureBattery = posArr[23] + '%'
      
        if(posArr[33] > 0) {
          if (posArr[33] >= 0 && posArr[33] <= 3.39) {
            featureBatteryExtended = 0;
          }
          else if (posArr[33] >= 3.39 && posArr[33] <= 3.45) {
            featureBatteryExtended = 20;
          }
          else if (posArr[33] > 3.45 && posArr[33] <= 3.54) {
            featureBatteryExtended = 40;
          }
          else if (posArr[33] > 3.54 && posArr[33] <= 3.72) {
            featureBatteryExtended = 60;
          }
          else if (posArr[33] > 3.72 && posArr[33] <= 3.8927) {
            featureBatteryExtended = 80;
          }
          else {
            featureBatteryExtended = 100;
          }
          featureBatteryExtended = ' (' + featureBatteryExtended + '%)'
      }
      break;
    case ('GOT21'):
    case ('GOT 21'):
    case ('GOT20B'):
      if (typeof posArr[14] != "undefined") {
        featureVoltage = posArr[14].toFixed(2) + 'V';
      }
      break;
    case ('GOT 20'):
    case ('GOT20'):
      if(posArr[23]){
        featureVoltage =  Math.round(posArr[23]*10)/10 + 'V';
      }else{
        featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
      }
      if (posArr[23] >= 0 && posArr[23] <= 3.39) {
        featureBattery = 0;
      }
      else if (posArr[23] >= 3.39 && posArr[23] <= 3.45) {
        featureBattery = 20;
      }
      else if (posArr[23] > 3.45 && posArr[23] <= 3.54) {
        featureBattery = 40;
      }
      else if (posArr[23] > 3.54 && posArr[23] <= 3.72) {
        featureBattery = 60;
      }
      else if (posArr[23] > 3.72 && posArr[23] <= 3.8927) {
        featureBattery = 80;
      }
      else {
        featureBattery = 100;
      }
      if (featureBattery || featureBattery == 0) {
        featureBattery = parseInt(featureBattery) + '%';
      }
      break;
    case ('GPT19H'):
        if (typeof posArr[24] != "undefined") {
          featureBattery = parseInt(posArr[24]) + '%';
        }
        if (typeof posArr[23] != "undefined" && posArr[23] != 0 ) {
          featureVoltage = posArr[23].toFixed(2) + 'V';
        }else{
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        if (posArr[23] >= 0 && posArr[23] <= 3.39) {
          featureBattery = 0;
        }
        else if (posArr[23] >= 3.39 && posArr[23] <= 3.45) {
          featureBattery = 20;
        }
        else if (posArr[23] > 3.45 && posArr[23] <= 3.54) {
          featureBattery = 40;
        }
        else if (posArr[23] > 3.54 && posArr[23] <= 3.72) {
          featureBattery = 60;
        }
        else if (posArr[23] > 3.72 && posArr[23] <= 3.8927) {
          featureBattery = 80;
        }
        else {
          featureBattery = 100;
        }
        break;
      case ('TIANQIN_LK3GP'):
      case ('BSJV3'):
      case ('JT808_GLOCK'):
        if (typeof posArr[23] != "undefined") {
          featureBattery = parseInt(posArr[23]) + '%';
        }
        if (typeof posArr[14] != "undefined") {
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        break;
      case ('EELINK_OBD'):
        if (typeof posArr[14] != "undefined") {
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        break;
      case ('TIANQIN_EN4G'):
        if (typeof posArr[14] != "undefined") {
          featureVoltage = posArr[14] + 'V';
        }
        if (typeof posArr[23] != "undefined") {
          featureBattery = posArr[23] + '%';
        }
        break;
      case ('VT600'):
        if (typeof posArr[25] != "undefined" && posArr[25] != 0) {
          featureVoltage = posArr[25].toFixed(2) + 'V';
        }else{
            featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        if (typeof posArr[24] == "undefined" || posArr[24] == 0) {
          featureFuel = 'No data'
        }else{
          featureFuel = parseInt(((parseFloat(posArr[24]) - FIELD_FLOAT2) / (FIELD_FLOAT1 - FIELD_FLOAT2)) * 100) + '% (' + parseFloat(posArr[24]).toFixed(2) + 'V)';
        }
        if (typeof posArr[26] != "undefined") {
          featureRfid = posArr[26];
        }
        if (posArr[29]) {
          featureTemperature = posArr[29] + '°C';
        }
        if (posArr[30]) {
          featureTemperature +='...';
        }
        /*
        if (posArr[30]) {
          featureTemperature +=','+ posArr[30] + '°C';
        }
        if (posArr[31]) {
          featureTemperature +=','+ posArr[31] + '°C';
        }
        if (posArr[32]) {
          featureTemperature +=','+ posArr[32] + '°C';
        }
        if (posArr[33]) {
          featureTemperature +=','+ posArr[33] + '°C';
        }
        if (posArr[34]) {
          featureTemperature +=','+ posArr[34] + '°C';
        }
        if (posArr[35]) {
          featureTemperature +=','+ posArr[35] + '°C';
        }
        if (posArr[36]) {
          featureTemperature +=','+ posArr[36] + '°C';
        }*/
        break;
      case ('EELINK_TEMP'):
        if (typeof posArr[14] != "undefined") {
          featureTemperature = posArr[14].toFixed(2) + '°C';
        }
        if (typeof posArr[23] != "undefined" && posArr[23] != 0) {
          featureVoltage = posArr[23].toFixed(2) + 'V';
        }else{
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        break;
      case ('TIANQIN_LK3GV2'):
        if (typeof posArr[14] != "undefined") {
          featureVoltage = posArr[14].toFixed(2) + 'V';
        }
        if (typeof posArr[23] != "undefined") {
          featureBattery = posArr[23] + '%';
        }
        break;
      case ('AVL05'): 
        if (typeof posArr[14] != "undefined") {
          featureVoltage = posArr[14].toFixed(2) + 'V';
        }
        
        if (typeof posArr[25] == "undefined" || posArr[25] == 0) {
          featureFuel = 'No data';
        }else{
          featureFuel = parseInt(((parseFloat(posArr[25]) - FIELD_FLOAT2) / (FIELD_FLOAT1 - FIELD_FLOAT2)) * 100) + '% (' + parseFloat(posArr[25]).toFixed(2) + 'V)';
        }
        break;
      case ('AVL09'):
        if (typeof posArr[14] != "undefined") {
          featureVoltage = posArr[14].toFixed(2) + 'V';
        }
        if (typeof posArr[23] != "undefined") {
          featureRfid = posArr[23];
        }
        if (typeof posArr[24] != "undefined") {
          featureDrivingTime = posArr[24];
        }
        break;
      case ('RUPTELA'):
        if (typeof posArr[24] != "undefined") {
          featureTemperature = Math.round(posArr[24]*10)/10 + '°C';
        }
        if (typeof posArr[24] != "undefined" && posArr[24] != 0) {
          featureVoltage = posArr[24].toFixed(2) + 'V';
        }else{
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        if (typeof posArr[24] != "undefined") {
          featureAlt = posArr[24] + 'ft';
        }
        if (typeof posArr[25] != "undefined") {
          featureRfid = posArr[25];
        }
        break;
      case ('VT600_3G'):
      case ('VT600_FUEL'):
      case ('VT900_DOOR'):
        if (typeof posArr[25] != "undefined" && posArr[25] != 0) {
          featureVoltage = posArr[25].toFixed(2) + 'V';
        }else{
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        if (typeof posArr[25] != "undefined") {
          featureTemperature = Math.round(posArr[25]*10)/10 + '°C';
        }
        if (typeof posArr[25] != "undefined") {
          featureAlt = posArr[25] + 'ft';
        }
        if (typeof posArr[24] == "undefined" || posArr[24] == 0) {
          featureFuel = 'No data';
        }else{
          featureFuel = parseInt(((parseFloat(posArr[24]) - FIELD_FLOAT2) / (FIELD_FLOAT1 - FIELD_FLOAT2)) * 100) + '% (' + parseFloat(posArr[24]).toFixed(2) + 'V)';
        }
        if (typeof posArr[26] != "undefined") {
          featureRfid = posArr[26];
        }
        break;
      case ('SINOCASTEL'):
      case ('HONGYUAN'):
      case ('TIANQIN_QT525'):
      case ('JT808_KMXXX'):
      case ('HONGYUAN_V'):
      case ('KKS'):
        if (typeof posArr[14] != "undefined") {
          featureVoltage = posArr[14].toFixed(2) + 'V';
        } 
        break;
      case ('TIANQIN_QT206'):
      case ('TIANQIN_QTPTW'):
      case ('TIANQIN_LK3G'):
        if (typeof posArr[23] != "undefined") {
          featureBattery = posArr[23] + '%';
        }
        break;
      case ('VJOY'):
      case ('TKXXX'):
        if (typeof posArr[25] != "undefined") {
          featureBattery = ((posArr[25] / 6) * 100) + '%';
        }
        break;
      case ('OUNING_WATCH'):
      case ('TKSTAR'):
        if (typeof posArr[23] != "undefined") {
          featureBattery = posArr[23] + '%';
        }
        if (typeof posArr[25] != "undefined") {
          featureHeartrate = parseInt(posArr[25]);
        }
        break;
      case ('PHONE_TRACK'):
        if (typeof posArr[14] != "undefined") {
          featureBattery = posArr[14] + '%';
        }
        break;
      case ('GOT10'):
        if (typeof posArr[24] != "undefined") {
          featureTemperature = Math.round(posArr[24]*10)/10 + '°C';
        }
        if (typeof posArr[24] != "undefined" && posArr[24] != 0) {
          featureVoltage = posArr[24].toFixed(2) + 'V';
        }else{
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
        if (typeof posArr[24] != "undefined") {
          featureAlt = posArr[24] + 'ft';
        }
        break;
      default:
        if (typeof posArr[14] != "undefined") {
          featureBattery = parseInt(posArr[14]) + '%';
        }
        if (typeof posArr[14] != "undefined") {
          featureVoltage = (posArr[14] > 50 || !posArr[14] ? "No data" : ""+ Math.round(posArr[14]*10)/10 + 'V');
        }
  }

  switch(featureCode){
    case 2:
      return featureAlt
    case 512:
      return featureVoltage
    case 1024:
      return featureBattery + featureBatteryExtended
    case 4096:
      return featureTemperature
    case 2048:
      return featureFuel
    case 16384:
      return featureRfid
    case 65536:
      return featureDrivingTime
    case 262144:
      return featureHeartrate
    default:
      return ret;
  }
  
}

export function getBatteryValue(classBattery, batteryValue){
  let ret = 'No data';

  switch (classBattery){
    case ('GOT 20'):
    case ('GOT20'):
      if(batteryValue <= 5){
        if (batteryValue >= 0 && batteryValue <= 3.39) {
          ret = 0;
        }
        else if (batteryValue >= 3.39 && batteryValue <= 3.45) {
            ret = 20;
        }
        else if (batteryValue > 3.45 && batteryValue <= 3.54) {
            ret = 40;
        }
        else if (batteryValue > 3.54 && batteryValue <= 3.72) {
            ret = 60;
        }
        else if (batteryValue > 3.72 && batteryValue <= 3.8927) {
            ret = 80;
        }
        else {
            ret = 100;
        }
      }else{
        ret = batteryValue.toFixed(2)
      }

      break;

      case ('TIANQIN_LK3GP')://%
      case ('GPT19H'):
      case ('JT808_GLOCK'):
        ret = batteryValue.toFixed(2)
      break;

      case ('EELINK_OBD'): //12 v
      case ('VT600_3G'): //25
      case ('KKS')://12
      case ('TIANQIN_EN4G')://27
      case ('VT600')://12
      case ('EELINK_TEMP')://12
      case ('TIANQIN_LK3GV2')://12
      //case ('GOT20')://25
      //case ('GOT 20')://25
      //case ('GOT10')://25
        ret = batteryValue.toFixed(2)
      break;
      
      case ('AVL05'): //no data
      case ('AVL09'):
      case ('HONGYUAN_V'):
      case ('RUPTELA'):
      case ('TIANQIN_QT525'):
      case ('VT600_FUEL'):
      case ('SINOCASTEL'):
      case ('HONGYUAN'):
      case ('JT808_KMXXX'):
      case ('VT900_DOOR'):
      case ('TIANQIN_LK3G'):
      case ('TIANQIN_QT206'):
      case ('TIANQIN_QTPTW'):
      case ('VJOY'):
      case ('BSJV3'):
      case ('TKSTAR'):
      case ('OUNING_WATCH'):
      case ('TKXXX'):
      case ('PHONE_TRACK'):
      case ('GOT10'):
        ret = 'No data'
      break;
  }
  
  return ret
}

export function getBatteryUnit(classBattery, batteryValue) {
  let ret = '';

  switch (classBattery){
    case ('GOT 20'):
    case ('GOT20'):
      if(batteryValue <= 5){
        
        ret = '%'
      }else{
        ret = 'V'
      }
      break;
    case ('TIANQIN_LK3GP')://%
    case ('GPT19H'):
    case ('JT808_GLOCK'):
      ret = '%'
    break;

    case ('EELINK_OBD'): //12 v
    case ('VT600_3G'): //25
    case ('KKS')://12
    case ('TIANQIN_EN4G')://27
    case ('VT600')://12
    case ('EELINK_TEMP')://12
    case ('TIANQIN_LK3GV2')://12
    //case ('GOT 20'):
    //case ('GOT20'):
     
    //case ('GOT10')://25
      ret = 'V'
    break;
  }
  
  return ret
}

function guid() {
  function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  }
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function getDevicePushInfo(){
  let uid = guid();
  if(window.device) {
    if(!localStorage.PUSH_MOBILE_TOKEN){
      localStorage.PUSH_MOBILE_TOKEN = uid;
    }
    localStorage.PUSH_APP_KEY = BuildInfo.packageName;
    localStorage.PUSH_APPID_ID = BuildInfo.packageName;
    localStorage.DEVICE_TYPE = device.platform;
  }else{
    if(!localStorage.PUSH_MOBILE_TOKEN)
      localStorage.PUSH_MOBILE_TOKEN = uid;
    if(!localStorage.PUSH_APP_KEY)
      localStorage.PUSH_APP_KEY = uid;
    if(!localStorage.PUSH_DEVICE_TOKEN)
      localStorage.PUSH_DEVICE_TOKEN = uid;
    localStorage.PUSH_APPID_ID = 'android.app.quiktrak.eu.agent.controller';
    localStorage.DEVICE_TYPE = "android.app.quiktrak.eu.agent.controller";
  }
}

export function formatNewNotification(messageList = []){
  let newMessageList = [];

  if (Array.isArray(messageList)) {
    for (let i = 0; i < messageList.length; i++) {
      let msg = null;
      let msgTemp = null;
      let popped = null;
      let localTime = null
      if (messageList[i].payload) {
        msg = isJsonString(messageList[i].payload);
        if (!msg) {
          msg = messageList[i].payload;
        }
      }else{
        msg = isJsonString(messageList[i]);
        if (!msg) {
          msg = messageList[i];
        }
      }
      if (msg ) {
        if (msg.PositionTime) {
          localTime  = moment.utc(msg.PositionTime).toDate();
          msg.PositionTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
        }
        if (msg.time) {
          localTime  = moment.utc(msg.time).toDate();
          msg.time = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
        }
        if (msg.CreateDateTime) {
          localTime  = moment.utc(msg.CreateDateTime).toDate();
          msg.CreateDateTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
        }
        if(msg.lat){
          msg.Lat = msg.lat;
        }
        if(msg.lng){
          msg.Lng = msg.lng;
        }
        if(msg.title){
          msg.alarm = msg.title;
        }
        if(msg.imei){
          msg.Imei = msg.imei;
        }
        if(msg.IMEI){
          msg.Imei = msg.IMEI;
        }
        if(msg.name){
          msg.AssetName = msg.name;
        }
        if(msg.speed){
          msg.Speed = msg.speed;
        }
        if(typeof msg.direct !== 'undefined'){
          msg.Direction = msg.direct;
        }
        if(msg.time){
          msg.CreateDateTime = msg.time;
        }

        /*if(msg.type && parseInt(msg.type) === 1024){
          self.methods.getNewData(false, true);
        }*/

        msgTemp = msg;
        popped = newMessageList.pop();
        if (popped) {
          popped = JSON.stringify(popped);
          msgTemp = JSON.stringify(msgTemp);

          if (popped !== msgTemp) {
            popped = JSON.parse(popped);
            newMessageList.push(popped);
          }
        }

        newMessageList.push(msg);
      }
    }
  }
  return newMessageList;
}

export function isJsonString(str){
  let ret = false;
  try{ret=JSON.parse(str);}catch(e){return false;}return ret;
}

export function getAssetTypeIcon(assetType){
  let assetTypes = getAssetTypes();
  let result = assetTypes.find( (item) => item.code === assetType )
  return result && result.icon ? result.icon : 'other'
}

export function getInputTypeIcon(inputType){
  let inputTypes = getInputsTypes();
  let result = inputTypes.find( (item) => item.code.toLowerCase() === inputType.toLowerCase() )
  return result && result.icon ? result.icon : ''
}

export function getAssetTypes() {
  return [
    { code: 'Bike', name: 'Bike', icon: 'moto' },
    { code: 'Boat', name: 'Boat', icon: 'boat' },
    { code: 'Bus', name: 'Bus', icon: 'bus' },
    { code: 'Car', name: 'Car', icon: 'car' },
    { code: 'Caravan', name: 'Caravan', icon: 'caravan' },
    { code: 'Containor', name: 'Containor', icon: 'container' },
    { code: 'Dump Truck', name: 'Dump Truck', icon: 'dumptruck' },
    { code: 'Forklift', name: 'Forklift', icon: 'forklift' },
    { code: 'Jetski', name: 'Jetski', icon: 'jetski' },
    { code: 'Personal', name: 'Personal', icon: 'person' },
    { code: 'Pet', name: 'Pet', icon: 'pet' },
    { code: 'Plant and Equipment', name: 'Plant and Equipment', icon: 'plant' },
    { code: 'SUV', name: 'SUV', icon: 'suv' },
    { code: 'Truck', name: 'Truck', icon: 'fleet' },
    { code: 'Pick-up', name: 'Pick-up', icon: 'fleet' },
    { code: 'Trailer', name: 'Trailer', icon: 'caravan' },
    { code: 'WiTi', name: 'WiTi', icon: 'witi' },
    { code: 'Van', name: 'Van', icon: 'van' },
    { code: 'Other', name: 'Other', icon: 'other' },
  ]
}

export function getSolutionTypeIcon(type){
  let icons = {
    unknown: 'icon-live-no-plan',
    protect: 'icon-live-protect2',
    loc8: 'icon-live-loc82',
    track: 'icon-live-track2',
    watch: 'icon-live-watch2',
  }
  let ret = 'unknown'

  switch (type.toLowerCase()){
    case 'protect':
    case 'qprotect':
    case 'witiqprotect':
    case 'al3rtprotect':
      ret = 'protect'
      break;

    case 'loc8':
    case 'al3rtloc8':
      ret = 'loc8'
      break;

    case 'track':
    case 'al3rttrack':
    case 'boat_watch':
    case 'boatwatch':
    case 'smarttrack':
    case 'wititrack':
      ret = 'track'
      break;

    case 'watch':
      ret = 'watch'
      break;
  }

  return icons[ret]
}

export function getInputsTypes(){
  const { t } = i18n.global
  return [
    { code: "NONE", name: t('None'),    icon: '',  },
    { code: "ACC2", name: t('ACC2'),    icon: 'input-car-alarm', },
    { code: "ALARM",name: t('Alarm'),   icon: 'input-car-alarm3', },
    { code: "BILGE",name: t('Bilge'),   icon: 'input-bilge-alarm', },
    { code: "DOOR", name: t('Door'),    icon: 'input-door-alarm', },
    { code: "PTO",  name: t('PTO'),     icon: 'input-pto-alarm', },
    { code: "SHORE",name: t('Shore'),   icon: 'input-shore-power', },
    { code: "PLOW", name: t('SnowPlow'),icon: 'input-snow-plow', },
    { code: "TILT", name: t('Tilt'),    icon: 'input-tilt-alarm', },
  ]
}
export function getInputMaskTimes(){
  const { t } = i18n.global
  return [
    { code: "5",  name: `5 ${ t('seconds') }` },
    { code: "10", name: `10 ${ t('seconds') }` },
    { code: "30", name: `30 ${ t('seconds') }` },
  ]
}
export function getInputVeTypes(){
  return [
    { code: "+Ve", name: `+Ve` },
    { code: "-Ve", name: `-Ve` },
  ]
}

export function getAlarmMonitorList() {
  const { t } = i18n.global
  return [
    { name: t('LowBatteryWarning'), code: 512 },
    { name: t('PowerDisconnectionWarning'), code: 4 },
    { name: t('SosDuressWarning'), code: 2 },
    { name: t('Input1OnWarning'), code: 131072 },
    { name: t('Input2OnWarning'), code: 262144 },
  ]
}

export function getInstallerPermissionList() {
  const { t } = i18n.global
  return [
    { name: t('SendAnyCommand'), code: 1 },
    { name: t('ReplaceSims'), code: 2 },
    { name: t('ReplaceImei'), code: 4 },
    { name: t('EditAssetDetails'), code: 8 },
    { name: t('InstallationNotice'), code: 16 },
    { name: t('ActivateNewDevices'), code: 32 },
    { name: t('DeactivateDevices'), code: 64 },
    { name: t('SuspendDeviceSim'), code: 128 },
  ]
}

export const InstallerPermissionEnum = {
  SendAnyCommand: 1,
  ReplaceSims: 2,
  ReplaceImei: 4,
  EditAssetDetails: 8,
  InstallationNotice: 16,
  ActivateNewDevices: 32,
  DeactivateDevices: 64,
  SuspendDeviceSim: 128,
}

export const AdminPermissionsEnum = {
  AddAsset: 2,
  DelAsset: 4,
  InstallNotice: 32,
}

export function getAdminPermissions(isRoot) {
  const { t } = i18n.global
  let ret = [
    { name: t('ReplaceUnit'), code: "ReplaceUnit" },
    { name: t('UpgradeSolution'), code: "UpgradeToLive" },
    { name: t('SimManagement'), code: "SIM" },
    { name: t('SolutionManagement'), code: "Solutions" },
    { name: t('ScheduledImmobilizer'), code: "ScheduledImmobilzer" },
    { name: t('ProductManagement'), code: "prdt" },
    { name: t('MonitorCenter'), code: "Monitor" },
    { name: t('DeactivateDevices'), code: "Deactivate" },
  ]
  if(isRoot){
    ret.push(
      {name: t('AddIMEIs'), code: "2"},
      {name: t('DeleteIMEIs'), code: "4"},
      {name: t('InstallNotice'), code: "32"},
      )
  }
  return ret
}

export function getUserPermissions() {
  const { t } = i18n.global
  return [
    { name: t('Controllable'), code: "Controllable" },
    { name: t('Commands'), code: "AllowCommand" },
  ]
}
export function getCustomerTypes() {
  const { t } = i18n.global
  return [
    { name: t('EndUserClient'), code: 5 },
    { name: t('Enterprise'), code: 4 },
    { name: t('Agent'), code: 2 },
    { name: t('Dealer'), code: 6 },
    { name: t('Master'), code: 7 },
    { name: t('ProtectEnterprise'), code: 8 },
  ]
}
export function getCustomerTypesFull() {
  const { t } = i18n.global
  return [
    { name: t('Admin'), code: 1 },
    { name: t('EndUserClient'), code: 5 },
    { name: t('Enterprise'), code: 4 },
    { name: t('Agent'), code: 2 },
    { name: t('Dealer'), code: 6 },
    { name: t('Master'), code: 7 },
    { name: t('ProtectEnterprise'), code: 8 },
  ]
}

export function getSpeedUnitList(){
  return [
    { name: "km/h", code: "KPH" },
    { name: "m/s", code: "MPS" },
    { name: "mile/h", code: "MPH" },
    { name: "kt", code: "KT" },
  ]
}

export function getLanguages() {
  return [
    { name: "Arab", code: "ar" },
    { name: "Danish", code: "da" },
    { name: "Spanish", code: "es" },
    { name: "Deutsch", code: "de" },
    { name: "English", code: "en" },
    { name: "Portuguese", code: "pg" },
    { name: "Thai", code: "th" },
    { name: "Russian", code: "ru" },
    { name: "Chinese-China", code: "zh" },
    { name: "French", code: "fr" },
  ]
}

export function getTimeFormats(){
  return [
    { name: "yyyy-MM-dd HH:mm:ss", code: "yyyy-MM-dd HH:mm:ss" },
    { name: "dd/MM/yy HH:mm:ss", code: "dd/MM/yy HH:mm:ss" },
    { name: "dd.MM.yy HH:mm:ss", code: "dd.MM.yy HH:mm:ss" },
    { name: "dd MM yy HH:mm:ss", code: "dd MM yy HH:mm:ss" },
    { name: "yyMMdd HH:mm:ss", code: "yyMMdd HH:mm:ss" },
    { name: "yy/MM/dd HH:mm:ss", code: "yy/MM/dd HH:mm:ss" },
    { name: "yy.MM.dd HH:mm:ss", code: "yy.MM.dd HH:mm:ss" },
    { name: "MM-dd-yy HH:mm:ss", code: "MM-dd-yy HH:mm:ss" },
    { name: "MM/dd/yyyy HH:mm:ss", code: "MM/dd/yyyy HH:mm:ss" },
    { name: "MM dd.yy HH:mm:ss", code: "MM dd.yy HH:mm:ss" },
    { name: "MM dd yyyy HH:mm:ss", code: "MM dd yyyy HH:mm:ss" },
    { name: "dd/MM/yyyy HH:mm:ss", code: "dd/MM/yyyy HH:mm:ss" },
  ]
}

export function getTimeZones() {
  return [
    { code: 'Dateline Standard Time_-12', id: 'Dateline Standard Time', name: '(UTC-12:00) International Date Line West', offset: '-12' },
    { code: 'Hawaiian Standard Time_-10', id: 'Hawaiian Standard Time', name: '(UTC-10:00) Hawaii', offset: '-10' },
    { code: 'Alaskan Standard Time_-9', id: 'Alaskan Standard Time', name: '(UTC-09:00) Alaska', offset: '-9' },
    { code: 'Pacific Standard Time (Mexico)_-8', id: 'Pacific Standard Time (Mexico)', name: '(UTC-08:00) Baja California', offset: '-8' },
    { code: 'Pacific Standard Time_-8', id: 'Pacific Standard Time', name: '(UTC-08:00) Pacific Time (US & Canada)', offset: '-8' },
    { code: 'US Mountain Standard Time_-7', id: 'US Mountain Standard Time', name: '(UTC-07:00) Arizona', offset: '-7' },
    { code: 'Mountain Standard Time (Mexico)_-7', id: 'Mountain Standard Time (Mexico)', name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan', offset: '-7' },
    { code: 'Mountain Standard Time_-7', id: 'Mountain Standard Time', name: '(UTC-07:00) Mountain Time (US & Canada)', offset: '-7' },
    { code: 'Central America Standard Time_-6', id: 'Central America Standard Time', name: '(UTC-06:00) Central America', offset: '-6' },
    { code: 'Central Standard Time_-6', id: 'Central Standard Time', name: '(UTC-06:00) Central Time (US & Canada)', offset: '-6' },
    { code: 'Central Standard Time (Mexico)_-6', id: 'Central Standard Time (Mexico)', name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey', offset: '-6' },
    { code: 'Canada Central Standard Time_-6', id: 'Canada Central Standard Time', name: '(UTC-06:00) Saskatchewan', offset: '-6' },
    { code: 'SA Pacific Standard Time_-5', id: '-SA Pacific Standard Time', name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco', offset: '-5' },
    { code: 'Eastern Standard Time_-5', id: 'Eastern Standard Time', name: '(UTC-05:00) Eastern Time (US & Canada)', offset: '-5' },
    { code: 'US Eastern Standard Time_-5', id: 'US Eastern Standard Time', name: '(UTC-05:00) Indiana (East)', offset: '-5' },
    { code: 'Venezuela Standard Time_-4.5', id: 'Venezuela Standard Time', name: '(UTC-04:30) Caracas', offset: '-4.5' },
    { code: 'Paraguay Standard Time_-4', id: 'Paraguay Standard Time', name: '(UTC-04:00) Asuncion', offset: '-4' },
    { code: 'Atlantic Standard Time_-4', id: 'Atlantic Standard Time', name: '(UTC-04:00) Atlantic Time (Canada)', offset: '-4' },
    { code: 'Central Brazilian Standard Time_-4', id: 'Central Brazilian Standard Time', name: '(UTC-04:00) Cuiaba', offset: '-4' },
    { code: 'SA Western Standard Time_-4', id: 'SA Western Standard Time', name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan', offset: '-4' },
    { code: 'Pacific SA Standard Time_-4', id: 'Pacific SA Standard Time', name: '(UTC-04:00) Santiago', offset: '-4' },
    { code: 'Newfoundland Standard Time_-3.5', id: 'Newfoundland Standard Time', name: '(UTC-03:30) Newfoundland', offset: '-3.5' },
    { code: 'E. South America Standard Time_-3', id: 'E. South America Standard Time', name: '(UTC-03:00) Brasilia', offset: '-3' },
    { code: 'Argentina Standard Time_-3', id: 'Argentina Standard Time', name: '(UTC-03:00) Buenos Aires', offset: '-3' },
    { code: 'SA Eastern Standard Time_-3', id: 'SA Eastern Standard Time', name: '(UTC-03:00) Cayenne, Fortaleza', offset: '-3' },
    { code: 'Greenland Standard Time_-3', id: 'Greenland Standard Time', name: '(UTC-03:00) Greenland', offset: '-3' },
    { code: 'Montevideo Standard Time_-3', id: 'Montevideo Standard Time', name: '(UTC-03:00) Montevideo', offset: '-3' },
    { code: 'Bahia Standard Time_-3', id: 'Bahia Standard Time', name: '(UTC-03:00) Salvador', offset: '-3' },
    { code: 'Mid-Atlantic Standard Time_-2', id: 'Mid-Atlantic Standard Time', name: '(UTC-02:00) Mid-Atlantic - Old', offset: '-2' },
    { code: 'Azores Standard Time_-1', id: 'Azores Standard Time', name: '(UTC-01:00) Azores', offset: '-1' },
    { code: 'Cape Verde Standard Time_-1', id: 'Cape Verde Standard Time', name: '(UTC-01:00) Cape Verde Is.', offset: '-1' },
    { code: 'Morocco Standard Time_0', id: 'Morocco Standard Time', name: '(UTC) Casablanca', offset: '0' },
    { code: 'UTC_0', id: '0_UTC', name: '(UTC) Coordinated Universal Time', offset: '0' },
    { code: 'GMT Standard Time_0', id: 'GMT Standard Time', name: '(UTC) Dublin, Edinburgh, Lisbon, London', offset: '0' },
    { code: 'Greenwich Standard Time_0', id: 'Greenwich Standard Time', name: '(UTC) Monrovia, Reykjavik', offset: '0' },
    { code: 'W. Europe Standard Time_1', id: 'W. Europe Standard Time', name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', offset: '1' },
    { code: 'Central Europe Standard Time_1', id: 'Central Europe Standard Time', name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', offset: '1' },
    { code: 'Romance Standard Time_1', id: 'Romance Standard Time', name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris', offset: '1' },
    { code: 'Central European Standard Time_1', id: 'Central European Standard Time', name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb', offset: '1' },
    { code: 'W. Central Africa Standard Time_1', id: 'W. Central Africa Standard Time', name: '(UTC+01:00) West Central Africa', offset: '1' },
    { code: 'Namibia Standard Time_1', id: 'Namibia Standard Time', name: '(UTC+01:00) Windhoek', offset: '1' },
    { code: 'Jordan Standard Time_2', id: 'Jordan Standard Time', name: '(UTC+02:00) Amman', offset: '2' },
    { code: 'GTB Standard Time_2', id: 'GTB Standard Time', name: '(UTC+02:00) Athens, Bucharest', offset: '2' },
    { code: 'Middle East Standard Time_2', id: 'Middle East Standard Time', name: '(UTC+02:00) Beirut', offset: '2' },
    { code: 'Egypt Standard Time_2', id: 'Egypt Standard Time', name: '(UTC+02:00) Cairo', offset: '2' },
    { code: 'Syria Standard Time_2', id: 'Syria Standard Time', name: '(UTC+02:00) Damascus', offset: '2' },
    { code: 'E. Europe Standard Time_2', id: 'E. Europe Standard Time', name: '(UTC+02:00) E. Europe', offset: '2' },
    { code: 'South Africa Standard Time_2', id: 'South Africa Standard Time', name: '(UTC+02:00) Harare, Pretoria', offset: '2' },
    { code: 'FLE Standard Time_2', id: 'FLE Standard Time', name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', offset: '2' },
    { code: 'Turkey Standard Time_2', id: 'Turkey Standard Time', name: '(UTC+02:00) Istanbul', offset: '2' },
    { code: 'Israel Standard Time_2', id: 'Israel Standard Time', name: '(UTC+02:00) Jerusalem', offset: '2' },
    { code: 'Libya Standard Time_2', id: 'Libya Standard Time', name: '(UTC+02:00) Tripoli', offset: '2' },
    { code: 'Arabic Standard Time_3', id: 'Arabic Standard Time', name: '(UTC+03:00) Baghdad', offset: '3' },
    { code: 'Kaliningrad Standard Time_3', id: 'Kaliningrad Standard Time', name: '(UTC+03:00) Kaliningrad, Minsk', offset: '3' },
    { code: 'Arab Standard Time_3', id: 'Arab Standard Time', name: '(UTC+03:00) Kuwait, Riyadh', offset: '3' },
    { code: 'E. Africa Standard Time_3', id: 'E. Africa Standard Time', name: '(UTC+03:00) Nairobi', offset: '3' },
    { code: 'Iran Standard Time_3.5', id: 'Iran Standard Time', name: '(UTC+03:30) Tehran', offset: '3.5' },
    { code: 'Arabian Standard Time_4', id: 'Arabian Standard Time', name: '(UTC+04:00) Abu Dhabi, Muscat', offset: '4' },
    { code: 'Azerbaijan Standard Time_4', id: 'Azerbaijan Standard Time', name: '(UTC+04:00) Baku', offset: '4' },
    { code: 'Russian Standard Time_4', id: 'Russian Standard Time', name: '(UTC+04:00) Moscow, St. Petersburg, Volgograd', offset: '4' },
    { code: 'Mauritius Standard Time_4', id: 'Mauritius Standard Time', name: '(UTC+04:00) Port Louis', offset: '4' },
    { code: 'Georgian Standard Time_4', id: 'Georgian Standard Time', name: '(UTC+04:00) Tbilisi', offset: '4' },
    { code: 'Caucasus Standard Time_4', id: 'Caucasus Standard Time', name: '(UTC+04:00) Yerevan', offset: '4' },
    { code: 'Afghanistan Standard Time_4.5', id: 'Afghanistan Standard Time', name: '(UTC+04:30) Kabul', offset: '4.5' },
    { code: 'West Asia Standard Time_5', id: 'West Asia Standard Time', name: '(UTC+05:00) Ashgabat, Tashkent', offset: '5' },
    { code: 'Pakistan Standard Time_5', id: 'Pakistan Standard Time', name: '(UTC+05:00) Islamabad, Karachi', offset: '5' },
    { code: 'India Standard Time_5.5', id: 'India Standard Time', name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi', offset: '5.5' },
    { code: 'Sri Lanka Standard Time_5.5', id: 'Sri Lanka Standard Time', name: '(UTC+05:30) Sri Jayawardenepura', offset: '5.5' },
    { code: 'Nepal Standard Time_5.75', id: 'Nepal Standard Time', name: '(UTC+05:45) Kathmandu', offset: '5.75' },
    { code: 'Central Asia Standard Time_6', id: 'Central Asia Standard Time', name: '(UTC+06:00) Astana', offset: '6' },
    { code: 'Bangladesh Standard Time_6', id: 'Bangladesh Standard Time', name: '(UTC+06:00) Dhaka', offset: '6' },
    { code: 'Ekaterinburg Standard Time_6', id: 'Ekaterinburg Standard Time', name: '(UTC+06:00) Ekaterinburg', offset: '6' },
    { code: 'Myanmar Standard Time_6.5', id: 'Myanmar Standard Time', name: '(UTC+06:30) Yangon (Rangoon)', offset: '6.5' },
    { code: 'SE Asia Standard Time_7', id: 'SE Asia Standard Time', name: '(UTC+07:00) Bangkok, Hanoi, Jakarta', offset: '7' },
    { code: 'N. Central Asia Standard Time_7', id: 'N. Central Asia Standard Time', name: '(UTC+07:00) Novosibirsk', offset: '7' },
    { code: 'China Standard Time_8', id: 'China Standard Time', name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi', offset: '8' },
    { code: 'North Asia Standard Time_8', id: 'North Asia Standard Time', name: '(UTC+08:00) Krasnoyarsk', offset: '8' },
    { code: 'Singapore Standard Time_8', id: 'Singapore Standard Time', name: '(UTC+08:00) Kuala Lumpur, Singapore', offset: '8' },
    { code: 'W. Australia Standard Time_8', id: 'W. Australia Standard Time', name: '(UTC+08:00) Perth', offset: '8' },
    { code: 'Taipei Standard Time_8', id: 'Taipei Standard Time', name: '(UTC+08:00) Taipei', offset: '8' },
    { code: 'Ulaanbaatar Standard Time_8', id: 'Ulaanbaatar Standard Time', name: '(UTC+08:00) Ulaanbaatar', offset: '8' },
    { code: 'North Asia East Standard Time_9', id: 'North Asia East Standard Time', name: '(UTC+09:00) Irkutsk', offset: '9' },
    { code: 'Tokyo Standard Time_9', id: 'Tokyo Standard Time', name: '(UTC+09:00) Osaka, Sapporo, Tokyo', offset: '9' },
    { code: 'Korea Standard Time_9', id: 'Korea Standard Time', name: '(UTC+09:00) Seoul', offset: '9' },
    { code: 'Cen. Australia Standard Time_9.5', id: '_Cen. Australia Standard Time', name: '(UTC+09:30) Adelaide', offset: '9.5' },
    { code: 'AUS Central Standard Time_9.5', id: 'AUS Central Standard Time', name: '(UTC+09:30) Darwin', offset: '9.5' },
    { code: 'E. Australia Standard Time_10', id: 'E. Australia Standard Time', name: '(UTC+10:00) Brisbane', offset: '10' },
    { code: 'AUS Eastern Standard Time_10', id: 'AUS Eastern Standard Time', name: '(UTC+10:00) Canberra, Melbourne, Sydney', offset: '10' },
    { code: 'West Pacific Standard Time_10', id: 'West Pacific Standard Time', name: '(UTC+10:00) Guam, Port Moresby', offset: '10' },
    { code: 'Tasmania Standard Time_10', id: 'Tasmania Standard Time', name: '(UTC+10:00) Hobart', offset: '10' },
    { code: 'Yakutsk Standard Time_10', id: 'Yakutsk Standard Time', name: '(UTC+10:00) Yakutsk', offset: '10' },
    { code: 'Central Pacific Standard Time_11', id: 'Central Pacific Standard Time', name: '(UTC+11:00) Solomon Is., New Caledonia', offset: '11' },
    { code: 'Vladivostok Standard Time_11', id: 'Vladivostok Standard Time', name: '(UTC+11:00) Vladivostok', offset: '11' },
    { code: 'New Zealand Standard Time_12', id: 'New Zealand Standard Time', name: '(UTC+12:00) Auckland, Wellington', offset: '12' },
    { code: 'Fiji Standard Time_12', id: 'Fiji Standard Time', name: '(UTC+12:00) Fiji', offset: '12' },
    { code: 'Magadan Standard Time_12', id: 'Magadan Standard Time', name: '(UTC+12:00) Magadan', offset: '12' },
    { code: 'Kamchatka Standard Time_12', id: 'Kamchatka Standard Time', name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old', offset: '12' },
    { code: 'Tonga Standard Time_13', id: 'Tonga Standard Time', name: '(UTC+13:00) Nuku\'alofa', offset: '13' },
    { code: 'Samoa Standard Time_13', id: 'Samoa Standard Time', name: '(UTC+13:00) Samoa', offset: '13' },
  ]
}

export function getCountries() {
  return [
    {	CountryCode: 'ABW',	CountryPhoneCode: '+297',	Country: 'Aruba',	},
    {	CountryCode: 'AFG',	CountryPhoneCode: '+93',	Country: 'Afghanistan',	},
    {	CountryCode: 'AGO',	CountryPhoneCode: '+244',	Country: 'Angola',	},
    {	CountryCode: 'AIA',	CountryPhoneCode: '+1',	Country: 'Anguilla',	},
    {	CountryCode: 'ALB',	CountryPhoneCode: '+355',	Country: 'Albania',	},
    {	CountryCode: 'AND',	CountryPhoneCode: '+376',	Country: 'Andorra',	},
    {	CountryCode: 'ANT',	CountryPhoneCode: '+376',	Country: 'Netherlands Antilles',	},
    {	CountryCode: 'ARE',	CountryPhoneCode: '+971',	Country: 'United Arab Emirates',	},
    {	CountryCode: 'ARG',	CountryPhoneCode: '+54',	Country: 'Argentina',	},
    {	CountryCode: 'ARM',	CountryPhoneCode: '+374',	Country: 'Armenia',	},
    {	CountryCode: 'ASM',	CountryPhoneCode: '+1',	Country: 'American Samoa',	},
    {	CountryCode: 'ATA',	CountryPhoneCode: '+1',	Country: 'Antarctica',	},
    {	CountryCode: 'ATF',	CountryPhoneCode: '+1',	Country: 'French Southern territories',	},
    {	CountryCode: 'ATG',	CountryPhoneCode: '+1',	Country: 'Antigua and Barbuda',	},
    {	CountryCode: 'AUS',	CountryPhoneCode: '+61',	Country: 'Australia',	},
    {	CountryCode: 'AUT',	CountryPhoneCode: '+43',	Country: 'Austria',	},
    {	CountryCode: 'AZE',	CountryPhoneCode: '+994',	Country: 'Azerbaijan',	},
    {	CountryCode: 'BDI',	CountryPhoneCode: '+257',	Country: 'Burundi',	},
    {	CountryCode: 'BEL',	CountryPhoneCode: '+32',	Country: 'Belgium',	},
    {	CountryCode: 'BEN',	CountryPhoneCode: '+229',	Country: 'Benin',	},
    {	CountryCode: 'BFA',	CountryPhoneCode: '+226',	Country: 'Burkina Faso',	},
    {	CountryCode: 'BGD',	CountryPhoneCode: '+880',	Country: 'Bangladesh',	},
    {	CountryCode: 'BGR',	CountryPhoneCode: '+359',	Country: 'Bulgaria',	},
    {	CountryCode: 'BHR',	CountryPhoneCode: '+973',	Country: 'Bahrain',	},
    {	CountryCode: 'BHS',	CountryPhoneCode: '+1',	Country: 'Bahamas',	},
    {	CountryCode: 'BIH',	CountryPhoneCode: '+387',	Country: 'Bosnia and Herzegovina',	},
    {	CountryCode: 'BLR',	CountryPhoneCode: '+375',	Country: 'Belarus',	},
    {	CountryCode: 'BLZ',	CountryPhoneCode: '+501',	Country: 'Belize',	},
    {	CountryCode: 'BMU',	CountryPhoneCode: '+1',	Country: 'Bermuda',	},
    {	CountryCode: 'BOL',	CountryPhoneCode: '+591',	Country: 'Bolivia',	},
    {	CountryCode: 'BRA',	CountryPhoneCode: '+55',	Country: 'Brazil',	},
    {	CountryCode: 'BRB',	CountryPhoneCode: '+1',	Country: 'Barbados',	},
    {	CountryCode: 'BRN',	CountryPhoneCode: '+673',	Country: 'Brunei',	},
    {	CountryCode: 'BTN',	CountryPhoneCode: '+975',	Country: 'Bhutan',	},
    {	CountryCode: 'BVT',	CountryPhoneCode: '+975',	Country: 'Bouvet Island',	},
    {	CountryCode: 'BWA',	CountryPhoneCode: '+267',	Country: 'Botswana',	},
    {	CountryCode: 'CAF',	CountryPhoneCode: '+236',	Country: 'Central African Republic',	},
    {	CountryCode: 'CAN',	CountryPhoneCode: '+1',	Country: 'Canada',	},
    {	CountryCode: 'CCK',	CountryPhoneCode: '+1',	Country: 'Cocos (Keeling) Islands',	},
    {	CountryCode: 'CHE',	CountryPhoneCode: '+41',	Country: 'Switzerland',	},
    {	CountryCode: 'CHL',	CountryPhoneCode: '+56',	Country: 'Chile',	},
    {	CountryCode: 'CHN',	CountryPhoneCode: '+86',	Country: 'China',	},
    {	CountryCode: 'CIV',	CountryPhoneCode: '+225',	Country: 'Cote d\'Ivoire',	},
    {	CountryCode: 'CMR',	CountryPhoneCode: '+237',	Country: 'Cameroon',	},
    {	CountryCode: 'COD',	CountryPhoneCode: '+242',	Country: 'Congo(COD)',	},
    {	CountryCode: 'COG',	CountryPhoneCode: '+243',	Country: 'Congo(COG)',	},
    {	CountryCode: 'COK',	CountryPhoneCode: '+682',	Country: 'Cook Islands',	},
    {	CountryCode: 'COL',	CountryPhoneCode: '+57',	Country: 'Colombia',	},
    {	CountryCode: 'COM',	CountryPhoneCode: '+269',	Country: 'Comoros',	},
    {	CountryCode: 'CPV',	CountryPhoneCode: '+238',	Country: 'Cape Verde',	},
    {	CountryCode: 'CRI',	CountryPhoneCode: '+506',	Country: 'Costa Rica',	},
    {	CountryCode: 'CUB',	CountryPhoneCode: '+53',	Country: 'Cuba',	},
    {	CountryCode: 'CXR',	CountryPhoneCode: '+1',	Country: 'Christmas Island',	},
    {	CountryCode: 'CYM',	CountryPhoneCode: '+1',	Country: 'Cayman Islands',	},
    {	CountryCode: 'CYP',	CountryPhoneCode: '+357',	Country: 'Cyprus',	},
    {	CountryCode: 'CZE',	CountryPhoneCode: '+420',	Country: 'Czech Republic',	},
    {	CountryCode: 'DEU',	CountryPhoneCode: '+49',	Country: 'Germany',	},
    {	CountryCode: 'DJI',	CountryPhoneCode: '+253',	Country: 'Djibouti',	},
    {	CountryCode: 'DMA',	CountryPhoneCode: '+1',	Country: 'Dominica',	},
    {	CountryCode: 'DNK',	CountryPhoneCode: '+45',	Country: 'Denmark',	},
    {	CountryCode: 'DOM',	CountryPhoneCode: '+1',	Country: 'Dominican Republic',	},
    {	CountryCode: 'DZA',	CountryPhoneCode: '+213',	Country: 'Algeria',	},
    {	CountryCode: 'ECU',	CountryPhoneCode: '+593',	Country: 'Ecuador',	},
    {	CountryCode: 'EGY',	CountryPhoneCode: '+20',	Country: 'Egypt',	},
    {	CountryCode: 'ERI',	CountryPhoneCode: '+291',	Country: 'Eritrea',	},
    {	CountryCode: 'ESH',	CountryPhoneCode: '+212',	Country: 'Western Sahara',	},
    {	CountryCode: 'ESP',	CountryPhoneCode: '+34',	Country: 'Spain',	},
    {	CountryCode: 'EST',	CountryPhoneCode: '+372',	Country: 'Estonia',	},
    {	CountryCode: 'ETH',	CountryPhoneCode: '+251',	Country: 'Ethiopia',	},
    {	CountryCode: 'FIN',	CountryPhoneCode: '+358',	Country: 'Finland',	},
    {	CountryCode: 'FJI',	CountryPhoneCode: '+679',	Country: 'Fiji Islands',	},
    {	CountryCode: 'FLK',	CountryPhoneCode: '+500',	Country: 'Falkland Islands',	},
    {	CountryCode: 'FRA',	CountryPhoneCode: '+33',	Country: 'France',	},
    {	CountryCode: 'FRO',	CountryPhoneCode: '+298',	Country: 'Faroe Islands',	},
    {	CountryCode: 'FSM',	CountryPhoneCode: '+691',	Country: 'Federated States of Micronesia',	},
    {	CountryCode: 'GAB',	CountryPhoneCode: '+241',	Country: 'Gabon',	},
    {	CountryCode: 'GBR',	CountryPhoneCode: '+44',	Country: 'United Kingdom',	},
    {	CountryCode: 'GEO',	CountryPhoneCode: '+995',	Country: 'Georgia',	},
    {	CountryCode: 'GHA',	CountryPhoneCode: '+233',	Country: 'Ghana',	},
    {	CountryCode: 'GIB',	CountryPhoneCode: '+350',	Country: 'Gibraltar',	},
    {	CountryCode: 'GIN',	CountryPhoneCode: '+224',	Country: 'Guinea',	},
    {	CountryCode: 'GLP',	CountryPhoneCode: '+590',	Country: 'Guadeloupe',	},
    {	CountryCode: 'GMB',	CountryPhoneCode: '+220',	Country: 'Gambia',	},
    {	CountryCode: 'GNB',	CountryPhoneCode: '+245',	Country: 'Guinea-Bissau',	},
    {	CountryCode: 'GNQ',	CountryPhoneCode: '+240',	Country: 'Equatorial Guinea',	},
    {	CountryCode: 'GRC',	CountryPhoneCode: '+30',	Country: 'Greece',	},
    {	CountryCode: 'GRD',	CountryPhoneCode: '+1',	Country: 'Grenada',	},
    {	CountryCode: 'GRL',	CountryPhoneCode: '+299',	Country: 'Greenland',	},
    {	CountryCode: 'GTM',	CountryPhoneCode: '+502',	Country: 'Guatemala',	},
    {	CountryCode: 'GUF',	CountryPhoneCode: '+594',	Country: 'French Guiana',	},
    {	CountryCode: 'GUM',	CountryPhoneCode: '+1',	Country: 'Guam',	},
    {	CountryCode: 'GUY',	CountryPhoneCode: '+592',	Country: 'Guyana',	},
    {	CountryCode: 'HKG',	CountryPhoneCode: '+852',	Country: 'Hong Kong',	},
    {	CountryCode: 'HMD',	CountryPhoneCode: '+592',	Country: 'Heard Island and McDonald Islands',	},
    {	CountryCode: 'HND',	CountryPhoneCode: '+504',	Country: 'Honduras',	},
    {	CountryCode: 'HRV',	CountryPhoneCode: '+385',	Country: 'Croatia',	},
    {	CountryCode: 'HTI',	CountryPhoneCode: '+509',	Country: 'Haiti',	},
    {	CountryCode: 'HUN',	CountryPhoneCode: '+36',	Country: 'Hungary',	},
    {	CountryCode: 'IDN',	CountryPhoneCode: '+62',	Country: 'Indonesia',	},
    {	CountryCode: 'IND',	CountryPhoneCode: '+91',	Country: 'India',	},
    {	CountryCode: 'IOT',	CountryPhoneCode: '+246',	Country: 'British Indian Ocean Territory',	},
    {	CountryCode: 'IRL',	CountryPhoneCode: '+353',	Country: 'Ireland',	},
    {	CountryCode: 'IRN',	CountryPhoneCode: '+98',	Country: 'Iran',	},
    {	CountryCode: 'IRQ',	CountryPhoneCode: '+964',	Country: 'Iraq',	},
    {	CountryCode: 'ISL',	CountryPhoneCode: '+354',	Country: 'Iceland',	},
    {	CountryCode: 'ISR',	CountryPhoneCode: '+972',	Country: 'Israel',	},
    {	CountryCode: 'ITA',	CountryPhoneCode: '+39',	Country: 'Italy',	},
    {	CountryCode: 'JAM',	CountryPhoneCode: '+1',	Country: 'Jamaica',	},
    {	CountryCode: 'JOR',	CountryPhoneCode: '+962',	Country: 'Jordan',	},
    {	CountryCode: 'JPN',	CountryPhoneCode: '+81',	Country: 'Japan',	},
    {	CountryCode: 'KAZ',	CountryPhoneCode: '+7',	Country: 'Kazakstan',	},
    {	CountryCode: 'KEN',	CountryPhoneCode: '+254',	Country: 'Kenya',	},
    {	CountryCode: 'KGZ',	CountryPhoneCode: '+996',	Country: 'Kyrgyzstan',	},
    {	CountryCode: 'KHM',	CountryPhoneCode: '+855',	Country: 'Cambodia',	},
    {	CountryCode: 'KIR',	CountryPhoneCode: '+686',	Country: 'Kiribati',	},
    {	CountryCode: 'KNA',	CountryPhoneCode: '+1',	Country: 'Saint Kitts and Nevis',	},
    {	CountryCode: 'KOR',	CountryPhoneCode: '+850',	Country: 'South Korea',	},
    {	CountryCode: 'KWT',	CountryPhoneCode: '+965',	Country: 'Kuwait',	},
    {	CountryCode: 'LAO',	CountryPhoneCode: '+965',	Country: 'Laos',	},
    {	CountryCode: 'LBN',	CountryPhoneCode: '+961',	Country: 'Lebanon',	},
    {	CountryCode: 'LBR',	CountryPhoneCode: '+231',	Country: 'Liberia',	},
    {	CountryCode: 'LBY',	CountryPhoneCode: '+218',	Country: 'Libyan Arab Jamahiriya',	},
    {	CountryCode: 'LCA',	CountryPhoneCode: '+1',	Country: 'Saint Lucia',	},
    {	CountryCode: 'LIE',	CountryPhoneCode: '+423',	Country: 'Liechtenstein',	},
    {	CountryCode: 'LKA',	CountryPhoneCode: '+94',	Country: 'Sri Lanka',	},
    {	CountryCode: 'LSO',	CountryPhoneCode: '+266',	Country: 'Lesotho',	},
    {	CountryCode: 'LTU',	CountryPhoneCode: '+370',	Country: 'Lithuania',	},
    {	CountryCode: 'LUX',	CountryPhoneCode: '+352',	Country: 'Luxembourg',	},
    {	CountryCode: 'LVA',	CountryPhoneCode: '+371',	Country: 'Latvia',	},
    {	CountryCode: 'MAC',	CountryPhoneCode: '+853',	Country: 'Macao',	},
    {	CountryCode: 'MAR',	CountryPhoneCode: '+212',	Country: 'Morocco',	},
    {	CountryCode: 'MCO',	CountryPhoneCode: '+377',	Country: 'Monaco',	},
    {	CountryCode: 'MDA',	CountryPhoneCode: '+373',	Country: 'Moldova',	},
    {	CountryCode: 'MDG',	CountryPhoneCode: '+261',	Country: 'Madagascar',	},
    {	CountryCode: 'MDV',	CountryPhoneCode: '+960',	Country: 'Maldives',	},
    {	CountryCode: 'MEX',	CountryPhoneCode: '+52',	Country: 'Mexico',	},
    {	CountryCode: 'MHL',	CountryPhoneCode: '+692',	Country: 'Marshall Islands',	},
    {	CountryCode: 'MKD',	CountryPhoneCode: '+389',	Country: 'Macedonia',	},
    {	CountryCode: 'MLI',	CountryPhoneCode: '+223',	Country: 'Mali',	},
    {	CountryCode: 'MLT',	CountryPhoneCode: '+356',	Country: 'Malta',	},
    {	CountryCode: 'MMR',	CountryPhoneCode: '+95',	Country: 'Myanmar',	},
    {	CountryCode: 'MNG',	CountryPhoneCode: '+976',	Country: 'Mongolia',	},
    {	CountryCode: 'MNP',	CountryPhoneCode: '+1',	Country: 'Northern Mariana Islands',	},
    {	CountryCode: 'MOZ',	CountryPhoneCode: '+258',	Country: 'Mozambique',	},
    {	CountryCode: 'MRT',	CountryPhoneCode: '+222',	Country: 'Mauritania',	},
    {	CountryCode: 'MSR',	CountryPhoneCode: '+1',	Country: 'Montserrat',	},
    {	CountryCode: 'MTQ',	CountryPhoneCode: '+596',	Country: 'Martinique',	},
    {	CountryCode: 'MUS',	CountryPhoneCode: '+230',	Country: 'Mauritius',	},
    {	CountryCode: 'MWI',	CountryPhoneCode: '+265',	Country: 'Malawi',	},
    {	CountryCode: 'MYS',	CountryPhoneCode: '+60',	Country: 'Malaysia',	},
    {	CountryCode: 'MYT',	CountryPhoneCode: '+262',	Country: 'Mayotte',	},
    {	CountryCode: 'NAM',	CountryPhoneCode: '+264',	Country: 'Namibia',	},
    {	CountryCode: 'NCL',	CountryPhoneCode: '+687',	Country: 'New Caledonia',	},
    {	CountryCode: 'NER',	CountryPhoneCode: '+227',	Country: 'Niger',	},
    {	CountryCode: 'NFK',	CountryPhoneCode: '+672',	Country: 'Norfolk Island',	},
    {	CountryCode: 'NGA',	CountryPhoneCode: '+234',	Country: 'Nigeria',	},
    {	CountryCode: 'NIC',	CountryPhoneCode: '+505',	Country: 'Nicaragua',	},
    {	CountryCode: 'NIU',	CountryPhoneCode: '+683',	Country: 'Niue',	},
    {	CountryCode: 'NLD',	CountryPhoneCode: '+31',	Country: 'Netherlands',	},
    {	CountryCode: 'NOR',	CountryPhoneCode: '+47',	Country: 'Norway',	},
    {	CountryCode: 'NPL',	CountryPhoneCode: '+977',	Country: 'Nepal',	},
    {	CountryCode: 'NRU',	CountryPhoneCode: '+674',	Country: 'Nauru',	},
    {	CountryCode: 'NZL',	CountryPhoneCode: '+64',	Country: 'New Zealand',	},
    {	CountryCode: 'OMN',	CountryPhoneCode: '+968',	Country: 'Oman',	},
    {	CountryCode: 'PAK',	CountryPhoneCode: '+92',	Country: 'Pakistan',	},
    {	CountryCode: 'PAN',	CountryPhoneCode: '+507',	Country: 'Panama',	},
    {	CountryCode: 'PCN',	CountryPhoneCode: '+507',	Country: 'Pitcairn',	},
    {	CountryCode: 'PER',	CountryPhoneCode: '+51',	Country: 'Peru',	},
    {	CountryCode: 'PHL',	CountryPhoneCode: '+63',	Country: 'Philippines',	},
    {	CountryCode: 'PLW',	CountryPhoneCode: '+680',	Country: 'Palau',	},
    {	CountryCode: 'PNG',	CountryPhoneCode: '+675',	Country: 'Papua New Guinea',	},
    {	CountryCode: 'POL',	CountryPhoneCode: '+48',	Country: 'Poland',	},
    {	CountryCode: 'PRI',	CountryPhoneCode: '+1',	Country: 'Puerto Rico',	},
    {	CountryCode: 'PRK',	CountryPhoneCode: '+82',	Country: 'North Korea',	},
    {	CountryCode: 'PRT',	CountryPhoneCode: '+351',	Country: 'Portugal',	},
    {	CountryCode: 'PRY',	CountryPhoneCode: '+595',	Country: 'Paraguay',	},
    {	CountryCode: 'PSE',	CountryPhoneCode: '+970',	Country: 'Palestine',	},
    {	CountryCode: 'PYF',	CountryPhoneCode: '+689',	Country: 'French Polynesia',	},
    {	CountryCode: 'QAT',	CountryPhoneCode: '+974',	Country: 'Qatar',	},
    {	CountryCode: 'REU',	CountryPhoneCode: '+262',	Country: 'Reunion',	},
    {	CountryCode: 'ROM',	CountryPhoneCode: '+40',	Country: 'Romania',	},
    {	CountryCode: 'RUS',	CountryPhoneCode: '+7',	Country: 'Russian',	},
    {	CountryCode: 'RWA',	CountryPhoneCode: '+250',	Country: 'Rwanda',	},
    {	CountryCode: 'SAU',	CountryPhoneCode: '+966',	Country: 'Saudi Arabia',	},
    {	CountryCode: 'SDN',	CountryPhoneCode: '+249',	Country: 'Sudan',	},
    {	CountryCode: 'SEN',	CountryPhoneCode: '+221',	Country: 'Senegal',	},
    {	CountryCode: 'SGP',	CountryPhoneCode: '+65',	Country: 'Singapore',	},
    {	CountryCode: 'SGS',	CountryPhoneCode: '+65',	Country: 'South Georgia and the South Sandwich Islands',	},
    {	CountryCode: 'SHN',	CountryPhoneCode: '+290',	Country: 'Saint Helena',	},
    {	CountryCode: 'SJM',	CountryPhoneCode: '+290',	Country: 'Svalbard and Jan Mayen',	},
    {	CountryCode: 'SLB',	CountryPhoneCode: '+677',	Country: 'Solomon Islands',	},
    {	CountryCode: 'SLE',	CountryPhoneCode: '+232',	Country: 'Sierra Leone',	},
    {	CountryCode: 'SLV',	CountryPhoneCode: '+503',	Country: 'El Salvador',	},
    {	CountryCode: 'SMR',	CountryPhoneCode: '+378',	Country: 'San Marino',	},
    {	CountryCode: 'SOM',	CountryPhoneCode: '+252',	Country: 'Somalia',	},
    {	CountryCode: 'SPM',	CountryPhoneCode: '+508',	Country: 'Saint Pierre and Miquelon',	},
    {	CountryCode: 'STP',	CountryPhoneCode: '+239',	Country: 'Sao Tome and Principe',	},
    {	CountryCode: 'SUR',	CountryPhoneCode: '+597',	Country: 'Suriname',	},
    {	CountryCode: 'SVK',	CountryPhoneCode: '+421',	Country: 'Slovakia',	},
    {	CountryCode: 'SVN',	CountryPhoneCode: '+386',	Country: 'Slovenia',	},
    {	CountryCode: 'SWE',	CountryPhoneCode: '+46',	Country: 'Sweden',	},
    {	CountryCode: 'SWZ',	CountryPhoneCode: '+268',	Country: 'Swaziland',	},
    {	CountryCode: 'SYC',	CountryPhoneCode: '+248',	Country: 'Seychelles',	},
    {	CountryCode: 'SYR',	CountryPhoneCode: '+963',	Country: 'Syria',	},
    {	CountryCode: 'TCA',	CountryPhoneCode: '+1',	Country: 'Turks and Caicos Islands',	},
    {	CountryCode: 'TCD',	CountryPhoneCode: '+235',	Country: 'Chad',	},
    {	CountryCode: 'TGO',	CountryPhoneCode: '+228',	Country: 'Togo',	},
    {	CountryCode: 'THA',	CountryPhoneCode: '+66',	Country: 'Thailand',	},
    {	CountryCode: 'TJK',	CountryPhoneCode: '+992',	Country: 'Tajikistan',	},
    {	CountryCode: 'TKL',	CountryPhoneCode: '+690',	Country: 'Tokelau',	},
    {	CountryCode: 'TKM',	CountryPhoneCode: '+993',	Country: 'Turkmenistan',	},
    {	CountryCode: 'TMP',	CountryPhoneCode: '+670',	Country: 'East Timor',	},
    {	CountryCode: 'TON',	CountryPhoneCode: '+676',	Country: 'Tonga',	},
    {	CountryCode: 'TTO',	CountryPhoneCode: '+1',	Country: 'Trinidad and Tobago',	},
    {	CountryCode: 'TUN',	CountryPhoneCode: '+216',	Country: 'Tunisia',	},
    {	CountryCode: 'TUR',	CountryPhoneCode: '+90',	Country: 'Turkey',	},
    {	CountryCode: 'TUV',	CountryPhoneCode: '+688',	Country: 'Tuvalu',	},
    {	CountryCode: 'TWN',	CountryPhoneCode: '+886',	Country: 'Taiwan',	},
    {	CountryCode: 'TZA',	CountryPhoneCode: '+255',	Country: 'Tanzania',	},
    {	CountryCode: 'UGA',	CountryPhoneCode: '+256',	Country: 'Uganda',	},
    {	CountryCode: 'UKR',	CountryPhoneCode: '+380',	Country: 'Ukraine',	},
    {	CountryCode: 'UMI',	CountryPhoneCode: '+380',	Country: 'United States Minor Outlying Islands',	},
    {	CountryCode: 'UNK',	CountryPhoneCode: '+1',	Country: 'Unknown',	},
    {	CountryCode: 'URY',	CountryPhoneCode: '+598',	Country: 'Uruguay',	},
    {	CountryCode: 'USA',	CountryPhoneCode: '+1',	Country: 'United States',	},
    {	CountryCode: 'UZB',	CountryPhoneCode: '+998',	Country: 'Uzbekistan',	},
    {	CountryCode: 'VAT',	CountryPhoneCode: '+39',	Country: 'Holy See (Vatican City State)',	},
    {	CountryCode: 'VCT',	CountryPhoneCode: '+1',	Country: 'Saint Vincent and the Grenadines',	},
    {	CountryCode: 'VEN',	CountryPhoneCode: '+58',	Country: 'Venezuela',	},
    {	CountryCode: 'VGB',	CountryPhoneCode: '+1',	Country: 'Virgin Islands, British',	},
    {	CountryCode: 'VIR',	CountryPhoneCode: '+1',	Country: 'Virgin Islands, U.S.',	},
    {	CountryCode: 'VNM',	CountryPhoneCode: '+84',	Country: 'Vietnam',	},
    {	CountryCode: 'VUT',	CountryPhoneCode: '+678',	Country: 'Vanuatu',	},
    {	CountryCode: 'WLF',	CountryPhoneCode: '+681',	Country: 'Wallis and Futuna',	},
    {	CountryCode: 'WSM',	CountryPhoneCode: '+685',	Country: 'Samoa',	},
    {	CountryCode: 'YEM',	CountryPhoneCode: '+967',	Country: 'Yemen',	},
    {	CountryCode: 'YUG',	CountryPhoneCode: '+967',	Country: 'Yugoslavia',	},
    {	CountryCode: 'ZAF',	CountryPhoneCode: '+27',	Country: 'South Africa',	},
    {	CountryCode: 'ZMB',	CountryPhoneCode: '+260',	Country: 'Zambia',	},
    {	CountryCode: 'ZWE',	CountryPhoneCode: '+263',	Country: 'Zimbabwe',	},
  ]
}


/*
* @rows - data as array
* @captions - column titles
* @name - file name
* @addTimeStamp - should date stamp be added to the file name
* */
export function exportToCsv(params){
  let defaultParams = {
    name: moment().format('YYYY-MM-DD-HH-mm-ss'),
  }
  if(!params.rows || !Array.isArray(params.rows) || !Array.isArray(params.rows[0])){
    console.warn("add rows parameter, and it should be array with array data, exp. [['a','b','c'],['d','e','f']]")
    return
  }
  if(!params.name){
    params.name = defaultParams.name
  }else if(params.addTimeStamp){
    params.name += ' '+defaultParams.name
  }
  let csvContent = ''
  if(params.captions && params.captions.length){
    csvContent += params.captions.join(";") + "\n"
  }
  csvContent += params.rows.map(e => e.join(";")).join("\n");
  let blob = new Blob([csvContent], {type: "text/csv"});
  let href = window.URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.setAttribute("href", href);
  link.setAttribute("download", `${ params.name }.csv`);
  link.setAttribute("class", 'external display-none');
  document.body.appendChild(link); // Required for FF
  link.click(); // This will download the data file named "my_data.csv".
  document.body.removeChild(link);
}