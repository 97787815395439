<template>
  <f7-popup
    tablet-fullscreen
    class="bg-color-lightgray"
    v-model:opened="isOpened"
    @popup:closed="onHide"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          <i class="icon-back size-20"></i>
        </f7-link>
      </f7-nav-left>
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link href="/dashboard/tab-dashboard/" popup-close icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link href="/commands/" popup-close icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link href="/users/" popup-close icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link href="/summary/" popup-close icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
        >{{ pageTitle }}</f7-actions-label
      >
    </f7-actions-group>

    <f7-list
      media-list
      no-hairlines
      no-chevron
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      form
      @submit.prevent="onSubmitCommand"
    >
      <f7-list-item class="toggle-list-item" required validate validate-on-blur>
        <span>Public</span>
        <template #after>
          <f7-toggle v-model:value="isPublic" checked color="blue"></f7-toggle>
        </template>
      </f7-list-item>

      <f7-list-input
        type="text"
        class="command-menu-list"
        v-model:value="localCommandData.Name"
        label="Command’s Name"
        placeholder="Enter Command’s Name Below"
      >
        <template #media>
          <i class="icon-profile size-20 text-color-blue display-flex"></i>
        </template>
      </f7-list-input>

      <f7-list-input
        class="command-menu-list"
        v-model:value="localCommandData.Content"
        label="SMS Format"
        placeholder="Enter SMS Format Below"
      >
        <template #media>
          <i class="icon-mail size-20 text-color-orange display-flex"></i>
        </template>
      </f7-list-input>
    </f7-list>
    <div id="submit-btn" style="position: absolute; bottom: 60px; width: 100%">
      <f7-block v-if="localCommandData.Code">
        <f7-button
          @click="deleteCommand(localCommandData.Code)"
          color="black"
          fill
          class="col"
          >DELETE</f7-button
        >
      </f7-block>
      <f7-block>
        <f7-button @click="editCommand()" color="blue" fill class="col"
          >CONFIRM</f7-button
        >
      </f7-block>
    </div>
  </f7-popup>
</template>

<script>
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { QTAPI } from "../../../services/qt-api";


export default {
  name: "command-menu",

  props: {
    commandData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isOpened: { type: Boolean, default: false },
  },

  emits: ["update:isOpened"],

  data() {
    return {
      pageTitle: "",
      initialCommandData: null,
      localCommandData: null,
      isPublic: false
    };
  },
  watch: {
    commandData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          this.initialCommandData = { ...newValue };
          this.localCommandData = { ...newValue };
          this.pageTitle = newValue.Name;
        } else {
          this.resetData(true);
        }
      },
    },
    isOpened(newVal) {
      if (!newVal) {
        this.resetData(false);
      }
    },
  },

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
  },

  mounted() {
    document.addEventListener(
      "deviceready",
      function () {
        window.addEventListener("keyboardWillShow", function () {
          document.getElementById("submit-btn").style.display = "none";
        });

        window.addEventListener("keyboardWillHide", function () {
          document.getElementById("submit-btn").style.display = "block";
        });
      },
      false
    );
  },

  methods: {
   async editCommand() {
      if(this.localCommandData.isNewCommand) {
        let response = await QTAPI.addSMSTemplate({
          Name: this.localCommandData.Name,
          Content: this.localCommandData.Content,
          Parameters: '',
          Public: false
        });
      } else {
        let response = await QTAPI.editSMSTemplate({
          Code:  this.localCommandData.Code,
          Name: this.localCommandData.Name,
          Content: this.localCommandData.Content,
          Parameters: '',
          Public: false
        });
      }
    },
    async deleteCommand(code) {
      let response = await QTAPI.removeSMSTemplate({
          Code:  code,
       
        });
    },

    resetData(isNewCommand) {
      if (isNewCommand) {
        this.localCommandData = { Name: "", Content: "", isNewCommand: true };
        this.pageTitle = "Add New Command";
        
      } else {
        this.localCommandData = { ...this.initialCommandData };
        this.pageTitle = this.initialCommandData
          ? this.initialCommandData.Name
          : "";
      }
    },

    onHide() {
      this.resetData();
      this.$emit("update:isOpened", false);
    },
  },

  beforeDestroy() {},
};
</script>

<style>
.toggle-list-item .item-after {
  position: absolute;
  right: 0;
  top: 50%;
}

.command-menu-list .item-inner {
  margin: 0 !important;
}
</style>