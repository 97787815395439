<template>
  <f7-page
    ref="page"
    name="renewal"
     
    class=" bg-color-lightgray"
  >
 
    <f7-navbar :sliding="false">
      <f7-link back>
        <i class="icon-back size-20"></i>
      </f7-link>
    </f7-navbar>
    
    <f7-toolbar position="top">
      <f7-nav-title sliding>{{ $t("Renewal") }}</f7-nav-title>
    </f7-toolbar>

    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link> 
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'" href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link  v-tooltip="'Users'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

 

    <f7-list
      ref="vl"
      class="vl no-margin-top bigger-item-text template-list"
      virtual-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      :virtual-list-params="{ items, renderExternal}"
    >
      <ul>
      <f7-list-item
        class="item-input custom-smart-select-wrapper"
        :header="$t('SIMs')"
        :title="$t('987234987234987, 09123091237123, 008172367238')"
        link="#"
        @click="openSIMsSelect()"
      >
        <template #media>
          <i class="icon-imsi size-20 text-color-blue display-flex"></i>
        </template>
      </f7-list-item>
      <f7-list-item
        class="item-input custom-smart-select-wrapper"
        :header="$t('ServicePlan')"
        :title="$t('Monthly')"
        link="#"
        @click="openSPSelect()"

      >
        <template #media>
          <i class="icon-profile size-20 text-color-green display-flex"></i>
        </template>
      </f7-list-item>
      </ul>
    </f7-list>

    
  </f7-page>
</template>

<script>
//import {ApiMethods} from "../services/qt-api";
import { f7, theme } from "framework7-vue";
import { QTAPI } from "../../services/qt-api";
import { mapGetters } from "vuex";
import { HideKeyboard } from "../../js/helpers";
import $$ from "dom7";

 
export default {
  name: "commands",

  props: {
    f7router: Object,
    f7route: Object,
  },

 

  data() {
    return {
      isLoading: false,
      isFirstSearchDone: false,
      isInfiniteAllowed: true,
      isNoData: false,
      isCommandMenuOpened: false,
       
      items: [],
      vlData: {
        items: [],
      },
      vl: null,
      queryPage: 1,
      search: "",
      selectedMenuData: {},
      mainCustomerName: this.f7route.query.mainCustomerName,
      mainCustomerCode: this.f7route.query.mainCustomerCode,
    };
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",

      "SearchCancelToken",
    ]),
  },

  watch: {
   
  },

  mounted() {
    this.vl = f7.virtualList.get(this.$refs.vl.$el);
  },

  methods: {
   
 
    async getData() {
 
      this.isLoading = true;
      let response = await QTAPI.getSMSTemplate();

     // console.log('response', response)

      this.isLoading = false;

      if (!response) return;

      this.isFirstSearchDone = true;
      this.isNoData = !response.length;
      if (this.queryPage === 1) {
        if (this.isNoData) {
          this.vl.deleteAllItems();
          return;
        }
        this.vl.replaceAllItems(response);
      } else {
        if (this.isNoData) {
          return;
        }
        this.vl.appendItems(response);
        this.isInfiniteAllowed = true;
      }
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc() {
      return theme.ios ? 162 : 166;
    },
   
    getCustomerStatusClass(state) {
      let states = {
        //'9_NO_DATA': 'status-gray',
        Suspended: "status-red",
        //'Suspended': 'status-orange',
        Active: "status-green",
      };
      return states[state] ? states[state] : "status-gray";
    },
    getCustomerIconLetters(name) {
      name = name.trim();
      let ret = name[0];
      let splitted = name.split(" ");
      if (splitted.length > 1) {
        ret += " " + splitted[1][0];
      } else if (name[1]) {
        ret += name[1];
      }
      return ret.toUpperCase();
    },
    openSIMsSelect(item) {
      f7.views.main.router.navigate({
        name: "renewal-sims",
       
      }); 
    },
    openSPSelect() {
      f7.views.main.router.navigate({
        name: "service-plan",
       
      }); 
    }
  },
};
</script>

<style lang="scss">
  .template-list {
    .item-inner {
      margin: 0 !important;
    }
  }
</style>