 
<template>
  <f7-card header-divider footer-divider>
    <f7-row>
      <f7-col width="50">
        <f7-list>
          <f7-list-item
            smart-select
            :after="selectedTypeData"
            :smart-select-params="{ openIn: 'popover', closeOnSelect: true }"
          >
            <select name="dashboard-type-filter" v-model="selectedTypeData">
              <option value="data" selected>Data</option>
              <option value="smsS">SMS</option>
              <!-- <option value="smsR">SMS Received</option> -->
            </select>
          </f7-list-item>
        </f7-list>
      </f7-col>
      <f7-col width="50">
        <f7-list>
          <f7-list-item
            smart-select
            :after="selectedFilter"
            :smart-select-params="{ openIn: 'popover', closeOnSelect: true }"
          >
            <select name="dashboard-overview-filter" v-model="selectedFilter">
              <option value="lastday" selected>Last day</option>
              <option value="week">Week</option>
              <option value="thismonth">This month</option>
            </select>
          </f7-list-item>
        </f7-list>
      </f7-col>
    </f7-row>

    <div v-if="!isLoading" class="data-table">
      <table>
        <thead>
          <tr>
            <th class="label-cell">№</th>
            <th class="numeric-cell">IMSI</th>
            <th class="numeric-cell">Customer</th>
            <th class="numeric-cell">Data</th>
            <th class="numeric-cell">Last Update</th>
            <th class="numeric-cell">SMS Sent</th>
            <th class="numeric-cell">Last Update</th>
            <th class="numeric-cell">SMS Received</th>
            <th class="numeric-cell">Last Update</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataTable" :key="index">
            <td class="label-cell">{{ item.num }}</td>
            <td class="numeric-cell">{{ item.imsi }}</td>
            <td class="numeric-cell">{{ item.customer }}</td>
            <td class="numeric-cell">{{ item.totald + " МБ"}}</td>
            <td class="numeric-cell">{{  dateFormat(item.lastUpdateDATA + "+0000", "datetime")  }}</td>
            <td class="numeric-cell">{{ item.smsS }}</td>
            <td class="numeric-cell">{{  dateFormat(item.lastUpdateSMSMT + "+0000", "datetime")  }}</td>
            <td class="numeric-cell">{{ item.smsR }}</td>
            <td class="numeric-cell">{{  dateFormat(item.lastUpdateSMSMO + "+0000", "datetime")  }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <f7-progressbar
      v-else
      color="green"
      infinite
      class="position-absolute"
      key="1"
    ></f7-progressbar>
  </f7-card>
</template>

<script>
import { QTAPI } from "../../../services/qt-api";
import { dateFormat } from "../../../js/helpers";


export default {
  data() {
    return {
      isLoading: false,
      selectedFilter: "lastday",
      selectedTypeData: "data",
      dataTable: [], // Dynamically filled data will go here
    };
  },
  mounted() {
    let data = {
      ReportType: "DD",
      sort: "DataDay",
      order: "desc",
    };
    this.getDataTable(data);
  },
  methods: {
    dateFormat(...options) {
      return dateFormat(...options);
    },
    async getDataTable(data) {
      let self = this
      this.isLoading = true;

      let result = await QTAPI.getSIMInfoTable(data);
      this.isLoading = false;

      let arrTable = [];

      result.rows.forEach((element, index) => {
        arrTable.push({
          num: index + 1,
          imsi: element.IMSI.toString() + " ",
          //iccid: element.ICCID.toString(),
          //msisdn: element.MSISDN.toString(),
          customer: element.OrganizeName,
          //csp: element.ServiceProfileName,
          totald: self.selectedFilter === "lastday" ? (element.DataDay / 1048576).toFixed(3) : self.selectedFilter === "week" ? (element.DataWeek / 1048576).toFixed(3) : (element.DataMonth / 1048576).toFixed(3),
          smsS: self.selectedFilter === "lastday" ? element.SMSMTDay : self.selectedFilter === "week" ? element.SMSMTWeek : element.SMSMTMonth,
          smsR: self.selectedFilter === "lastday" ? element.SMSMODay : self.selectedFilter === "week" ? element.SMSMOWeek : element.SMSMOMonth,
          lastUpdateDATA:  element.DataUpdateTime,
          lastUpdateSMSMT:  element.SMSMTUpdateTime,
          lastUpdateSMSMO:  element.SMSMOUpdateTime,
 
        });
      });
      this.dataTable = arrTable;
    },
    updateData() {
      let data = {
        ReportType: this.getReportType(),
        sort: this.getSortOrder(),
        order: "desc",
      };
      this.getDataTable(data);
    },
    getReportType() {
      switch (this.selectedTypeData) {
        case "data":
          return this.selectedFilter === "lastday"
            ? "DD"
            : this.selectedFilter === "week"
            ? "DW"
            : "DM";
        case "smsS":
          return this.selectedFilter === "lastday"
            ? "SD"
            : this.selectedFilter === "week"
            ? "SW"
            : "SM";
        case "smsR":
          return this.selectedFilter === "lastday"
            ? "SD"
            : this.selectedFilter === "week"
            ? "SW"
            : "SM";
        default:
          return "DD";
      }
    },
    getSortOrder() {
      switch (this.selectedTypeData) {
        case "data":
          return this.selectedFilter === "lastday"
            ? "DataDay"
            : this.selectedFilter === "week"
            ? "DataWeek"
            : "DataMonth";
        case "smsS":
          return this.selectedFilter === "lastday"
            ? "SMSMtDay"
            : this.selectedFilter === "week"
            ? "SMSMtWeek"
            : "SMSMtMonth";
        case "smsR":
          return this.selectedFilter === "lastday"
            ? "SMSMoDay"
            : this.selectedFilter === "week"
            ? "SMSMoWeek"
            : "SMSMoMonth";
        default:
          return "DataDay"; // Default value
      }
    },
  },
  watch: {
    selectedTypeData() {
  
      this.updateData();
    },
    selectedFilter() {
      this.updateData();
    },
  },
};
</script>
