<template>
  <f7-page name="customer-edit" ref="page" class="bg-color-lightgray">
    <f7-navbar :sliding="false">
      <f7-link back>
        <i class="icon-back size-20"></i>
      </f7-link>
      <!-- <f7-nav-title sliding>{{ !CustomerCode ? $t('NewCustomer') : $t('EditCustomer') }}</f7-nav-title> -->

      <!-- <f7-nav-right>
        <label :for="inputSubmitId" class="link icon-only">
          <i class="icon f7-icons">checkmark_alt</i>
        </label>
      </f7-nav-right> -->
    </f7-navbar>
    <f7-toolbar position="top" class>
      <f7-nav-title sliding>{{ $t("CustomerDetails") }}</f7-nav-title>
    </f7-toolbar>
    <f7-toolbar href="/dashboard/tab-dashboard/" position="bottom" tabbar icons>
      <f7-link icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link  href="/commands/"  icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link  href="/users/"  icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link   href="/summary/"  icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>
    <f7-progressbar
      v-if="isLoading || isLoadingGetData"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>

    <!-- <f7-list v-if="isLoadingGetData" media-list class="skeleton-text skeleton-effect-wave" key="assetEditSkeleton">
      <f7-list-item v-for="n in 4" :key="n" title="Title" subtitle="Subtitle">
        <template #media>
          <f7-skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list> -->

    <form v-else @submit.prevent="onSubmit">
      <input type="submit" :id="inputSubmitId" hidden />

      <!-- <f7-block-title class="display-flex justify-content-space-between white-space-normal">
        <span style="width: 35%">{{ $t('CustomerInfo') }}</span>
        <span class="font-style-italic margin-left-half ">
          <f7-checkbox name="Renewal" 
          v-if="GlobalAgent != 'Leisure Technologies - WiTi ADMIN' && GlobalAgent != 'AL3RT GPS' && GlobalAgent != 'ReconoGPS Agent' && GlobalAgent != 'AutoProtect' && GlobalAgent != 'Secure Track GPS' && GlobalAgent != 'VIZN' && GlobalAgent != 'Western Powersports' && GlobalAgent != 'ISS Secure' && GlobalAgent != 'Digital Guard Dawg'"
          
          v-model:checked="isLocalRenewal" color="green"
            class="display-flex align-items-center"><span class="margin-left-half ">{{ $t('Renewal')
            }}</span></f7-checkbox>
        </span>
        <span class="font-style-italic margin-left-half ">
          <f7-checkbox name="SendRegLetter" v-model:checked="isSendRegLetter" color="green"
            class="display-flex align-items-center"><span class="margin-left-half ">{{ $t('SendRegLetter')
            }}</span></f7-checkbox>
        </span>
      </f7-block-title> -->
      <f7-list
        class="no-margin-top no-hairline-bottom"
        media-list
        no-hairlines
        no-hairlines-between
        dividers-ios
        strong
        outline
        inset
      >
        <f7-list-input
          type="text"
          name="CustomerName"
          required
          validate
          validate-on-blur
          :label="$t('CustomerName')"
          :placeholder="$t('CustomerName')"
          v-model:value.trim="AccountName"
        >
          <template #media>
            <i class="icon-customer size-20 text-color-blue display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="ParentName"
          required
          validate
          validate-on-blur
          :label="$t('ParentName')"
          :placeholder="$t('ParentName')"
          v-model:value.trim="ParentName"
        >
          <template #media>
            <i class="icon-customer size-20 text-color-green display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="FirstAccountName"
          required
          validate
          validate-on-blur
          :label="$t('FirstAccountName')"
          :placeholder="$t('FirstAccountName')"
          v-model:value.trim="FirstAccountName"
        >
          <template #media>
            <i class="icon-customer size-20 text-color-lilac display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="SubAccountName"
          required
          validate
          validate-on-blur
          :label="$t('SubAccountName')"
          :placeholder="$t('SubAccountName')"
          v-model:value.trim="SubAccountName"
        >
          <template #media>
            <i class="icon-customer size-20 text-color-red display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="Email"
          required
          validate
          validate-on-blur
          :label="$t('Email')"
          :placeholder="$t('Email')"
          v-model:value.trim="Email"
        >
          <template #media>
            <i class="icon-mail size-20 text-color-orange display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="Number2"
          required
          validate
          validate-on-blur
          :label="$t('MobileOptional')"
          :placeholder="$t('MobileOptional')"
          v-model:value.trim="Mobile"
        >
          <template #media>
            <i class="icon-phone size-20 text-color-aqua display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="Number"
          required
          validate
          validate-on-blur
          :label="$t('Number')"
          :placeholder="$t('Number1')"
          v-model:value.trim="NumberCode"
        >
          <template #media>
            <i
              class="icon-phone size-20 text-color-customgray display-flex"
            ></i>
          </template>
        </f7-list-input>
        <f7-list-input
          type="text"
          name="Login"
          required
          validate
          validate-on-blur
          :label="$t('Login')"
          :placeholder="$t('Login')"
          v-model:value.trim="Login"
        >
          <template #media>
            <i class="icon-customer size-20 text-color-yellow display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-item
          class="item-input custom-smart-select-wrapper"
          :title="$t('Country')"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            closeOnSelect: true,
            scrollToSelectedItem: true,
            formColorTheme: 'green',
          }"
          :key="'keyCountryList' + keyCountryList"
        >
          <template #media>
            <i class="icon-location size-20 text-color-blue display-flex"></i>
          </template>
          <select name="CountryList" v-model="CountryCode">
            <option
              v-for="country in CountryList"
              :key="country.CountryCode"
              :value="country.CountryCode"
            >
              {{ country.Country }}
            </option>
          </select>
        </f7-list-item>
        <!-- <f7-list-input
          type="text"
          name="State"
          required
          validate
          validate-on-blur
          :label="$t('StateProvince')"
          :placeholder="$t('StateProvince')"
          v-model:value.trim="State"
        >
          <template #media>
            <i class="icon-location size-20 text-color-green display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-input
          type="text"
          name="City"
          required
          validate
          validate-on-blur
          :label="$t('City')"
          :placeholder="$t('City')"
          v-model:value.trim="City"
        >
          <template #media>
            <i class="icon-location size-20 text-color-lilac display-flex"></i>
          </template>
        </f7-list-input> -->

        <f7-list-input
        type="text"
        name="ServiceProfile"
        required
        validate
        validate-on-blur
        :label="$t('ServiceProfile')"
        :placeholder="$t('ServiceProfile')"
        v-model:value.trim="ServiceProfile"
      >
        <template #media>
          <i class="icon-profile size-20 text-color-customgray display-flex"></i>
        </template>
      </f7-list-input>
      
      </f7-list>
      <f7-block >
        <f7-button @click="openSelectCustomer()" color="black" fill class="col">DISABLE</f7-button>
      </f7-block>
      <f7-block>
    
          <f7-button @click="onSubmit()" color="blue" fill class="col">SAVE</f7-button>
     
      </f7-block>
    </form>
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";
import {
 
  getCountries,
  getTimeZones,
  getTimeFormats,
  getLanguages,
  getSpeedUnitList,
  getCustomerTypes,
 
  getAdminPermissions,
 
  InstallerPermissionEnum,
  SortArrayByObjProps,
  AdminPermissionsEnum,
  Permissions2Enum,
} from "../../js/helpers";
import { QTAPI } from "../../services/qt-api";
import { mapGetters } from "vuex";
import EmailList from "../../components/email-list";

export default {
  name: "customer-edit",

  props: {
    f7router: Object,
    f7route: Object,
  },

  components: {
    EmailList,
  },

  data() {
    let UserInfoPreEdit = this.$store.getters.UserInfoPreEdit;

    return {
      inputSubmitId: f7.utils.id(),
      isLoading: false,
      isLoadingGetData: false,
      isLoadingSSP: false,

    

     
      CustomerTypeList: getCustomerTypes(),
      LanguageList: getLanguages(),
      CountryList: getCountries(),
      TimeFormatList: getTimeFormats(),
      TimeZoneList: getTimeZones(),
      SpeedUnitList: getSpeedUnitList(),
 
 
  
      AccountName: null,
      ParentName: null,
      FirstAccountName: null,
      SubAccountName: null,
      Email: null,
      Mobile: null,
      NumberCode: null,
      Login: null,
      CountryCode: null,
      State: null,
      ServiceProfile: null,


      LanguageCode: UserInfoPreEdit?.ContactInfo?.Language || "en",
     
      TimeFormatCode:
        UserInfoPreEdit?.ContactInfo?.TimeFormat || "dd/MM/yyyy HH:mm:ss",
      TimeZoneCode: "W. Australia Standard Time_8",
  
      FirstName: null,
      LastName: null,

     
      Phone: null,
      GroupCode: null,
      SpeedUnitCode: UserInfoPreEdit?.CustomerInfo?.SpeedUnit || "KPH",
      Address: null,
      Address1: null,
      City: null,
    
      Zipcode: null,
      AlarmEmails: null,
      //ActivationEmails: null,
      Credits: null,
      PaymentType: "NONE",
   
      UserCode: null,
      SecurityCode: null,
      CustomerCode: this.f7route.query.customerCode,
      isSolutionSyncSubAgent: false,
      isSendRegLetter: false,
      isLocalRenewal: false,
      isSendMonthlyAccountReport: true,


      organizeCode: null
    };
  },

  computed: {
    ...mapGetters(["IS_ROOT", "MajorToken", "MinorToken", "UserInfoPreEdit"]),
    phoneCode() {
      let country = this.CountryList.length
        ? this.CountryList.find((itm) => itm.CountryCode === this.CountryCode)
        : "";
      return country ? country.CountryPhoneCode : "";
    },
    isAgentOrDealer() {
      return (
        +this.CustomerTypeCode === +this.CustomerTypesEnum?.Agent ||
        +this.CustomerTypeCode === +this.CustomerTypesEnum?.Dealer
      );
    },
  },

  async mounted() {
    this.AdminPermissionList = SortArrayByObjProps(
      getAdminPermissions(this.IS_ROOT),
      [{ prop: "name", direction: 1 }]
    );

    

    this.getPreEditCustomerInfo();
  },

  methods: {
    async getPreEditCustomerInfo() {

      this.isLoadingGetData = true;
      if (!this.f7route.query.imsi) {
        f7.dialog.alert(this.$t("MissingIMSI", this.$t("Error")));
        return;
      }
      let data = {
        IMSIs: [this.f7route.query.imsi],
      };

      let response = await QTAPI.GetSimStatus(data);
      
      if (!response || !response.length) {
        this.isNoData = true;
        f7.dialog.alert(
          this.$t("IMSINotFound", { imsi: data.IMSIs }),
          this.$t("Error")
        );
        return;
      }
     
 
      this.organizeCode = response[0].OrgCode;
 
     let listQuery = {
        Page: 1,
        Rows: 50,
        Order: 'ASC',
        Sort: 'Name',
        Email: '',
        FirstName: '',
        SubName: '',
        Mobile: '',
        Name: response[0].OrgName ? response[0].OrgName : '',
      }
      let result = await QTAPI.GetSimOwners(listQuery);
      let customerList = result.rows   

      let customerData = customerList.find(itm => itm.Code === this.organizeCode)

      this.isLoadingGetData = false;

     // console.log('customerList', customerList)

      this.setCustomerInfo(customerData)
    },
    
    setCustomerInfo(customerInfo) {
      //console.log('customerInfo', customerInfo)
      this.AccountName = customerInfo?.Name;
      this.ParentName = customerInfo?.ParentName;
      this.FirstAccountName = customerInfo?.FirstName;
      this.SubAccountName = customerInfo?.SubName
      this.Email = customerInfo?.Email
      this.Mobile = customerInfo?.Mobile
      this.NumberCode = customerInfo?.Number
      this.Login = customerInfo?.Account
      this.CountryCode = customerInfo?.CountryCode
      this.ServiceProfile = customerInfo?.ServiceProfileName



      // this.CustomerTypeCode = customerInfo.Type;
      // //this.GlobalAgent = customerInfo?.GlobalAgentName?.length ? customerInfo?.GlobalAgentName : ''
      // this.ThemeCode = !customerInfo.ExtDoaminCode
      //   ? "0"
      //   : customerInfo.ExtDoaminCode;
      // this.WebsiteCode = customerInfo.WebSiteCode;
      // this.SpeedUnitCode = !customerInfo.SpeedUnit
      //   ? "KPH"
      //   : customerInfo.SpeedUnit;
      // this.SecurityCode = customerInfo.SecurityCode;

      // let addressArr = !customerInfo.Address
      //   ? []
      //   : customerInfo.Address.split("\n");
      // if (addressArr.length) {
      //   this.Address = addressArr[1];
      //   this.Address1 = addressArr[2];
      //   this.City = addressArr[3];
      //   this.State = addressArr[4];
      //   this.Zipcode = addressArr[5];
      // }

      // this.keyCustomerTypeList++;
      // this.keyWebsiteList++;
      // this.keySpeedUnitList++;
    },
 
  

    async onSubmit() {
      let uniqNum = f7.utils.id("xxxxxx");
      let data = {
        Code: this.organizeCode,
        Name: this.AccountName,
        ParentName: this.ParentName,
        FirstName: this.FirstAccountName,
        SurName: this.SubAccountName,
        Email: this.Email,
        Mobile: this.Mobile,
        Number: this.NumberCode,
        Account: this.Login,
        CountryCode: this.CountryCode,
        ServiceProfileCode: this.ServiceProfile,

       
        // Code: edbf42cb-3d02-43f4-91df-b0397a6d6bb0
        // ParentCode: 00000000-0000-0000-0000-000000000000
   
        // ServiceProfileName: Agent
        // WebSiteCode: 1
     
      
        // Language: en
        // TimeZoneCode: UTC
        // DateTimeFormat: MM-dd-YYYY HH:mm:ss
        // DistanceUnit: 0
        // VolumeUnit: 0
        // EconomyUnit: 0
        // PressureUnit: 0
        // TemperatureUnit: 0
        // LatlngFormat: 
        // ProvinceCode: Ontario
        // CityCode: Toronto
        // Address: 
        // AddressCode: 
        // State: 1
        // CreateTime: 2021-03-10T13:17:20
        // UserCode: edbf42cb-3d02-43f4-91df-b0397a6d6bb0
        // Token: 82a7bd84-7398-4b90-add1-128a87ca913f
   
 
      };

 
   

      this.isLoading = true;
      let result = await QTAPI.EditSimOwners(data);
      this.isLoading = false;
      if (!result) return;


      this.$store.commit("CUSTOMER_UPDATED");
      f7.notification
        .create({
          subtitle: this.$t("Success"),
          text: !this.CustomerCode
            ? this.$t("CustomerCreated", { name: data.Name })
            : this.$t("CustomerUpdated", { name: data.Name }),
        })
        .open();
      this.f7router.back();
    },
 
  },
};
</script>

<style scoped>

</style>