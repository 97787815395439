<template>
  <f7-page name="notification">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back">
      </f7-nav-left>
      <f7-nav-title sliding>{{pageTitle}}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon="f7-icons icon-menu2" popover-open=".popover-settings"></f7-link>
      </f7-nav-right>

    </f7-navbar>

    <f7-popover class="popover-settings">
      <f7-list>
        <f7-list-item @click="disableNotifications" link="#" :title="$t('DisableNotifications')" no-chevron popover-close>
          <template #media>
            <f7-icon icon="f7-icons text-color-lightgray">bell_slash_fill</f7-icon>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <notification-asset-status
        v-if="notificationData.alarm.toLowerCase() === 'status' || notificationData.alarm.toLowerCase() === 'config'"
        :notificationData="notificationData"
    />
    <notification-asset-position
        v-else
        :notificationData="notificationData"
    />
  </f7-page>
</template>

<script>
import {QTAPI} from "../../services/qt-api";
import {mapGetters} from "vuex";

import notificationAssetPosition from './components/asset-position'
import notificationAssetStatus from './components/asset-status'
import {f7} from "framework7-vue";

export default {
  name: "notification",
  components: {
    notificationAssetPosition,
    notificationAssetStatus
  },

  props: {
    f7router: Object,
    f7route: Object,

    notificationData: { type: Object, default: () => { return {} } },
  },

  data() {
    return {
      pageTitle: this.notificationData.AlertName ? this.notificationData.AlertName : this.notificationData.alarm,
      isLoading: false,
      //details: this.$f7route.context,

    }
  },

  computed: {
    ...mapGetters(['MinorToken'])
  },

  methods: {
    async disableNotifications() {
      this.isLoading = true
      let result = await QTAPI.SetNotificationStatus({
        IMEI: this.notificationData.Imei ? this.notificationData.Imei : this.notificationData.imei ? this.notificationData.imei : this.notificationData.IMEI,
        MinorToken: this.MinorToken,
        State: 0,
        MobileToken: !localStorage.PUSH_MOBILE_TOKEN ? '123' : localStorage.PUSH_MOBILE_TOKEN,
        AppKey: !localStorage.PUSH_APP_KEY ? '123' : localStorage.PUSH_APP_KEY,
        Token: !localStorage.PUSH_DEVICE_TOKEN ? '123' : localStorage.PUSH_DEVICE_TOKEN,
        Type: !localStorage.DEVICE_TYPE ? 'webapp' : localStorage.DEVICE_TYPE,
      })
      this.isLoading = false
      if(!result){
        return
      }
      f7.notification.create({
        subtitle: this.$t('Success'),
        text: this.$t('NotificationsDisabled', {name: this.notificationData.AssetName}),
      }).open()
    }
  }
}
</script>

<style scoped>

</style>