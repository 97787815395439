<template>

  <f7-list no-hairlines class="no-margin-top">
    <f7-list-item v-if="notificationData.AssetName" :title="$t('Name')" :after="notificationData.AssetName">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-assets"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Imei" :title="$t('IMEI')" :after="notificationData.Imei">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-barcode"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Imsi" :title="$t('IMSI')" :after="notificationData.Imsi">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-other-imei"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Direction" :title="$t('Direction')" :after="directionFormat(notificationData.Direction)">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-menu-geofence"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Relay" :title="$t('Relay')" :after="notificationData.Relay">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-status-relay"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Battery" :title="$t('Battery')" :after="notificationData.Battery">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-live-battery"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Power" :title="$t('Power')" :after="notificationData.Power">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-status-power"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.GPS" :title="$t('GPS')" :after="notificationData.GPS">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-other-gps"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.GSM" :title="$t('GSM')" :after="notificationData.GSM">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-other-gsm"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.GPRS" :title="$t('GPRS')" :after="notificationData.GPRS">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-other-gprs"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.Charger" :title="$t('Charger')" :after="notificationData.Charger">
      <template #media>
        <f7-icon slot="media" icon="f7-icons text-color-lightgray icon-live-battery"></f7-icon>
      </template>
    </f7-list-item>
    <f7-list-item v-if="notificationData.PARAMS" :after="$t('AdditionalInfo')"></f7-list-item>
    <li v-if="notificationData.PARAMS">
      <ul>
        <f7-list-item
            v-for="(value, key) in parsedParams"
            :key="key"
            :title="key"
            :after="value"></f7-list-item>
      </ul>
    </li>
  </f7-list>

</template>

<script>
/*import {QTAPI} from "../../services/qt-api";
import {mapGetters} from "vuex";*/

import {directionText, isJsonString} from "../../../js/helpers";

export default {
  name: "notification-asset-status",

  props: {
    notificationData: { type: Object, default: () => { return {} } },
  },

  data() {
    let parsedParams = isJsonString(this.notificationData.PARAMS)

    return {
      parsedParams: this.notificationData.PARAMS && parsedParams ? parsedParams : (this.notificationData.PARAMS) ? this.notificationData.PARAMS : false

    }
  },
  methods: {
    directionFormat(direction){
      return directionText(direction) + `(${direction}°)`
    },
  }
}
</script>

<style scoped>

</style>