<template>
  <f7-page name="command-history" class="bg-color-lightgray">
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="progressbar-top"
      key="1"
    ></f7-progressbar>
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back"> </f7-nav-left>
      <f7-nav-right>
        <f7-link @click="getCommands">
          <i class="icon f7-icons icon-refresh"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top class="toolbar-font-size-12">
      <f7-nav-title sliding>{{ $t("CommandHistory") }}</f7-nav-title>
    </f7-toolbar>

    <f7-list
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      v-if="isLoading && isNoData"
      class="skeleton-text skeleton-effect-wave"
    >
      <f7-list-item
        v-for="n in 4"
        :key="n"
        title="Title"
        after="after text"
        text="some very long text will go here"
      >
        <template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 50%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>

    <f7-block v-if="!isLoading && isNoData">
      <p>{{ $t("NoRecordsFound") }}</p>
    </f7-block>

    <f7-list
      ref="vl"
      class="vl vl-commands-history no-margin-top"
      virtual-list
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      :virtual-list-params="{ items, renderExternal, height: heightCalc }"
    >
      <ul>
        <f7-list-item
          v-for="(item, index) in vlData.items"
          link="#"
          :key="index"
          :style="`top: ${vlData.topPosition}px`"
          class="break-word-item-text bigger-item-text"
          @click="showSMS(item)"
        >
          <template #title>
            <i
              class="f7-icons size-16 margin-right-half"
              :class="[
                getSmsIcon(item.Direction),
                getSmsStateColor(item.State),
              ]"
            ></i>
            <span :class="getSmsStateColor(item.State)">{{
              getSmsStateText(item.State)
            }}</span>
          </template>
          <template #after>
            {{ timeByUserTimeZone(item.CreateTime) }}
          </template>
          <template #text>
            <div v-html="cleanMsgText(item.Message)"></div>
          </template>
        </f7-list-item>
      </ul>
    </f7-list>
  </f7-page>
</template>

<script>
import { QTAPI } from "../../../services/qt-api";

import { mapGetters } from "vuex";
//import {HideKeyboard, SortArrayByObjProps} from "../js/helpers";
import { f7, theme } from "framework7-vue";
import {
  dateFormat,
  SimSMSDirectionEnum,
  SimSMSStatusEnum,
} from "../../../js/helpers";
import moment from "moment-mini";

export default {
  name: "command-history",

  computed: {
    ...mapGetters(["MajorToken", "TargetAsset", "UserInfo"]),
  },

  props: {
    f7router: Object,
    f7route: Object,
  },

  data() {
    return {
      isLoading: false,
      isNoData: true,
      popupSMSData: null,
      SimSMSDirectionEnum,
      SimSMSStatusEnum,

      items: [],
      vlData: {
        items: [],
      },
      vl: null,
    };
  },

  mounted() {
    this.vl = f7.virtualList.get(".vl-commands-history");
    //this.getCommands()
    if (this.TargetAsset.IMSI) {
      this.getCommands();
    }
  },

  onPageBeforeRemove() {
    const self = this;
    // Destroy popup when page removed
    if (self.popupSMSData) self.popupSMSData.destroy();
  },

  methods: {
    loadMore(done) {
      setTimeout((el) => done(), 2000);
      this.getCommands();
    },
    async getCommands() {
      this.isLoading = true;
      let list = [];
      let page = 1;
      let isLast = false;
      let requestLimit = 100;
      let pageSize = 100;


      while (!isLast) {
        let result = await QTAPI.GetSmsHistory({
          IMSI: this.TargetAsset.IMSI,
          Page: page,
          PageSize: pageSize,
        });
        if (!result || !Array.isArray(result)) {
          isLast = true;
          break;
        }
        page++;
        if (result.length) {
          list = [...list, ...result];
          console.log('list', list)
        }
        if (
          !result.length ||
          result.length < pageSize ||
          page === requestLimit
        ) {
          isLast = true;
        }
      }

      this.isLoading = false;
      this.isNoData = !list.length;
      if (list.length) {
        list.sort(
          (a, b) =>
            moment(b.CreateTime).valueOf() - moment(a.CreateTime).valueOf()
        );
      }
      this.vl.replaceAllItems(list);
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    heightCalc(item) {
      if (item.Message?.length < 46) {
        return theme.ios ? 64 : 72;
      }
      return theme.ios ? 86 : 92;
    },
    dateFormat(...options) {
      return dateFormat(...options);
    },
    cleanMsgText(msg) {
      return msg?.replace(/</g, "&lt;")?.replace(/>/g, "&gt;");
    },
    timeByUserTimeZone(time) {
      // let isMinus = this.UserInfo.TimeZone.toString().indexOf("-");
      // let newTimeZone = "";

      // if (isMinus != -1) {
      //   newTimeZone = "-";
      // } else {
      //   newTimeZone = "+";
      // }

      // let absTimeZone = Math.abs(this.UserInfo.TimeZone)
      //   .toString()
      //   .padStart(2, "0");
      // newTimeZone = newTimeZone + absTimeZone + "00";

      return moment(time)
        // .utc(time, "YYYY-MM-DDTHH:mm:ss")
        // .zone(newTimeZone)
        .format("DD/MM/YYYY HH:mm:ss");

      // return moment(time,'YYYY-MM-DDTHH:mm:ss').utc().utcOffset(userTz ).format('DD/MM/YYYY HH:mm:ss');
    },
    getSmsStateColor(state) {
      let colors = {};
      colors[SimSMSStatusEnum.Error] = "text-color-red";
      colors[SimSMSStatusEnum.Sent] = "text-color-lightblue";
      colors[SimSMSStatusEnum.Submitted] = "text-color-orange";
      colors[SimSMSStatusEnum.Delivered] = "text-color-green";
      colors[SimSMSStatusEnum.Received] = "text-color-blue";
      return !colors[state] ? "" : colors[state];
    },
    getSmsStateText(state) {
      let textes = {};
      textes[SimSMSStatusEnum.Error] = this.$t("Error");
      textes[SimSMSStatusEnum.Sent] = this.$t("Sent");
      textes[SimSMSStatusEnum.Submitted] = this.$t("Submitted");
      textes[SimSMSStatusEnum.Delivered] = this.$t("Delivered");
      textes[SimSMSStatusEnum.Received] = this.$t("Received");
      return !textes[state] ? this.$t("Unknown") : textes[state];
    },
    getSmsIcon(direction) {
      let icons = {};
      icons[SimSMSDirectionEnum.Outbound] = "icon-mail";
      icons[SimSMSDirectionEnum.Inbound] = "icon-sendcommand";
      return !icons[direction] ? "" : icons[direction];
    },
    showSMS(item) {
      const self = this;
      // Create popup
      if (self.popupSMSData) {
        self.popupSMSData.destroy();
      }
      self.popupSMSData = f7.popup.create({
        content: `
            <div class="popup">
              <div class="page">
                <div class="navbar">
                  <div class="navbar-bg"></div>
                  <div class="navbar-inner">
                    <div class="title">${item.IMSI}</div>
                    <div class="right"><a href="#" class="link popup-close">${this.$t(
                      "Close"
                    )}</a></div>
                  </div>
                </div>
                <div class="page-content">
                  <div class="list media-list no-hairlines bigger-item-text">
                  <ul>
                    <li class="item-content">
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">
                            <i class="f7-icons size-16 margin-right-half ${this.getSmsIcon(
                              item.Direction
                            )} ${this.getSmsStateColor(item.State)}"></i>
                            <span class="${this.getSmsStateColor(
                              item.State
                            )}">${this.getSmsStateText(item.State)}</span>
                           </div>
                           <div class="item-after">
                            ${dateFormat(item.CreateTime, "datetime")}
                           </div>
                        </div>
                        <div class="item-text word-break-break-word">
                          ${self.cleanMsgText(item.Message)}
                        </div>
                      </div>
                    </li>
                  </ul>
                  </div>
                </div>
              </div>
            </div>
          `.trim(),
      });

      // Open it
      self.popupSMSData.open();
    },
  },
};
</script>

<style>

 .vl-commands-history.media-list .item-link .item-title-row:before, li.media-item .item-link .item-title-row:before {
  display: none
}

.media-list.vl-commands-history .item-link .item-title-row, li.media-item .item-link .item-title-row {
  padding-right: 0;
}
</style>