<template>
  <div>
    <f7-progressbar
      v-if="isLoading"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-list
      media-list
      no-hairlines
      no-chevron
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      form
      v-if="isLoading"
      class="skeleton-text skeleton-effect-wave"
      key="simHlrInfoLoadSkeleton"
    >
      <f7-list-item v-for="n in 4" :key="n" header="Header" title="Title">
      </f7-list-item>
    </f7-list>

    <f7-block v-else-if="!isLoading && isNoData">
      <p>{{ $t("NoDataFound") }}</p>
    </f7-block>

    <f7-list
      v-else
      media-list
      no-hairlines
      no-chevron
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
      form
      class="no-margin-top"
    >
      <f7-list-item
        class="connectivity-list"
        v-for="(item, index) in hlrList"
        :key="`key-hlr-${index}`"
        :title="item.value"
        :header="item.displayAs"
      >
      <template #media>
        <i :class="`icon-${item.icon} size-20 text-color-${item.color} display-flex`"></i>
      </template>
      </f7-list-item>
    </f7-list>
    <f7-block :style="isNoData ? 'position: absolute; bottom: 60px; width: 100%' : ''">
      <f7-button  @click="forceReconnect" color="blue" fill class="col">REFRESH SIM</f7-button>
    </f7-block>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { QTAPI } from "../../../../services/qt-api";
import { f7 } from "framework7-vue";
import {dateFormat} from "../../../../js/helpers";
export default {
  name: "sim-hlr-info",

  data() {
    return {
      isLoading: false,
      isNoData: false,
      hlrList: [],
    };
  },

  computed: {
    ...mapGetters(["TargetAsset"]),
  },

  mounted() {
    if(this.TargetAsset.IMSI?.toString().startsWith('234')){
      this.getHlrInfo()
    }else{
      this.getHlrInfo2()
    }
  },

  methods: {
    async forceReconnect() {
      this.isLoading = true;

      let result = await QTAPI.ForceReconnect({
        IMSIs: [this.TargetAsset.IMSI],
      });

      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: "Session refreshed",
          })
          .open();  
      }

    },
    async getHlrInfo2() {
      this.isLoading = true;
      let result = await QTAPI.GetSimDiagnostics({ imsi: this.TargetAsset.IMSI });
      this.isLoading = false;
      if (!result) {
        this.isNoData = true;
        return;
      }

      console.log('r',result)
      /*deviceName
imei
inDataSession
lastCountry
lastNetworkActivity
latitude
longitude
radius 312300004982152*/
      let resList = [
        {
          name: "IMSI", //hlr name
          value: this.TargetAsset.IMSI,
          displayAs: this.$t("IMSI"),
          icon: 'imsi',
          color: 'blue'
        },
        {
          name: "MSISDN", //hlr name4
          value: this.TargetAsset.MSISDN,
          displayAs: this.$t("MSISDN"),
          icon: 'imsi',
          color: 'lilac'
        },
        {
          name: "VLR number", //hlr name2
          value: "",
          displayAs: this.$t("VisitorLocationRegister"),
          icon: 'phone',
          color: 'green'
        },
        {
          name: "Packet Switched Up Time", //hlr name1 Packet Switched Up Time
          value: dateFormat(result.lastNetworkActivity, 'datetime'),
          displayAs: this.$t("DataConnectUpTime"),
          icon: 'date',
          color: 'red'
        },
        {
          name: "Circuit Switch Up Time", //hlr name3 Circuit Switch Up Time
          value: "",
          displayAs: this.$t("SMSConnectUpTime"),
          icon: 'mail',
          color: 'orange'
        },
        {
          name: "MME Realm", //hlr name5 EPCMMERealm
          value: "",
          displayAs: "MME Realm", //this.$t('EPCMMERealm'),
          icon: 'status',
          color: 'aqua'
        },
        {
          name: "SGSN number", //hlr name6
          value: "",
          displayAs: "SGSN number",
          icon: 'phone',
          color: 'customgray'
        },
      ];
      this.hlrList = resList;
    },
    async getHlrInfo() {
      this.isLoading = true;
      let result = await QTAPI.GetHLRInfo({ imsi: this.TargetAsset.IMSI });
      this.isLoading = false;
      if (!result) {
        this.isNoData = true;
        return;
      }

      let resList = [
        {
          name: "IMSI", //hlr name
          value: "",
          displayAs: this.$t("IMSI"),
          icon: 'imsi',
          color: 'blue'
        },
        {
          name: "MSISDN", //hlr name4
          value: "",
          displayAs: this.$t("MSISDN"),
          icon: 'imsi',
          color: 'lilac'
        },
        {
          name: "VLR number", //hlr name2
          value: "",
          displayAs: this.$t("VisitorLocationRegister"),
          icon: 'phone',
          color: 'green'
        },
        {
          name: "Packet Switched Up Time", //hlr name1 Packet Switched Up Time
          value: "",
          displayAs: this.$t("DataConnectUpTime"),
          icon: 'date',
          color: 'red'
        },
        {
          name: "Circuit Switch Up Time", //hlr name3 Circuit Switch Up Time
          value: "",
          displayAs: this.$t("SMSConnectUpTime"),
          icon: 'mail',
          color: 'orange'
        },
        {
          name: "MME Realm", //hlr name5 EPCMMERealm
          value: "",
          displayAs: "MME Realm", //this.$t('EPCMMERealm'),
          icon: 'status',
          color: 'aqua'
        },
        {
          name: "SGSN number", //hlr name6
          value: "",
          displayAs: "SGSN number",
          icon: 'phone',
          color: 'customgray'
        },
      ];
      let arr1 = [0, 1, 2, 5, 6];
      let arr2 = [3, 4];
      //let arr3 = [1,5]

      for (const element of arr1) {
        let obj = result.dataMap.find((el) => el.key === resList[element].name);
        resList[element].value = !obj || !obj.value ? this.$t("na") : obj.value;
      }
      for (const element of arr2) {
        let dataStr = result.dataMap.find(
          (el) => el.key === resList[element].name
        )?.value;
        resList[element].value =
          !dataStr || dataStr === "00000000000000"
            ? ""
            : dataStr.slice(0, 4) +
              "-" +
              dataStr.slice(4, 6) +
              "-" +
              dataStr.slice(6, 8) +
              " " +
              dataStr.slice(8, 10) +
              ":" +
              dataStr.slice(10, 12) +
              ":" +
              dataStr.slice(12, 14);
      }
      /*for (const element of arr3) {
        let obj = result.dataMap.find(el => el.key === resList[element].name)
        resList[element].value = !obj || !obj.value ? this.$t('na') : obj.value
      }*/
      this.hlrList = resList;
    },
  },
};
</script>

<style>

.connectivity-list .item-inner {
  margin: 0 !important;
}
</style>