<template>
  <f7-page name="send-command" class="bg-color-lightgray">
    <f7-progressbar
      v-if="isLoading"
      color="green"
      infinite
      class="progressbar-top"
      key="1"
    ></f7-progressbar>
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back"> </f7-nav-left>
      <!-- <f7-nav-right>
        <label :for="inputSubmitId" class="link icon-only">
          <i class="icon f7-icons">checkmark_alt</i>
        </label>
      </f7-nav-right> -->
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link v-tooltip="'Dashboard'"  href="/dashboard/tab-dashboard/" icon-only tab-link="#tab-1">
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link v-tooltip="'SIM List'"  href="/sim-list/" icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link v-tooltip="'Commands'"  href="/commands/" icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link v-tooltip="'Users'"  href="/users/" icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link v-tooltip="'Summary'"  href="/summary/" icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>
    <f7-toolbar position="top" class>
      <f7-nav-title sliding>{{ $t("Commands") }}</f7-nav-title>
    </f7-toolbar>

    <form @submit.prevent="onSubmit">
      <input type="submit" :id="inputSubmitId" hidden />
      <f7-list
        class="no-margin-top no-hairline-bottom"
        media-list
        no-hairlines
        no-hairlines-between
        dividers-ios
        strong
        outline
        inset
      >
        <f7-list-input
          type="text"
          :label="$t('ICCID')"
          :placeholder="$t('ICCID')"
          class="disabled"
          :value="f7route.query.iccid"
        >
          <template #media>
            <i class="icon-imsi size-20 text-color-blue display-flex"></i>
          </template>
        </f7-list-input>
        <f7-list-input
          type="text"
          :label="$t('IMSI')"
          :placeholder="$t('IMSI')"
          class="disabled"
          :value="f7route.query.imsi"
        >
          <template #media>
            <i class="icon-imsi size-20 text-color-lilac display-flex"></i>
          </template>
        </f7-list-input>
        <f7-list-input
          type="text"
          :label="$t('CSP')"
          :placeholder="$t('CSP')"
          value="M2M Data / Optimus Profile"
          class="disabled"
        >
          <template #media>
            <i class="icon-profile size-20 text-color-green display-flex"></i>
          </template>
        </f7-list-input>

        <f7-list-item
          class="item-input custom-smart-select-wrapper commands-select"
          :title="$t('Command')"
          smart-select
          :smart-select-params="{
            openIn: 'popup',
            searchbar: true,
            closeOnSelect: true,
            formColorTheme: 'green',
          }"
          :after="$t('SelectOption')"
          required
          validate
        >
          <template #media>
            <i
              class="icon-other-commands size-20 text-color-orange display-flex"
            ></i>
          </template>

          <select
            class="command-options"
            inset
            name="SimCommands"
            v-model="CommandCode"
          >
            <option
              v-for="command in SimCommands"
              :key="command.Code"
              :value="command.Code"
            >
              {{ command.Name }}
            </option>
          </select>
        </f7-list-item>
        <template v-if="CommandCode == 'custom'" 
        :class="`p-col-12 p-sm-12`">
          <f7-list-input
            type="text"
            :label="'Message'"
            :placeholder="$t('Type a command')"
            v-model:value="custom"
          >
            <template #media>
              <i class="size-20 icon-mail text-color-red"></i>
            </template>
          </f7-list-input>
        </template>
        <f7-list-item
          v-if="isShowViaMSISDN"
          checkbox
          v-model:checked="SMSviaMSISDN"
          title="Send command as SMS via MSISDN"
        ></f7-list-item>
      </f7-list>
    </form>
    <f7-block class="submit-btn" id="submit-btn">
      <f7-button @click="onSubmit" class="col" color="blue" fill
        >SEND</f7-button
      >
    </f7-block>
  </f7-page>
</template>

<script>
//import VConsole from "vconsole";
import { mapGetters } from "vuex";
import { SortArrayByObjProps } from "../../js/helpers";
import { f7 } from "framework7-vue";
import { QTAPI } from "../../services/qt-api";

export default {
  name: "send-command",

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "SimCommands", "Permissions"]),
  },

  props: {
    f7router: Object,
    f7route: Object,
  },

  data() {
    return {
      isLoading: false,
      inputSubmitId: f7.utils.id(),
      custom: "",
      CommandCode: null,
      SMSviaMSISDN: false,
      isShowViaMSISDN: true
    };
  },

  mounted() {
    //const vConsole = new VConsole();
    document.addEventListener(
      "deviceready",
      function () {
        window.addEventListener("keyboardWillShow", function () {
          document.getElementById("submit-btn").style.display = "none";
        });

        window.addEventListener("keyboardWillHide", function () {
          document.getElementById("submit-btn").style.display = "block";
        });
      },
      false
    );


    this.isShowViaMSISDN = this.Permissions.SMS_SEND_MSISDN > 0
  },

  watch: {},

  methods: {
    checkSMSPermission() {
      var success = (hasPermission) => {
        if (hasPermission) {
          this.sendSMSMessage();
        } else {
          this.requestSMSPermission();
        }
      };
      var error = (e) => alert("Something went wrong:" + e);
      window.sms.hasPermission(success, error);
    },
    requestSMSPermission() {
      var success = (hasPermission) => {
        if (!hasPermission) {
          window.sms.requestPermission(
            () => {
              console.log("[OK] Permission accepted");
              this.sendSMSMessage();
            },
            (error) => {
              console.info("[WARN] Permission not accepted");
              console.log("window.sms", window.sms);
              console.log("cordova", cordova);
              console.log("sms", sms);
              // Обработка случая, когда разрешение не получено
            }
          );
        }
      };
      var error = (e) => alert("Something went wrong:" + e);
      window.sms.hasPermission(success, error);
    },
    sendSMSMessage() {
      let simNumber = "+" + this.f7route.query.iccid;
      let message = "";
      if (this.CommandCode == "custom") {
        message = this.custom;
      } else {
        message = this.CommandCode;
      }

      console.log(simNumber, message);
      window.sms.send(
        simNumber,
        message,
        { replaceLineBreaks: true, android: { intent: "" } },
        () => {
          f7.notification
            .create({
              subtitle: this.$t("Success"),
              text: this.$t("CommandSent"),
            })
            .open();
        },
        (error) => {
          console.error("Error sending SMS:", error);
          f7.dialog.alert("Command was not sent", "M2M Data Controller");
        }
      );
    },
    async onSubmit() {
      let self = this;
      if (this.SMSviaMSISDN) {
        f7.dialog.confirm(
          "This is a paid service. The price depends on the conditions of your telephone operator.",
          "Send Command as SMS",
          async (dialog) => {
            self.checkSMSPermission();
          }
        );
        return;
      }

      this.isLoading = true;

      if (this.CommandCode == "custom") {
        let query = {
          IMSI: this.f7route.query.imsi,
          SMS: this.custom,
        };
        if (!this.custom.length) {
          f7.dialog.alert("Custom field is empty", "M2M Data Controller");
          this.isLoading = false;
          return;
        }
        let assetsToSend = query.SMS.split("\r\n")
          .join("@@@separator@@@")
          .split("\n")
          .join("@@@separator@@@")
          .split("<br>")
          .join("@@@separator@@@")
          .split("@@@separator@@@");

        assetsToSend.forEach(async (subEl) => {
          const result = await fetch(
            "https://newapi.quiktrak.co/QuikData/v1/SMS/M2mSendSMS?deviceId=" +
              query.IMSI +
              "&message=" +
              encodeURIComponent(subEl)
          ).catch((e) => {
            console.log(e);
            f7.dialog.alert("Command was not sent", "M2M Data Controller");
            return;
          });

          this.isLoading = false;

          const jsonResult = await result.json();
          if (jsonResult.MajorCode == "000") {
            f7.notification
              .create({
                subtitle: this.$t("Success"),
                text: this.$t("CommandSent"),
              })
              .open();
            this.f7router.back();
          } else {
            f7.dialog.alert("Command was not sent", "Alert");
            return;
          }
        });
      } else {
        let response = await QTAPI.SimSendCommand({
          IMSI: this.f7route.query.imsi,
          SMSType: this.CommandCode,
          CustomerCode: this.MajorToken,
        });
        this.isLoading = false;
        if (!response) {
          return;
        }

        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("CommandSent"),
          })
          .open();
        this.f7router.back();
      }
      this.custom = "";
    },
  },
};
</script>

<style>
.submit-btn {
  position: absolute;
  bottom: calc(var(--f7-toolbar-height) + var(--f7-safe-area-bottom) + 20px);
  width: 100%;
}

[data-select-name="SimCommands"] li:not(.login-list-input) {
  margin: 10px 16px 5px 16px;
}

[data-select-name="SimCommands"] .page-content {
  background-color: #f0f2f5;
}

.list ul:before {
  display: none;
}
</style>