<template>
  <f7-popup
    tablet-fullscreen
    class="bg-color-lightgray"
    v-model:opened="isOpened"
    @popup:closed="onHide"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          <i class="icon-back size-20"></i>
        </f7-link>
      </f7-nav-left>

      <f7-nav-right>
        <f7-link @click.stop="copyIMSI(simData.IMSI)">
          <i class="icon-copy size-20"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link
        href="/dashboard/tab-dashboard/"
        popup-close
        icon-only
        tab-link="#tab-1"
      >
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link href="/sim-list/" popup-close icon-only tab-link="#tab-2">
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link href="/commands/" popup-close icon-only tab-link="#tab-3">
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link href="/users/" popup-close icon-only tab-link="#tab-4">
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link href="/summary/" popup-close icon-only tab-link="#tab-5">
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-actions-group>
      <f7-actions-label
        class="font-weight-bold size-22 text-color-black no-padding-top"
        >{{ simData.IMSI }}</f7-actions-label
      >
    </f7-actions-group>

    <div v-if="isShowSimStatus" class="sim-menu row">
      <div class="col-50 sim-menu__item" @click.stop="openSimStatus">
        <div class="actions-icon bg-color-lilac">
          <i class="icon-status size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("SimStatus") }}</span>
      </div>

      <div class="col-50 sim-menu__item" @click.stop="openCustomerDetails">
        <div class="actions-icon bg-color-orange">
          <i class="icon-customer size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("CustomerDetails") }}</span>
      </div>

      <div
        v-if="isShowCommands"
        class="col-50 sim-menu__item padding-top"
        @click.stop="openCommands"
      >
        <div class="actions-icon bg-color-blue">
          <i class="icon-sendcommand size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("SendCommand") }}</span>
      </div>

      <div
        v-if="isShowCommandsHistory"
        class="col-50 sim-menu__item padding-top"
        @click.stop="openCommandsHistory"
      >
        <div class="actions-icon bg-color-customgray">
          <i class="icon-mail size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("CommandHistory") }}</span>
      </div>

      <div
        v-if="isShowConnectivity"
        class="col-50 sim-menu__item padding-top"
        @click.stop="openConnectivity"
      >
        <div class="actions-icon bg-color-yellow">
          <i class="icon-data size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("Connectivity") }}</span>
      </div>

      <div
        class="col-50 sim-menu__item padding-top"
        @click.stop="forceReconnect"
      >
        <div class="actions-icon bg-color-brown">
          <i class="icon-refresh size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("ResetConnection") }}</span>
      </div>

      <div
        class="col-50 sim-menu__item padding-top"
        @click.stop="openActivationDialog"
      >
        <div class="actions-icon bg-color-green">
          <i class="icon-activation size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("Activation") }}</span>
      </div>

      <div
        class="col-50 sim-menu__item padding-top"
        @click.stop="openDeactivationDialog"
      >
        <div class="actions-icon bg-color-red">
          <i
            class="icon-deactivation size-20 text-color-white display-flex"
          ></i>
        </div>
        <span>{{ $t("Deactivation") }}</span>
      </div>

      <div class="col-50 sim-menu__item padding-top" @click.stop="openLocation">
        <div class="actions-icon bg-color-aqua">
          <i class="icon-location size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("Location") }}</span>
      </div>

      <div
        class="col-50 sim-menu__item padding-top"
        @click.stop="openServiceProfile()"
      >
        <div class="actions-icon bg-color-lilac">
          <i class="icon-profile size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("ServiceProfile") }}</span>
      </div>

      <div class="col-50 sim-menu__item padding-top" @click.stop="transferSIM">
        <div class="actions-icon bg-color-orange">
          <i class="icon-transfer size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("TransferSIM") }}</span>
      </div>

      <div
        v-if="isShowReports"
        class="col-50 sim-menu__item padding-top"
        @click.stop="openDataUsage"
      >
        <div class="actions-icon bg-color-blue">
          <i class="icon-data size-20 text-color-white display-flex"></i>
        </div>
        <span>{{ $t("DataUsage") }}</span>
      </div>
    </div>
  </f7-popup>

  <!-- <select-csp
    v-model:isOpened="isSelectCSPOpened"
    :simData="simData"
    :CSPList="CSPList"
  />   -->
</template>

<script>
import {
  openExternalUrl,
  AdminPermissionsEnum,
  SortArrayByObjProps,
  CustomerTypes,
} from "../../../js/helpers";
import { f7 } from "framework7-vue";
import { mapGetters } from "vuex";
import { ApiMethods, QTAPI } from "../../../services/qt-api";
import moment from "moment-mini";
import clipboardCopy from "clipboard-copy";
//import SelectCsp from "./select-csp"

export default {
  name: "sim-menu",

  props: {
    simData: {
      type: Object,
      default: () => {
        return {
          startY: 0,
          isShowCommands: true,
          isShowCommandsHistory: true,
          isShowSimStatus: true,
          isShowReports: true,
          isShowConnectivity: true,
          // isSelectCSPOpened: false,
          // keyCSPList: 1,
          // CSPList: [],
        };
      },
    },
    isOpened: { type: Boolean, default: false },
  },

  emits: ["update:isOpened"],

  data() {
    return {
      Permission: null,
    };
  },
  components: {
    // SelectCsp
  },

  computed: {
    ...mapGetters([
      "MajorToken",
      "MinorToken",
      "UserInfo",
      "SimCommands",
      "Permissions",
    ]),
  },

  methods: {
    async activateSim(spc) {
      f7.preloader.show();
      let result = {}
      if(this.simData.IMSI?.toString()?.startsWith('234')){
        result = await QTAPI.SimSetActivate({
          IMSIs: [this.simData.IMSI],
          ServiceProfileCode: spc,
        });
      }else{
        result = await QTAPI.SimSetActivate2({
          IMSIs: [this.simData.IMSI],
          ServiceProfileCode: spc,
        });
      }
      f7.preloader.hide();
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();
        //  
        f7.views.main.router.refreshPage();
        f7.popup.close();
      }
    },
    // async getCSP() {

    //   let csp = await QTAPI.GetCSP({});

    //   if (!csp) {
    //     return;
    //   }
    //   this.CSPList = csp.Data;

    //   this.simCSP = this.CSPList.find(item => item.Code === this.simData?.ServiceProfileCode)

    //   this.keyCSPList++;
    // },
    // openSelectCSP() {
    //   this.isSelectCSPOpened = true;
    // },
    async openActivationDialog() {
      let self = this;
      let data = {
        IMSIs: [this.simData.IMSI],
      };
      f7.dialog
        .create({
          title: "Activation Option",
          content: ` <div class="list no-hairlines-md">
              <ul class="bg-color-white">
                <li>
                  <label class="item-radio item-content">
                    <input type="radio" name="activation-option" value="activate" checked="checked"/>
                    <i class="icon icon-radio"></i>
                    <div class="item-inner">
                      <div class="item-title">Activate</div>
                    </div>
                  </label>
                </li>
                <li>
                  <label class="item-radio item-content">
                    <input type="radio" name="activation-option" value="activate-testproductive"/>
                    <i class="icon icon-radio"></i>
                    <div class="item-inner">
                      <div class="item-title">Activate to TestProductive</div>
                    </div>
                  </label>
                </li>
                <li>
                  <label class="item-radio item-content">
                    <input type="radio" name="activation-option" value="resume"/>
                    <i class="icon icon-radio"></i>
                    <div class="item-inner">
                      <div class="item-title">Resume</div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>`,
          buttons: [
            {
              text: "Cancel",
            },
            {
              text: "OK",
              bold: true,
              onClick: async function (dialog, e) {
                var selectedValue = dialog.$el
                  .find('input[type="radio"]:checked')
                  .val();

                switch (selectedValue) {
                  case "activate":
                   
                    
                    f7.dialog
                      .create({
                        cssClass: "csp-dialog-select",
                        title: "CSP",
                        text: "Please select CSP to activate",
                        buttons: [
                          {
                            text: "M2M Data",
                            onClick: function () {
                              self.activateSim("M2M Data");
                            },
                          },
                          {
                            text: "M2M Data Normal CSP Z1 &2",
                            onClick: function () {
                              self.activateSim("M2M Data Normal CSP Z1 &2");
                            },
                          },
                          {
                            text: "M2M Data High CSP Z1-3",
                            onClick: function () {
                              self.activateSim("M2M Data High CSP Z1-3");
                            },
                          },
                          {
                            text: "M2M Data Emergency CSP Z1- 9",
                            onClick: function () {
                              self.activateSim("M2M Data Emergency CSP Z1- 9");
                            },
                          },
                          {
                            text: "M2M Data Custom Profile Tier 1",
                            onClick: function () {
                              self.activateSim("M2M Data Custom Profile Tier 1");
                            },
                          },
                          {
                            text: "M2M Data Telstra Only",
                            onClick: function () {
                              self.activateSim("M2M Data Telstra Only");
                            },
                          },
                          {
                            text: "Cancel Activation",
                            onClick: function () {
                              f7.dialog.close();
                            },
                          },
                        ],
                        verticalButtons: true,
                      })
                      .open();
                   
                    
                    break;
                  case "activate-testproductive":
                    f7.preloader.show();
                    let result2 = {}
                    if(self.simData.IMSI?.toString()?.startsWith('234')){
                        result2 = await QTAPI.SimSetTest({
                        IMSIs: [self.simData.IMSI],
                        ServiceProfileCode: "M2M Data High CSP Z1-3",
                      });
                    }else{
                        result2 = await QTAPI.SimSetTest2({
                        IMSIs: [self.simData.IMSI],
                        ServiceProfileCode: "M2M Data High CSP Z1-3",
                      });
                    }
                    f7.preloader.hide();
                    if (result2) {
                      f7.notification
                        .create({
                          subtitle: self.$t("Success"),
                          text: "Success! SIM state will be changed soon.",
                        })
                        .open();
                      //
                      f7.views.main.router.refreshPage();
                      f7.popup.close();
                    } else {
                      f7.notification
                        .create({
                          //subtitle: self.$t("Success"),
                          text: "Currently this state is not available for this SIM.",
                        })
                        .open();
                    }
                    break;
                  case "resume":
                    f7.preloader.show();
                    let result3 = {}
                    if(self.simData.IMSI?.toString()?.startsWith('234')){
                      result3 = await QTAPI.SimSetResume({
                        IMSIs: [self.simData.IMSI],
                        ServiceProfileCode: "M2M Data High CSP Z1-3",
                      });
                    }else{ 
                      result3 = await QTAPI.SimSetResume2({
                        IMSIs: [self.simData.IMSI],
                        ServiceProfileCode: "M2M Data High CSP Z1-3",
                      });
                    }
                    f7.preloader.hide();
                    if (result3) {
                      f7.notification
                        .create({
                          subtitle: self.$t("Success"),
                          text: "Success! SIM state will be changed soon.",
                        })
                        .open();
                      //
                      f7.views.main.router.refreshPage();
                      f7.popup.close();
                    } else {
                      f7.notification
                        .create({
                          //subtitle: self.$t("Success"),
                          text: "Currently this state is not available for this SIM.",
                        })
                        .open();
                    }
                    break;
                }

                dialog.close();
              },
            },
          ],
        })
        .open();
    },
    openDeactivationDialog() {
      let self = this;
      let data = {
        IMSIs: [this.simData.IMSI],
      };
      f7.dialog
        .create({
          title: "Deactivation Option",
          content: ` <div class="list no-hairlines-md">
              <ul class="bg-color-white">
                <li>
                  <label class="item-radio item-content">
                    <input type="radio" name="deactivation-option" value="terminate" checked="checked"/>
                    <i class="icon icon-radio"></i>
                    <div class="item-inner">
                      <div class="item-title">Terminate</div>
                    </div>
                  </label>
                </li>
                <li>
                  <label class="item-radio item-content">
                    <input type="radio" name="deactivation-option" value="suspend"/>
                    <i class="icon icon-radio"></i>
                    <div class="item-inner">
                      <div class="item-title">Suspend</div>
                    </div>
                  </label>
                </li>
              
              </ul>
            </div>`,
          buttons: [
            {
              text: "Cancel",
            },
            {
              text: "OK",
              bold: true,
              onClick: async function (dialog, e) {
                var selectedValue = dialog.$el
                  .find('input[type="radio"]:checked')
                  .val();

                switch (selectedValue) {
                  case "terminate":
                    f7.preloader.show();
                    let result = {}
                    if(self.simData.IMSI?.toString()?.startsWith('234')){
                      result = await QTAPI.SimSetTerminate({
                        IMSIs: [self.simData.IMSI],
                      });
                    }else{
                      result = await QTAPI.SimSetTerminate2({
                        IMSIs: [self.simData.IMSI],
                      });
                    }
                    f7.preloader.hide();
                    if (result) {
                      f7.notification
                        .create({
                          subtitle: self.$t("Success"),
                          text: "Success! SIM state will be changed soon.",
                        })
                        .open();
                      //
                      f7.views.main.router.refreshPage();
                      f7.popup.close();
                    } else {
                      f7.notification
                        .create({
                          text: "Currently this state is not available for this SIM.",
                        })
                        .open();
                    }
                    break;
                  case "suspend":
                    f7.preloader.show();
                    let result2 = {}
                    if(self.simData.IMSI?.toString()?.startsWith('234')){
                      result2 = await QTAPI.SimSetSuspend({
                        IMSIs: [self.simData.IMSI],
                      });
                    }else{
                      result2 = await QTAPI.SimSetSuspend2({
                        IMSIs: [self.simData.IMSI],
                      });
                    }
                    
                    f7.preloader.hide();
                    if (result2) {
                      f7.notification
                        .create({
                          subtitle: self.$t("Success"),
                          text: "Success! SIM state will be changed soon.",
                        })
                        .open();
                      //
                      f7.views.main.router.refreshPage();
                      f7.popup.close();
                    } else {
                      f7.notification
                        .create({
                          //subtitle: self.$t("Success"),
                          text: "Currently this state is not available for this SIM.",
                        })
                        .open();
                    }
                    break;
                }

                dialog.close();
              },
            },
          ],
        })
        .open();
    },
    copyIMSI(imsi) {
      clipboardCopy(imsi)
        .then(() => {
          f7.toast
            .create({
              text: "IMSI copied to clipboard",
              position: "center",
              closeTimeout: 2000,
            })
            .open();
        })
        .catch((error) => {
          console.error("Copy error", error);
        });
    },
    async forceReconnect() {
      this.isLoading = true;

      let result = await QTAPI.ForceReconnect({
        IMSIs: [this.simData.IMSI],
      });

      this.isLoading = false;
      if (result) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: "Session refreshed",
          })
          .open();
      }
    },

    transferSIM() {
      this.onHide();
      f7.views.main.router.navigate(
        {
          name: "transfer-sim",
          query: { imsi: this.simData.IMSI },
        },
        {
          // props: { simData: this.simData },
        }
      );
    },
    openDataUsage() {
      this.onHide();
      f7.views.main.router.navigate(
        {
          name: "data-usage",
          query: { imsi: this.simData.IMSI },
        },
        {
          // props: { simData: this.simData },
        }
      );
    },
    openSimStatus() {
      this.onHide();
      f7.views.main.router.navigate(
        {
          name: "sim-status",
          query: { imsi: this.simData.IMSI },
        },
        {
          // props: { simData: this.simData },
        }
      );
    },
    openServiceProfile() {
      this.onHide();
      f7.views.main.router.navigate({
        name: "service-profile",
        query: { imsi: this.simData.IMSI },
      });
    },
    openCustomerDetails() {
      this.onHide();
      f7.views.main.router.navigate({
        name: "customer-edit",
        //query: { customerCode: item.IMEI },
        query: {
          imsi: this.simData.IMSI,
        },
      });
    },
    openLocation() {
      this.onHide();
      f7.views.main.router.navigate({
        name: "sim-position",
        query: {
          imei: this.simData.IMEI,
          imsi: this.simData.IMSI,
          customer: this.simData.CustomerCode,
          user: this.simData.UserCode,
          name: this.simData.Name,
          id: this.simData.ID,
        },
      });
    },
    // openSimStatus() {
    //   this.onHide();
    //   f7.views.main.router.navigate({
    //     name: "sim-status",
    //     query: { imsi: this.simData.SimNO },
    //   });
    // },
    openTransfer() {
      this.onHide();
      f7.views.main.router.navigate(
        {
          name: "asset-transfer",
          query: {
            imsi: this.simData.IMSI,
          },
        },
        {
          props: { simData: this.simData },
        }
      );
    },
    openLastAlarms() {
      // console.log(this.simData);
      this.onHide();
      f7.views.main.router.navigate({
        name: "asset-last-alarms",
        query: {
          id: this.simData.ID,
          name: this.simData.Name,
          userId: this.simData.UserCode,
          IMEI: this.simData.IMEI,
        },
      });
    },
    openCommands() {
      this.onHide();

      f7.views.main.router.navigate({
        name: "sim-send-command",
        query: {
          iccid: this.simData.ICCID,
          imsi: this.simData.IMSI,
          imei: this.simData.IMEI,
          name: this.simData.Name,
          deviceType: this.simData.Product,
        },
      });
    },
    openCommandsHistory() {
      this.onHide();
      this.$store.commit("SET_TARGET_ASSET", this.simData);
      // console.log(this.simData);
      f7.views.main.router.navigate({
        name: "command-history",
        query: {
          imsi: this.simData.IMSI,
          imei: this.simData.IMEI,
          name: this.simData.Name,
          deviceType: this.simData.Product,
        },
      });
    },
    openTCPCommands() {
      this.onHide();
      f7.views.main.router.navigate({
        name: "tcp-sim-send-command",
        query: {
          imsi: this.simData.SimNO,
          imei: this.simData.IMEI,
          name: this.simData.Name,
          deviceType: this.simData.Product,
        },
      });
    },
    // openTCPCommandsHistory(){
    //   this.onHide()
    //   f7.views.main.router.navigate({
    //     name: 'tcp-command-history',
    //     query: { imsi: this.simData.SimNO, imei: this.simData.IMEI, name: this.simData.Name, deviceType: this.simData.Product }
    //   })
    // },
    openDeviceCheck() {
      this.onHide();
      this.$store.commit("SET_TARGET_ASSET", this.simData);
      f7.views.main.router.navigate({
        name: "device-check",
        query: { id: this.simData.ID, name: this.simData.Name },
      });
    },
    openDeviceConfig() {
      this.onHide();
      this.$store.commit("SET_TARGET_ASSET", this.simData);
      f7.views.main.router.navigate({
        name: "device-config",
        query: { id: this.simData.ID, name: this.simData.Name },
      });
    },
    openConnectivity() {
      this.onHide();
      this.$store.commit("SET_TARGET_ASSET", this.simData);
      f7.views.main.router.navigate({
        name: "sim-connectivity",
        query: { id: this.simData.ID, name: this.simData.Name },
      });
    },
    async openDialogActivation(isReactivate = false) {
      this.onHide();

      f7.progressbar.show("green");
      //get asset data
      /*let assetInfo = await QTAPI.GetAssetInfo({
        majortoken: this.MajorToken,
        imeis: simData.IMEI
      });
      await f7.utils.nextTick()
      if (!assetInfo) {
        f7.progressbar.hide();
        return
      }*/

      //get agent data and all solutions
      // let agentInfo = await QTAPI.GetPreEditCustomerInfo({
      //   MajorToken: this.MajorToken,
      //   MinorToken: this.MinorToken,

      //   Code: this.MajorToken,
      // });
      await f7.utils.nextTick();
      if (!agentInfo) {
        f7.progressbar.hide();
        return;
      }

      //get user data for correct activation link
      // let userInfo = await QTAPI.GetPreEditCustomerInfo({
      //   MajorToken: this.MajorToken,
      //   MinorToken: this.MinorToken,

      //   Code: this.simData.CustomerCode,
      // });
      // await f7.utils.nextTick();
      f7.progressbar.hide();
      if (!userInfo) {
        return;
      }

      let sspInfo = await QTAPI.GetSSP({
        //MajorToken: this.MajorToken,
        IMEI: this.simData.IMEI,
        DealerToken: this.MajorToken,
        ProductCode: this.simData.ProductCode,
      });
      if (!sspInfo) {
        return;
      }
      /*
      let sspInfo = await this.SSPService.getSSP1({
        DealerToken: this.MajorToken,
        IMEI: simData.IMEI,
        ProductCode: assetInfo.ProductCode
      });
      if (!sspInfo) {
        return;
      }
      await f7.utils.nextTick();
      f7.progressbar.hide();
      if (!sspInfo) {
        return;
      }*/

      let resActivation = await QTAPI.GetWebsiteList({
        Imei: this.simData.IMEI,
      });
      if (!resActivation) {
        return;
      }
      let activationDomain = resActivation.data?.Data[0]?.AURL;

      if (!activationDomain) {
        activationDomain = "https://activation.gps-secure.co/"; //APIMETHODS.GO_FOR_ACTIVATION;
      }

      //@activationOptionsArr for storing allowed solutions to activate
      //@activationDomain customer domain with activation pages
      let activationOptionsArr = [];
      /*let activationDomain
      if(userInfo.WebSites && userInfo.WebSites.length && userInfo.CustomerInfo && userInfo.CustomerInfo.WebSiteCode){
        //we get user website code from user data and search for this website details in websites array
        activationDomain = userInfo.WebSites.find(itm => itm.Code === userInfo.CustomerInfo.WebSiteCode)?.AURL

      }else if(agentInfo.WebSites && agentInfo.WebSites.length && agentInfo.CustomerInfo && agentInfo.CustomerInfo.WebSiteCode){
        //we get agent website code from user data and search for this website details in websites array
        activationDomain = agentInfo.WebSites.find(itm => itm.Code === agentInfo.CustomerInfo.WebSiteCode)?.AURL
      }*/

      if (agentInfo.CustomerInfo) {
        if (agentInfo.CustomerInfo._FIELD_NVAR1) {
          let permissionsArr = agentInfo.CustomerInfo._FIELD_NVAR1.split(",");

          if (
            isReactivate &&
            (this.simData.Solution == "Deactivate" ||
              this.simData.PayPlan == "Deactivate") &&
            (activationDomain == "https://activation.quiktrak.au/" ||
              activationDomain == "https://activation.al3rtgps.co/" ||
              activationDomain == "https://activation.autoprotect.live/" ||
              activationDomain == "https://activationcbhgroup.cartrack.co/" ||
              activationDomain == "https://activationisstrack.cartrack.co/" ||
              activationDomain == "https://activation.ispygps.co/" ||
              activationDomain == "https://activation.iss-secure.co/" ||
              activationDomain == "https://activation.witigps.com/" ||
              activationDomain == "https://activation.boatwatch.co/" ||
              activationDomain == "https://activation.nutechgps.com/" ||
              activationDomain == "https://activation.pillalogps.com/" ||
              activationDomain == "https://activation.smartostrichfleet.com/" ||
              activationDomain == "https://activation.blackboxgps.co/" ||
              activationDomain == "https://activation.tekamogps.co/" ||
              activationDomain == "https://activation.ufindgps.co/" ||
              activationDomain == "https://activation.vizngps.co/" ||
              activationDomain == "https://activation.wardengps.com/" ||
              activationDomain == "https://activation.gps-secure.co/")
          ) {
            //console.log(isReactivate)//https://activation.quiktrak.au/
            let reActivationDomain =
              activationDomain +
              `reactivation.php?MajorToken=${this.MajorToken}&MinorToken=${this.MinorToken}&DealerToken=${this.MajorToken}&imei=${this.simData.IMEI}&SolutionType=&DealerType=${userInfo.CustomerInfo.Type}`;
            openExternalUrl(reActivationDomain);
            return;
          } else if (
            isReactivate &&
            activationDomain != "https://activation.quiktrak.au/" &&
            activationDomain != "https://activation.al3rtgps.co/" &&
            activationDomain != "https://activation.autoprotect.live/" &&
            activationDomain != "https://activationcbhgroup.cartrack.co/" &&
            activationDomain != "https://activationisstrack.cartrack.co/" &&
            activationDomain != "https://activation.ispygps.co/" &&
            activationDomain != "https://activation.iss-secure.co/" &&
            activationDomain != "https://activation.witigps.com/" &&
            activationDomain != "https://activation.boatwatch.co/" &&
            activationDomain != "https://activation.nutechgps.com/" &&
            activationDomain != "https://activation.pillalogps.com/" &&
            activationDomain != "https://activation.smartostrichfleet.com/" &&
            activationDomain != "https://activation.blackboxgps.co/" &&
            activationDomain != "https://activation.tekamogps.co/" &&
            activationDomain != "https://activation.ufindgps.co/" &&
            activationDomain != "https://activation.vizngps.co/" &&
            activationDomain != "https://activation.wardengps.com/" &&
            activationDomain != "https://activation.gps-secure.co/"
          ) {
            f7.dialog.alert("Functionality under development", "Alert");
            return;
          } else if (isReactivate) {
            f7.dialog.alert(
              "Only Deactivated Assets can be Reactivated",
              "Alert"
            );
            return;
          }

          //if customer has permission to Replace device we add this option into @activationOptionsArr
          if (permissionsArr.indexOf("ReplaceUnit") > -1) {
            activationOptionsArr.push({
              value: "ReplaceUnit",
              name: this.$t("Replace"),
            });
          }
          //if customer has permission to Upgrade device we add this option into @activationOptionsArr
          if (permissionsArr.indexOf("UpgradeToLive") > -1) {
            activationOptionsArr.push({
              value: "UpgradeToLive",
              name: this.$t("Upgrade"),
            });
          }
          //we check each solution if it allowed to activate and if yes then add into @activationOptionsArr
          if (agentInfo.Solutions && agentInfo.Solutions.length) {
            agentInfo.Solutions.forEach((solution) => {
              //if(permissionsArr.indexOf(solution.Value) > -1){
              if (
                permissionsArr.indexOf(solution.Value) > -1 &&
                sspInfo?.Solutions.findIndex(
                  (el) => el.Code == solution.Value
                ) > -1
              ) {
                activationOptionsArr.push({
                  value: solution.Value,
                  name: solution.Name,
                });
              }
            });
          }
        }
        //we check if customer allowed to do Install Notice and add it into @activationOptionsArr
        if (
          agentInfo.CustomerInfo._FIELD_INT1 &&
          (agentInfo.CustomerInfo._FIELD_INT1 &
            AdminPermissionsEnum.InstallNotice) >
            0
        ) {
          activationOptionsArr.push({
            value: "InstallNotice",
            name: this.$t("InstallNotice"),
          });
        }
      }
      //if there are activation options we sort it into alphabet order
      if (activationOptionsArr.length) {
        activationOptionsArr = SortArrayByObjProps(activationOptionsArr, [
          { prop: "name", direction: 1 },
        ]);
      }
      //if no activation options we show alert
      if (!activationOptionsArr.length) {
        f7.dialog.alert(this.$t("NoActivationOptions"));
        return;
      }
      //creating html template of activation options
      let content = `
				<div class="list no-hairlines no-margin">
          <ul>${activationOptionsArr
            .map(
              (itm) =>
                `<li>
              <label class="item-radio item-content color-green">
                <input type="radio" name="radio-activation" value="${itm.value}" checked />
                <i class="icon icon-radio"></i>
                <div class="item-inner">
                  <div class="item-title">${itm.name}</div>
                </div>
              </label>
						</li>`
            )
            .join("")}
          </ul>
        </div>`;

      //show activation dialog
      f7.dialog.confirm(
        content,
        this.$t("SelectActivationOption"),
        (dialog) => {
          let selected = dialog.$el
            .find('[name="radio-activation"]:checked')
            .val();
          //add backslash to the end of activation domain if there is no such
          if (activationDomain[activationDomain.length - 1] !== "/") {
            activationDomain += "/";
          }
          //check selected option
          switch (selected) {
            //Install Notice always use one url
            case "InstallNotice":
              activationDomain =
                ApiMethods.GO_FOR_INSTALL_NOTICE +
                `install-notice/?MajorToken=${this.MajorToken}&MinorToken=${this.MinorToken}&DealerToken=${this.MajorToken}&imei=${this.simData.IMEI}&DealerType=${this.UserInfo.CustomerType}`;
              break;
            //UpgradeToLive && ReplaceUnit has separate activation pages but on agent domain
            case "UpgradeToLive":
              activationDomain += `upgrade-to-live.php?MajorToken=${this.MajorToken}&MinorToken=${this.MinorToken}&DealerToken=${this.MajorToken}&imeis=${this.simData.IMEI}&SolutionType=Track&DealerType=${this.UserInfo.CustomerType}`;
              break;
            case "ReplaceUnit":
              activationDomain += `upgrade.php?MajorToken=${this.MajorToken}&MinorToken=${this.MinorToken}&DealerToken=${this.MajorToken}&imeis=${this.simData.IMEI}&DealerType=${this.UserInfo.CustomerType}`;
              break;
            case "ReActivate":
              activationDomain += `activate.php?MajorToken=${this.MajorToken}&MinorToken=${this.MinorToken}&DealerToken=${this.MajorToken}&imei=${this.simData.IMEI}&DealerType=${this.UserInfo.CustomerType}`;
              break;
            case "Deactivate":
              this.openDialogDeactivation();
              return;
            //break;
            //and for all solutions selected we use one url
            default:
              let isActivated =
                +moment(this.simData.SuspendDate, "DD/MM/YYYY").valueOf() -
                +moment().valueOf();
              if (
                !isNaN(isActivated) &&
                isActivated > 0 &&
                this.simData.Solution != "NonActivated" &&
                this.simData.PayPlan != "Preactivation"
              ) {
                f7.dialog.alert(
                  "This device has already been activated.",
                  "Alert"
                );
                return;
              } else if (
                !isReactivate &&
                (this.simData.Solution == "Deactivate" ||
                  this.simData.PayPlan == "Deactivate")
              ) {
                f7.dialog.alert(
                  "Deactivated assets can not be activated.",
                  "Alert"
                );
                return;
              } else if (!this.simData.Solution) {
                f7.dialog.alert(
                  "Asset can not be activated due to empty Solution field.",
                  "Alert"
                );
                return;
              } else if (!this.simData.PayPlan) {
                f7.dialog.alert(
                  "Asset can not be activated due to empty Service plan field.",
                  "Alert"
                );
                return;
              }
              activationDomain += `?MajorToken=${this.MajorToken}&MinorToken=${this.MinorToken}&DealerToken=${this.MajorToken}&imei=${this.simData.IMEI}&SolutionType=${selected}&DealerType=${this.UserInfo.CustomerType}`;
          }

          //open activation page on new tab
          openExternalUrl(activationDomain);
        }
      );
    },

    async openDialogReActivation() {
      this.onHide();

      //get agent data and all solutions
      /*f7.progressbar.show('green');
      let agentInfo = await QTAPI.GetPreEditCustomerInfo({
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,

        Code: this.MajorToken
      })
      await f7.utils.nextTick()
      if (!agentInfo) {
        f7.progressbar.hide();
        return
      }

      //get user data for correct activation link
      let userInfo = await QTAPI.GetPreEditCustomerInfo({
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,

        Code: this.simData.CustomerCode
      })
      await f7.utils.nextTick()
      f7.progressbar.hide();
      if (!userInfo) {
        return
      }

      //@activationOptionsArr for storing allowed solutions to activate
      //@activationDomain customer domain with activation pages
      let activationOptionsArr = []
      let activationDomain
      if(userInfo.WebSites && userInfo.WebSites.length && userInfo.CustomerInfo && userInfo.CustomerInfo.WebSiteCode){
        //we get user website code from user data and search for this website details in websites array
        activationDomain = userInfo.WebSites.find(itm => itm.Code === userInfo.CustomerInfo.WebSiteCode)?.AURL

      }else if(agentInfo.WebSites && agentInfo.WebSites.length && agentInfo.CustomerInfo && agentInfo.CustomerInfo.WebSiteCode){
        //we get agent website code from user data and search for this website details in websites array
        activationDomain = agentInfo.WebSites.find(itm => itm.Code === agentInfo.CustomerInfo.WebSiteCode)?.AURL
      }

      //if customer has no website we use default activation domain
      if(!activationDomain){
        activationDomain = ApiMethods.GO_FOR_ACTIVATION;
      }

      if(agentInfo.CustomerInfo){
        if(agentInfo.CustomerInfo._FIELD_NVAR1){
          let permissionsArr = agentInfo.CustomerInfo._FIELD_NVAR1.split(',')
          //if customer has permission to Replace device we add this option into @activationOptionsArr
          if(permissionsArr.indexOf('ReplaceUnit') > -1){
            activationOptionsArr.push({
              value: 'ReplaceUnit',
              name: this.$t('Replace')
            })
          }
          //if customer has permission to Upgrade device we add this option into @activationOptionsArr
          if(permissionsArr.indexOf('UpgradeToLive') > -1){
            activationOptionsArr.push({
              value: 'UpgradeToLive',
              name: this.$t('Upgrade')
            })
          }
          //we check each solution if it allowed to activate and if yes then add into @activationOptionsArr
          if(agentInfo.Solutions && agentInfo.Solutions.length){
            agentInfo.Solutions.forEach(solution => {
              if(permissionsArr.indexOf(solution.Value) > -1){
                activationOptionsArr.push({
                  value: solution.Value,
                  name: solution.Name
                })
              }
            })
          }
        }
        //we check if customer allowed to do Install Notice and add it into @activationOptionsArr
        if(agentInfo.CustomerInfo._FIELD_INT1 && (agentInfo.CustomerInfo._FIELD_INT1 & AdminPermissionsEnum.InstallNotice) > 0){
          activationOptionsArr.push({
            value: 'InstallNotice',
            name: this.$t('InstallNotice')
          })
        }
      }
      //if there are activation options we sort it into alphabet order
      if(activationOptionsArr.length){
        activationOptionsArr = SortArrayByObjProps(activationOptionsArr, [{prop: 'name', direction: 1}])
      }
      //if no activation options we show alert
      if(!activationOptionsArr.length){
        f7.dialog.alert(this.$t('NoActivationOptions'))
        return
      }*/
      //creating html template of activation options
      let content = `
				<div class="list no-hairlines no-margin">
          <ul>
            <li>
                <label class="item-radio item-content no-padding color-red">
                  <input type="radio" name="radio-reactivate" value="renewal" checked />
                  <i class="icon icon-radio"></i>
                  <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                    <div class="item-title text-color-black">${this.$t(
                      "Renewal"
                    )}</div>
                  </div>
                </label>
              </li>
              <li>
                <label class="item-radio item-content no-padding color-red">
                  <input type="radio" name="radio-reactivate" value="reactivate" />
                  <i class="icon icon-radio"></i>
                  <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                    <div class="item-title text-color-black">${this.$t(
                      "Reactivate"
                    )}</div>
                  </div>
                </label>
              </li>
          </ul>
        </div>`;

      //show activation dialog
      f7.dialog.confirm(
        content,
        this.$t("SelectReactivationOption"),
        async (dialog) => {
          let selected = dialog.$el
            .find('[name="radio-reactivate"]:checked')
            .val();
          //add backslash to the end of activation domain if there is no such
          /*if(activationDomain[activationDomain.length -1] !== "/"){
          activationDomain += '/'
        }*/
          //check selected option
          switch (selected) {
            //Install Notice always use one url
            case "renewal":
              /*let resActivation = await this.$store.dispatch("GET_WEBSITES", {
              Imei: this.selectedAsset[0].IMEI
            });
            if (!resActivation) {
              return;
            }
            await f7.utils.nextTick();
            f7.progressbar.hide();
            if (!resActivation) {
              return;
            }*/
              /*let result = await QTAPI.GetWebsiteList({
              Imei: this.simData.IMEI
            });
            console.log(result, this.simData.IsAutoSuspend)
            if(result.data?.Data[0]?.IsRenew != 'ON'){
              f7.dialog.alert("This asset is not renewable", 'Alert');
              return
            }*/
              //  console.log(this.simData.IsAutoSuspend);
              if (this.simData.IsAutoSuspend != 1) {
                f7.dialog.alert(
                  "This asset is not renewable. Only assets with autosuspend option have this ability",
                  "Alert"
                );
                return;
              }

              f7.views.main.router.navigate({
                name: "renewal",
                query: {
                  imsi: this.simData.SimNO,
                  imei: this.simData.IMEI,
                  name: this.simData.Name,
                  deviceType: this.simData.Product,
                },
              });
              //this.fakeRenewal();

              break;
            //UpgradeToLive && ReplaceUnit has separate activation pages but on agent domain

            default:
            // this.openDialogActivation(true);
          }

          //open activation page on new tab
        }
      );
    },
    openDialogReset() {
      this.onHide();
      let content = `
     
				<div class="list media-list no-hairlines no-hairlines-between no-margin">
          <ul>
            <li>
              <label class="item-radio item-content no-padding color-red">
                <input type="radio" name="radio-reset" value="mileage" checked />
                <i class="icon icon-radio"></i>
                <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                  <div class="item-title text-color-black">${this.$t(
                    "Mileage"
                  )}</div>
                 </div>
              </label>
						</li>
						<li>
              <label class="item-radio item-content no-padding color-red">
                <input type="radio" name="radio-reset" value="hours" />
                <i class="icon icon-radio"></i>
                <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                  <div class="item-title text-color-black">${this.$t(
                    "LaunchHours"
                  )}</div>
                 </div>
              </label>
						</li>
						<li>
              <label class="item-radio item-content no-padding color-red">
                  <input type="radio" name="radio-reset" value="history"/>
                  <i class="icon icon-radio"></i>
                  <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                      <div class="item-title text-color-black">${this.$t(
                        "History"
                      )}</div>
                   </div>
              </label>
            </li>
          </ul>
        </div>`;
      let content2 = `
        <div class="custom-modal-text">${this.$t("ResetPrompt")}</div>
`;
      f7.dialog.confirm(content, this.$t("Reset"), async (dialog) => {
        let selected = dialog.$el.find('[name="radio-reset"]:checked').val();
        let data = {
          MinorToken: this.MinorToken,
          MajorToken: this.MajorToken,
          IMEI: this.simData.IMEI,
        };
        f7.dialog.confirm(content2, this.$t("Reset"), async (dialog) => {
          f7.progressbar.show("green");
          const result = await QTAPI.resetInitialValue(data, selected);
          f7.progressbar.hide();
          if (!result) {
            return;
          }
          f7.notification
            .create({
              subtitle: this.$t("Success"),
              text: this.$t("ResetSuccess"),
              closeTimeout: 3000,
            })
            .open();
        });
      });
    },
    openDialogDeactivation() {
      this.onHide();
      let content = `
        <div class="custom-modal-text">${this.$t("DeactivationPrompt", {
          imei: this.simData.IMEI,
        })}</div>
				<div class="list media-list no-hairlines no-hairlines-between no-margin">
          <ul>
            <li>
              <label class="item-radio item-content no-padding color-red">
                <input type="radio" name="radio-deactivate" value="deactivate" checked />
                <i class="icon icon-radio"></i>
                <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                  <div class="item-title text-color-black">${this.$t(
                    "Deactivate"
                  )}</div>
                  <div class="item-text size-12 line-height-14">${this.$t(
                    "DeactivateDescription"
                  )}</div>
                </div>
              </label>
						</li>
						<li>
              <label class="item-radio item-content no-padding color-red">
                <input type="radio" name="radio-deactivate" value="stock" />
                <i class="icon icon-radio"></i>
                <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                  <div class="item-title text-color-black">${this.$t(
                    "Stock"
                  )}</div>
                  <div class="item-text size-12 line-height-14">${this.$t(
                    "DeactivateStockDescription"
                  )}</div>
                </div>
              </label>
						</li>
						<li>
              <label class="item-radio item-content no-padding color-red">
                  <input type="radio" name="radio-deactivate" value="terminate"/>
                  <i class="icon icon-radio"></i>
                  <div class="item-inner no-margin padding-vertical-half no-padding-right no-padding-left">
                      <div class="item-title text-color-black">${this.$t(
                        "Terminate"
                      )}</div>
                      <div class="item-text size-12 line-height-14">${this.$t(
                        "DeactivateTerminateDescription"
                      )}</div>
                  </div>
              </label>
            </li>
          </ul>
        </div>`;

      f7.dialog.confirm(
        content,
        this.$t("SelectDeactivationOption"),
        async (dialog) => {
          let selected = dialog.$el
            .find('[name="radio-deactivate"]:checked')
            .val();
          let data = {
            MinorToken: this.MinorToken,
            MajorToken: this.MajorToken,
            IMEI: this.simData.IMEI,
            type: !selected ? "deactivate" : selected,
          };

          f7.progressbar.show("green");
          const result = await QTAPI.AssetDeactivation(data);
          f7.progressbar.hide();
          if (!result) {
            return;
          }
          f7.notification
            .create({
              subtitle: this.$t("Success"),
              text: this.$t("DeactivationSuccess"),
              closeTimeout: 3000,
            })
            .open();
        }
      );
    },
    async openSendActLetter() {
      this.onHide();

      f7.progressbar.show("green");
      let fullCustomerData = await QTAPI.GetPreEditCustomerInfo({
        MajorToken: this.MajorToken,
        MinorToken: this.MinorToken,

        Code: this.simData.CustomerCode,
      });
      f7.progressbar.hide();
      if (!fullCustomerData) return;

      if (
        fullCustomerData?.CustomerInfo?.Type === CustomerTypes.Agent ||
        fullCustomerData?.CustomerInfo?.Type === CustomerTypes.Dealer
      ) {
        f7.dialog.alert(
          this.$t("ActivationLetterErrorAccType"),
          this.$t("CustomerTypeError")
        );
        return;
      }

      f7.dialog.confirm(
        this.$t("SendActivationLetterDescription", {
          name: this.simData.Owner,
        }),
        this.$t("SendActivationLetter"),
        async (dialog) => {
          f7.progressbar.show("green");
          let result = await QTAPI.SendAssetActivationLetter({
            MajorToken: this.MajorToken,
            MinorToken: this.MinorToken,

            IMEI: this.simData.IMEI,
          });
          f7.progressbar.hide();

          if (!result) return;

          f7.notification
            .create({
              subtitle: this.$t("Success"),
              text: this.$t("LetterSent"),
            })
            .open();
        }
      );
    },

    onHide() {
      this.$emit("update:isOpened", false);
    },
  },
  watch: {
    isOpened(newVal) {
      if (newVal) {
        if (this.Permissions && Object.keys(this.Permissions).length > 0) {
          this.isShowCommands =
            this.Permissions.SMS > 0 &&
            (this.Permissions.SMS_SEND_CUSTOM > 0 ||
              this.Permissions.SMS_SEND_SYSTEM > 0);

          this.isShowCommandsHistory =
            this.Permissions.SIM_SHOW_SMS_HISTORY > 0;
          this.isShowSimStatus = this.Permissions.SIM_PROFILE > 0;
          this.isShowReports = this.Permissions.REPORTS > 0;

          this.isShowConnectivity =
            this.Permissions.SIM_SHOW_SESSIONS > 0 &&
            this.Permissions.SIM_SHOW_HLR > 0;
        }
      }
    },
  },

  async mounted() {
    // this.getCSP();
  },

  beforeDestroy() {},
};
</script>

<style  lang="scss" scoped>
.sim-menu {
  padding: 20px 10px 0px 10px;
}

.sim-menu__item {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  span {
    display: flex;
    font-size: var(--f7-actions-button-font-size);
    line-height: var(--f7-actions-button-height);
  }
}
</style>