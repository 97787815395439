<template>
  <f7-page name="sim-status" class="bg-color-lightgray">
    <!-- Top Navbar -->
    <f7-progressbar
      v-if="isLoading"
      infinite
      color="green"
      class="progressbar-top"
    ></f7-progressbar>
    <f7-navbar :sliding="false">
      <f7-nav-left back-link="Back"> </f7-nav-left>
      <!--  <f7-nav-right>
        <label :for="inputSubmitId" class="link icon-only">
          <i class="icon f7-icons">checkmark_alt</i>
        </label>
      <f7-link @click="openDialogChangeStatus(simInfo)" icon="icon-notification text-color-red" :class="{disabled: isHandled}"></f7-link>
        <f7-link :href="`/asset-position/?name=${ simInfo.AS_NAME }&imei=${ simInfo.EHLOG_AS_IMEI }`" icon="icon-position"></f7-link>
      </f7-nav-right>-->
      <f7-nav-right>
        <f7-link @click="setCSP">
          <i class="icon-header-apply size-20"></i>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar position="top" class>
      <f7-nav-title sliding>{{ $t("ServiceProfile") }}</f7-nav-title>
    </f7-toolbar>
    <f7-toolbar position="bottom" tabbar icons>
      <f7-link
        v-tooltip="'Dashboard'"
        href="/dashboard/tab-dashboard/"
        icon-only
        tab-link="#tab-1"
      >
        <i class="icon-reports size-18 text-color-green"></i>
      </f7-link>
      <f7-link
        v-tooltip="'SIM List'"
        href="/sim-list/"
        icon-only
        tab-link="#tab-2"
      >
        <i class="icon-sim size-18 text-color-blue"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Commands'"
        href="/commands/"
        icon-only
        tab-link="#tab-3"
      >
        <i class="icon-commands size-18 text-color-lilac"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Users'"
        href="/users/"
        icon-only
        tab-link="#tab-4"
      >
        <i class="icon-users size-18 text-color-orange"></i>
      </f7-link>
      <f7-link
        v-tooltip="'Summary'"
        href="/summary/"
        icon-only
        tab-link="#tab-5"
      >
        <i class="icon-data size-18 text-color-red"></i>
      </f7-link>
    </f7-toolbar>

    <f7-list
      class="page-content"
      style="padding: 0; margin-top: 0; padding-bottom: 150px"
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
    >
      <f7-list-item
        v-for="item in CSPList"
        :key="item.Code"
        radio
        radio-icon="start"
        :title="item.Name"
        name="csp-radio-start"
        :value="item"
        :checked="item.Code === selectedCSP?.Code"
        @change="onCSPChange(item)"
      />
    </f7-list>
  </f7-page>
</template>

<script>
import { QTAPI } from "../../services/qt-api";

import { mapGetters } from "vuex";

export default {
  name: "sim-status",

  props: {
    f7router: Object,
    f7route: Object,
    simData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      isLoading: false,
      simData: null,
      selectedCSP: null,
      CSPList: []
    };
  },

  watch: {
   
  },

  computed: {
    ...mapGetters(["MajorToken", "MinorToken", "UserInfo", "SimCommands"]),
    simIconColorClass() {
      let ret = "text-color-lightgray";
      switch (this.simData.State) {
        case "Productive":
        case "TestProductive":
          ret = "text-color-green";
          break;
        case "Suspended":
          ret = "text-color-orange";
          break;
        case "Deleted":
          ret = "text-color-red";
          break;
      }
      return ret;
    },
  },

  async mounted() {
    await this.getSimStatus();
    await this.getCSP();
  },

  methods: {
    async getSimStatus() {
      if (!this.f7route.query.imsi) {
        this.isNoData = true;
        f7.dialog.alert(this.$t("MissingIMSI", this.$t("Error")));
        return;
      }

      this.isLoading = true;
      let data = {
        IMSIs: [this.f7route.query.imsi],
      };
      let response = await QTAPI.GetSimStatus(data);
      this.isLoading = false;
      
      if (!response || !response.length) {
        this.isNoData = true;
        f7.dialog.alert(
          this.$t("IMSINotFound", { imsi: data.IMSIs }),
          this.$t("Error")
        );
        return;
      }
     
      this.simData = response[0];

    
    },
    async getCSP() {
      let csp = await QTAPI.GetCSP({});

      if (!csp) {
        return;
      }
      this.CSPList = csp.Data;

      this.simCSP = this.CSPList.find(
        (item) => item.Code === this.simData?.ServiceProfileCode
      );

      this.keyCSPList++;

      console.log('this.CSPList' , this.CSPList)

      this.selectedCSP = this.CSPList.find(
        (item) => item.Code === this.simData?.ServiceProfileCode
      );
    },
 
    onCSPChange(item) {
      this.selectedCSP = item;
    },
    async setCSP() {
      let self = this;

      this.isLoading = true;
      let data = {
        IMSIs: [this.simData.IMSI],
        ServiceProfileCode: this.selectedCSP.Code,
      };
      let response = await QTAPI.SetCSP(data);

      this.isLoading = false;
      if (response) {
        f7.notification
          .create({
            subtitle: this.$t("Success"),
            text: this.$t("DataUpdated"),
          })
          .open();

        f7.views.main.router.refreshPage();
        f7.popup.close();
      }
    },
  },
};
</script>

<style>
.simple-list li:after,
.links-list a:after,
.list .item-inner:after {
  display: none;
}
</style>