<template>
  <div>
    <f7-list
      class="no-margin-top no-hairline-bottom"
      media-list
      no-hairlines
      no-hairlines-between
      dividers-ios
      strong
      outline
      inset
    >
      <f7-list-item
        class="item-input custom-smart-select-wrapper"
        :title="$t('TimePeriod')"
        smart-select
        :smart-select-params="{
          openIn: 'popover',
          closeOnSelect: true,
          formColorTheme: 'green',
        }"
      >
        <template #media>
          <i class="icon-date size-20 text-color-lilac display-flex"></i>
        </template>

        <select
          name="timeperiod"
          v-model="selectedPeriod"
          @change="changePeriod"
        >
          <option value="day" selected>Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </f7-list-item>
    </f7-list>
    <f7-actions-group
      v-if="isTotalStatsLoaded"
      class="row total-summary no-gap"
    >
      <f7-actions-button
        v-for="(item, index) in updatedBlocks"
        class="col-50 margin-bottom"
        :key="`card-devices-solution-stat-${index}`"
      >
        <div :class="`actions-icon bg-color-${item.bgColor} margin-bottom`">
          <i
            :class="`icon-${item.icon} size-20 text-color-white display-flex`"
          ></i>
        </div>
        <span class="actions-title">{{ item.name }}</span>
        <span>{{ item.value }}</span>
      </f7-actions-button>
    </f7-actions-group>
    <f7-list v-else-if="!isTotalStatsLoaded" no-hairlines>
      <f7-list-item
        header="header"
        title="simInfo.IMSI"
        class="skeleton-text skeleton-effect-blink"
        v-for="n in 4"
        :key="n"
      >
        <template #media>
          <f7-skeleton-block
            style="width: 40px; height: 40px; border-radius: 100%"
          ></f7-skeleton-block>
        </template>
      </f7-list-item>
    </f7-list>

    <f7-card outline header-divider footer-divider title="Service">
      <f7-card-content>
        <bar-chart :bar="cspData" />
      </f7-card-content>
    </f7-card>
    <f7-card outline header-divider footer-divider title="SIM Inventory">
      <f7-card-content>
        <bar-chart :bar="stateData" />
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
import { QTAPI } from "../../../services/qt-api";
import BarChart from "../components/bar-chart";

export default {
  name: "dashboard",
  components: {
    BarChart,
  },
  data() {
    return {
      isLoading: false,
      isTotalStatsLoaded: false,
      totalStats: [],
      updatedBlocks: [],
      selectedPeriod: "day",
      sessionData: {
        day: 0,
        month: 0,
        week: 0,
        year: 0,
      },
      onlineNumbersData: {
        day: 0,
        month: 0,
        week: 0,
        year: 0,
      },
      smsUsage: {
        day: 0,
        month: 0,
        week: 0,
        year: 0,
      },
      dataUsage: {
        day: 0,
        month: 0,
        week: 0,
        year: 0,
      },
      totalStats: [
        { bgColor: "green", icon: "activation", name: "Data Sessions", key: "sessionData" },
        { bgColor: "orange", icon: "mail", name: "SMS Usage", key: "smsUsage" },
        { bgColor: "blue", icon: "data", name: "Data Usage", key: "dataUsage" },
        { bgColor: "red", icon: "other-gprs", name: "Online Numbers", key: "onlineNumbersData" },
      ],
      stateData: [],
      cspData: [],
    };
  },

  async mounted() {
    this.getM2MDashbord();
  },

  methods: {
    changePeriod() {
      this.updateBlocks();
    },
    updateBlocks() {
      let periodValueMapping = {
        day: "day",
        weekly: "week",
        monthly: "month",
        yearly: "year",
      };

      let currentPeriodKey = periodValueMapping[this.selectedPeriod];

      //console.log(currentPeriodKey)
      if (
        !this.sessionData[currentPeriodKey] ||
        !this.onlineNumbersData[currentPeriodKey]
      ) {
        console.error("No data available for the selected period");
        return;
      }

     
      this.updatedBlocks = this.totalStats.map((stat, index) => {
         let value = this[stat.key][currentPeriodKey];
        return { ...stat, value: value };
      });

     // console.log('this.updatedBlocks', this.updatedBlocks)
      this.isTotalStatsLoaded = true;
    },
    async getM2MDashbord() {
      this.isLoading = true;
      let result = await QTAPI.getM2MDashboard();
      this.isLoading = false;

      if (result) {
        let total = result.Data;
        this.sessionData = {
          day: total.SessionDay,
          month: total.SessionMonth,
          week: total.SessionWeek,
          year: total.SessionYear,
        };

        this.onlineNumbersData = {
          day: total.OnlineNumbersDay,
          month: total.OnlineNumbersMonth,
          week: total.OnlineNumbersWeek,
          year: total.OnlineNumbersYear,
        };
        this.smsUsage = {
          day:
            total.SMSMODay + total.SMSMTDay
              ? total.SMSMODay + total.SMSMTDay
              : "No data",
          month:
            total.SMSMOMonth + total.SMSMTMonth
              ? total.SMSMOMonth + total.SMSMTMonth
              : "No data",
          week:
            total.SMSMOWeek + total.SMSMTWeek
              ? total.SMSMOWeek + total.SMSMTWeek
              : "No data",
          year:
            total.SMSMOYear + total.SMSMTYear
              ? total.SMSMOYear + total.SMSMTYear
              : "No data",
        };
       // console.log('this.smsUsage', this.smsUsage)
        this.dataUsage = {
          day: total.DataDay ? (total.DataDay / 1048576).toFixed(0) : "No data",
          month: total.DataMonth
            ? (total.DataMonth / 1048576).toFixed(0)
            : "No data",
          week: total.DataWeek
            ? (total.DataWeek / 1048576).toFixed(0)
            : "No data",
          year: total.DataYear
            ? (total.DataYear / 1048576).toFixed(0)
            : "No data",
        };

        const newCspArr = [];
        const cspArr = Object.keys(total.ServiceProfiles);
        cspArr.forEach((element) => {
          newCspArr.push({
            name: element,
            value: total.ServiceProfiles[element],
          });
        });
        this.cspData = newCspArr;

        const newStateArr = [];
        const stateArr = Object.keys(total.Status);
        stateArr.forEach((element) => {
          newStateArr.push({
            name: element,
            value: total.Status[element],
          });
        });
        this.stateData = newStateArr;

       // console.log('this.stateData', this.stateData)
      }

      this.$nextTick(() => {
        this.updateBlocks();
      });
    },
  },
};
</script>
<style>
.md .custom-smart-select-wrapper .item-after:after {
}
</style>
 